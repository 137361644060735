import React from "react";
import { createTheme } from "@material-ui/core/styles";
// import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from "@material-ui/styles";

const theme = createTheme({
  palette: {
    primary: {
      // main: "#0E5B9F",
      main: '#41b60b',
      contrastText: "#fff",
    },
    secondary: {
      main: "rgba(128,128,128,1)",
    },
  },
});

const DefaultThemeProvider = (props: any) => (
  <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
);

export default DefaultThemeProvider;
