import React from "react";
import styles from "./style.module.scss";
import ModalWrapperOpenProp from "@Components/common/ModalWrapperOpenProp";
import { Box, Typography, Tooltip, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import phoneCallIcon from "@Assets/icons/event/Phone.svg";
import qrCodeIcon from "@Assets/icons/event/qrcode.svg";

interface IProps {
  open?: any;
  onClose: any;
  children?: any;
  styles?: any;
  onClick?: any;
  guidePostsList?: any;
}

const ModalSupportService = (props: IProps) => {
  const {
    open,
    onClose,
    children,
    guidePostsList,
  } = props;

  return (
    <ModalWrapperOpenProp
      open={open}
      onClose={onClose}
      styles={{
        overflowY: "scroll",
        height: "90%",
        marginLeft: "20px",
        marginRight: "20px",
        padding: "0",
        maxWidth: "1292px",
      }}>
      <Box className={styles.Wrapper}>
        <Box className={styles.SupportInfoWrapper}>
          <Box className={styles.SupportInfoHeader}>
            <Typography className={styles.SupportInfoTitle}>Hỗ trợ</Typography>
          </Box>
          <Box className={styles.SupportInfoBody}>
            <Typography className={styles.SupportInfoText}>
              Từ Thứ Hai - Thứ Sáu, 9:00 sáng - 6:00 chiều
            </Typography>
            <Box className={styles.ContactInfoWrapper}>
              <img src={phoneCallIcon} alt="phone-call" />
              <Typography className={styles.ContactInfoPhoneNumber}>
                08 6868 5247
              </Typography>
            </Box>
            <div className={styles.QrCode}>
              <img src={qrCodeIcon} alt="QrCode" />
            </div>
          </Box>
        </Box>
        <Box className={styles.GuideWrapper}>
          <Box className={styles.GuideHeader}>
            <Typography className={styles.GuideTitle}>Hướng dẫn</Typography>
            <Tooltip title="Đóng" className="header-close-button">
              <IconButton component="span" onClick={onClose}>
                <CloseIcon style={{ color: "#000" }} />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className={styles.GuideBody}>
            {/* <Typography className={styles.GuideText}>
              Xin chào! Hãy bắt đầu làm quen với NineEvent thông qua các video
              hướng dẫn đơn giản!
            </Typography> */}
            <div className={styles.VideosWrapper}>
              {guidePostsList?.map((post: any) => (
                <a
                  key={post?.id}
                  className={styles.VideoItem}
                  href="https://event.9code.vn/how-to-use.html"
                  target="_blank"
                  >
                  <div className={styles.VideoThumbnailWrapper}>
                    <img
                      src={post?.thumbnail}
                      alt=""
                      className={styles.VideoThumbnailImg}
                    />
                  </div>
                  <Typography className={styles.VideoTitle}>
                    {post?.title}
                  </Typography>
                </a>
              ))}
            </div>
          </Box>
        </Box>
      </Box>
    </ModalWrapperOpenProp>
  );
};

export default ModalSupportService;
