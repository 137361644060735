import { notificationOpenAction } from "@Actions/Notification/action";
import {
  addEventUserErrorAction,
  addEventUserSuccessAction,
  deleteUserEventErrorAction,
  deleteUserEventSuccessAction,
  getEventPermissionErrorAction,
  getEventPermissionSuccessAction,
  getListEventRoleErrorAction,
  getListEventRoleSuccessAction,
  getListEventUserErrorAction,
  getListEventUserSuccessAction,
  updateUserEventErrorAction,
  updateUserEventSuccessAction,
} from "@Actions/UserEvent/action";
import actionTypes from "@Actions/UserEvent/action-types";
import baseApi from "@Apis/UserEvent/api";
import { NOTIFICATION_TYPE, TYPE_REFRESH_DATA } from "@Constants/common";
import { call, put, takeLatest } from "redux-saga/effects";

export function* addEventUser({
  data,
  callbackSuccess,
  callbackError,
}: any): any {
  try {
    const result = yield call(baseApi.addEventUser, data);
    yield put(addEventUserSuccessAction(result));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
        message: "Thành Công",
        typeRefreshData: TYPE_REFRESH_DATA.ADD_USER_REFRESH,
        description: "Tạo thành công ! ",
      })
    );
    yield callbackSuccess();
  } catch (err) {
    let temp = err as any;
    yield put(addEventUserErrorAction(err));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Lỗi",
        description: temp?.response?.data?.message || "Lỗi chưa xác định",
      })
    );
    yield callbackError();
  }
}

export function* getListEventUser({ prams, callbackSuccess }: any): any {
  try {
    const result = yield call(baseApi.getListEventUser, prams);
    // console.log(result, "result");

    yield put(getListEventUserSuccessAction(result));
    yield callbackSuccess(result);
  } catch (err) {
    // handle annotation
    let temp = err as any;

    yield put(getListEventUserErrorAction(err));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Lỗi",
        description: temp?.response?.data?.message || "Lỗi chưa xác định",
      })
    );
  }
}

export function* getListEventRole({ prams, callbackSuccess }: any): any {
  try {
    const result = yield call(baseApi.getListEventRole, prams);
    yield put(getListEventRoleSuccessAction(result));
    yield callbackSuccess(result);
  } catch (err) {
    let temp = err as any;
    yield put(getListEventRoleErrorAction(err));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Lỗi",
        description: temp?.response?.data?.message || "Lỗi chưa xác định",
      })
    );
  }
}

export function* updateEventUser({
  selectedUserID,
  selectedRole,
  callbackSuccess,
}: any): any {
  try {
    const result = yield call(
      baseApi.updateEventUser,
      selectedUserID,
      selectedRole
    );
    yield put(updateUserEventSuccessAction(result));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
        message: "Thành Công",
        typeRefreshData: TYPE_REFRESH_DATA.UPDATE_USER_REFRESH,
        description: "Cập nhật thành công ! ",
      })
    );
    yield callbackSuccess(result);
  } catch (err) {
    let temp = err as any;

    yield put(updateUserEventErrorAction(err));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Lỗi",
        description: temp?.response?.data?.message || "Lỗi chưa xác định",
      })
    );
  }
}

export function* deleteEventUser({
  selectedUserID,
  callbackSuccess,
}: any): any {
  try {
    const result = yield call(baseApi.deleteEventUser, selectedUserID);
    yield put(deleteUserEventSuccessAction(result));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
        message: "Thành Công",
        typeRefreshData: TYPE_REFRESH_DATA.DELETE_USER_REFRESH,
        description: "Xoá thành công ! ",
      })
    );
    yield callbackSuccess(result);
  } catch (err) {
    let temp = err as any;
    yield put(deleteUserEventErrorAction(err));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Lỗi",
        description: temp?.response?.data?.message || "Lỗi chưa xác định",
      })
    );
  }
}

export function* getListEventPermission({ prams, callbackSuccess }: any): any {
  try {
    const result = yield call(baseApi.getEventPermission, prams);

    yield put(getEventPermissionSuccessAction(result));
    yield callbackSuccess(result);
  } catch (err) {
    let temp = err as any;
    yield put(getEventPermissionErrorAction(err));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Lỗi",
        description: temp?.response?.data?.message || "Lỗi chưa xác định",
      })
    );
  }
}

export default function* EventUserSaga() {
  yield takeLatest(actionTypes?.ADD_USER_EVENT_START, addEventUser);
  yield takeLatest(actionTypes?.GET_LIST_USER_EVENT_START, getListEventUser);
  yield takeLatest(actionTypes?.GET_LIST_ROLE_EVENT_START, getListEventRole);
  yield takeLatest(actionTypes?.UPDATE_USER_EVENT_START, updateEventUser);
  yield takeLatest(actionTypes?.DELETE_USER_EVENT_START, deleteEventUser);
  yield takeLatest(
    actionTypes?.GET_PERMISSION_EVENT_START,
    getListEventPermission
  );
}
