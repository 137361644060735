//Lib
import React, { useEffect   } from "react";
//Styled
import { EmptyLayoutWrapper } from "./styled";
interface IProps {
  children: React.ReactNode;
}
const RECAPTCHA_TOKEN =
  process.env.REACT_APP_RECAPTCHA_TOKEN || process.env.RECAPTCHA_TOKEN;
const DefaultLayout = (props: IProps) => {
  const { children } = props;

  
  // useEffect(() => {
  //   const { label }:any = props
  //   const listCreateCaptcha:any = ["Add event","Modal Register","Profile","Forgot Password","Login","Register","Reset Password"]
  //   if(listCreateCaptcha.includes(label)){
  //     console.log(label);
      
  //     const script = document.createElement("script");
  //     // script.id = "googleRecaptchaScript";
  //     script.src =
  //       `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_TOKEN}`;
  //     document.body.appendChild(script);
  //   }
    
  //   return () => {
  //     const getCaptcha = document.getElementsByClassName("grecaptcha-badge")
  //     if(getCaptcha){
  //       for (var i=0; i < getCaptcha.length; i++) {
  //         getCaptcha[i].remove()
  //       }
  //     }
  //   }
  // }, [props]);  
  return (
    <EmptyLayoutWrapper>
        {children}
    </EmptyLayoutWrapper>
  );
};

export default DefaultLayout;