//lib
//components
import { Div } from "@Components/common/HTMLTag/HTMLTag";
import Notification from "@Components/common/Notification/Notification";
//config
import i18next from "@Configs/i18n/i18n";
import React from "react";
import { I18nextProvider } from "react-i18next";
//const
//store
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
//Styled Component Config
import rootSagas from "@Reduxs/root-sagas";
import DefaultThemeProvider from "@Themes/index";
//style
import "./app.scss";
import AppRouter from "./appRoute/AppRoute";
import storeConfig from "@Reduxs/store";

// lib
import Toast from './lib/Toast'


const { store, persistor, sagaMiddleware } = storeConfig();
sagaMiddleware.run(rootSagas);


export default function App() {
  return (
    <Div className="app">
      <I18nextProvider i18n={i18next}>
        <DefaultThemeProvider>
          <BrowserRouter>
            <React.Suspense fallback={<div className="fallback" />}>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <Toast />
                  <AppRouter />
                  <Notification />
                  {/* <ButtonDarkMode theme={theme} toggleTheme={themeToggler} /> */}
                </PersistGate>
              </Provider>
            </React.Suspense>
          </BrowserRouter>
        </DefaultThemeProvider>
      </I18nextProvider>
    </Div>
  );
}
