import React, { useState } from "react";
// import PropTypes from "prop-types";
import "./style.scss";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { IconButton, Tab, Tabs, Container } from "@material-ui/core";
import EventTabPanel from "@Components/Event/ViewAll/EventTabPanel/EventTabPanel";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "@Reduxs/store";
import "@StyleSheets/grid.scss";
import baseApi from "@Apis/Event/api";
// ViewAllEvent.propTypes = {};

const ViewAllEvent: React.FC = () => {
  const history = useHistory();
  const [valueTab, setValueTab] = useState(0);
  const [eventData, setEventData]:any = useState([])
  const [eventDeleted,setEventDeleted]:any = useState([])
  const { infoEnterprise } = useSelector((state: RootState) => ({
    infoEnterprise: state.enterpriseReducer?.get("infoEnterprise")?.toJS()
      ?.result,
  }));
  const [refEventData,setRefEventData] = useState(false)

  const handleChangeTab = (event:any,tab:any) => {
    setValueTab(tab);
  };

  const fetchData = async()=>{
    try {
      const {result}:any = await baseApi.getListEvent()
      const data = result.getListHappening.concat(result.getListFinished)
      const dataDeleted:any = await baseApi.getListDeletedEvent()
      setEventData(data)
      setEventDeleted(dataDeleted.result)
    } catch (error) {
      console.log("error fetchData event detail",error);
    }
  }

  React.useEffect(()=>{
    fetchData()
  },[refEventData])
  const RefreshEventData = ()=>setRefEventData(!refEventData)

  return (
    <div className='event-viewall '>
      <div className="event-viewall-header"> 
        <Container className='back-event'>
          <IconButton
            onClick={() => history.push(`/${infoEnterprise.url}/event`)}
          >
            <KeyboardBackspaceIcon color='error' fontSize='large' />
          </IconButton>
          <p>Tất cả event</p>
        </Container>
        <Container className='tabs-event-wrapper'>
          <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            indicatorColor='primary'
            textColor='inherit'
            className='tabs-event'
          >
            <Tab label='Event' className='tab' />
            <Tab label='Đã xoá' className='tab' />
          </Tabs>
        </Container>
      </div>

      <EventTabPanel value={valueTab} index={0} eventData={eventData} RefreshEventData={RefreshEventData}></EventTabPanel>
      <EventTabPanel value={valueTab} index={1} eventData={eventDeleted} RefreshEventData={RefreshEventData} deleteForever={true}></EventTabPanel>
    </div>
  );
};

export default ViewAllEvent;
