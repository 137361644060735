import React, { useState, useEffect } from 'react'
import { Dialog, makeStyles } from '@material-ui/core';
import { InviteSuccessModalPropsInterface } from './types';
import SuccessIcon from "@Assets/icons/event/successIcon.svg"



const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minWidth: "300px",
    "@media only screen and (max-width: 600px)":{
      width:"100%",
      maxWidth:"unset !important",
      maxHeight:"unset !important",
      margin:0,
    }
  },
}))


const InviteSuccessModal = (props: InviteSuccessModalPropsInterface) => {
  const { onCloseInviteSuccessModal, openInviteSuccessModal } = props;
  const classes = useStyles()

  return (
    <Dialog
              className="modal-register-success"
              open={openInviteSuccessModal}
              onClose={onCloseInviteSuccessModal}
              classes={{ paper: classes.dialogPaper }}
            >
              <div className="form-success">
                <div className="form-content">
                  <img src={SuccessIcon} alt="successIcon"/>
                  <div className="title">
                  Bạn đã gửi sự kiện <br/> thành công!
                  </div>
                  <div className="des">
                    Sự kiện bạn chia sẻ đã được gửi đến người nhận
                  </div>
                  <button onClick={onCloseInviteSuccessModal} >Xác nhận</button>
                </div>
              </div>
            </Dialog>
  )
}

export default InviteSuccessModal