import ActionType from "./action-type";
import { INotificationState } from "@Interfaces/notification";

export const notificationAction = () => ({
  type: ActionType.NOTIFICATION_START,
  payload: null,
  error: null,
});
export const notificationCloseAction = () => ({
  type: ActionType.NOTIFICATION_CLOSE,
  payload: null,
  error: null,
});
export const notificationOpenAction = (result: INotificationState) => ({
  type: ActionType.NOTIFICATION_OPEN,
  payload: result,
  error: null,
});
