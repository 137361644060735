import React, { useState } from "react";
// import PropTypes from "prop-types";
import "./style.scss";

import bussinessIcon from "@Assets/icons/event/bussiness.svg";
import { Box, Button, TextField, Typography } from "@material-ui/core";

// React hook form
import { Controller, useForm } from "react-hook-form";
// Validation yup
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import * as action from "@Actions/Enterprise/action";
// import { URL_CACHE } from './const'
import { ENTERPRISE_ID, ENTERPRISE_NAME, ENTERPRISE_URL } from "@Constants/localStorage";
import { IEnterpriseForm, IEnterpriseResponse } from "@Interfaces/enterprise";

// Import component

// Enterprise.propTypes = {};

const schema = yup.object().shape({
  name: yup.string().required("Vui lòng nhập tên doanh nghiệp !"),
});

const Enterprise: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Form
  const {
    //   register,
    handleSubmit,
    errors,
    control,
    formState,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [getText,setGetText]: any = useState("")
  const typingTimeoutRef: any = React.useRef(null)

  const handleSubmitForm = (data: IEnterpriseForm) => {
    const callbackSuccess = (data: IEnterpriseResponse) => {
      localStorage.setItem(ENTERPRISE_NAME, data?.result?.name);
      localStorage.setItem(ENTERPRISE_ID, data?.result?.id);
      localStorage.setItem(ENTERPRISE_URL, data?.result?.url);
      // history.push(`/${data?.result?.url}/booking-room`);
      history.push(`/${data?.result?.url}/event`);
    };
    const callbackError = () => {};
    dispatch(action.addEnterpriseAction(data, callbackSuccess, callbackError));
  };

  const getValueText =(e: any)=>{
    const data = e?.target?.value.trim()
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      setGetText(data)
    }, 300)
  }

  return (
    <Box className='Enterprise-wrapper'>
      <form className='form-wrapper' onSubmit={handleSubmit(handleSubmitForm)}>
        <Typography className='title'>Tạo doanh nghiệp</Typography>
        <Box className='input-name'>
          <img src={bussinessIcon} alt={bussinessIcon} />
          <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextField
                onChange={(e)=>{
                  onChange(e);
                  getValueText(e)
                }}
                onBlur={onBlur}
                value={value}
                fullWidth
                placeholder="Tên doanh nghiệp"
                helperText={errors.name?.message}
                error={!!formState.errors.name}
                color='secondary'
                InputProps={{
                  style:{fontSize:16}
                }}
              />
            )}
            name='name'
            defaultValue=''
          />
        </Box>
        <Box className='action-wrapper'>
          {
            (getText && getText!=="")
            ? <Button className='add' size='large' type='submit' variant="contained" color="primary">
                Hoàn thành
              </Button>
            : <Button className='add' disabled size='large' type='submit' variant="outlined" color="primary">
                Hoàn thành
              </Button>
          }
        </Box>
      </form>
    </Box>
  );
};

export default Enterprise;
