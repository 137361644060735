import {
  Button,
  Dialog,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Grid,
  IconButton,
  Tab,
  Tabs
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import "./style.scss";
import eventImageTemp from "@Assets/images/Event/add.png";
import calendarIcon from "@Assets/icons/event/calendarIcon.svg";
import { useForm } from "react-hook-form";
import baseApi from "@Apis/Event/api";
import baseEnterpriseApi from "@Apis/Enterprise/api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import * as action from "@Actions/Event/action";
import { ENTERPRISE_URL } from "@Constants/localStorage";
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import { useLocation } from "react-router";
import { NOTIFICATION_TYPE } from "@Constants/common";
import { notificationOpenAction } from "@Actions/Notification/action";
import CircularProgress from "@material-ui/core/CircularProgress";
import DetailDescription from "@Components/Event/QuillEditor/DetailDescription";
import UserBlueIcon from "@Assets/icons/event/UserBlue.svg";
import PhoneBlueIcon from "@Assets/icons/event/PhoneBlue.svg";
import GmailBlueIcon from "@Assets/icons/event/GmailBlue.svg";
import noteBlueIcon from "@Assets/icons/event/noteBlue.svg";
import addressBlueIcon from "@Assets/icons/event/addressBlue.svg";
import ArrowBackGray from "@Assets/icons/event/arrowBackGray.svg"
import SuccessIcon from "@Assets/icons/event/successIcon.svg"
import trailingIcon from "@Assets/icons/event/trailing-icon.svg";
import { ErrorMessage } from "@hookform/error-message";
import { ENTERPRISE_ID } from "@Constants/localStorage";
import CloseIcon from "@Assets/icons/event/closeIcon.svg";
import NineEventLogo from "@Assets/images/Common/nine-event-logo.png";
import MicrosoftTeamIcon from "@Assets/icons/event/teamsEvent.svg";
import GoogleMeetImageIcon from "@Assets/icons/event/GoogleMeetEvent.svg";


const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minWidth: "300px",
    "@media only screen and (max-width: 600px)":{
      width:"100%",
      maxWidth:"unset !important",
      maxHeight:"unset !important",
      margin:0,
    }
  },
}))

const formRegister = [
  {
    required: true,
    type: "text",
    title: "Họ và tên",
    name: "name",
    format: "text",
    img: UserBlueIcon
  },
  {
    required: true,
    type: "number",
    title: "Số điện thoại",
    name: "phone",
    format: "text",
    img: PhoneBlueIcon
  },
  {
    required: true,
    type: "email",
    title: "Email",
    name: "email",
    format: "text",
    img: GmailBlueIcon
  },
  {
    required: true,
    type: "text",
    title: "Địa chỉ",
    name: "address",
    format: "text",
    img: addressBlueIcon
  },
  {
    required: false,
    type: "text",
    title: "Ghi chú",
    name: "note",
    format: "text",
    img: noteBlueIcon,
    multiline: true
  }
]

const EventDetailUser: React.FC = (props: any) => {
  const [validateTextErr, setValidateTextErr]: any = useState({})
  const schema = yup.object().shape(validateTextErr)
  const id = props.match.params.id
  const [eventDetail, setEventDetail]: any = useState({})
  const dispatch = useDispatch();
  const history = useHistory();
  const [dataRegister, setDataRegister]: any = useState([])
  const location = useLocation()
  const isBack = new URLSearchParams(location.search).get('back')
  const businessId = localStorage.getItem(ENTERPRISE_ID)
  const [btnSubmit, setBtnSubmit]: any = useState(false)
  const [validGroup, setValidGroup]: any = useState([])
  const [hideError, setHideError]: any = useState(false)
  const [refData, setRefData]: any = useState(false)
  const dataRadioRef: any = React.useRef({})
  const dataCheckRef: any = React.useRef({})
  const typingTimeoutRef: any = React.useRef(null)
  const [openModalRegister, setOpenModalRegister] = useState(false);
  const classes = useStyles()
  const [registerSuccess, setRegisterSuccess] = useState(false)
  const [registerSuccessAfter, setRegisterSuccessAfter] = useState(false)
  const [nameCompany,setNameCompany] = useState("Company name");

  const getValidate = (data: any) => {
    const notValidate = ["radio", "check-box"]
    let result: any = {}
    let dataValidGroup: any = []
    data.map((values: any, index: any) => {
      if (values.required) {
        if (!notValidate.includes(values.format)) {
          result[values.name] = yup.string().required(`Vui lòng nhập ${values.title.toLowerCase()}`)
        }
        else {
          dataValidGroup.push(values.name)
        }
      }
    })
    return { result, dataValidGroup }
  }

  const { register, handleSubmit, errors } = useForm(schema ? {
    resolver: yupResolver(schema),
  } : {});

  const fetchData = async () => {
    try {
      const { result }: any = await baseApi.getDetailEvent(id)
      const { result : enterpriseInfo } : any = await baseEnterpriseApi.getNameEnterprise(result?.business);
      setNameCompany(enterpriseInfo?.name);
      const dataValidate: any = await getValidate(result?.requiredRegister || formRegister)
      setEventDetail(result)
      setDataRegister(result?.requiredRegister || formRegister)
      setValidateTextErr(dataValidate.result)
      setValidGroup(dataValidate.dataValidGroup)
    } catch (error) {
      console.log("error fetchData event detail", error)
    }
  }

  useEffect(() => {
    if (id) {
      fetchData()
    }
  }, [id])

  const callbackSuccess = async (data: any) => {
    //create Qr_Code
    const hostName = window?.location?.origin
    const eventUrl: any =
      localStorage.getItem(ENTERPRISE_URL) ||
      window?.location?.pathname?.split("/")[1]
    const link =
      hostName +
      "/" +
      eventUrl +
      "/checkin-event/" +
      id +
      `?userid=${data.result.id}`
    try {
      const result: any = await baseApi.createQrCodeEvent(link)
      const updateUser = await baseApi.updateUserCheckInEvent(
        { qrCode: result.qrImage, eventID: data.result.eventID },
        data.result.id
      )
      //send email register
      const inforRegister = {
        email: data.result.email,
        data: eventDetail,
        link,
        name: updateUser?.result?.name
      }

      await baseApi.sendEmailRegisterEvent(inforRegister)

      // dispatch(
      //   notificationOpenAction({
      //     isNotification: true,
      //     typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
      //     message: "Thành Công",
      //     description: "Đăng ký thành công ! ",
      //   })
      // )
      setRegisterSuccess(true)
    } catch (error) {
      console.log("error register event", error)
      //delete user if not Qr_code
      await baseApi.deleteUserEvent(data.result.id)
      dispatch(notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Thất bại",
        description: "Đăng ký thất bại ! ",
      }))
    }
    setOpenModalRegister(false)
    setBtnSubmit(false)
  }
  const callbackError = () => {
    setBtnSubmit(false)
  }

  const onSubmit = async (values: any) => {
    if (validGroup.length === 0) {
      //add title for add data
      const addTitle: any = ["short-text", "paragraph"]
      for (let i in dataRegister) {
        const getName: any = dataRegister[i].name
        if (addTitle.includes(dataRegister[i].format)) {
          if (Object.keys(values).includes(getName)) {
            values[getName] = { data: values[getName], title: dataRegister[i].title }
          }
        }
      }
      //add title for add data//
      //change data select radio and checkbox
      const dataRadio: any = dataRadioRef.current
      const dataCheck: any = dataCheckRef.current
      if (Object.keys(dataRadio).length > 0) {
        const dataTmp: any = {}
        for (let i in dataRadio) {
          if (dataRadio[i].select !== "") dataTmp[i] = { data: dataRadio[i]?.select, title: dataRadio[i]?.title }
          else dataTmp[i] = { data: dataRadio[i]?.text, title: dataRadio[i]?.title }
        }
        values = Object.assign({}, values, dataTmp)
      }
      if (Object.keys(dataCheck).length > 0) {
        const dataTmp: any = {}
        for (let i in dataCheck) {
          const valueTmp: any = dataCheck[i]
          if (dataCheck[i]?.other) {
            const { text, other, title, ...getValue } = valueTmp
            dataTmp[i] = { data: Object.keys(getValue).concat([text]), title }
          } else {
            delete dataCheck[i]?.text
            const { text, title, ...getValue } = valueTmp
            dataTmp[i] = { data: Object.keys(getValue), title }
          }
          values = Object.assign({}, values, dataTmp)
        }
      }
      //change data select radio and checkbox//
      let { name, email, phone, address, note, ...dataAdd }: any = { ...values }
      values.addFormRegister = dataAdd
      setBtnSubmit(true)
      if (eventDetail?.isPublish) {
        values.eventID = id
        values.accountId = eventDetail?.accountId || ""
        values.eventTitle = eventDetail?.eventTitle || ""
        if (eventDetail?.onlineItems === "google" && eventDetail?.eventDetail?.id) {
          values.idGoogleMeet = eventDetail.eventDetail.id
        }
        dispatch(action.addUserEventAction(values, callbackSuccess, callbackError))
      }
      else {
        dispatch(notificationOpenAction({
          isNotification: true,
          typeNotification: NOTIFICATION_TYPE.ERROR as "error",
          message: "Thất bại",
          description: "Bạn phải publish sự kiện ! ",
        }))
        setOpenModalRegister(false)
      }
    }
  }

  const handleChange = (e: any, name: any, title: any) => {
    const getIndex = validGroup.indexOf(name)
    const getName = e.target.name
    const getStatus = e.target.checked
    dataCheckRef.current[name] = {
      ...dataCheckRef.current[name],
      [getName]: getStatus,
      text: dataCheckRef.current[name]?.text || "",
      title
    }
    if (!getStatus) delete dataCheckRef.current[name][getName]

    if (dataCheckRef.current[name]?.other) {
      if (dataCheckRef.current[name].text === "" || !dataCheckRef.current[name]?.text) {
        if (getIndex === -1) validGroup.push(name)
      }
      else {
        if (getIndex > -1) validGroup.splice(getIndex, 1)
      }
    } else {
      let getLength = Object.keys(dataCheckRef.current[name])?.length
      if (getLength > 0) {
        if (getLength === 2) {
          if (Object.keys(dataCheckRef.current[name]).sort()[0] === "text") {
            if (getIndex === -1) validGroup.push(name)
          } else {
            if (getIndex > -1) validGroup.splice(getIndex, 1)
          }
        }
        else {
          if (getIndex > -1) validGroup.splice(getIndex, 1)
        }
      }
      else {
        if (getIndex === -1) validGroup.push(name)
      }
    }
    setValidGroup(validGroup)
    setRefData(!refData)
  }

  const handleChangeTextCheck = (e: any, nameText: any, title: any) => {
    let dataText: any = e.target?.value?.trim()
    let getIndex = validGroup.indexOf(nameText)
    if (dataCheckRef.current[nameText]?.other) {
      if ((dataText === "" || !dataText) && getIndex === -1) {
        validGroup.push(nameText)
      }
      else {
        if (dataText && dataText !== "" && getIndex > -1) validGroup.splice(getIndex, 1)
      }
    }

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      dataCheckRef.current[nameText] = {
        ...dataCheckRef.current[nameText],
        text: dataText,
        title
      }
      setValidGroup(validGroup)
      setRefData(!refData)
    }, 300)
  }

  const handleChangeTextRadio = (e: any, nameText: any, title: any) => {
    let dataText: any = e.target?.value?.trim()
    let getIndex = validGroup.indexOf(nameText)
    if (dataRadioRef.current[nameText]?.select === "") {
      if (dataText === "") validGroup.push(nameText)
      else {
        if (getIndex !== -1) validGroup.splice(getIndex, 1)
      }
    }
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      dataRadioRef.current[nameText] = { select: dataRadioRef.current[nameText]?.select, text: dataText, title }
      setValidGroup(validGroup)
      setRefData(!refData)
    }, 300)
  }

  const handleSelect = (data: any, name: any, title: any) => {
    const dataSelect: any = data.target.value
    const getIndex = validGroup.indexOf(name)
    if (getIndex !== -1) {
      if (dataSelect && dataSelect !== "") { validGroup.splice(getIndex, 1) }
      else {
        if (dataRadioRef.current[name]?.text) {
          if (dataRadioRef.current[name]?.text === "") { validGroup.push(name) }
          else { validGroup.splice(getIndex, 1) }
        }
      }
    }
    else {
      if (dataSelect === "" && dataRadioRef.current[name]?.text === '') {
        validGroup.push(name)
      }
    }
    setValidGroup(validGroup)
    dataRadioRef.current[name] = { select: dataSelect, text: dataRadioRef.current[name]?.text || "", title }
    setRefData(!refData)
  }

  useEffect(() => { }, [refData])
  const linkOrigin = window.location.origin || "https://event-app.9code.vn"

  return (
    <React.Fragment>
      {eventDetail?.isPublish && (
        <>
          {isBack || (eventDetail.business === businessId) && ( window?.location.origin === linkOrigin ) 
            ?
              <Grid
                container
                direction="row"
                alignItems="center"
                className="header-event-for-user"
              >
                <Grid item xs={3}>
                  <div className="exit-page">
                    <IconButton
                      onClick={
                        () => {
                          isBack
                            ? history.goBack()
                            : localStorage.getItem(ENTERPRISE_URL)
                              ? history.push(
                                `/${localStorage.getItem(ENTERPRISE_URL)}/event`
                              )
                              : history.goBack()
                        }
                      }
                      disableFocusRipple
                      disableRipple
                      className="exit-btn"
                    >
                      <img src={ArrowBackGray} alt={ArrowBackGray} />
                      <span className="hidden-mobile">Trở lại </span>
                      
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            :
            <></>
          }
          <div className="event-for-user-wrapper">
            <div className="event-wrapper ">
              <div className="event-image">
                <img
                  className="event-background"
                  src={eventDetail?.image || eventImageTemp}
                  alt={eventImageTemp}
                />
                {eventDetail?.qrCode && (
                  <div className="image-qr">
                    <div className="image-qr-wrapper">
                      <img src={eventDetail?.qrCode} alt="QR Img" />
                    </div>
                    <p className="image-qr--note">Scan QR hoặc click tham gia</p>
                    <div className="image-qr--action">
                    {registerSuccessAfter ?
                      <Button
                        color="primary"
                        variant="contained"
                        style={{
                          textTransform: "none",
                          width: "126px",
                          fontFamily: "Roboto-Bold",
                          fontWeight: 700,
                          color: "var(--clr-primary)",
                          backgroundColor: "#ffffff",
                          border: "1px solid var(--clr-primary)",
                          boxShadow: "none",
                          cursor:"auto"
                        }}
                      >
                      Đã đăng ký
                      </Button>
                      :
                      <Button
                        color="primary"
                        variant="contained"
                        className="join-btn"
                        onClick={() => setOpenModalRegister(true)}
                        style={{
                          textTransform: "none",
                          width: "126px",
                          fontFamily: "Roboto-Bold",
                          fontWeight: 700,
                        }}
                      >
                      Tham gia
                      </Button>
                    }
                    </div>
                  </div>
                )}
                {/* <div className="business-info">
                  <div className="business-info--logo-wrapper">
                    <img src={NineEventLogo} alt="NineEvent Logo" />
                  </div>
                  <p className="business-info--name">{nameCompany || ""}</p>
                </div> */}
              </div>
              <div className="content-website">
                <div
                  className="row no-gutters"
                  style={{
                    marginBottom: "3em",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="short-content" style={{padding: "0"}}>
                    <p className="title">{eventDetail?.eventTitle}</p>
                    <div className="time-place">
                      <div className="datetime-box">
                          <div className="datetime-box__header">
                            <img src={calendarIcon} alt="" className="datetime-box__header-date-icon" />
                            <span className="datetime-box__header-month-year">
                              {moment(eventDetail?.startDateTime).format("MM/YYYY")}
                            </span>
                          </div>
                          <div className="datetime-box__body">
                            <h4 className="datetime-box__body-day">
                              {moment(eventDetail?.startDateTime).format("DD")}
                            </h4>
                          </div>
                      </div>
                      <div className="short-content__info">
                        <p className="short-content__info-company">{nameCompany || ""}</p>
                        <p>
                          <span>Bắt đầu: </span>
                          {moment(eventDetail.startDateTime)
                            .format("HH:mm")
                            .replace(":", "h") +
                            " - " +
                            moment(eventDetail.startDateTime).format(
                              "DD/MM/YYYY"
                            )}
                        </p>
                        {eventDetail.endDateTime && (
                          <p>
                            <span>Kết thúc: </span>
                            {moment(eventDetail.endDateTime)
                              .format("HH:mm")
                              .replace(":", "h") +
                              " - " +
                              moment(eventDetail.endDateTime).format(
                                "DD/MM/YYYY"
                              )}
                          </p>
                        )}

                        {eventDetail?.isOnline === "off" 
                          ? (
                            eventDetail?.address?.address && (
                              <p>
                                <span>Tại: </span>
                                {eventDetail?.address?.address}
                              </p>
                            )
                          ) : (
                             eventDetail?.onlineItems === "google" ? (
                              <div className="short-content__info-address">
                                <span>Tại: </span>
                                <img
                                  src={GoogleMeetImageIcon}
                                  alt="GoogleMeetImageIcon"
                                />
                                Google Meet
                              </div>
                            ) : (
                              <div className="short-content__info-address">
                                <span>Tại: </span>
                                <img src={MicrosoftTeamIcon} alt="TeamIcon" />
                                Microsoft Team
                              </div>
                            )
                          )
                        }
                      </div>
                    </div>
                    <p className="description">{eventDetail?.note}</p>
                  </div>
                </div>

                <div id="timeline-content">
                  <ul className="time-list">
                    {eventDetail?.eventSchedules &&
                      eventDetail?.eventSchedules.length > 0 &&
                      eventDetail?.eventSchedules.map(
                        (value: any, index: any) => (
                          <li key={index} className="items">
                            <p className="time-item">
                              {moment(value.endDateTime)
                                .format("HH:mm")
                                .replace(":", "h") +
                                " - " +
                                moment(value.endDateTime).format("DD/MM/YYYY")}
                            </p>
                            <div className="content-item">
                              <DetailDescription text={value.note} />
                            </div>
                          </li>
                        )
                      )}
                  </ul>
                </div>
              </div>
              <div className="content-mobile">
                <div className="row ">
                  <div className="short-content">
                    <p className="title">{eventDetail?.eventTitle}</p>
                    <div className="time-place">
                      <div className="datetime-box">
                          <div className="datetime-box__header">
                            <img src={calendarIcon} alt="" className="datetime-box__header-date-icon" />
                            <span className="datetime-box__header-month-year">
                              {moment(eventDetail?.startDateTime).format("MM/YYYY")}
                            </span>
                          </div>
                          <div className="datetime-box__body">
                            <h4 className="datetime-box__body-day">
                              {moment(eventDetail?.startDateTime).format("DD")}
                            </h4>
                          </div>
                      </div>
                      <div className="short-content__info">
                        <p className="short-content__info-company">{nameCompany || ""}</p>
                        <p>
                          <span>Bắt đầu: </span>
                          {moment(eventDetail.startDateTime)
                            .format("HH:mm")
                            .replace(":", "h") +
                            " - " +
                            moment(eventDetail.startDateTime).format(
                              "DD/MM/YYYY"
                            )}
                        </p>
                        {eventDetail.endDateTime && (
                          <p>
                            <span>Kết thúc: </span>
                            {moment(eventDetail.endDateTime)
                              .format("HH:mm")
                              .replace(":", "h") +
                              " - " +
                              moment(eventDetail.endDateTime).format(
                                "DD/MM/YYYY"
                              )}
                          </p>
                        )}

                        {eventDetail?.isOnline === "off" 
                          ? (
                            eventDetail?.address?.address && (
                              <p>
                                <span>Tại: </span>
                                {eventDetail?.address?.address}
                              </p>
                            )
                          ) : (
                             eventDetail?.onlineItems === "google" ? (
                              <div className="short-content__info-address">
                                <span>Tại: </span>
                                <img
                                  src={GoogleMeetImageIcon}
                                  alt="GoogleMeetImageIcon"
                                />
                                Google Meet
                              </div>
                            ) : (
                              <div className="short-content__info-address">
                                <span>Tại: </span>
                                <img src={MicrosoftTeamIcon} alt="TeamIcon" />
                                Microsoft Team
                              </div>
                            )
                          )
                        }
                      </div>
                      {/* <p className="short-content__info-company">{nameCompany || ""}</p>
                      <p>
                        <span>Bắt đầu: </span>
                        {moment(eventDetail.startDateTime)
                          .format("HH:mm")
                          .replace(":", "h") +
                          " - " +
                          moment(eventDetail.startDateTime).format(
                            "DD/MM/YYYY"
                          )}
                      </p>
                      {eventDetail.endDateTime && (
                        <p>
                          <span>Kết thúc: </span>
                          {moment(eventDetail.endDateTime)
                            .format("HH:mm")
                            .replace(":", "h") +
                            " - " +
                            moment(eventDetail.endDateTime).format(
                              "DD/MM/YYYY"
                            )}
                        </p>
                      )}

                      {eventDetail?.address?.address &&
                        eventDetail?.isOnline === "off" && (
                          <p>
                            <span>Tại: </span>
                            {eventDetail?.address?.address}
                          </p>
                        )} */}
                    </div>
                    <p className="description">{eventDetail?.note}</p>
                  </div>
                </div>
                <div id="timeline-content">
                  <ul className="time-list">
                    {eventDetail?.eventSchedules &&
                      eventDetail?.eventSchedules.length > 0 &&
                      eventDetail?.eventSchedules.map(
                        (value: any, index: any) => (
                          <li key={index} className="items">

                            <div className="content-item">
                              <p className="time-item">
                                {moment(value.endDateTime)
                                  .format("HH:mm")
                                  .replace(":", "h") +
                                  " - " +
                                  moment(value.endDateTime).format("DD/MM/YYYY")}
                              </p>
                              <DetailDescription text={value.note} />
                            </div>
                          </li>
                        )
                      )}
                  </ul>
                </div>
                <div className="join-aciton">
                {registerSuccessAfter?
                    <Button
                    color="primary"
                    variant="contained"
                    style={{
                      textTransform: "none",
                      width: "126px",
                      fontFamily: "Roboto-Bold",
                      fontWeight: 700,
                      color: "var(--clr-primary)",
                      backgroundColor: "#ffffff",
                      border: "1px solid var(--clr-primary)",
                      boxShadow: "none",
                      cursor:"auto"
                    }}
                  >
                    Đã đăng ký
                  </Button>
                    :
                    <Button
                    color="primary"
                    variant="contained"
                    className="join-btn"
                    onClick={() => setOpenModalRegister(true)}
                    style={{
                      textTransform: "none",
                      width: "126px",
                      fontFamily: "Roboto-Bold",
                      fontWeight: 700,
                    }}
                  >
                    Tham gia
                  </Button>
                    }
                </div>
              </div>
            </div>

            {/* Modal register */}
            <Dialog
              // onClose={() => setOpenModalRegister(false)}
              open={openModalRegister}
              className='event-detail-register'
              maxWidth='sm'
              fullWidth
              scroll="body"
              classes={{ paper: classes.dialogPaper }}
            >
              <div className='content'>
                <div className="close-fixhead">
                  <IconButton
                    onClick={() => setOpenModalRegister(false)}
                    className="exit-btn"
                  >
                    <img src={CloseIcon} alt={CloseIcon} />
                  </IconButton>
                </div>
                <div className='content__title'>Đăng ký tham gia sự kiện</div>
                <form className='form' onSubmit={handleSubmit(onSubmit)}>
                  {
                    dataRegister?.map((values: any, index: any) => {
                      switch (values?.format) {
                        case "text":
                        case "short-text":
                          return <div className='fields' key={index}>
                            {
                              values.multiline
                                ? <div className='field' style={{ alignItems: "flex-start" }}>
                                  <img src={values?.img} alt='' />
                                  <TextField
                                    name={values.name}
                                    type={values.type}
                                    placeholder={values.title}
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    className='field-input'
                                    inputRef={register}
                                    style={{
                                      background: "#F6F7FA",
                                      borderRadius: "4px",
                                      padding: "8px 4px 8px 16px"
                                    }}
                                    InputProps={{
                                      style: { fontSize: 16, color: "#666666", fontFamily: 'Roboto-Regular' },
                                      disableUnderline: true
                                    }}
                                  />
                                </div>
                                : <div className='field'>
                                  <img src={values?.img} alt='' />
                                  <TextField
                                    name={values.name}
                                    type={values.type}
                                    placeholder={values.title}
                                    fullWidth
                                    className='field-input'
                                    inputRef={register}
                                  />
                                </div>
                            }

                            <ErrorMessage
                              errors={errors}
                              name={values.name}
                              render={({ message }) => (
                                <p className="error-message error-form">
                                  <img src={trailingIcon} alt={trailingIcon} />
                                  {message}
                                </p>
                              )}
                            />
                          </div>

                        case "paragraph":
                          return <div className='fields' key={index}>
                            <div className='field'>
                              <img src={values?.img} alt='' />
                              <TextField
                                name={values.name}
                                placeholder={values.title}
                                fullWidth
                                multiline
                                className='field-input'
                                inputRef={register}
                              />
                            </div>
                            <ErrorMessage
                              errors={errors}
                              name={values.name}
                              render={({ message }) => (
                                <p className="error-message error-form">
                                  <img src={trailingIcon} alt={trailingIcon} />
                                  {message}
                                </p>
                              )}
                            />
                          </div>
                        case "radio":
                          return <div className='fields field radio-group' key={index}>
                            <div className="title-radio">
                              <img src={values?.img} alt='' />
                              <TextField
                                fullWidth
                                variant="standard"
                                value={values.title}
                                className='field-input'
                                InputProps={{ readOnly: true, disableUnderline: true }}
                              />
                            </div>
                            {
                              hideError
                                ? (validGroup.includes(values.name)) && <div className="show-error">
                                  <img src={trailingIcon} alt={trailingIcon} />
                                  <span className="error-message">Đây là câu hỏi bắt buộc</span>
                                </div>
                                : ""
                            }
                            <div className="wrap-radio">
                              <RadioGroup
                                onChange={(e) => handleSelect(e, values.name, values.title)}
                                className="format-wrap"
                              >
                                {
                                  values.question.map((data: any, key: any) => (
                                    <FormControlLabel
                                      key={key + index}
                                      value={typeof data === "object" ? "" : data}
                                      control={<Radio color="primary" size="small" style={{ marginLeft: "5px", marginRight: "10px" }} />}
                                      label={
                                        typeof data === "object"
                                          ? <TextField
                                            fullWidth
                                            placeholder={data.other}
                                            className='field-input'
                                            onChange={(e) => handleChangeTextRadio(e, values.name, values.title)}
                                          />
                                          : data
                                      }
                                    />
                                  ))
                                }
                              </RadioGroup>
                            </div>
                          </div>
                        case "check-box":
                          return <div className='fields field radio-group' key={index}>
                            <div className="title-radio">
                              <img src={values?.img} alt='' />
                              <TextField
                                fullWidth
                                variant="standard"
                                value={values.title}
                                className='field-input'
                                InputProps={{ readOnly: true, disableUnderline: true }}
                              />
                            </div>
                            {
                              hideError
                                ? (validGroup.includes(values.name)) && <div className="show-error">
                                  <img src={trailingIcon} alt={trailingIcon} />
                                  <span className="error-message">Đây là câu hỏi bắt buộc</span>
                                </div>
                                : ""
                            }
                            <div className="wrap-radio">
                              <FormGroup className="format-wrap">
                                {
                                  values.question.map((data: any, key: any) => (
                                    <FormControlLabel
                                      key={`${key}-${index}`}
                                      value={typeof data === "object" ? Object.keys(data)[0] : data}
                                      control={<Checkbox
                                        size="small"
                                        color="primary"
                                        onChange={(e) => handleChange(e, values.name, values.title)}
                                        style={{ marginLeft: "5px", marginRight: "10px" }}
                                        // checked={data}
                                        name={typeof data === "object" ? Object.keys(data)[0] : data}
                                      />}
                                      label={
                                        typeof data === "object"
                                          ? <TextField
                                            fullWidth
                                            placeholder={data.other}
                                            className='field-input'
                                            onChange={(e) => handleChangeTextCheck(e, values.name, values.title)}
                                          />
                                          : data
                                      }
                                    />
                                  ))
                                }
                              </FormGroup>
                            </div>
                          </div>
                        default:
                          console.log("vlaue", values)
                          return
                      }
                    })
                  }

                  <div className='action'>
                    <Button
                      onClick={() => setHideError(true)}
                      className='action__register'
                      variant='contained'
                      color='primary'
                      type="submit"
                      style={{ textTransform: "none", fontFamily: "Roboto-Regular", backgroundColor: "var(--clr-primary)" }}
                      disabled={btnSubmit}
                      startIcon={btnSubmit && <CircularProgress color="inherit" style={{ height: "20px", width: "20px" }} />}
                    >
                      Đăng ký
                    </Button>
                  </div>
                </form>
              </div>
            </Dialog>
            {/* Modal register success */}
            <Dialog
              className="modal-register-success"
              open={registerSuccess}
              onClose={() => {
                setRegisterSuccess(false);
                setRegisterSuccessAfter(true)
              }}
              aria-labelledby="customized-dialog-title"
              maxWidth="md"
              classes={{ paper: classes.dialogPaper }}
            >
              <div className="form-success">
                <div className="form-content">
                  <img src={SuccessIcon} alt="successIcon"/>
                  <div className="title">
                    Bạn đã đăng ký sự kiện thành công!
                  </div>
                  <div className="des">
                    Mã QR của sự kiện đã được gửi đến email của bạn
                  </div>
                  <button onClick={() => {
                    setRegisterSuccess(false);
                    setRegisterSuccessAfter(true)
                  }} >Xác nhận</button>
                </div>
              </div>
            </Dialog>
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default EventDetailUser
