import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./actions";
import component from "./component";

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.toastReducer,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
