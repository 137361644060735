import { ENTERPRISE_ID } from './../../constants/localStorage';
import { Api } from "@Configs/api/api";
import apiConfig from "@Configs/api/api.config";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { 
  API_ADD_ENTERPRISE, 
  API_GET_LIST_ENTERPRISE, 
  API_GET_INFO_ENTERPRISE, 
  API_CHANGE_ENTERPRISE, 
  API_GET_NAME_ENTERPRISE
} from "./endpoint";

class BaseApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config);
    this.addEnterprise = this.addEnterprise.bind(this);
    this.getListEnterprise = this.getListEnterprise.bind(this);
    this.getInfoEnterprise = this.getInfoEnterprise.bind(this);
    this.changeInfoEnterprise = this.changeInfoEnterprise.bind(this);
  }
  public addEnterprise(data: any): Promise<any> {
    return this.post<any>(API_ADD_ENTERPRISE, JSON.stringify(data), {
      headers: {
      },
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public getListEnterprise(prams: any): Promise<any> {
    return this.get<any>(API_GET_LIST_ENTERPRISE, {
      headers: {
        "x-business-id": localStorage.getItem(ENTERPRISE_ID) || ""
      },
      params: prams
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public getInfoEnterprise(prams: any): Promise<any> {
    const enterPriseID = localStorage.getItem(ENTERPRISE_ID)
    return this.get<any>(`${API_GET_INFO_ENTERPRISE}/${enterPriseID}`, {
      headers: {"x-business-id": localStorage.getItem(ENTERPRISE_ID) || ""},
      params: prams
    }).then((response: AxiosResponse<any>) => {
      const { data } = response;
      return data
    }).catch((error: AxiosError) => { throw error })
  }

  public changeInfoEnterprise(dataInfoEnterprise: any): Promise<any> {
    const enterPriseID = localStorage.getItem(ENTERPRISE_ID)
    return this.patch<any>(`${API_CHANGE_ENTERPRISE}/${enterPriseID}`, JSON.stringify(dataInfoEnterprise), {
      headers: {
        "x-business-id": localStorage.getItem(ENTERPRISE_ID) || ""
      }
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        console.log(error?.response);
        throw error;
      });
  }

  public getNameEnterprise(enterpriseID: any): Promise<any> {
    return this.get<any>(`${API_GET_NAME_ENTERPRISE}/${enterpriseID}/name`, {
      headers: {},
    }).then((response: AxiosResponse<any>) => {
      const { data } = response;
      return data
    }).catch((error: AxiosError) => { throw error })
  }
}

const baseApi = new BaseApi(apiConfig);
export default baseApi;
