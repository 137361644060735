import { Api } from "@Configs/api/api";
import apiConfig from "@Configs/api/api.config";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import {
  API_CHANGE_PROFILE,
} from "./endpoint";

class BaseApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config);
    this.changeProfile = this.changeProfile.bind(this);
    // this.getProfile = this.getProfile.bind(this)

  }


  // public getProfile(prams: any): Promise<any> {
  //   return this.get<any>(API_GET_PROFILE, {
  //     headers: {}, params: prams
  //   }).then((response: AxiosResponse<any>) => {
  //     const { data } = response;
  //     return data
  //   }).catch((error: AxiosError) => { throw error; })
  // }


  public changeProfile(dataProfile: any): Promise<any> {
    return this.patch<any>(API_CHANGE_PROFILE, JSON.stringify(dataProfile), {
      headers: {
      }
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        console.log(error?.response);
        throw error;
      });
  }

}

const baseApi = new BaseApi(apiConfig);
export default baseApi;
