//Icon
import * as enterpriseAction from "@Actions/Enterprise/action"
import * as userActionEvent from "@Actions/UserEvent/action"
import {
  getUserNotificationListAction,
  // markAsReadAction,
  removeNotificationAction,
} from "@Actions/UserNotification/action"
import logo from "@Assets/icons/logo/logo.svg"
import userBlack from "@Assets/icons/roombooking/userBlack.svg"
import StarIcon from "@Assets/icons/roombooking/starIcon.svg"
import BookService from "@Assets/icons/roombooking/bookService.svg"
import Calendar from "@Assets/icons/roombooking/calendarBlack.svg"
import AccountHeader from "@Components/common/AccountHeader/AccountHeader"
import { PERMISSIONS } from "@Constants/common"
import {
  ENTERPRISE_ID,
  ENTERPRISE_NAME,
  ENTERPRISE_URL,
} from "@Constants/localStorage"
import WithPermission from "@Hocs/withPermission"
import {
  Badge,
  Box,
  TextField,
  Grid,
  IconButton,
  Typography,
  InputAdornment,
  InputBase,
  Paper,
  Drawer,
} from "@material-ui/core"
import Popover from "@material-ui/core/Popover"
import CloseIcon from "@material-ui/icons/Close"
import React, { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import NotificationItem from "../NotificationItem/Notification"
import styles from "./Header.module.scss"
import moduleIcon from "@Assets/icons/moduleIcon.svg"
import CloseDrawIcon from "@Assets/icons/event/closeIcon.svg";
import { RootState } from "@Reduxs/store"
import SearchIcon from "@Assets/icons/event/Search.svg"
import SettingIcon from "@Assets/icons/event/Setting.svg"
import NotificationIcon from "@Assets/icons/event/Bell2.svg"
import SearchMobileIcon from "@Assets/icons/event/searchmobile.svg"
import SupportIcon from "@Assets/icons/event/SupportIcon.svg"
import { useMediaQuery } from "@Hooks/useMediaQuery";
import { useHistory } from "react-router"
import baseApi from "@Apis/UserNotification/api";
import ModalSupportService from "@Common/ModalSupportService";
import ModalSupportServiceMobile from "@Common/ModalSupportServiceMobile";

const MOCK_DATA = [
  {
    id: "1",
    thumbnail: "https://images.unsplash.com/photo-1533738363-b7f9aef128ce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=435&q=80",
    title: 'Hướng dẫn "Tạo lớp học" trên Ninequiz',
    src: "https://www.youtube.com/embed/0sn5Z8Blkek/?autoplay=1",
  },
  {
    id: "1",
    thumbnail: "https://images.unsplash.com/photo-1533738363-b7f9aef128ce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=435&q=80",
    title: 'Hướng dẫn "Tạo lớp học" trên Ninequiz',
    src: "https://www.youtube.com/embed/0sn5Z8Blkek/?autoplay=1",
  },
]

const Header: React.FC = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [infoEnterprise, setInfoEnterprise] = useState({} as any)
  const [permissions, setPermissions] = useState([])
  const [enterpriseID, setEnterpriseID] = useState(
    localStorage.getItem(ENTERPRISE_ID)
  )
  const [searchMobile, setSearchMobile] = useState(false)
  const [anchorElSetting, setAnchorElSetting] = useState(null)
  const openAnchorElSetting = Boolean(anchorElSetting)
  const [anchorElModule, setAnchorElModule] = useState(null)
  const openAnchorElModule = Boolean(anchorElModule)
  const [anchorElNotification, setAnchorElNotification] = useState(null)
  const [isOpenModalSupportService, setIsOpenModalSupportService] = useState(false);
  const openAnchorElNotification = Boolean(anchorElNotification)
  //hide search bar in header
  let hideSearch = window?.location?.pathname
  let isPageWide = useMediaQuery("(max-width: 630px)");
  //all link hide search
  // const pathHideSearch = ["/enterprise-events/add","/event/user"]
  // get link hide search
  if (hideSearch.split("/").length === 4) {
    hideSearch = "/" + hideSearch.split("/")[2] + "/" + hideSearch.split("/")[3]
  }
  
  const { numOfUnread, userNotificationList, infoEnterpriseState } = useSelector(
    (state: RootState) => ({
      loginReducer: state.loginReducer,
      infoEnterpriseState: state.enterpriseReducer?.get("infoEnterprise")?.toJS()
      ?.result,
      userNotificationList: state.userNotificationReducer
        ?.get("payload")
        ?.toJS()?.result,
      numOfUnread: state.userNotificationReducer?.get("payload")?.toJS()?.meta
        ?.numOfUnread,
    })
  )


  const handleRemoveNotification = (id: any) => {
    console.log(id, "id")

    dispatch(removeNotificationAction(id))
  }

  const removeNotification = async()=>{
    try {
      const remove = await baseApi.markAllAsRead()
      // dispatch(getUserNotificationListAction({}));
    } catch (error) {
      console.log("mark all read error",error)
    }
  }

  const onSelectEnterprise = (data: any) => {
    setEnterpriseID(data.result.id)
    localStorage.setItem(ENTERPRISE_NAME, data?.result?.name)
    localStorage.setItem(ENTERPRISE_URL, data?.result?.url)
  }

  const onOpenModalSupportService = () => {
    setIsOpenModalSupportService(true);
  };
  
  const onCloseModalSupportService = () => {
    setIsOpenModalSupportService(false);
  };
  
  useEffect(() => {
    if (enterpriseID) {
      dispatch(
        userActionEvent.getPermissionAction({}, (result: any) => {
          setPermissions(result.permissions)
        })
      )
    }
    dispatch(
      enterpriseAction.getInfoEnterpriseAction({}, (result: any) => {
        setInfoEnterprise(result)
      })
    )
    
  }, [enterpriseID, dispatch])

  const enterPriseUrl = localStorage.getItem(ENTERPRISE_URL)

  useEffect(()=>{
  },[infoEnterpriseState?.companyDetail?.logo])

  const typingTimeoutRef: any = React.useRef(null);
  const searchData = (e:any)=>{
    const data = e.target.value.trim()
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(async () => {
      if(data==="") history.push(`/${enterPriseUrl}/event`)
      else history.push(`/${enterPriseUrl}/event?limit=12&page=1&search=${data}`)
    }, 300);
  }

  return (
    <Fragment>
      <div className={styles?.Wrapper}>
        {searchMobile ? (
          <div className={styles?.searchMobile}>
            <Paper
              elevation={0}
              variant="outlined"
              component="form"
              className="search-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                borderRadius: "40px",
                height: "40px",
                backgroundColor: "#f3f3f3",
                border: "none",
                padding: "0 10px",
              }}
            >
              <InputBase
                placeholder="Nhập từ khoá tìm kiếm"
                style={{ width: "100%" }}
                onChange={searchData}
              />
              <IconButton
                aria-label="search"
                onClick={() => setSearchMobile(false)}
                style={{ padding: "8px" }}
              >
                <CloseIcon />
              </IconButton>
            </Paper>
          </div>
        ) : (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ height: "100%" }}
            className={styles?.HeaderContainer}
          >
            <Grid
              item
              container
              alignItems="center"
              className={styles?.HeaderLeft}
            >
              {/* <IconButton
                className={styles?.iconBtnHeader}
                onClick={(event: any) => setAnchorElModule(event.currentTarget)}
              >
                <img src={moduleIcon} alt="" />
              </IconButton> */}
              <img
                // src={
                //   infoEnterpriseState?.companyDetail?.logo?`/media/images/${infoEnterpriseState?.companyDetail?.logo}`: logo
                // }
                src="/Logo_ninetick_192.png"
                alt=""
                className={styles?.Logo}
                onClick={() => history.push(`/${enterPriseUrl}/event`)}
                style={{ cursor: "pointer" }}
              />
              <Typography className={styles?.Title}>
                {localStorage.getItem(ENTERPRISE_NAME) || "Nine Event"}
              </Typography>
            </Grid>
            <Grid item className={styles?.searchHeader}>
              {hideSearch.slice(-6) !== "/event"?"":
                <Box sx={{ display: "flex", alignItems: "flex-end", padding:"4px 10px" }}>
                  <TextField
                    onChange={searchData}
                    id="input-with-sx"
                    placeholder="Nhập từ khoá tìm kiếm"
                    variant="standard"
                    fullWidth={true}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            className="img-search"
                            src={SearchIcon}
                            alt={SearchIcon}
                          />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                      style: { fontSize: "14px" },
                    }}
                  />
                </Box>
              }
            </Grid>
            <Grid
              item
              container
              justifyContent="flex-end"
              alignItems="center"
              className={styles?.HeaderRight}
            >
              {
                hideSearch.slice(-6) !== "/event"?"":
                <IconButton
                  onClick={() => setSearchMobile(true)}
                  className={
                    styles?.iconBtnHeader + " " + styles?.iconSearchMobile
                  }
                >
                  <img src={SearchMobileIcon} alt="SearchMobileIcon" />
                </IconButton>
              }
              <div 
                className={styles.HeaderIconAction}
                onClick={(event: any) =>
                  onOpenModalSupportService()
                }
              >
                <IconButton
                  style={{padding: "5px"}}
                  className={styles?.iconBtnHeader + " " + styles?.IconSetting}
                >
                  <img src={SupportIcon} alt="SettingIcon" />
                </IconButton>
                <p className={styles.HeaderIconActionLabel}>Hỗ trợ</p>
              </div>

              <div 
                className={styles.HeaderIconAction}
                onClick={(event: any) =>
                  setAnchorElSetting(event.currentTarget)
                }
              >
                <IconButton
                  style={{padding: "5px"}}
                  className={styles?.iconBtnHeader + " " + styles?.IconSetting}
                >
                  <img src={SettingIcon} alt="SettingIcon" />
                </IconButton>
                <p className={styles.HeaderIconActionLabel}>Cài đặt</p>
              </div>

              <div 
                className={styles.HeaderIconAction}
                style={{marginRight: "43px"}}
                  onClick={(event: any) =>
                    {
                      setAnchorElNotification(event.currentTarget)
                      removeNotification()
                    }
                  }
                >
                <IconButton
                  style={{padding: '5px'}}
                  className={
                    styles?.iconBtnHeader + " " + styles?.IconNotification
                  }
                >
                  <Badge 
                    badgeContent={numOfUnread} color="error">
                    <img src={NotificationIcon} alt="NotificationIcon"/>
                  </Badge>
                </IconButton>
                <p className={styles.HeaderIconActionLabel}>Thông báo</p>
              </div>

              <AccountHeader onSelectEnterprise={onSelectEnterprise} />
            </Grid>
          </Grid>
        )}
      </div>

      <Drawer
            className="sp-only"
            anchor="left"
            open={openAnchorElModule}
            onClose={() => setAnchorElModule(null)}
      >
        <div className={styles?.PopoverModule}>
          <div className={styles?.LogoCompanyHead}>
            <div className={styles?.LogoCompanyName}>
              <img
                src={
                  infoEnterpriseState?.companyDetail?.logo?`/media/images/${infoEnterpriseState?.companyDetail?.logo}`: logo
                }
                alt=""
                className={styles?.Logo}
                style={{ cursor: "pointer" }}
              />
              <Typography className={styles?.Title}>
                {localStorage.getItem(ENTERPRISE_NAME) || "Nine Event"}
              </Typography>
            </div>
            <div className="exit-page">
              <IconButton
                onClick={() => setAnchorElModule(null)}
                className="exit-btn"
                disableFocusRipple
                disableRipple
              >
              <img src={CloseDrawIcon} alt="CloseDrawIcon" />
            </IconButton>
          </div>
          </div>
          <Link
            to={infoEnterprise?.result?.url?`/${infoEnterprise?.result?.url}/event`:"/"}
            className={styles?.Link}
            style={{ padding: "6px 18px" }}
          >
            <img src={StarIcon} alt="" />
            <p>Sự kiện</p>
          </Link>
          <Link
            to={`/`}//${infoEnterprise?.result?.url}/booking-room
            className={styles?.Link}
            //style={{ marginTop: "15px" }}
            style={{ padding: "6px 18px" }}
          >
            <img src={BookService} alt="" />
            <p>Dịch vụ</p>
          </Link>
          <Link
            to={infoEnterprise?.result?.url?`/${infoEnterprise?.result?.url}/event`:"/"}
            className={styles?.Link}
            //style={{ marginTop: "15px" }}
            style={{ padding: "6px 18px" }}
          >
            <img src={Calendar} alt="" />
            <p>Phòng họp</p>
          </Link>
        </div>
      </Drawer>
      {/* Popover module */}
      <Popover
      className="sp-except"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={openAnchorElModule}
        anchorEl={anchorElModule}
        onClose={() => setAnchorElModule(null)}
        //elevation={0}
        //style={{ marginTop: "1em" }}
      >
        <div className={styles?.PopoverModule}>
          <Link
            to={infoEnterprise?.result?.url?`/${infoEnterprise?.result?.url}/event`:"/"}
            className={styles?.Link}
            style={{ padding: "6px 18px" }}
          >
            <img src={StarIcon} alt="" />
            <p>Sự kiện</p>
          </Link>
          <Link
            to={`/`}//${infoEnterprise?.result?.url}/booking-room
            className={styles?.Link}
            //style={{ marginTop: "15px" }}
            style={{ padding: "6px 18px" }}
          >
            <img src={BookService} alt="" />
            <p>Dịch vụ</p>
          </Link>
          <Link
            to={infoEnterprise?.result?.url?`/${infoEnterprise?.result?.url}/event`:"/"}
            className={styles?.Link}
            //style={{ marginTop: "15px" }}
            style={{ padding: "6px 18px" }}
          >
            <img src={Calendar} alt="" />
            <p>Phòng họp</p>
          </Link>
        </div>
      </Popover>

      {/* Popover setting */}
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openAnchorElSetting}
        anchorEl={anchorElSetting}
        onClose={() => setAnchorElSetting(null)}
        //elevation={0}
      >
        <div className={styles?.PopoverSetting}>
          {WithPermission(
            [
              PERMISSIONS.IAM_ACCOUNT_CREATE,
              PERMISSIONS.IAM_ACCOUNT_UPDATE,
              PERMISSIONS.IAM_ACCOUNT_READ,
              PERMISSIONS.IAM_ACCOUNT_DELETE, 
            ],
            permissions
          ) ? (
            <Link
              to={`/${infoEnterprise?.result?.url}/event/user`}
              className={styles?.Link}
              style={{ padding: "6px 18px" }}
            >
              <img src={userBlack} alt="" />
              <p>Quản trị viên</p>
            </Link>
          ) : (
            ""
          )}
          {/* Link to="/" is temporary */}
          <Link
            to={`/${infoEnterprise?.result?.url}/setting`}
            className={styles?.Link}
            //</div> style={{ marginTop: "2em" }}
            style={{ padding: "6px 18px" }}
          >
            <img src={SettingIcon} alt="" />
            <p>Cài đặt hệ thống</p>
          </Link>
        </div>
      </Popover>

      {/* Popover notification */}
      <Popover
        className="notiPopover sp-except"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openAnchorElNotification}
        anchorEl={anchorElNotification}
        onClose={() => setAnchorElNotification(null)}
      >
        <Paper className={styles?.PopoverNotification}>
          <div className={styles?.TitleWrapper}>
            <Typography>Thông báo</Typography>
            <IconButton onClick={() => setAnchorElNotification(null)}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles?.ContentWrapper}>
            {userNotificationList?.map((element: any) => {
              return (
                <NotificationItem
                  key={element.id}
                  notification={element}
                  onRemoveNotification={handleRemoveNotification}
                />
              )
            })}
          </div>
          {/* <div className="notifications">
            {userNotificationList?.map((element) => {
              return (
                <NotificationItem
                  key={element.id}
                  notification={element}
                  onRemoveNotification={handleRemoveNotification}
                />
              );
            })}
          </div> */}
        </Paper>
      </Popover>
      <Popover
        className="notiPopover sp-only"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openAnchorElNotification}
        anchorEl={anchorElNotification}
        onClose={() => setAnchorElNotification(null)}
        PaperProps={{ style: { width: "75%" } }}
      >
        <Paper className={styles?.PopoverNotification}>
          <div className={styles?.TitleWrapper}>
            <Typography>Thông báo</Typography>
            <IconButton onClick={() => setAnchorElNotification(null)}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles?.ContentWrapper}>
            {userNotificationList?.map((element: any) => {
              return (
                <NotificationItem
                  key={element.id}
                  notification={element}
                  onRemoveNotification={handleRemoveNotification}
                />
              )
            })}
          </div>
        </Paper>
      </Popover>

    {isPageWide ? (
      <ModalSupportServiceMobile
        open={isOpenModalSupportService}
        onClose={onCloseModalSupportService}
        guidePostsList={[]}
      />
    ) : (
      <ModalSupportService
        open={isOpenModalSupportService}
        onClose={onCloseModalSupportService}
        guidePostsList={[]}
      />      
    )}
    </Fragment>
  )
}

export default Header
