import { IAction } from "@Interfaces/common";
import ActionType from "@Actions/UserEvent/action-types";
import { fromJS } from "immutable";

const initialState = fromJS({
  type: null,
  payload: null,
  roles: null,
  error: null,
  isLoading: false,
  isLoadingPermission: false,
  permissions: null,
});
export default (state: any = initialState, action: IAction) => {
  switch (action.type) {
    case ActionType.GET_LIST_USER_EVENT_START:
      return state
        .set("type", fromJS(action.type))
        .set("isLoading", true)
        .set("error", null);

    case ActionType.GET_LIST_USER_EVENT_SUCCESS:
      return state
        .set("type", fromJS(action.type))
        .set("isLoading", false)
        .set("payload", fromJS(action.payload))
        .set("error", null);
    case ActionType.GET_LIST_USER_EVENT_ERROR:
      return state
        .set("type", fromJS(action.type))
        .set("isLoading", false)
        .set("isFetched", false)
        .set("error", fromJS(action.error));
    case ActionType.GET_LIST_ROLE_EVENT_START:
      return state.set("type", fromJS(action.type)).set("error", null);
    case ActionType.GET_LIST_ROLE_EVENT_SUCCESS:
      return state
        .set("type", fromJS(action.type))
        .set("roles", fromJS(action.payload))
        .set("error", null);
    case ActionType.GET_LIST_ROLE_EVENT_ERROR:
      return state
        .set("type", fromJS(action.type))
        .set("error", fromJS(action.error));
    case ActionType.GET_PERMISSION_EVENT_START:
      return state
        .set("type", fromJS(action.type))
        .set("error", null)
        .set("isLoadingPermission", true);
    case ActionType.GET_PERMISSION_EVENT_SUCCESS:
      return state
        .set("type", fromJS(action.type))
        .set("permissions", fromJS(action.payload))
        .set("error", null)
        .set("isLoadingPermission", false);
    case ActionType.GET_PERMISSION_EVENT_ERROR:
      return state
        .set("type", fromJS(action.type))
        .set("error", fromJS(action.error))
        .set("isLoadingPermission", false);
    default:
      return state;
  }
};
