import forgotPasswordSaga from "@Sagas/forgotPasswordSaga";
import loginSaga from "@Sagas/loginSaga";
import mediaSaga from "@Sagas/mediaSaga";
import registerSaga from "@Sagas/registerSaga";
import resetPasswordSaga from "@Sagas/resetPasswordSaga";
import profileSaga from "@Sagas/profileSaga";
import enterpriseSaga from "@Sagas/enterpriseSaga";
import userSaga from "@Sagas/userSaga";
import userEventSaga from "@Sagas/userEventSaga";
import userNotificationSaga from "@Sagas/userNotificationSaga";
import eventSaga from "@Sagas/eventSaga";
import accountSaga from "@Sagas/accountSaga";
import { all } from "redux-saga/effects";

export default function* rootSaga() {
  yield all([
    loginSaga(),
    forgotPasswordSaga(),
    resetPasswordSaga(),
    registerSaga(),
    profileSaga(),
    mediaSaga(),
    enterpriseSaga(),
    userSaga(),
    userEventSaga(),
    userNotificationSaga(),
    eventSaga(),
    accountSaga(),
  ]);
  // code after all-effect
}
