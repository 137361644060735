
import ActionType from "./action-types";
export const changeProfileAction = (dataProfile:any,callbackSuccess:any) => ({
  type: ActionType.CHANGE_PROFILE_START,
  dataProfile,
  callbackSuccess,
  payload: null,
  error: null,
});
export const changeProfileSuccessAction = (result: any) => ({
  type: ActionType.CHANGE_PROFILE_SUCCESS,
  payload: result,
  error: null,
});
export const changeProfileErrorAction = (error: Error) => ({
  type: ActionType.CHANGE_PROFILE_ERROR,
  payload: null,
  error: error,
});
// Get profile
// export const getProfileAction = (prams:any,callbackSuccess:any) => ({
//   type: ActionType.GET_PROFILE_START,
//   prams,
//   callbackSuccess,
//   payload: null,
//   error: null,
// });
// export const getProfileSuccessAction = (result: any) => ({
//   type: ActionType.GET_PROFILE_SUCCESS,
//   payload: result,
//   error: null,
// });
