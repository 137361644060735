//Lib
//Icon
import React from "react";
//styled
import { ComponentWrapper } from "./styled";



const ErrorMessageStyle = (props: any) => {
  return (
    <ComponentWrapper>
      {
        props?.children
      }
    </ComponentWrapper>
  );
};

export default ErrorMessageStyle;
