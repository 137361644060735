
import ActionType from "./action-types";
export const uploadImageAction = (formData:FormData,callbackSuccess:Function, callbackError:Function) => ({
  type: ActionType.UPLOAD_IMAGE_START,
  formData,
  callbackSuccess,
  callbackError,
  payload: null,
  error: null,
});
export const uploadImageSuccessAction = (result: any) => ({
  type: ActionType.UPLOAD_IMAGE_SUCCESS,
  payload: result,
  error: null,
});
export const uploadImageErrorAction = (error: Error) => ({
  type: ActionType.UPLOAD_IMAGE_ERROR,
  payload: null,
  error: error,
});
