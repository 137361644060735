import ModalAddUser from "@Components/User/ModalAddUser/ModalAddUser";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Paper,
  Popover,
  Typography,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  ListItemText, ListItem, ListItemIcon,
} from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import React, {useState} from "react";
import { TYPE_CONFIRM } from "./const";
import "./style.scss";
import { MEDIA_IMAGE_URL, PERMISSIONS } from "@Constants/common";
import WithPermission from "@Hocs/withPermission";
import MoreOverIcon from '@Assets/icons/user/moreover.svg';
import addIcon from '@Assets/icons/event/PlusIconWhite.svg';
import deleteIcon from "@Assets/icons/event/deleteIcon.svg";

interface IMain {
  isLoading: any;
  permissions: any;
  triggerOpenModalAddUser: any;
  users: any;
  pagination: any;
  limit: any;
  page: any;
  onChangePage: any;
  handleShowMenuRole: any;
  handleShowMenuRoleSp: any;
  handleOpenModalConfirm: any;
  openMenuRole: any;
  openMenuRoleSp: any;
  menuRoleAnchorEl: any;
  menuRoleAnchorElSp: any;
  handleCloseMenuRole: any;
  handleCloseMenuRoleSp: any;
  roles: any;
  openModalConfirm: any;
  handleCloseModalConfirm: any;
  titleConfirm: any;
  handleSubmitConfirm: any;
  openModalAddUser: any;
}

const Main: React.FC<IMain> = ({
  isLoading,
  permissions,
  triggerOpenModalAddUser,
  users,
  pagination,
  limit,
  page,
  onChangePage,
  handleShowMenuRole,
  handleShowMenuRoleSp,
  handleOpenModalConfirm,
  openMenuRole,
  openMenuRoleSp,
  menuRoleAnchorEl,
  menuRoleAnchorElSp,
  handleCloseMenuRole,
  handleCloseMenuRoleSp,
  roles,
  openModalConfirm,
  handleCloseModalConfirm,
  titleConfirm,
  handleSubmitConfirm,
  openModalAddUser,
}) => {
  const [anchorElDelete, setAnchorElDelete] = useState(null);
  const openDelete = Boolean(anchorElDelete);
  const [userId,setUserId] = useState(null)
  const handleCloseDelete = () => {
    setAnchorElDelete(null);
  };

  const handleOpenDelete = (event:any)=>{
    setAnchorElDelete(event.currentTarget);
  }

  const handleDelete = ()=>{
    if(userId){
      handleOpenModalConfirm(
        TYPE_CONFIRM?.DELETE_USER,
        userId
      )
      setAnchorElDelete(null);
    }
    console.log("id",userId)
  }

  return (
    <Box className='User-wrapper'>
      <Box className='list-user-wrapper'>
        {isLoading ? (
          <div className='isLoading'>
            <Skeleton animation="wave" height={80} width="20%" style={{ marginBottom: 1, alignSelf:"flex-end" }} />
            <Skeleton animation="wave" height={80} width="100%" style={{ marginBottom: 1 }} />
            <Skeleton animation="wave" height={80} width="100%" style={{ marginBottom: 1 }} />
            <Skeleton animation="wave" height={80} width="100%" style={{ marginBottom: 1 }} />
            <Skeleton animation="wave" height={80} width="100%" style={{ marginBottom: 1 }} />
            <Skeleton animation="wave" height={80} width="100%" style={{ marginBottom: 1 }} />
          </div>
        ) : (
          <TableContainer className="userlist-table-container">
              <Table className='userlist-table'  aria-label="a dense table">
                {
                  WithPermission([PERMISSIONS.IAM_ACCOUNT_CREATE], permissions) && <TableHead>
                    <TableCell align="right" colSpan={5} className="header-button">
                      <Button 
                        onClick={triggerOpenModalAddUser}
                        variant="contained"
                        color="primary"
                        className="btn-add-user"
                      >
                         <img src={addIcon} alt="" style={{marginRight: "12px"}}/>
                        Thêm quản trị
                      </Button>
                    </TableCell>
                  </TableHead> 
                }
                <TableBody className="table-content sp-except">
                    {users?.map((row: any) => (
                        <TableRow key={row.id}>
                          <TableCell width={40}>
                            <Avatar
                              alt=''
                              src={`${MEDIA_IMAGE_URL}/${row.userDetail?.photo}`}
                              className='avatar'
                            />
                          </TableCell>
                          <TableCell className="general-table">
                            {
                              row.userDetail
                              ? (row.userDetail.firstName +" "+ row.userDetail.lastName)
                              : row.email
                            }
                          </TableCell>
                          <TableCell className="general-table mail-hide">{row.email}</TableCell>
                          <TableCell className="general-table" align="right">
                            <div className="role-option">
                              {
                                 row.role === "meeting-booking" ? "Quản lý event":row?.roleDetail?.title
                              }
                              {/* {row?.roleDetail?.title} */}
                              {WithPermission([PERMISSIONS.IAM_ACCOUNT_UPDATE],permissions) && (
                                <IconButton
                                  color='default'
                                  component='span'
                                  onClick={(event: any) =>
                                    handleShowMenuRole(event, row?.id)
                                  }
                                >
                                  <ArrowDropDownIcon />
                                </IconButton>
                              )}
                            </div>
                            
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                            className="more-over-btn"
                            style={{minWidth: "83px"}}
                            onClick={(e)=>{
                              handleOpenDelete(e);
                              setUserId(row?.id)
                            }}>
                              <img src={MoreOverIcon} alt="" />
                            </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableBody className="table-content sp-only">
                    {users?.map((row: any) => (
                        <TableRow key={row.id}>
                          <TableCell width={40}>
                            <Avatar
                              alt=''
                              src={`${MEDIA_IMAGE_URL}/${row.userDetail?.photo}`}
                              className='avatar'
                            />
                          </TableCell>
                          <TableCell className="general-table">
                           <div>
                           {
                              row.userDetail
                              ? (row.userDetail.firstName +" "+ row.userDetail.lastName)
                              : row.email
                            }
                            &nbsp;-&nbsp;
                            {
                              row.role === "meeting-booking" ? "Quản lý event":row?.roleDetail?.title
                            }
                           </div>
                           <div>
                           {row.email}
                           </div>
                          </TableCell>
                          <TableCell align="center">
                            <IconButton className="more-over-btn"
                            onClick={(event: any) =>{
                                    handleShowMenuRoleSp(event, row?.id);
                                    setUserId(row?.id)
                                  }
                                  }>
                              <img src={MoreOverIcon} alt="" />
                            </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
              </Table>
          </TableContainer>
        )}
      </Box>

      {/* Delete */}
      <Popover
        open={openDelete}
        anchorEl={anchorElDelete}
        onClose={handleCloseDelete}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            width: `150px`,
          },
        }}
        className="list-item"
      >
        <ListItem className="list-item-btn" onClick={handleDelete}>
          <ListItemIcon className="img-delete">
            <img src={deleteIcon} alt={deleteIcon} />
          </ListItemIcon>
          <ListItemText className="btn-delete">Xóa quản trị</ListItemText>
        </ListItem>
      </Popover>

      {/* Popover */}
      <Popover
        open={openMenuRole}
        anchorEl={menuRoleAnchorEl}
        onClose={handleCloseMenuRole}
        className='popover-select-role'
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Grid
          container
          justifyContent='space-between'
          className='popover-select-role-main'
        >
          {roles?.map((item: any, index: any) => {
            return (
              (item.name === "meeting-manager")
              ? <></>
              : <Grid
                  key={index}
                  container
                  justifyContent='space-between'
                  className='popover-select-role-main-item'
                  onClick={() =>
                    handleOpenModalConfirm(TYPE_CONFIRM?.CHANGE_ROLE, item?.name)
                  }
                >
                  {
                    item.name === "meeting-booking" ? "Quản lý event":item?.title
                  }
              </Grid>
            );
          })}
        </Grid>
      </Popover>

      {/* Popover for sp */}
      <Popover
        open={openMenuRoleSp}
        anchorEl={menuRoleAnchorElSp}
        onClose={handleCloseMenuRoleSp}
        className='popover-select-role'
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Grid
          container
          justifyContent='space-between'
          className='popover-select-role-main'
        >
          {roles?.map((item: any, index: any) => {
            return (
              (item.name === "meeting-manager")
              ? <></> :
               <Grid
                  key={index}
                  container
                  justifyContent='space-between'
                  className='popover-select-role-main-item'
                  onClick={() =>
                    handleOpenModalConfirm(TYPE_CONFIRM?.CHANGE_ROLE, item?.name)
                  }
                >
                  {
                    item.name === "meeting-booking" ? "Quản lý event":item?.title
                  }
              </Grid>
            );
          })}
            <ListItem className="list-item-btn" onClick={handleDelete}>
              <ListItemIcon className="img-delete">
                <img src={deleteIcon} alt={deleteIcon} />
              </ListItemIcon>
              <ListItemText className="btn-delete">Xóa quản trị</ListItemText>
            </ListItem>
        </Grid>
      </Popover>

      {/* Modal confirm */}
      {openModalConfirm && (
        <Modal
          open={true}
          onClose={handleCloseModalConfirm}
          className='modal-confirm'
        >
          <Paper className='modal-confirm-main'>
            <Typography
              variant='h5'
              color='primary'
              style={{ marginBottom: "4rem", textAlign: "center" }}
            >
              {titleConfirm}
            </Typography>
            <Grid container justifyContent='space-between'>
              <Button
                variant='contained'
                color='default'
                onClick={handleCloseModalConfirm}
                className="cancel-btn"
              >
                Hủy
              </Button>
              <Button
                variant='contained'
                color='primary'
                style={{ marginLeft: "1rem" }}
                className="submit-btn"
                onClick={handleSubmitConfirm}
              >
                Xác Nhận
              </Button>
            </Grid>
          </Paper>
        </Modal>
      )}
      {/* Modal add user */}
      {openModalAddUser && (
        <ModalAddUser
          roles={roles}
          triggerOpenModalAddUser={triggerOpenModalAddUser}
        />
      )}
    </Box>
  );
};

export default Main;
