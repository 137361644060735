export default {
  ADD_USER_START: "ADD_USER_START",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_ERROR: "ADD_USER_ERROR",
  GET_LIST_USER_START: "GET_LIST_USER_START",
  GET_LIST_USER_SUCCESS: "GET_LIST_USER_SUCCESS",
  GET_LIST_USER_ERROR: "GET_LIST_USER_ERROR",
  GET_LIST_ROLE_START: "GET_LIST_ROLE_START",
  GET_LIST_ROLE_SUCCESS: "GET_LIST_ROLE_SUCCESS",
  GET_LIST_ROLE_ERROR: "GET_LIST_ROLE_ERROR",
  UPDATE_USER_START: "UPDATE_USER_START",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_ERROR: "UPDATE_USER_ERROR",
  DELETE_USER_START: "DELETE_USER_START",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_ERROR: "DELETE_USER_ERROR",
  GET_PERMISSION_START: "GET_PERMISSION_START",
  GET_PERMISSION_SUCCESS: "GET_PERMISSION_SUCCESS",
  GET_PERMISSION_ERROR: "GET_PERMISSION_ERROR",
};
