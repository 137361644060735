import { Api } from "@Configs/api/api";
import apiConfig from "@Configs/api/api.config";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import {
  API_CHANGE_ACCOUNT,
  API_GET_ACCOUNT,
  API_ADD_ACCOUNT
} from "./endpoint";

class BaseApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config);
    this.changeAccount = this.changeAccount.bind(this);
    this.getAccount = this.getAccount.bind(this)
    this.addAccount = this.getAccount.bind(this)
  }


  public getAccount(prams: any): Promise<any> {
    return this.get<any>(API_GET_ACCOUNT, {
      headers: {}, params: prams
    }).then((response: AxiosResponse<any>) => {
      const { data } = response;
      return data
    }).catch((error: AxiosError) => { throw error; })
  }


  public changeAccount(id: any): Promise<any> {
    return this.delete<any>(`${API_CHANGE_ACCOUNT}/${id}`, {
      headers: {},
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public addAccount(id: any, value:any): Promise<any> {
    return this.post<any>(`${API_ADD_ACCOUNT}/${id}`, value,  {
      headers: {},
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }
}

const baseApi = new BaseApi(apiConfig);
export default baseApi;
