export default {
    ADD_EVENT_START: "ADD_EVENT_START",
    ADD_EVENT_SUCCESS: "ADD_EVENT_SUCCESS",
    ADD_EVENT_ERROR: "ADD_EVENT_ERROR",
    GET_LIST_EVENT_START: "GET_LIST_EVENT_START",
    GET_LIST_EVENT_SUCCESS: "GET_LIST_EVENT_SUCCESS",
    GET_LIST_EVENT_ERROR: "GET_LIST_EVENT_ERROR",
    GET_DETAIL_EVENT_START: "GET_DETAIL_EVENT_START",
    GET_DETAIL_EVENT_SUCCESS: "GET_DETAIL_EVENT_SUCCESS",
    GET_DETAIL_EVENT_ERROR: "GET_DETAIL_EVENT_ERROR",
    UPDATE_EVENT_START: "UPDATE_EVENT_START",
    UPDATE_EVENT_SUCCESS: "UPDATE_EVENT_SUCCESS",
    UPDATE_EVENT_ERROR: "UPDATE_EVENT_ERROR",
    DELETE_EVENT_START: "DELETE_EVENT_START",
    DELETE_EVENT_SUCCESS: "DELETE_EVENT_SUCCESS",
    DELETE_EVENT_ERROR: "DELETE_EVENT_ERROR",
    UPDATE_CHECK_IN_EVENT_SUCCESS: "UPDATE_CHECK_IN_EVENT_SUCCESS",
    UPDATE_CHECK_IN_EVENT_START: "UPDATE_CHECK_IN_EVENT_START",
    UPDATE_CHECK_IN_EVENT_ERROR: "UPDATE_CHECK_IN_EVENT_ERROR",
    GET_LIST_EVENT_USER_START: "GET_LIST_EVENT_USER_START",
    GET_LIST_EVENT_USER_SUCCESS: "GET_LIST_EVENT_USER_SUCCESS",
    GET_LIST_EVENT_USER_ERROR: "GET_LIST_EVENT_USER_ERROR",
    ADD_USER_EVENTS_START: "ADD_USER_EVENTS_START",
    ADD_USER_EVENT_SUCCESS: "ADD_USER_EVENT_SUCCESS",
    ADD_USER_EVENT_ERROR: "ADD_USER_EVENT_ERROR",
    RESTORE_EVENT_START: "RESTORE_EVENT_START",
    RESTORE_EVENT_SUCCESS: "RESTORE_EVENT_SUCCESS",
    RESTORE_EVENT_ERROR: "RESTORE_EVENT_ERROR",
    PUBLISH_EVENT_START: "PUBLISH_EVENT_START",
    PUBLISH_EVENT_SUCCESS: "PUBLISH_EVENT_SUCCESS",
    PUBLISH_EVENT_ERROR: "PUBLISH_EVENT_ERROR",
  };
  