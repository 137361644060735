import { Paper, TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

const PageWrapper = styled("div")`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const RegisterPaper = styled(Paper)`
  padding: 2rem;
  min-width: 400px;
`;
const TextFieldStyle = styled(TextField)`
  width: 100%;
`;
const LinkStyle = styled(Link)`
  color: #606060;
  font-size: 0.8rem;
`;
export { PageWrapper, RegisterPaper, TextFieldStyle, LinkStyle };
