//Component Custom
//Image-Icon
//Action
import { registerAction } from "@Actions/Register/action";
import logo from "@Assets/images/RegisterLogin/logo.svg";
import ErrorMessageStyle from "@Components/common/ErrorMessageStyle/ErrorMessageStyle";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
//Component Material
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
//Hocs
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import PhoneIphoneOutlinedIcon from "@material-ui/icons/PhoneIphoneOutlined";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";

//Icon Material
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
//Lib
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import {
  LinkStyle,
  PageWrapper,
  RegisterPaper,
  TextFieldStyle,
} from "./styled";
import { IRegisterCredentials } from "@Interfaces/credentials";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

const RECAPTCHA_TOKEN =
  process.env.REACT_APP_RECAPTCHA_TOKEN || process.env.RECAPTCHA_TOKEN;
const RegisterLogin: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);

  const handleSubmitForm = (values: IRegisterCredentials) => {
    // console.log(values);

    if (window.grecaptcha && window.grecaptcha.ready) {
      // (_)
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(`${RECAPTCHA_TOKEN}`, { action: "register" })
          .then((token: string) => {
            const callbackSuccess = () => {
              history.push("/login");
            };
            const callbackError = () => {};
            dispatch(
              registerAction(token, values, callbackSuccess, callbackError)
            );
          });
      });
    }
  };
  const handleClickToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Vui lòng nhập email")
      .email("Email không hợp lệ !"),
    firstName: yup.string().required("Vui lòng nhập tên"),
    lastName: yup.string().required("Vui lòng nhập họ"),
    password: yup.string().required("Vui lòng nhập mật khẩu"),
    phone: yup
      .string()
      .required("Vui lòng nhập số điện thoại")
      .matches(
        /((?:\+?)84[3|5|7|8|9]|0[3|5|7|8|9])+([0-9]{8})\b/g,
        "Số điện thoại không hợp lệ"
      ),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <PageWrapper>
      <RegisterPaper>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
        >
          <Avatar src={logo} alt='Logo' />
          <Typography>Đăng ký tài khoản </Typography>
        </Grid>

        <Grid container>
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <Grid container direction={"column"} spacing={1}>
              <Grid container justifyContent='space-between' item xs={12}>
                <Grid item xs={12} sm={5}>
                  <TextField
                    name='firstName'
                    label='Nhập tên'
                    inputProps={{}}
                    inputRef={register}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <PermIdentityOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <ErrorMessage
                    errors={errors}
                    name='firstName'
                    render={({ message }) => (
                      <ErrorMessageStyle>{message}</ErrorMessageStyle>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextFieldStyle
                    name='lastName'
                    label='Nhập họ'
                    inputProps={{}}
                    inputRef={register}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <PermIdentityOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <ErrorMessage
                    errors={errors}
                    name='lastName'
                    render={({ message }) => (
                      <ErrorMessageStyle>{message}</ErrorMessageStyle>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <TextFieldStyle
                  name='email'
                  label='Email'
                  inputProps={{}}
                  inputRef={register}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <EmailOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <ErrorMessage
                  errors={errors}
                  name='email'
                  render={({ message }) => (
                    <ErrorMessageStyle>{message}</ErrorMessageStyle>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <TextFieldStyle
                  name='phone'
                  label='Số điện thoại'
                  inputProps={{}}
                  inputRef={register}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <PhoneIphoneOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <ErrorMessage
                  errors={errors}
                  name='phone'
                  render={({ message }) => (
                    <ErrorMessageStyle>{message}</ErrorMessageStyle>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldStyle
                  name='password'
                  type={showPassword ? "text" : "password"}
                  label='Mật khẩu'
                  inputProps={{}}
                  inputRef={register}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <VpnKeyOutlinedIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickToggleShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name='password'
                  render={({ message }) => (
                    <ErrorMessageStyle>{message}</ErrorMessageStyle>
                  )}
                />
              </Grid>

              <Grid
                item
                container
                justifyContent='space-between'
                alignItems='center'
                xs={12}
              >
                <LinkStyle to='/login'>Đăng Nhập</LinkStyle>
                <Button variant='contained' color='primary' type='submit'>
                  Đăng Ký
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </RegisterPaper>
    </PageWrapper>
  );
};

export default RegisterLogin;
