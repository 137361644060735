import React, { useState } from "react";
//Component
import { Div, Span, P } from "@Common/TagAntdNotSupport/TagAntdNotSupport";
import PopoverEditProfile from "@Components/Profile/PopoverEditProfile/index"
// import AddressPicker from "@Components/common/AddressPicker/AddressPicker"
// React Hook Form
//Const
// antd

// Img

import editIcon from "@Assets/icons/user/editProfile.svg";
import "./styles.scss"
interface IProps {
    dataInfoGrid: any,
  onSubmitProfile: Function,
  rowConfigItem: {
    id: string,
    name: string,
    type: string,
    className: string,
    popoverTitle: string,
    keyValue: string
  }
}

const FormContentInFo = (props: IProps) => {
  const { dataInfoGrid, onSubmitProfile, rowConfigItem } = props;

  // Popover
  const [typePopover, setTypePopover] = useState("");

  const [editProfileAnchorEl, setEditProfileAnchorEl] = useState(null);
  const handleClosePopoverEditProfile = () => setEditProfileAnchorEl(null);
  const handleOpenPopoverEditProfile = (event:any) => {
    setEditProfileAnchorEl(event.currentTarget);
  };
  const handleChangeVisiblePopover=(event:any,type:any)=>{
    setTypePopover(type);
    handleOpenPopoverEditProfile(event);
  }
  return (<>
      <Div className="value" id={rowConfigItem.className}  >
        <Span className={rowConfigItem.className}>
          {dataInfoGrid?.[rowConfigItem?.keyValue]?.value}
        </Span>
        
        {/* {rowConfigItem.className !== "email"?  */}
        <span className="editBtn" >
        <img 
        onClick={(event:any)=>handleChangeVisiblePopover(event,rowConfigItem.type)} 
        src={editIcon} 
        alt="editIcon" />
        </span>
        {/* :""} */}
      </Div>
     { editProfileAnchorEl? <PopoverEditProfile 
     dataInfoGrid={dataInfoGrid} 
     dataDefault={dataInfoGrid?.[rowConfigItem?.keyValue]} 
     onSubmit={onSubmitProfile} type={typePopover} rowConfigItem={rowConfigItem}
     onClosePopover={handleClosePopoverEditProfile} 
     anchorEl={editProfileAnchorEl}/>
     :null}
     </>
  );
};

export default FormContentInFo;
