import { Api } from "@Configs/api/api";
import apiConfig from "@Configs/api/api.config";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import {
    API_ADD_EVENT,
    API_GET_LIST_EVENT,
    API_GET_DETAIL_EVENT,
    API_UPDATE_EVENT,
    API_DELETE_EVENT,
    API_UPDATE_CHECK_IN_EVENT,
    API_GET_LIST_USER_EVENT,
    API_ADD_USER_EVENT,
    API_GET_LIST_DELETE_EVENT,
    API_RESTORE_EVENT,
    API_PUBLISH_EVENT,
    API_GET_LIST_SHARE_EVENT,
    API_GET_DETAIL_USER_EVENT,
    API_DELETE_USER_EVENT,
    API_SEND_EMAIL_REGISTER_EVENT,
    API_SEND_EMAIL_CREATE_EVENT,
    API_GET_LIST_EVENT_EMBED,
    API_ADD_GET_DETAIL_EVENT,
    API_UPLOAD_EXCEL,
    API_INVITE_EMAIL,
} from "./endpoint";
import { ENTERPRISE_ID } from './../../constants/localStorage';
import { cleanQueryParams } from "@Helpers/index";

class BaseApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config);
    this.addEvent = this.addEvent.bind(this);
    this.getListEvent = this.getListEvent.bind(this);
    this.getDetailEvent = this.getDetailEvent.bind(this);
    this.updateEvent = this.updateEvent.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
    this.updateUserCheckInEvent = this.updateUserCheckInEvent.bind(this);
    this.getListUserEvent = this.getListUserEvent.bind(this);
    this.addUserEvent = this.addUserEvent.bind(this);
    this.getListDeletedEvent = this.getListDeletedEvent.bind(this);
    this.restoreEvent = this.restoreEvent.bind(this);
    this.PublishEvent = this.PublishEvent.bind(this);
    this.getListShareEvent = this.getListShareEvent.bind(this);
    this.createQrCodeEvent = this.createQrCodeEvent.bind(this);
    this.getDetailUserEvent = this.getDetailUserEvent.bind(this);
    this.deleteUserEvent = this.deleteUserEvent.bind(this);
    this.sendEmailRegisterEvent = this.sendEmailRegisterEvent.bind(this);
    this.sendEmailCreateEvent = this.sendEmailCreateEvent.bind(this);
    this.getListEventEmbed = this.getListEventEmbed.bind(this);
    this.getAddDetailEvent = this.getAddDetailEvent.bind(this);
    this.uploadExcel = this.uploadExcel.bind(this)
    this.sendEmailInvites = this.sendEmailInvites.bind(this)
  }
  public addEvent(data: any): Promise<any> {
    return this.post<any>(API_ADD_EVENT, data, {
        headers: {
            "x-business-id": localStorage.getItem(ENTERPRISE_ID)
        },
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        

        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public getListEvent(params?: any): Promise<any> {
    return this.get<any>(API_GET_LIST_EVENT, {
        headers: {
            "x-business-id": localStorage.getItem(ENTERPRISE_ID)
        },
        params: cleanQueryParams({...params})
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public getListEventEmbed(params?: any): Promise<any> {
    return this.get<any>(API_GET_LIST_EVENT_EMBED, {
        headers: {
            "x-business-id": localStorage.getItem(ENTERPRISE_ID)
        },
        params: cleanQueryParams({...params})
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public getListShareEvent(business: any, params: any): Promise<any> {
    return this.get<any>(`${API_GET_LIST_SHARE_EVENT}?id=${params}`, {
        headers: {
            "x-business-id": business
        }
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public getListDeletedEvent(): Promise<any> {
    return this.get<any>(API_GET_LIST_DELETE_EVENT, {
        headers: {
            "x-business-id": localStorage.getItem(ENTERPRISE_ID)
        }
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        

        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public getDetailEvent(params: any): Promise<any> {
    return this.get<any>(`${API_GET_DETAIL_EVENT}/${params}`, {
      headers: {},
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public getAddDetailEvent(params: any): Promise<any> {
    return this.get<any>(`${API_ADD_GET_DETAIL_EVENT}/${params}`, {
      headers: {"x-business-id": localStorage.getItem(ENTERPRISE_ID)},
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public updateEvent(data: any, params: any): Promise<any> {
    return this.patch<any>(`${API_UPDATE_EVENT}/${params}`, JSON.stringify(data), {
      headers: {
        "x-business-id": localStorage.getItem(ENTERPRISE_ID)
      }
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public deleteEvent(evenId: any): Promise<any> {
    return this.delete<any>(`${API_DELETE_EVENT}/${evenId}`, {
      headers: {"x-business-id": localStorage.getItem(ENTERPRISE_ID)},
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public restoreEvent(evenId: any): Promise<any> {
    return this.delete<any>(`${API_RESTORE_EVENT}/${evenId}`, {
      headers: {"x-business-id": localStorage.getItem(ENTERPRISE_ID)},
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response; 
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public PublishEvent(data: any, params: any): Promise<any> {
    return this.post<any>(`${API_PUBLISH_EVENT}/${params}`, JSON.stringify(data) , {
      headers: {"x-business-id": localStorage.getItem(ENTERPRISE_ID)},
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  //user event

  public updateUserCheckInEvent(data: any, params: any): Promise<any> {
    return this.patch<any>(`${API_UPDATE_CHECK_IN_EVENT}/${params}`, JSON.stringify(data), {
      headers: {
        "x-business-id": localStorage.getItem(ENTERPRISE_ID)
      }
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        console.log("error",error);
        throw error;
      });
  }

  public getListUserEvent(id:any,params: any): Promise<any> {
    return this.get<any>(`${API_GET_LIST_USER_EVENT}/${id}`, {
      headers: {"x-business-id": localStorage.getItem(ENTERPRISE_ID)},
      params: cleanQueryParams({...params})
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        console.log("error",error);
        throw error;
      });
  }

  public addUserEvent(data: any): Promise<any> {
    return this.post<any>(API_ADD_USER_EVENT, JSON.stringify(data),{
      headers: {}
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        console.log("error",error);
        throw error;
      });
  }

  public deleteUserEvent(evenId: any): Promise<any> {
    return this.delete<any>(`${API_DELETE_USER_EVENT}/${evenId}`, {
      headers: {},
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public getDetailUserEvent(params: any): Promise<any> {
    return this.get<any>(`${API_GET_DETAIL_USER_EVENT}/${params}`, {
      headers: {},
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  //send email register event
  public sendEmailRegisterEvent(data: any): Promise<any> {
    return this.post<any>(API_SEND_EMAIL_REGISTER_EVENT, JSON.stringify(data),{
      headers: {}
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        console.log("error",error);
        throw error;
      });
  }

    //send email create Admin event
    public sendEmailCreateEvent(data: any): Promise<any> {
      return this.post<any>(API_SEND_EMAIL_CREATE_EVENT, JSON.stringify(data),{
        headers: {}
      })
        .then((response: AxiosResponse<any>) => {
          const { data } = response;
          return data;
        })
        .catch((error: AxiosError) => {
          console.log("error",error);
          throw error;
        });
    }

  public createQrCodeEvent(Url: any): Promise<any> {
    const QRCODE_URL = "/qrcode/api/qrcode-url";
    return this.post<any>(QRCODE_URL, JSON.stringify(
      {
        "content": Url,
        "contentType": "url"
      }
    ),{
      headers: {}
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        console.log("error",error);
        throw error;
      });
  }

  public uploadExcel(formData: any, eventID: string): Promise<number> {
    const headers: any = {};
    if (eventID) headers["x-event-id"] = eventID;
    return this.post<any>(API_UPLOAD_EXCEL, formData, {
      headers: {
        "x-business-id": localStorage.getItem(ENTERPRISE_ID),
        ...headers
      },
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public sendEmailInvites(data: any, id:any): Promise<any> {
    return this.post<any>(API_INVITE_EMAIL+`/${id}`, JSON.stringify(data),{
      headers: {
        "x-business-id": localStorage.getItem(ENTERPRISE_ID)
      }
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        console.log("error",error);
        throw error;
      });
  }
}

const baseApi = new BaseApi(apiConfig);
export default baseApi;
