import React, { useState } from "react";
import ModalWrapperOpenProp from "@Components/common/ModalWrapperOpenProp";
import TabPanel from "@Components/common/TabPanel";
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  Tab,
  Tabs,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// import phoneCallIcon from "@Assets/images/Common/Header/phone-call-icon.svg";
// import playVideoIcon from "@Assets/images/Common/play-video-icon.svg";
// import qrCodeIcon from "@Assets/images/Common/Header/qrcode.svg";
import phoneCallIcon from "@Assets/icons/event/Phone.svg";
import qrCodeIcon from "@Assets/icons/event/qrcode.svg";
import styles from "./style.module.scss";

interface IProps {
  open?: any;
  onClose: any;
  children?: any;
  styles?: any;
  onClick?: any;
  // handleOpenModalVideoPlayer: (selectedVideoId: number) => void;
  guidePostsList?: any;
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ModalSupportServiceMobile = (props: IProps) => {
  const {
    open,
    onClose,
    children,
    // handleOpenModalVideoPlayer,
    guidePostsList,
  } = props;

  const [tab, setTab] = useState(0);

  const handleTabChange = (event: any, tab: number) => {
    setTab(tab);
  };

  return (
    <ModalWrapperOpenProp
      open={open}
      onClose={onClose}
      isFullScreen={true}
      styles={{
        width: "100%",
        height: "100%",
        padding: "0",
        margin: "0",
      }}>
      <Box className={styles.Wrapper}>
        <Box className={styles.Header}>
          <Box className={styles.HeaderCloseButton}>
            <Tooltip
              title="Đóng"
              className="header-close-button"
              style={{ padding: "0.5rem" }}>
              <IconButton component="span" onClick={onClose}>
                <CloseIcon style={{ color: "#000" }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box className={styles.Body}>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab
              label="Hỗ trợ"
              className={`${styles.Tab} ${tab === 0 ? styles.TabActive : null}`}
              {...a11yProps(0)}
            />
            <Tab
              label="Hướng dẫn"
              className={`${styles.Tab} ${tab === 1 ? styles.TabActive : null}`}
              {...a11yProps(1)}
            />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <Box className={styles.TabPanelInner}>
              <Typography className={styles.ContactText}>
                Từ Thứ Hai - Thứ Sáu, 9:00 sáng - 6:00 chiều
              </Typography>
              <Box className={styles.ContactInfoWrapper}>
                <img src={phoneCallIcon} alt="phone-call" />
                <Typography className={styles.ContactInfoPhoneNumber}>
                  08 6868 5247
                </Typography>
              </Box>
              <div className={styles.QrCode}>
                <img src={qrCodeIcon} alt="QrCode" />
              </div>
            </Box>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Box className={styles.TabPanelInner}>
              {/* <Typography className={styles.GuideText}>
                Xin chào! Hãy bắt đầu làm quen với Ninequiz thông qua các video
                hướng dẫn đơn giản!
              </Typography> */}
              <div className={styles.VideosWrapper}>
                {guidePostsList?.map((video: any) => (
                  <div
                    key={video?.id}
                    className={styles.VideoItem}
                    // onClick={() => handleOpenModalVideoPlayer(video?.id)}
                    >
                    <div className={styles.VideoThumbnailWrapper}>
                      <img
                        src={video?.thumbnail}
                        alt=""
                        className={styles.VideoThumbnailImg}
                      />
                    </div>
                    <Typography className={styles.VideoTitle}>
                      {video?.title}
                    </Typography>
                  </div>
                ))}
              </div>
            </Box>
          </TabPanel>
        </Box>
      </Box>
    </ModalWrapperOpenProp>
  );
};

export default ModalSupportServiceMobile;
