import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./root-reducer";
const sagaMiddleware = createSagaMiddleware();

const immutableTransform = require("redux-persist-transform-immutable");

// console.log(process.env)

export const history = createBrowserHistory();
const enhancers = [];
const middleware = [sagaMiddleware, routerMiddleware(history)];
//Config Persistent redux

export const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["loginReducer"],
  transforms: [
    immutableTransform({
      whitelist: ["loginReducer"],
    }),
  ],
  // stateReconciler: autoMergeLevel2
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

///Config Redux Dev Tool
if (process.env.REACT_APP_NODE_ENV === "development") {
  const devToolsExtension = (window as any).devToolsExtension
    ? (window as any).devToolsExtension()
    : (f: any) => f;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension);
  }
}

// declare global {
//   interface Window {
//     __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
//   }
// }

// const composeEnhancers =
//   typeof window === "object" && window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]
//     ? window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]({})
//     : compose;
// const enhancer = composeEnhancers(
//   applyMiddleware(sagaMiddleware /*other middleware*/)
//   /* other store enhancers if any */
// );

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore<any, any, any, any>(
  persistedReducer,
  composedEnhancers
);
const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;

export default () => {
  return { store, persistor, sagaMiddleware };
};
