  import {
    addEventSuccessAction,
    addEventErrorAction,
    getListEventSuccessAction,
    getListEventErrorAction,
    getDetailEventSuccessAction,
    getDetailEventErrorAction,
    deleteEventSuccessAction,
    deleteEventErrorAction,
    updateEventSuccessAction,
    updateCheckInEventSuccessAction,
    addUserEventSuccessAction,
    addUserEventErrorAction,
    restoreEventSuccessAction,
    publishEventSuccessAction,
  } from "@Actions/Event/action";
  import actionTypes from "@Actions/Event/action-types";
  import baseApi from "@Apis/Event/api";
  import { call, put, takeLatest } from "redux-saga/effects";
  import { notificationOpenAction } from "@Actions/Notification/action";
  import { NOTIFICATION_TYPE } from "@Constants/common";
  
  export function* addEvent({
    data,
    callbackSuccess,
    callbackError,
  }: any): any {
    try {
      const result = yield call(baseApi.addEvent, data);
  
      yield put(addEventSuccessAction(result));
      // yield put(
      //   notificationOpenAction({
      //     isNotification: true,
      //     typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
      //     message: "Thành Công",
      //     description: "Tạo thành công ! ",
      //   })
      // );
      yield callbackSuccess(result);
    } catch (err) {
      let temp = err as any;
  
      yield put(addEventErrorAction(err));
      yield put(
        notificationOpenAction({
          isNotification: true,
          typeNotification: NOTIFICATION_TYPE.ERROR as "error",
          message: "Lỗi",
          description: temp?.response?.data?.message || "Lỗi chưa xác định",
        })
      );
      yield callbackError();
    }
  }
  
  export function* getListEvent({ callbackSuccess }: any): any {
    try {
      const result = yield call(baseApi.getListEvent);
      yield put(getListEventSuccessAction(result));
      yield callbackSuccess(result);
    } catch (err) {
      yield put(getListEventErrorAction(err));
    }
  }
  
  export function* getDetailEvent({ prams, callbackSuccess }: any): any {
    try {
      const result = yield call(baseApi.getDetailEvent, prams);
      yield put(getDetailEventSuccessAction(result));
      yield callbackSuccess(result);
    } catch (err) {
      yield put(getDetailEventErrorAction(err));
    }
  }

  export function* updateEvent({data, params, callbackSuccess,callbackError }: any): any {
    try {
      const result = yield call(baseApi.updateEvent, data,params);
      yield put(updateEventSuccessAction(result));
      yield put(
        notificationOpenAction({
          isNotification: true,
          typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
          message: "Thành Công",
          description: "Cập nhật thành công ! ",
        })
      );
      yield callbackSuccess(result);
    } catch (err) {
        let temp = err as any;
        if(temp?.response?.data?.code !== "ACCOUNT_NOT_CONNECTED"){
          yield put(
            notificationOpenAction({
              isNotification: true,
              typeNotification: NOTIFICATION_TYPE.ERROR as "error",
              message: "Lỗi",
              description: temp?.response?.data?.message || "Lỗi chưa xác định",
            })
          );
        }
        yield callbackError(err);
    }
  }

  export function* deleteEvent({ params, callbackSuccess }: any): any {
    try {
      const result = yield call(baseApi.deleteEvent, params);
      yield put(deleteEventSuccessAction(result));
      yield put(
        notificationOpenAction({
          isNotification: true,
          typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
          message: "Thành Công",
          description: "Xóa thành công ! ",
        })
      );
      yield callbackSuccess(result);
    } catch (err) {
      let temp = err as any;
      yield put(
        notificationOpenAction({
          isNotification: true,
          typeNotification: NOTIFICATION_TYPE.ERROR as "error",
          message: "Lỗi",
          description: temp?.response?.data?.message || "Lỗi chưa xác định",
        })
      );
      yield put(deleteEventErrorAction(err));
    }
  }

  export function* updateCheckInUserEvent({data, params, callbackSuccess,callbackError }: any): any {
    try {
      const result = yield call(baseApi.updateUserCheckInEvent, data, params);
      yield put(updateCheckInEventSuccessAction(result));
      yield put(
        notificationOpenAction({
          isNotification: true,
          typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
          message: "Thành Công",
          description: "Check in thành công ! ",
        })
      );
      yield callbackSuccess(result);
    } catch (err) {
      console.log("err",err)
        let temp = err as any;
        console.log("temp?.response?.data?.message",temp?.response?.data?.message);
        
        yield put(
            notificationOpenAction({
              isNotification: true,
              typeNotification: NOTIFICATION_TYPE.ERROR as "error",
              message: "Lỗi",
              description: (temp?.response?.data?.code === "NOT_VALID_QRCODE") ? "Mã Qr Không hợp lệ":"Check in không thành công",
            })
          );
        yield callbackError();
    }
  }

  // export function* getListUserEvent({ prams, callbackSuccess }: any): any {
  //   try {
  //     const result = yield call(baseApi.getListUserEvent, prams);
  //     yield put(getListUserEventSuccessAction(result));
  //     yield callbackSuccess(result);
  //   } catch (err) {
  //     let temp = err as any;
  //     yield put(getListUserErrorAction(temp));
  //   }
  // }

  export function* addUserEvent({
    data,
    callbackSuccess,
    callbackError,
  }: any): any {
    try {
      const result = yield call(baseApi.addUserEvent, data);
  
      yield put(addUserEventSuccessAction(result));
      yield callbackSuccess(result);
    } catch (err) {
      let temp = err as any;
  
      yield put(addUserEventErrorAction(err));
      yield put(
        notificationOpenAction({
          isNotification: true,
          typeNotification: NOTIFICATION_TYPE.ERROR as "error",
          message: "Lỗi",
          description: (temp?.response?.data?.code === "ALREADY_EXITS_ACCOUNT") ? "Tài khoản đã đăng ký" : (temp?.response?.data?.message || "Đăng ký thất bại"),
        })
      );
      yield callbackError();
    }
  }

  export function* restoreEvent({ params, callbackSuccess }: any): any {
    try {
      const result = yield call(baseApi.restoreEvent, params);
      yield put(restoreEventSuccessAction(result));
      yield put(
        notificationOpenAction({
          isNotification: true,
          typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
          message: "Thành Công",
          description: "Restore thành công ! ",
        })
      );
      yield callbackSuccess(result);
    } catch (err) {
      let temp = err as any;
      yield put(
        notificationOpenAction({
          isNotification: true,
          typeNotification: NOTIFICATION_TYPE.ERROR as "error",
          message: "Lỗi",
          description: temp?.response?.data?.message || "Lỗi chưa xác định",
        })
      );
      yield put(deleteEventErrorAction(err));
    }
  }

  export function* publishEvent({ data, params, callbackSuccess }: any): any {
    try {
      const result = yield call(baseApi.PublishEvent,data, params);
      yield put(publishEventSuccessAction(result));
      yield put(
        notificationOpenAction({
          isNotification: true,
          typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
          message: "Thành Công",
          description: data?.isPublish ? "Công bố thành công ! " : "Không công bố thành công ! ",
        })
      );
      yield callbackSuccess(result);
    } catch (err) {
      let temp = err as any;
      yield put(
        notificationOpenAction({
          isNotification: true,
          typeNotification: NOTIFICATION_TYPE.ERROR as "error",
          message: "Lỗi",
          description: temp?.response?.data?.message || "Lỗi chưa xác định",
        })
      );
      yield put(deleteEventErrorAction(err));
    }
  }
  
  export default function* EventSaga() {
    yield takeLatest(actionTypes?.ADD_EVENT_START, addEvent);
    yield takeLatest(actionTypes?.GET_LIST_EVENT_START, getListEvent);
    yield takeLatest(actionTypes?.GET_DETAIL_EVENT_START, getDetailEvent);
    yield takeLatest(actionTypes?.UPDATE_EVENT_START, updateEvent);
    yield takeLatest(actionTypes?.DELETE_EVENT_START, deleteEvent);
    yield takeLatest(actionTypes?.UPDATE_CHECK_IN_EVENT_START, updateCheckInUserEvent);
    // yield takeLatest(actionTypes?.GET_LIST_EVENT_USER_START, getListUserEvent);
    yield takeLatest(actionTypes?.ADD_USER_EVENTS_START, addUserEvent);
    yield takeLatest(actionTypes?.RESTORE_EVENT_START, restoreEvent);
    yield takeLatest(actionTypes?.PUBLISH_EVENT_START, publishEvent);
  }
  