//Lib
import React from "react";
import { useSelector, useDispatch } from "react-redux";
//Component
// import { notification } from 'antd';
//Styled Component
//Const
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { notificationCloseAction } from "@Actions/Notification/action";
//Types

// import  {notificationResetAction} from "@Actions/Notification/action"
const Notification = () => {
  //======================Redux===================================
  //=====>Redux Action
  //=====>Redux Props
  const { notificationReducer } = useSelector((state: any) => ({
    notificationReducer: state.notificationReducer,
  }));

  const dispatch = useDispatch();
  //===================== Hook ==================================

  const oncloseAlert = () => {
    dispatch(notificationCloseAction());
  };

  return (
    <Snackbar
      open={notificationReducer?.get("isNotification")}
      autoHideDuration={4000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={oncloseAlert}
    >
      <Alert severity={notificationReducer?.get("typeNotification")}>
        {notificationReducer?.get("description")}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
