import React from 'react'
import { Div,  Span } from "@Common/TagAntdNotSupport/TagAntdNotSupport";
import { Grid,  } from "@material-ui/core";
import { useSelector,  } from "react-redux";
import { profileConfigRender } from "./const";
import FormContentInFo from "@Components/Profile/FormContentInFo";
import moment from "moment";


export default function Tab0({ onSubmitProfile }) {
  const profile = useSelector((state) => state.loginReducer?.get('profile').toJS(),);
  const dataProfile = profile?.result

  const dataInfoGrid = {
    email: {
      value: dataProfile?.email || "-------------",
    },
    password: {
      date:
        dataProfile?.passwordInfo?.updateAt ?
          moment(
            dataProfile?.passwordInfo?.updateAt
          ).format("DD/MM/YYYY") : "---",
      value: "****************",
    },
    fullName: {
      value: dataProfile?.firstName ? (dataProfile?.firstName + "  " + dataProfile?.lastName) : "------  -------",
      firstNameDefault: dataProfile?.firstName || "",
      lastNameDefault: dataProfile?.lastName || ""
    },
    dateOfBirth: {
      value: dataProfile?.dateOfBirth ? moment(dataProfile?.dateOfBirth).format("DD / MM / YYYY") : "-- / -- / ----",
      dateOfBirthDefault: dataProfile?.dateOfBirth ? moment(dataProfile?.dateOfBirth) : moment(),
    },
    phone: {
      value: dataProfile?.phone || "-------------",
      phoneDefault: dataProfile?.phone || ""
    },
    address: {
      value: dataProfile?.address?.address ?
        (dataProfile?.address?.address +
          ", " +
          dataProfile?.address?.districtDetail?.name +
          ", " +
          dataProfile?.address?.provinceDetail?.name) : "--- , --- , ---",
      addressDefault: dataProfile?.address?.address || "",
      districtIDDefault: dataProfile?.address?.districtDetail?.id,
      provinceIDDefault: dataProfile?.address?.provinceDetail?.id

    },
  };

  return (
    <>
      {
        profileConfigRender.map((element, index) => {
          return (
            <Div
              key={index}
              className="info-account"
            >
              <Grid container>
                <Grid item xs={12} md={3}>
                  <Div className="name-profile">{element.title}</Div>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Div className="father-profile">
                    {element?.rowConfig?.map(
                      (rowConfigItem, indexItem) => {
                        return (
                          <Div className="right-profile" key={indexItem}>
                            <Grid container>
                              <Grid item xs={3} sm={3}>
                                <Div className="title">
                                  <Span className="title-content">
                                    {rowConfigItem.name}
                                  </Span>
                                </Div>
                              </Grid> 
                              <Grid item xs={7} sm={7} className="value-container">
                                <FormContentInFo
                                  dataInfoGrid={dataInfoGrid}
                                  onSubmitProfile={onSubmitProfile}
                                  rowConfigItem={rowConfigItem}
                                />
                              </Grid>
                            </Grid>
                          </Div>
                        );
                      }
                    )}
                  </Div>
                </Grid>
              </Grid>
            </Div>
          );
        })
      }
    </>
  )
}