export const emailList = [
    "info@ninecode.vn",
    "contact@ninecode.vn",
    "nguyenvana@gmail.com"
]

export const phoneList = [
    "08 6868 5247",
    "028 22188 009",
    "0889587445"
]