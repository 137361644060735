import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as action from "@Actions/UserEvent/action";
import Main from "../main";
import { TYPE_CONFIRM } from "../const";
import getAllUrlParams from "@Helpers/getAllUrlParams";
import { TYPE_REFRESH_DATA } from "@Constants/common";
import { RootState } from "@Reduxs/store";
import { IRoleResponse } from "@Interfaces/event";

// UserEvent.propTypes = {};

const UserEvent: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [typeConfirm, setTypeConfirm] = useState(TYPE_CONFIRM.DELETE_USER);
  const [titleConfirm, setTitleConfirm] = useState("Xác nhận xóa");

  const [selectedRole, setSelectedRole] = useState("");
  const [selectedUserID, setSelectedUserID] = useState("");

  const [openModalAddUser, setOpenModalAddUser] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const [menuRoleAnchorEl, setMenuRoleAnchorEl] = useState(null);
  const [menuRoleAnchorElSp, setMenuRoleAnchorElSp] = useState(null);
  const openMenuRole = Boolean(menuRoleAnchorEl);
  const openMenuRoleSp = Boolean(menuRoleAnchorElSp);

  const queryParam = getAllUrlParams(window.location.href);
  const limit = queryParam.limit ? parseInt(queryParam.limit) : 10;
  const [page, setPage] = useState(
    queryParam.page ? parseInt(queryParam.page) : 1
  );

  const { users, roles, isLoading, typeRefreshData, pagination, permissions } =
    useSelector((state: RootState) => ({
      users: state.userEventReducer?.get("payload")?.toJS().result,
      pagination: state.userEventReducer?.get("payload")?.toJS()?.meta?.total,
      roles: state.userEventReducer?.get("roles")?.toJS().result,
      isLoading: state.userEventReducer?.get("isLoading"),
      typeRefreshData: state.notificationReducer?.get("typeRefreshData"),
      permissions: state.userEventReducer?.get("permissions")?.toJS()
        ?.permissions,
    }));

  // console.log(permissions, "sdsdssds");

  //   // trigger open modal add user
  const triggerOpenModalAddUser = () => {
    setOpenModalAddUser(!openModalAddUser);
  };
  //   // handle menu role
  const handleShowMenuRole = (event: any, userId: string) => {
    // console.log(event, "event");
    // console.log(userId, "userId");

    setMenuRoleAnchorEl(event.currentTarget);
    setSelectedUserID(userId);
  };

  const handleShowMenuRoleSp = (event: any, userId: string) => {
    setMenuRoleAnchorElSp(event.currentTarget);
    setSelectedUserID(userId);
  };

  const handleCloseMenuRole = () => {
    setMenuRoleAnchorEl(null);
  };

  const handleCloseMenuRoleSp = () => {
    setMenuRoleAnchorElSp(null);
  };

  //   // open modal confirm
  const handleOpenModalConfirm = (
    typeConfirm: string,
    dataSubmitConfirm: string
  ) => {
    // console.log(typeConfirm, "typeConfirm");
    // console.log(dataSubmitConfirm, "dataSubmitConfirm");

    switch (typeConfirm) {
      case TYPE_CONFIRM.DELETE_USER:
        setTitleConfirm("Xác nhận xóa");
        handleCloseMenuRoleSp();
        setSelectedUserID(dataSubmitConfirm);
        break;

      case TYPE_CONFIRM.CHANGE_ROLE:
        setTitleConfirm("Xác nhận thay đổi");
        handleCloseMenuRole();
        handleCloseMenuRoleSp();
        setSelectedRole(dataSubmitConfirm);
        break;

      default:
        break;
    }
    setTypeConfirm(typeConfirm);
    setOpenModalConfirm(!openModalConfirm);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(!openModalConfirm);
  };

  //   // handle change page
  const onChangePage = (event: any, page: number) => {
    // console.log(event, "event");

    setPage(page);
    history.push(`/user?page=${page}&limit=${limit}`);
  };

  // submit form
  const handleSubmitConfirm = () => {
    switch (typeConfirm) {
      case TYPE_CONFIRM.DELETE_USER:
        dispatch(
          action.deleteUserEventAction(selectedUserID, handleCloseModalConfirm)
        );
        break;

      case TYPE_CONFIRM.CHANGE_ROLE:
        dispatch(
          action.updateUserEventAction(
            selectedUserID,
            selectedRole,
            handleCloseModalConfirm
          )
        );
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const callbackSuccess = (result: IRoleResponse) => {
      // console.log(result, "result");
    };
    dispatch(action.getListEventRoleAction({}, callbackSuccess));
  }, [dispatch]);

  useEffect(() => {
    const callbackSuccess = () => {};
    dispatch(action.getListUserEventAction({}, callbackSuccess));
  }, [dispatch]);

  useEffect(() => {
    if (
      [
        TYPE_REFRESH_DATA?.ADD_USER_REFRESH,
        TYPE_REFRESH_DATA?.UPDATE_USER_REFRESH,
        TYPE_REFRESH_DATA?.DELETE_USER_REFRESH,
      ].includes(typeRefreshData)
    ) {
      const callbackSuccess = () => {};
      dispatch(action.getListUserEventAction({ page, limit }, callbackSuccess));
    }
  }, [typeRefreshData, page, dispatch, limit]);

  useEffect(() => {
    const callbackSuccess = () => {};
    dispatch(action.getListUserEventAction({ page, limit }, callbackSuccess));
  }, [page, dispatch, limit]);


  return (
    <>
      <Main
        isLoading={isLoading}
        permissions={permissions}
        triggerOpenModalAddUser={triggerOpenModalAddUser}
        users={users}
        pagination={pagination}
        limit={limit}
        page={page}
        onChangePage={onChangePage}
        handleShowMenuRole={handleShowMenuRole}
        handleShowMenuRoleSp={handleShowMenuRoleSp}
        handleOpenModalConfirm={handleOpenModalConfirm}
        openMenuRole={openMenuRole}
        openMenuRoleSp={openMenuRoleSp}
        menuRoleAnchorEl={menuRoleAnchorEl}
        menuRoleAnchorElSp={menuRoleAnchorElSp}
        handleCloseMenuRole={handleCloseMenuRole}
        handleCloseMenuRoleSp={handleCloseMenuRoleSp}
        roles={roles}
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleConfirm={titleConfirm}
        handleSubmitConfirm={handleSubmitConfirm}
        openModalAddUser={openModalAddUser}
      />
    </>
  );
};

export default UserEvent;
