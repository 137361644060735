import { Api } from "@Configs/api/api";
import apiConfig from "@Configs/api/api.config";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import {
    API_GET_USER_NOTIFICATION_LIST, API_REMOVE_NOTIFICATION
} from "./endpoint";
class BaseApi extends Api {
    public constructor(config: AxiosRequestConfig) {
        super(config);
        this.getUserNotificationList = this.getUserNotificationList.bind(this);
        this.markAsRead = this.markAsRead.bind(this);
        this.removeNotification = this.removeNotification.bind(this)
        this.markAllAsRead = this.markAllAsRead.bind(this)
    }
    //FETCH
    public getUserNotificationList(params: any): Promise<any> {
        return this.get<any>(API_GET_USER_NOTIFICATION_LIST,)
            .then((response: AxiosResponse<any>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    }

    public markAsRead(id: any): Promise<any> {
        return this.patch<any>(`${API_GET_USER_NOTIFICATION_LIST}/${id}/mark-as-read`)
            .then((response: AxiosResponse<any>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    }

    public markAllAsRead(): Promise<any> {
        return this.patch<any>(`${API_GET_USER_NOTIFICATION_LIST}/mark-all-as-read`)
            .then((response: AxiosResponse<any>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    }

    public removeNotification(id: any): Promise<any> {
        return this.delete<any>(`${API_REMOVE_NOTIFICATION}/${id}`)
            .then((response: AxiosResponse<any>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    }

}

const baseApi = new BaseApi(apiConfig);
export default baseApi;