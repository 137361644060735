import { call, put, takeLatest } from "redux-saga/effects";
import baseApi from "@Apis/Login/api";
import {
  loginErrorAction,
  loginSuccessAction,
  getProfileAction,
  getProfileSuccessAction,
  getProfileErrorAction,
  logoutSuccessAction,
  logoutErrorAction,
} from "@Actions/Login/action";
import { notificationOpenAction } from "@Actions/Notification/action";
import actionTypes from "@Actions/Login/action-types";
import { NOTIFICATION_TYPE } from "@Constants/common";

export function* loginSuccess(result : any) {
  // console.log(result);
  
  yield put(loginSuccessAction(result.result));
  yield put(getProfileAction(result?.result?.token, result?.result?.id));
}
export function* login({
  reCaptchaToken,
  credentials,
  callbackSuccess,
  callbackError,
}: any): any {
  try {
    const result = yield call(baseApi.loginUser, reCaptchaToken, credentials);
    console.log(result, "result");
    
    yield put(loginSuccessAction({
      "message": "Authentication success",
      "result": {
          "id": "621dfd1c8950b1fcbd2e3364",
          "token": "9fabe549822330c50442c4e468e418b547a7f1d23521a3c74a094c2d22886a7c5100e9084e64475776479b271922e314",
          "email": "tmp@gmail.com",
          "isThirdPartyAccount": false
      }
  }));
    yield put(getProfileAction(result?.result?.token, result?.result?.id));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
        message: "Thành Công",
        description: "Đăng Nhập Thành Công",
      })
    );
    yield callbackSuccess();
  } catch (err) {
    console.log(err);
    // handle error annotation
    let temp = err as any;

    yield put(loginErrorAction(err));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "success",
        message: "Lỗi",
        description: temp?.response?.data?.message || "Lỗi chưa xác định",
      })
    );
    yield callbackError();
  }
}

export function* getProfile({ token, uid }: any): any {
  try {
    const result = yield call(baseApi.getProfile, token, uid);
    // console.log(result);
    
    yield put(getProfileSuccessAction(result));
  } catch (err) {
    console.log(err);
    let temp = err as any;
    yield put(getProfileErrorAction(err));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "success",
        message: "Lỗi",
        description: temp?.response?.data?.message || "Lỗi chưa xác định",
      })
    );
  }
}

export function* logout(): any {
  try {
    yield put(logoutSuccessAction(null));
  } catch (err) {
    console.log(err);
    let temp = err as any;
    yield put(logoutErrorAction(err));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "success",
        message: "Lỗi",
        description: temp?.response?.data?.message || "Lỗi chưa xác định",
      })
    );
  }
}

export default function* loginSaga() {
  yield takeLatest(actionTypes?.LOGIN_START, login);
  yield takeLatest(actionTypes?.GET_PROFILE_START, getProfile);
  yield takeLatest(actionTypes?.LOGOUT_START, logout);
  yield takeLatest(actionTypes?.LOGIN, loginSuccess);
}
