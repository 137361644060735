import React, { FC, useCallback, useState } from "react";
import Cropper from 'react-easy-crop';
import styles from "./CropImage.module.scss"

import getCroppedImg from './Crop'
interface ICrop {
  image: any,
  aspect?: number,
  onCancel?(): any,
  onFinish?({ x, y }: { x: number, y: number ,zoom : number}): any,
}
const CropImage: FC<ICrop> = ({
  image,
  aspect = 2.333,
  onCancel = () => { },
  onFinish = () => { },
}) => {
  const [CropPos, setCropPos] = useState({ x: 0, y: 0 })
  const [Zoom, setZoom] = useState(1)
  const [croppedImage, setCroppedImage] = useState(null)
  const [ImagePos, setImagePos] = useState({ x: 0, y: 0, zoom: 100 })
  const onCropComplete = useCallback(async(croppedArea, croppedAreaPixels) => {
    setImagePos(croppedAreaPixels)
  }, [ImagePos])

  return (
    <>
      <div className={styles.Crop}>
        <div onClick={onCancel} className={styles.Mask}></div>
        <Cropper
          image={image}
          crop={CropPos}
          zoom={Zoom}
          aspect={aspect}
          onCropChange={setCropPos}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          showGrid={false}
          zoomWithScroll={false}
          // cropSize={{"width": 700, 'height': 300}}
        />

        <div
          style={{
            position: 'absolute',
            top: 100,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '50%',
            display: 'flex',
          }}
        >

          <label htmlFor="range" style={{color: "#fff", marginRight: "10px", fontSize: "16px"}}>
            Phóng to
          </label>
          <input
          id="range"
            style={{ width: '75%' }}
            type='range'
            defaultValue={1}
            min={0.5}
            max={3}
            step={0.01}
            onInput={(e: any) => setZoom(e.target.value)}
          />
        </div>

        <div className={styles.Buttons}>
          <div className={styles.ButtonCancel} onClick={onCancel}>
            Hủy
          </div>
          <div className={styles.Button} onClick={() => onFinish(ImagePos)}>
            Hoàn thành
          </div>
        </div>
      </div>
    </>
  )
}

export default CropImage