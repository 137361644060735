import React from "react";
import "./style.scss";
import { useHistory } from "react-router";
import MicrosoftTeamIcon from "@Assets/icons/event/teamsEvent.svg";
import GoogleMeetImageIcon from "@Assets/icons/event/GoogleMeetEvent.svg";
import moment from "moment";
import arrowIcon from "@Assets/icons/event/arrow.svg";

interface IEventItem {
  data: any;
  RefreshEventData: any;
}

const EventItemShare: React.FC<IEventItem> = (props:any) => {
  const { data }:any = props;
  const history = useHistory();
  const handleGetUrl = ()=>{
    history.push(data.id + "?back=true")
  }

  const checkDay =
    moment(data.startDateTime).format("llll").split(",")[0].toLowerCase() ===
      "cn" || false;
  const getDay = moment(data.startDateTime)
    .format("llll")
    ?.split(",")[0]
    ?.substring(1, 2);

  return (
    <React.Fragment>
    <div className="EventItem-wrapper">
      <div className="EventItem-content" onClick={handleGetUrl}>
        <div className="banner">
          {data?.image && data.image !== "" && (
            <img className="image" src={data.image} alt="" />
          )}
          <div className="overlay"></div>
        </div>

        <div className="content">
        <div className="content__cont">
          {data.status === "processing" ? (
            <p className="event-status">Đang diễn ra</p>
          ) : data.status === "notprocessing" ? (
            <p className="event-status">
              {data?.endDateTime ? (
                moment(data.startDateTime).format("DD/MM/YYYY") ===
                moment(data?.endDateTime).format("DD/MM/YYYY") ? (
                  <>
                    {checkDay ? "Chủ nhật" : `Thứ ${getDay}`}
                    ,&nbsp;ngày&nbsp;
                    {moment(data.startDateTime).format("DD/MM/YYYY") +
                      ", " +
                      moment(data.startDateTime)
                        .format("HH:mm")
                        .replace(":", "h") +
                      " - " +
                      moment(data?.endDateTime)
                        .format("HH:mm")
                        .replace(":", "h")}
                  </>
                ) : (
                  <>
                    {moment(data.startDateTime)
                      .format("HH:mm")
                      .replace(":", "h") +
                      " - " +
                      moment(data.startDateTime).format("DD/MM/YYYY")}
                    <img src={arrowIcon} alt={arrowIcon} />
                    {moment(data?.endDateTime)
                      .format("HH:mm")
                      .replace(":", "h") +
                      " - " +
                      moment(data?.endDateTime).format("DD/MM/YYYY")}
                  </>
                )
              ) : (
                <>
                  {checkDay ? "Chủ nhật" : `Thứ ${getDay}`}
                  ,&nbsp;ngày&nbsp;
                  {data.startDateTime
                    ? moment(data.startDateTime).format("DD/MM/YYYY") +
                      ", " +
                      moment(data.startDateTime)
                        .format("HH:mm")
                        .replace(":", "h")
                    : ""}
                </>
              )}
            </p>
          ) : (
            <p className="event-status">
              {data?.endDateTime ? (
                <>
                  {checkDay ? "Chủ nhật" : `Thứ ${getDay}`}
                  ,&nbsp;ngày&nbsp;
                  {data?.endDateTime
                    ? moment(data?.endDateTime).format("DD/MM/YYYY") +
                      ", " +
                      moment(data?.endDateTime)
                        .format("HH:mm")
                        .replace(":", "h")
                    : ""}
                </>
              ) : (
                <>
                  {checkDay ? "Chủ nhật" : `Thứ ${getDay}`}
                  ,&nbsp;ngày&nbsp;
                  {data.startDateTime
                    ? moment(data.startDateTime).format("DD/MM/YYYY") +
                      ", " +
                      moment(data.startDateTime)
                        .format("HH:mm")
                        .replace(":", "h")
                    : ""}
                </>
              )}
            </p>
          )}
          <p className="event-name">{data.eventTitle}</p>
          </div>
          <div className="event-description">
            {data.isOnline === "on" ? (
              <div className="status">
                {data?.onlineItems === "google" ? (
                  <>
                    <img
                      src={GoogleMeetImageIcon}
                      alt="GoogleMeetImageIcon"
                    />
                    <span>Google Meet</span>
                  </>
                ) : (
                  <>
                    <img src={MicrosoftTeamIcon} alt="TeamIcon" />
                    <span>Microsoft Team</span>
                  </>
                )}
              </div>
            ) : (
              <div className="place">
                <p>{data.address?.address}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
  );
};

export default EventItemShare;
