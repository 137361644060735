//Lib
import Footer from "@Common/Footer/Footer";
//Components
import Header from "@Common/Header/Header";
import Notification from "@Pages/Notification/Notification";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionTypes from "@Actions/UserNotification/action-types";
import { getUserNotificationListAction } from "@Actions/UserNotification/action";
//Styled
import { DefaultLayoutWrapper } from "./styled";
import { useToast } from "../../lib/Toast/hook";
import moment from "moment";
import io from "socket.io-client";
// const io = require('socket.io-client')

interface IProps {
  children: React.ReactNode;
  group: string;
}

const REACT_APP_NCM_URL: any = process.env.REACT_APP_NCM_URL;

const DefaultLayout = (props: IProps) => {
  const { children } = props;
  const dispatch = useDispatch();

  const { notify } = useToast();

  const { token, typeNotification } = useSelector((state: any) => ({
    token: state.loginReducer?.get("payload")?.toJS()?.result?.token,
    typeNotification: state.userNotificationReducer?.get("type"),
  }));

  useEffect(() => {
    if (typeNotification === ActionTypes?.REMOVE_NOTIFICATION_SUCCESS) {
      // dispatch(getUserNotificationListAction({}));
    }
  }, [typeNotification, dispatch]);

  useEffect(() => {
    const socket = io(REACT_APP_NCM_URL, {
      auth: {
        token: token,
      },
      transports: ["websocket", "polling"],
    });

    socket.on("connect", () => {
      // dispatch(getUserNotificationListAction({}));
    });

    socket.on("notification", async (data: any) => {
      const customer = data?.payload?.name;
      const eventName = data?.payload?.eventTitle;
      // dispatch(getUserNotificationListAction({}));
      await notify({
        message: `${customer} ${
          data?.type === "user-event.join-requested"
            ? "đã đăng ký tham gia sự kiện"
            : "đã thay đổi sự kiện"
        } ${eventName} vào lúc ${moment(data?.createdAt).format(
          "LT"
        ).replace(":", "h")}`,
        variant: `${data?.type === "user-event.join-requested" ? "success" : "info"}`,
      });
    });
  }, []);//dispatch, notify, token

  return (
    <DefaultLayoutWrapper>
      {/* <Header group={props?.group} /> */}
      <Header />
      {children}
      <Notification />
      <Footer />
    </DefaultLayoutWrapper>
  );
};

export default DefaultLayout;
