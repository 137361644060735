export default {
  ADD_ENTERPRISE_START: "ADD_ENTERPRISE_START",
  ADD_ENTERPRISE_SUCCESS: "ADD_ENTERPRISE_SUCCESS",
  ADD_ENTERPRISE_ERROR: "ADD_ENTERPRISE_ERROR",
  GET_LIST_ENTERPRISE_START: "GET_LIST_ENTERPRISE_START",
  GET_LIST_ENTERPRISE_SUCCESS: "GET_LIST_ENTERPRISE_SUCCESS",
  GET_LIST_ENTERPRISE_ERROR: "GET_LIST_ENTERPRISE_ERROR",
  GET_INFO_ENTERPRISE_START: "GET_INFO_ENTERPRISE_START",
  GET_INFO_ENTERPRISE_SUCCESS: "GET_INFO_ENTERPRISE_SUCCESS",
  GET_INFO_ENTERPRISE_ERROR: "GET_INFO_ENTERPRISE_ERROR",
  CHANGE_INFO_ENTERPRISE_START: "CHANGE_INFO_ENTERPRISE_START",
  CHANGE_INFO_ENTERPRISE_SUCCESS: "CHANGE_INFO_ENTERPRISE_SUCCESS",
  CHANGE_INFO_ENTERPRISE_ERROR: "CHANGE_INFO_ENTERPRISE_ERROR",
};
