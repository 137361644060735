import AddEventIcon from "@Assets/icons/event/plusIcon.svg";
import CopyLinkIcon from "@Assets/icons/event/copylink.svg";
import CopiedIcon from "@Assets/icons/event/copiedBlue.svg";
import WebScriptIcon from "@Assets/icons/event/webscript.svg";
import EventItem from "@Components/Event/EventItem/EventItem";
import { ENTERPRISE_ID, ENTERPRISE_URL } from "@Constants/localStorage";
import {
  Button,
  Container,
  Grid,
  MenuItem,
  Popover,
  Divider,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import "./style.scss";
import baseApi from "@Apis/Event/api";
import ModalAddToWeb from "@Components/Event/ModalAddToWeb/ModalAddToWeb";
import { notificationOpenAction } from "@Actions/Notification/action";
import { NOTIFICATION_TYPE } from "@Constants/common";
import { useDispatch, useSelector } from "react-redux";
import * as action from "@Actions/Event/action";
import processingIcon from "@Assets/icons/event/processing.svg";
import notProcessingIcon from "@Assets/icons/event/notprocessing.svg";
import finishedIcon from "@Assets/icons/event/finished.svg";
import getAllUrlParams from "@Helpers/getAllUrlParams";
import Paginantion from "@Common/Pagination/Paginantion";
import qs from "qs"
import { getProfileAction } from "@Actions/Login/action";


const Event: React.FC = (props:any) => {
  const payload = useSelector((state:any) => state?.loginReducer?.get('payload').toJS(),)
  const [isSearch,setIsSearch]:any = useState(props)
  const history = useHistory();
  const dispatch = useDispatch();
  const [eventData, setEventData]: any = useState([]);
  const [refEventData, setRefEventData] = useState(false);
  const [url] = useState("");
  const enterPriseID = localStorage.getItem(ENTERPRISE_ID);
  const [openAddToWeb, setOpenAddToWeb] = useState(false);
  const [refreshCheck] = useState(false);
  const [copy, setCopy] = useState(false);
  const typingTimeoutRef: any = React.useRef(null);
  const RefreshEventData = () => setRefEventData(!refEventData);
  const [selectView, setSelectView]: any = useState({
    select: "event",
    img: "",
    name: "",
  });
  const queryParam = getAllUrlParams(window.location.href);
  const limit = queryParam.limit ? parseInt(queryParam.limit) : 12;
  const status = queryParam?.status;
  const search = queryParam?.search;
  const [page, setPage] = useState(
    queryParam.page ? parseInt(queryParam.page) : 1
  );

  const [totalPage, setTotalPage] = useState(0);

  const handleCloseAddToWeb = () => {
    setOpenAddToWeb(false);
  };

  const getLink = () => {
    const hostName = window?.location?.origin;
    const eventUrl = localStorage.getItem(ENTERPRISE_URL) || "";
    const link =
      hostName +
      "/" +
      eventUrl +
      `/all-event`;
    return link;
  };

  const handleOpenCopy = async () => {
    const link = await getLink();
    navigator.clipboard.writeText(link);
    setCopy(true);
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(async () => {
      setCopy(false);
    }, 2000);
  };

  const handleEmbedEvent = () => {
    history.push(
      `/${localStorage.getItem(ENTERPRISE_URL)}/event/embed/all-event`
    );
  };

  const fetchData = async () => {
    try {
      const data: any = await baseApi.getListEvent({ limit, page, status, search });
      setEventData(data?.result);
      setTotalPage(data?.meta?.total);
    } catch (error) {
      console.log("error fetchData event detail", error);
    }
  };

  useEffect(() => {
    fetchData();
    switch (status) {
      case "publish":
        setSelectView({
          select: "publish",
          img: processingIcon,
          name: "Công bố",
        });
        break;
      case "notpublish":
        setSelectView({
          select: "notpublish",
          img: notProcessingIcon,
          name: "Không công bố",
        });
        break;
      case "finished":
        setSelectView({
          select: "finished",
          img: finishedIcon,
          name: "Đã xoá",
        });
        break;
      case "all-event":
        setSelectView({
          select: "all-event",
          name: "Tất cả sự kiện bao gồm xóa",
        });
        break;
      default:
        setSelectView({
          select: "event",
          img: "",
          name: "",
        });
        break;
    }
  }, [enterPriseID, refEventData, status]);

  useEffect(() => {}, [refreshCheck]);

  useEffect(()=>{
    dispatch(getProfileAction(payload.result?.token, payload?.result?.id));
  },[])

  const handleAddEvent = () => {
    dispatch(action.addEventAction({}, callbackSuccess, callbackError));
  };

  const callbackSuccess = async (data: any) => {
    history.push(
      `/${localStorage.getItem(ENTERPRISE_URL)}/event/${data.result.id}`
    );
  };

  const callbackError = async() => {
    console.log("error create new event")
      dispatch(notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Thất bại",
        description: "Thêm sự kiện thất bại ! ",
      }))
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const urlParams = localStorage.getItem(ENTERPRISE_URL);

  const handleClose = (value: any) => {
    setAnchorEl(null);
    setPage(1);
    let getParams = window.location.search
    let queryData:any = {limit:12, page:1}

    if(getParams.includes("&search=")){
      const dataTmp = getParams.split("&")
      const getSearch = dataTmp.filter((e:any)=> e.includes("search="))
      const dataSearch = getSearch[0]?.split("search=")[1]
      queryData.search = dataSearch
    }

    switch (value) {
      case "publish":
        queryData.status = "publish"
        break;
      case "notpublish":
        queryData.status = "notpublish"
        break;
      case "finished":
        queryData.status = "finished"
        break;
      case "all-event":
        queryData.status = "all-event"
        break;
      case "all-event-active":
      default:
        break;
    }

    const queryStr = qs.stringify(queryData)
    history.push(`/${urlParams}/event?${queryStr}`);
    // RefreshEventData();
  };

  const handleCloseSelectViewPopover = () => {
    setAnchorEl(null); 
  }
  
  const onChangePage = (page: any) => {
    setPage(page);
    let getParams = window.location.search
    let queryData:any = {limit, page}

    if(getParams.includes("&search=")){
      const dataTmp = getParams.split("&")
      const getSearch = dataTmp.filter((e:any)=> e.includes("search="))
      const dataSearch = getSearch[0]?.split("search=")[1]
      queryData.search = dataSearch
    }

    if (status) {
      queryData.status = status
    } 
    
    const queryStr = qs.stringify(queryData)
    history.push(`/${urlParams}/event?${queryStr}`);
    // RefreshEventData();
  };

  useEffect(()=>{
    //refresh data when search 
    if(props.location.search !== isSearch.location.search ){
      setIsSearch(props)
      RefreshEventData()
    }
  },[props])

  useEffect(()=>{
    localStorage.removeItem("FORM_ADD_EVENT")
    localStorage.removeItem("FORM_REGISTER_EVENT")
  },[])

  return (
    <React.Fragment>
      <div className="event-wrapper">
        <div className="event-header">
          <Container
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              padding: "0",
            }}
          >
            <div className="content">
              <div className="content-left">
                {selectView?.select === "event" ? (
                  <Button
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                    className="btn-view"
                  >
                    Sự kiện
                  </Button>
                ) : (
                  <Button
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                    className="btn-view"
                  >
                    {selectView?.img && (
                      <img
                        src={selectView?.img}
                        alt={selectView?.img}
                        style={{ paddingRight: "12px" }}
                      />
                    )}
                    {selectView?.name}
                  </Button>
                )}
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleCloseSelectViewPopover}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  className="select-view"
                >
                  <MenuItem
                    onClick={() => handleClose("all-event-active")}
                    className="item"
                  >
                    Tất cả sự kiện
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleClose("all-event")}
                    className="item"
                  >
                    Tất cả sự kiện bao gồm xoá
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleClose("publish")}
                    className="item"
                  >
                    <img
                      src={processingIcon}
                      alt="processingIcon"
                      style={{ paddingRight: "12px" }}
                    />
                    Công bố
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleClose("notpublish")}
                    className="item"
                  >
                    <img
                      src={notProcessingIcon}
                      alt="notProcessingIcon"
                      style={{ paddingRight: "12px" }}
                    />
                    Không công bố
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleClose("finished")}
                    className="item"
                  >
                    <img
                      src={finishedIcon}
                      alt="finishedIcon"
                      style={{ paddingRight: "12px" }}
                    />
                    Đã xoá
                  </MenuItem>
                </Popover>
              </div>
              <div className="content-right">
                <div className="btn-group">
                  <Button
                    className="script-copy"
                    onClick={handleEmbedEvent}
                  >
                    <img src={WebScriptIcon} alt={WebScriptIcon} />
                    <p className="sp-except">Copy embed code</p>
                  </Button>
                  {copy ? (
                    <Button className="script-copy">
                      <img src={CopiedIcon} alt={CopiedIcon} />
                      <p className="sp-except" style={{color:"var(--clr-primary)"}}>Copied</p>
                    </Button>
                  ) : (
                    <Button
                      onClick={handleOpenCopy}
                      className="script-copy"
                    >
                      <img src={CopyLinkIcon} alt={CopyLinkIcon} />
                      <p className="sp-except">Copy link</p>
                    </Button>
                  )}
                </div>
                <Button
                  color="primary"
                  variant="contained"
                  className="add-event"
                  onClick={handleAddEvent}
                >
                  <img src={AddEventIcon} alt="" />
                  <p>Thêm sự kiện</p>
                </Button>
              </div>
            </div>
          </Container>
        </div>
        <div className="event-body">
          <Container
            style={{
              height: "100%",
              padding: "0",
            }}
          >
            <Grid container>
              <Grid item lg={12} className="list-events">
                <Grid container spacing={2}>
                  {eventData?.map((item: any, index: any) => (
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={4}
                      sm={6}
                      xs={12}
                      key={item.id}
                    >
                      <EventItem
                        data={item}
                        RefreshEventData={RefreshEventData}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              {totalPage > limit && (
                <Paginantion
                  count={Math.ceil(totalPage / limit)}
                  page={page}
                  onChangePage={onChangePage}
                />
              )}
            </Grid>
          </Container>
        </div>
      </div>
      <ModalAddToWeb
        url={url}
        openAddToWeb={openAddToWeb}
        handleCloseAddToWeb={handleCloseAddToWeb}
      />
    </React.Fragment>
  );
};

export default Event;
