import { call, put, takeLatest } from "redux-saga/effects";
import baseApi from "@Apis/ResetPassword/api";
import { resetPasswordErrorAction, resetPasswordSuccessAction } from "@Actions/ResetPassword/action";
import {notificationOpenAction } from "@Actions/Notification/action";
import actionTypes from "@Actions/ResetPassword/action-types";
import {NOTIFICATION_TYPE} from "@Constants/common"

export function* resetPassword({ reCaptchaToken, credentials,callbackSuccess,callbackError }: any):any {    
  try {
    const result  = yield call(
      baseApi.resetPassword,
      reCaptchaToken,
      credentials
    );
    yield put(resetPasswordSuccessAction(result));
    yield put(notificationOpenAction({
      isNotification:true,
      typeNotification:NOTIFICATION_TYPE.SUCCESS as "success",
      message:"Thành Công",
      description:"Thay đổi mật khẩu thành công ! "
    }));
    yield callbackSuccess()
  } catch (err : any) {      
    yield put(resetPasswordErrorAction(err));
    yield put(notificationOpenAction({
      isNotification:true,
      typeNotification:NOTIFICATION_TYPE.ERROR as "error",
      message:"Lỗi",
      description: err?.response?.data?.message||"Lỗi chưa xác định",
    }));
    yield callbackError();

  }
}

export default function* resetPasswordSaga() {
  yield takeLatest(actionTypes?.RESET_PASSWORD_START, resetPassword);
}
