import ActionType from "./action-types";

export const addEnterpriseAction = (data: any, callbackSuccess: Function, callbackError: Function) => ({
  type: ActionType.ADD_ENTERPRISE_START,
  data,
  callbackSuccess,
  callbackError,
  payload: null,
  error: null,
});
export const addEnterpriseSuccessAction = (result: any) => ({
  type: ActionType.ADD_ENTERPRISE_SUCCESS,
  payload: result,
  error: null,
});
export const addEnterpriseErrorAction = (error: Error) => ({
  type: ActionType.ADD_ENTERPRISE_ERROR,
  payload: null,
  error: error,
});

export const getListEnterpriseAction = (params: any, callbackSuccess: Function) => ({
  type: ActionType.GET_LIST_ENTERPRISE_START,
  params,
  callbackSuccess,
  payload: null,
  error: null,
});
export const getListEnterpriseSuccessAction = (result: any) => ({
  type: ActionType.GET_LIST_ENTERPRISE_SUCCESS,
  payload: result,
  error: null,
});
export const getListEnterpriseErrorAction = (error: Error) => ({
  type: ActionType.GET_LIST_ENTERPRISE_ERROR,
  payload: null,
  error: error,
});
export const getInfoEnterpriseAction = (params: any, callbackSuccess: Function) => ({
  type: ActionType.GET_INFO_ENTERPRISE_START,
  params,
  callbackSuccess,
  payload: null,
  error: null,
});
export const getInfoEnterpriseSuccessAction = (result: any) => ({
  type: ActionType.GET_INFO_ENTERPRISE_SUCCESS,
  payload: result,
  error: null,
});
export const getInfoEnterpriseErrorAction = (error: Error) => ({
  type: ActionType.GET_INFO_ENTERPRISE_ERROR,
  payload: null,
  error: error,
});
export const changeInfoEnterpriseAction = (dataInfoEnterprise:any,callbackSuccess:any) => ({
  type: ActionType.CHANGE_INFO_ENTERPRISE_START,
  dataInfoEnterprise,
  callbackSuccess,
  payload: null,
  error: null,
});
export const changeInfoEnterpriseSuccessAction = (result: any) => ({
  type: ActionType.CHANGE_INFO_ENTERPRISE_SUCCESS,
  payload: result,
  error: null,
});
export const changeInfoEnterpriseErrorAction = (error: Error) => ({
  type: ActionType.CHANGE_INFO_ENTERPRISE_ERROR,
  payload: null,
  error: error,
});
