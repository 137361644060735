import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import apiConfig from "@Configs/api/api.config";
import { Api } from "@Configs/api/api";
import { IForgotPasswordCredentials } from "@Interfaces/credentials";

import { API_FORGOT_PASSWORD } from "./endpoint";

class BaseApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config);
    this.forgotPasswordUser = this.forgotPasswordUser.bind(this);
  }

  public forgotPasswordUser(
    reCaptchaToken: string,
    credentials: IForgotPasswordCredentials
  ): Promise<any> {
    return this.post<any>(API_FORGOT_PASSWORD, JSON.stringify(credentials), {
      headers: {
        "x-recaptcha-token": reCaptchaToken,
      },
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        // console.log(data, "data");

        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }
}

const baseApi = new BaseApi(apiConfig);
export default baseApi;
