import {makeStyles} from '@material-ui/core/styles'

export default makeStyles(theme => ({

    '@global': {
        ".MuiTextField-root":{
            width:"100%"
        }
      },
   
    
    contentItem:{
        marginBottom:"20px",
        alignItems: "flex-end"

    },
    inputField:{
        width:"calc(100% - 50px)",
        margin:"auto",
        fontFamily: 'Roboto-Regular',
        fontStyle:' normal',
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        "@media only screen and (max-width: 600px)":{
            width: "100%",
        }
    },
    textField:{
        width:"100%"
    },
  
}))
  