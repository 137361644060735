
import { Div, Img, P,  } from "@Common/TagAntdNotSupport/TagAntdNotSupport";
import { Grid,  Typography } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { uploadImageAction } from "@Actions/Media/action";
import { changeProfileAction } from "@Actions/Profile/action";
import { getProfileAction } from "@Actions/Login/action";
import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import { useDispatch, useSelector } from "react-redux";

import "./styles.scss";
import Tab0 from "./Tab0";
import TabHeader from "./TabHeader";
import Tab1 from "./Tab1";


const Profile = () => {
  const dispatch = useDispatch();
  const [avatars, setAvatars] = useState([]);
  const [avatarID, setAvatarID] = useState(null);
  const [ActiveTab, setActiveTab] = useState(0);
  const payload = useSelector(state => state.loginReducer?.get('payload').toJS(),)
  const profile = useSelector((state) => state.loginReducer?.get('profile').toJS(),);
  const dataProfile = profile?.result



  const onSubmitProfile = (values) => {
    const callbackSuccess = () => {
      dispatch(getProfileAction(payload.result?.token, payload?.result?.id));

    }
    dispatch(changeProfileAction(values, callbackSuccess));
  }
  const onChangeAvatar = async (imageList) => {
    setAvatars(imageList);
    let dataForm = new FormData();
    dataForm.append("photo", imageList[0]?.file);

    const callbackSuccess = (imageID) => {
      if (imageID) {
        onSubmitProfile({ photo: imageID })

      }
    }
    await dispatch(uploadImageAction(dataForm, callbackSuccess));

  };
  useEffect(() => {
    setAvatarID(dataProfile?.photo)
  }, [dataProfile])

  return (
    <>
      <Grid className="Profile-wrapper">
        <Div className="main ">
          <Div className="head">
            <div className="container header-content">
              <ImageUploading
                value={avatars}
                onChange={onChangeAvatar}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageUpdate,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <Div className="avatar">
                    {imageList.length === 0 && !avatarID ? (
                      <Div
                        className="avatar-add-item"
                        style={isDragging ? { color: "red" } : null}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        <AddIcon style={{ color: "gray", fontSize: "20px" }} />
                        <P className="rc-avatar-text">Thêm avatar</P>
                      </Div>
                    ) : avatarID ? (
                      <Div className="avatar-main-item">
                        <Img
                          src={
                            imageList[0]?.data_url ||
                            `/media/images/${avatarID}`
                          }
                          height="100%"
                          width="100%"
                        />
                        <Div className="avatar-control-edit">
                          <EditIcon style={{ color: "white", fontSize: "20px" }} onClick={() => onImageUpdate(0)} />
                        </Div>
                      </Div>
                    ) : (
                      imageList?.map((image, index) => (
                        <Div key={index} className="avatar-main-item">
                          <Img src={image?.data_url} alt="" width="100%" />
                          <Div className="avatar-control-edit" >
                            <EditIcon style={{ color: "white", fontSize: "20px" }} onClick={() => onImageUpdate(index)} />
                          </Div>
                        </Div>
                      ))
                    )}
                  </Div>
                )}
              </ImageUploading>
              <Div className="name-tab">
                <Typography variant={"h3"} className="user-name">
                  {dataProfile?.firstName ? (dataProfile?.firstName +
                    "  " +
                    dataProfile?.lastName) : "------- -------"}
                </Typography>
                <Typography variant={"h5"} className="user-id">
                  ID : {dataProfile?.id}
                </Typography>

                <TabHeader onChangeTab={setActiveTab} />
              </Div>
            </div>
          </Div>
          <div className="container">
            <div className="content">
              {
                ActiveTab === 0 
                ?
                <Tab0 onSubmitProfile={onSubmitProfile} />
                :
                <Tab1 />
              }
            </div>
          </div>
        </Div>
      </Grid>

    </>
  );
};

export default (Profile);
