import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import logoCircle from "@Assets/icons/logo/ninecodeCircle.svg";
import deleteIcon from "@Assets/icons/event/deleteNoti.svg";
import { Button, IconButton } from "@material-ui/core";
import {
  removeNotificationAction,
} from "@Actions/UserNotification/action"
import MoreOverIcon from '@Assets/icons/user/moreover.svg';
import moment from "moment";
import "moment/locale/vi";
import React from "react";
import "./style.scss";
import { useDispatch} from "react-redux"

const NotificationItem = (props) => {
  const dispatch = useDispatch()
  const { notification } = props;
  const [icon, setIcon] = React.useState(MoreOverIcon);

  const customer = notification?.payload?.name;
  const eventTitle = notification?.payload?.eventTitle;
  const isRead = notification.read;

  const handleClick = (id) => {
    setIcon(deleteIcon);
    if(icon === deleteIcon){
      dispatch(removeNotificationAction(id))
    }
  };

  const handleClickAway = () => {
    setIcon(MoreOverIcon);
  };

  return (
    <div className={`notification ${isRead?"":"unread"} ${icon === MoreOverIcon? "": "deleteActive"}`}>
      <div className="notification__content">
        <img src={logoCircle} alt=""/>
        <div className="content">
          <p className="content__time">
            {moment(notification?.createdAt).format("DD-MM-YYYY")} -{" "}
            {moment(notification?.createdAt).format("LT").replace(":", "h")}
          </p>
          <p className="content__description">
            <span>
              {customer || "Ai đó"}
            </span>
            {notification?.type === "user-event.join-requested"
              ? " đã đăng ký tham gia sự kiện "
              : " cập nhật sự kiện"}
            <span> {eventTitle}</span>
          </p>
        </div>
      </div>
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="notification__icon" onClick={()=> handleClick(notification?.id)}>
        <IconButton className="notification__icon__btn">
          <img src={icon} alt={icon}></img>
        </IconButton>
      </div>
    </ClickAwayListener>
    </div>
  );
};

export default NotificationItem;
