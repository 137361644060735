import {Button, Modal} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import "./style.scss";
import baseApi from "@Apis/Event/api";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useLocation } from "react-router";
import QrReader from 'react-qr-reader'
import * as action from "@Actions/Event/action";
import DetailDescription from "@Components/Event/QuillEditor/DetailDescription";


const CheckInEvent: React.FC = (props: any) => {
const id = props.match.params.id
const [eventDetail,setEventDetail]:any = useState({})
const [userInfor,setUserInfor]:any = useState({})
const [openScan,setOpenScan]:any = useState(false)
// const [dataScan, setDataScan]:any = useState(null)
const [verifyScan,setVerifyScan]:any = useState(null)
const [showError,setShowError]:any = useState(false)
const dispatch = useDispatch();
const location = useLocation()
const userId = new URLSearchParams(location.search).get('userid')
const typingTimeoutRef: any = React.useRef(null)


const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 600px)").matches
  )

  useEffect(() => {
    window
    .matchMedia("(max-width: 600px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);

const fetchData = async()=>{
    try {
    const {result}:any = await baseApi.getDetailEvent(id)
    const data:any = await baseApi.getDetailUserEvent(userId)
    if(data && data?.result){
        setUserInfor(data.result)
        setEventDetail(result)
    }
    
    } catch (error) {
    console.log("error fetchData event detail",error);
    }
}

useEffect(()=>{
    fetchData()
},[verifyScan])

const [statusScan,setStatusScan]:any = useState(true)

const callbackSuccess = (data: any) => {
    setVerifyScan("verifed")
    setStatusScan(true)
};
const callbackError = () => {
    setShowError(true)
    setStatusScan(true)
    // setDataScan(null)
};

const handleCloseScan = ()=>{
    setOpenScan(false)
    setShowError(false)
}

const handleScan = (data: any)=>{
    if(data && statusScan){
        setStatusScan(false)
        const getEventID:any = data.split("/")
        const eventID = getEventID.pop()
        dispatch(action.updateCheckInEventAction({checkIn:true, eventID},userId, callbackSuccess, callbackError))
    }
}

const handleCheckIn = ()=>{
    setOpenScan(true)
    if(userInfor.checkIn){
        // setDataScan("verified")
        setVerifyScan("verifed")
    }   
}

const handleCheckInAgain = ()=>{
    setShowError(false)
    // setDataScan(null)
}

return (
    <React.Fragment>
    {
    <div className='event-detail-user-wrapper'>
        {
            (eventDetail && Object.keys(eventDetail).length !== 0) && <div className='event-wrapper-checkin '>
                <div id="event-wrapper-content">
                    <div className="event-header-checkin">
                        <div className="infor-user">
                            <p>{userInfor?.name}</p>
                            <p>{userInfor?.phone}</p>
                            <p>{userInfor?.email}</p>
                        </div>
                        <img className="image-qr" src={userInfor?.qrCode} alt="QR Img"/>
                    </div>
                    <div className='content'>
                        <div className='row no-gutters' style={{ marginBottom: "20px", justifyContent:"space-between" }}>
                        <div className='short-content col'>
                            <p className='title'>{eventDetail?.eventTitle}</p>
                            <div className='time-place'>
                            <p>
                                <span>Bắt đầu: </span>{moment(eventDetail.startDateTime).format("HH:mm").replace(":","h") + " - " +  moment(eventDetail.startDateTime).format("DD/MM/YYYY")}
                            </p>
                            {
                                eventDetail.endDateTime && <p>
                                <span>Kết thúc: </span>{moment(eventDetail.endDateTime).format("HH:mm").replace(":","h") + " - " +  moment(eventDetail.endDateTime).format("DD/MM/YYYY")}
                                </p>
                            }
                            
                            {
                                (eventDetail?.address?.address && eventDetail?.isOnline==="off") && <p><span>Tại: </span>{eventDetail?.address?.address}</p>
                            } 
                            </div>
                        </div>
                        {/* */}
                        </div>

                        <div id='timeline-content'>
                            <ul className="time-list">
                                {
                                    (eventDetail?.eventSchedules && eventDetail?.eventSchedules.length > 0 )&& eventDetail?.eventSchedules.map((value:any,index:any)=>(
                                        <li key={index} className="items">
                                        <div className="content-item">
                                            {/* <h5>Bắt đầu sự kiện </h5> */}
                                            <p className="time-item">{moment(value.endDateTime).format("HH:mm").replace(":","h") + " - " +  moment(value.endDateTime).format("DD/MM/YYYY")}</p>
                                            <div className="detail-item">
                                                <DetailDescription className="detail-item" text={value.note}/>
                                            </div>
                                        </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="wrap-btn-checkin">
                        <Button
                        id="user-checkIn"
                        variant='contained'
                        disabled = {userInfor.checkIn}
                        onClick={handleCheckIn}
                        className={`btn-checkin ${userInfor.checkIn?"checkIn":""}`}
                        >
                        {userInfor.checkIn?"Đã tham gia":" Check in"}
                        </Button>
                    </div> 
                    {/* Scan QR */}
                    <Modal
                        open={openScan}
                        onClose={handleCloseScan}
                        className="popover-room"
                    >
                        <div className="popover-wrapper">
                            <div className={`form-checkIn ${(!verifyScan && !showError)?"qr-scan":""}`}>
                                {
                                    !verifyScan 
                                    ?   showError
                                        ?   <>
                                                <div className="verify-scan">
                                                    <p className="user-name">Check In thất bại</p>
                                                    <p>Mã QR Không hợp lệ</p>
                                                </div>
                                                <div className="btn-verified">
                                                    <Button
                                                        color='primary'
                                                        variant="contained"
                                                        size="small"
                                                        onClick={handleCheckInAgain}
                                                        className="action-btn"
                                                    >
                                                        Thử lại
                                                    </Button>
                                                </div>
                                            </>
                                        :   <div>
                                                <QrReader
                                                    delay={300}
                                                    onError={(err)=>console.error(err)}
                                                    onScan={handleScan}
                                                    style={{ width: '100%' }}
                                                    className="scanner"
                                                />
                                                <div className="action btn-scan">
                                                    <Button
                                                        color='primary'
                                                        variant="contained"
                                                        style={{ textTransform:"none", color:"#CEE8FF" }}
                                                        size="small"
                                                        onClick={handleCloseScan}
                                                        className="action-btn"
                                                    >
                                                        Đóng
                                                    </Button>
                                                </div>
                                            </div>
                                    :   <>
                                            <div className="verify-scan">
                                                <p className="user-name">{userInfor?.name}</p>
                                                <p>{userInfor?.email}</p>
                                                <p>{userInfor?.phone}</p>
                                            </div>
                                            <div className="btn-verified">
                                                <Button
                                                    color='primary'
                                                    variant="contained"
                                                    size="small"
                                                    onClick={handleCloseScan}
                                                    className="action-btn"
                                                >
                                                    Đã tham gia
                                                </Button>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        }
    </div>
    }
    </React.Fragment>
);
};

export default CheckInEvent;
