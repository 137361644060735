import styled from "styled-components/macro"



const EmptyLayoutWrapper = styled("div")`
width: 100%;
min-height: 100vh;

` as any;


export { EmptyLayoutWrapper };
