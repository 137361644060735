import { getInfoEnterpriseSuccessAction, getInfoEnterpriseErrorAction, changeInfoEnterpriseSuccessAction, changeInfoEnterpriseErrorAction } from './../actions/Enterprise/action';
import {
    addEnterpriseSuccessAction,
    addEnterpriseErrorAction,
    getListEnterpriseSuccessAction,
    getListEnterpriseErrorAction,
} from "@Actions/Enterprise/action";
import actionTypes from "@Actions/Enterprise/action-types";
import baseApi from "@Apis/Enterprise/api";
import { call, put, takeLatest } from "redux-saga/effects";
import { notificationOpenAction } from "@Actions/Notification/action";
import { NOTIFICATION_TYPE, TYPE_REFRESH_DATA } from "@Constants/common";
import { logoutSuccessAction } from '@Actions/Login/action';

export function* addEnterprise({
    data,
    callbackSuccess,
    callbackError,
}: any): any {
    try {
        const result = yield call(baseApi.addEnterprise, data);

        yield put(addEnterpriseSuccessAction(result));
        yield put(
            notificationOpenAction({
                isNotification: true,
                typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
                message: "Thành Công",
                typeRefreshData: TYPE_REFRESH_DATA.ADD_ROOM__REFRESH,
                description: "Tạo thành công ! ",
            })
        );
        yield callbackSuccess(result);
    } catch (err) {
      let temp = err as any;
        yield put(addEnterpriseErrorAction(temp));
        yield put(
            notificationOpenAction({
                isNotification: true,
                typeNotification: NOTIFICATION_TYPE.ERROR as "error",
                message: "Lỗi",
                description: temp?.response?.data?.message || "Lỗi chưa xác định",
            })
        );
        yield callbackError();
    }
}

export function* getListEnterprise({
    prams,
    callbackSuccess,
}: any): any {
    try {
        const result = yield call(baseApi.getListEnterprise, prams);
        yield put(getListEnterpriseSuccessAction(result));
        yield callbackSuccess(result);
    } catch (err) {
        localStorage.clear()
        yield put(logoutSuccessAction(null))
        let temp = err as any;
        yield put(getListEnterpriseErrorAction(temp));
    }
}

export function* getInfoEnterprise({
    prams, callbackSuccess,
}: any): any {
    try {
        const result = yield call(baseApi.getInfoEnterprise,prams)
        yield put(getInfoEnterpriseSuccessAction(result));
        yield callbackSuccess(result)
    } catch (err) {
        localStorage.clear()
        // yield put(logoutSuccessAction(null))
        let temp = err as any;
        yield put(getInfoEnterpriseErrorAction(temp));
    }
}

export function* changeInfoEnterprise({ dataInfoEnterprise, callbackSuccess }: any): any {
    try {
      const result = yield call(
        baseApi.changeInfoEnterprise,
        dataInfoEnterprise,
      );
      yield put(changeInfoEnterpriseSuccessAction(result));
      yield put(notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
        message: "Thành công",
        typeRefreshData: TYPE_REFRESH_DATA?.CHANGE_ENTERPRISE_REFRESH,
        time: Date.now().toString(),
        description: "Thay đổi thông tin doanh nghiệp thành công ! ",
      }));
      yield callbackSuccess()
    } catch (error) {
        let err = error as any
      yield put(changeInfoEnterpriseErrorAction(err));
      yield put(notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Lỗi",
        description: err?.response?.data?.message || "Lỗi chưa xác định",
      }));
    }
  }






export default function* EnterpriseSaga() {
    yield takeLatest(actionTypes?.ADD_ENTERPRISE_START, addEnterprise);
    yield takeLatest(actionTypes?.GET_LIST_ENTERPRISE_START, getListEnterprise);
    yield takeLatest(actionTypes?.GET_INFO_ENTERPRISE_START, getInfoEnterprise);
    yield takeLatest(actionTypes?.CHANGE_INFO_ENTERPRISE_START, changeInfoEnterprise);
}


