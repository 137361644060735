
import ActionType from "./action-types";
import {IResetPasswordCredentials} from "@Interfaces/credentials"
export const resetPasswordAction = (reCaptchaToken:string,credentials:IResetPasswordCredentials,callbackSuccess:Function,callbackError:Function) => ({
  type: ActionType.RESET_PASSWORD_START,
  reCaptchaToken,
  credentials,
  callbackSuccess,
  callbackError,
  payload: null,
  error: null,
});
export const resetPasswordSuccessAction = (result: any) => ({
  type: ActionType.RESET_PASSWORD_SUCCESS,
  payload: result,
  error: null,
});
export const resetPasswordErrorAction = (error: Error) => ({
  type: ActionType.RESET_PASSWORD_ERROR,
  payload: null,
  error: error,
});
