import React from "react";
import { usePagination } from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import "./style.scss";

const useStyles = makeStyles({
  ul: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
  }
});

export default function PaginationComponent(props) {
  const { count, page, onChangePage } = props
  const classes = useStyles();
  const { items } = usePagination({
    count,
    page,
    onChange: (event,page) => onChangePage(page)
  });

  return (
    <nav className="pagination-custom">
      <ul className={classes.ul}>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <IconButton
                key={index}
                style={{
                  fontSize: "16px",
                  fontFamily: "Roboto-Regular",
                  color: selected ? "var(--clr-primary)" : "#666666",
                  width: 30,
                  height: 30
                }}
                {...item}
              >
                {page}
              </IconButton>
            );
          } else {
            children = (
              <>
              {
                type === "previous" 
                ? <IconButton {...item} className="btn-redirect btn-previous"></IconButton>
                : <IconButton {...item} className="btn-redirect btn-next"></IconButton>
              }
              </>
            );
          }
          return (
            <>
              {
                type === "next" && <li key={index+page} style={{ margin: "auto 0" }}>
                  <span style={{color: "#666666", fontSize: "16px", paddingRight:"4px"}}>&nbsp;/ &ensp;{count}&nbsp; </span>
                </li>
              }
              <li key={index} style={{ margin: "auto 0" }}>
                {children}
              </li>
            </>
            
          );
        })}
      </ul>
    </nav>
  );
}

