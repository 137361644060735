//Component Custom
//Image-Icon
//Action
import { loginAction } from "@Actions/Login/action";
import logo from "@Assets/images/RegisterLogin/logo.svg";
import ErrorMessageStyle from "@Components/common/ErrorMessageStyle/ErrorMessageStyle";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { ILoginCredentials } from "@Interfaces/credentials";
//Component Material
import {
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
//Lib
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { LinkStyle, LoginPaper, PageWrapper, TextFieldStyle } from "./styled";
//Style
import "./styled.ts";

const RECAPTCHA_TOKEN =
  process.env.REACT_APP_RECAPTCHA_TOKEN || process.env.RECAPTCHA_TOKEN;

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);
  // const { enterprises, isLoading } = useSelector((state) => ({
  //   enterprises: state.enterpriseReducer?.get("payload")?.toJS().result,
  //   isLoading: state.loginReducer?.get("isLoading"),
  // }));

  const handleSubmitForm = (values: ILoginCredentials) => {
    // console.log(values);

    if (window.grecaptcha && window.grecaptcha.ready) {
      // (_)
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(`${RECAPTCHA_TOKEN}`, { action: "auth" })
          .then((token: string) => {
            const callbackSuccess = () => {
              history.push("/home");
            };
            const callbackError = () => {};
            dispatch(
              loginAction(token, values, callbackSuccess, callbackError)
            );
          })
          .catch((err: any) => {
            console.log(err);
          });
      });
    }
  };
  const handleClickToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  //Form
  const schema = yup.object().shape({
    username: yup
      .string()
      .required("Vui lòng nhập email")
      .email("Email không hợp lệ !"),
    password: yup.string().required("Vui lòng nhập mật khẩu"),
  });
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  //Effect Hooks
  const LOGIN_REDIRECT_URL = process.env.REACT_APP_LOGIN_REDIRECT_URL
  useEffect(()=>{
    window.open(`${LOGIN_REDIRECT_URL}?redirect=${window.location.origin}`,'_self')
  })

  return (
    <PageWrapper>
      <LoginPaper>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
        >
          <Avatar src={logo} alt='Logo' />
          <Typography> NineQuiz</Typography>
        </Grid>

        <Grid container>
          <form
            style={{ width: "100%" }}
            onSubmit={handleSubmit(handleSubmitForm)}
          >
            <Grid container direction={"column"} spacing={1}>
              <Grid item xs={12}>
                <TextFieldStyle
                  name='username'
                  label='Email'
                  inputProps={{}}
                  inputRef={register}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <EmailOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <ErrorMessage
                  errors={errors}
                  name='username'
                  render={({ message }) => (
                    <ErrorMessageStyle>{message}</ErrorMessageStyle>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldStyle
                  name='password'
                  type={showPassword ? "text" : "password"}
                  label='Mật khẩu'
                  inputProps={{}}
                  inputRef={register}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <VpnKeyOutlinedIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickToggleShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name='password'
                  render={({ message }) => (
                    <ErrorMessageStyle>{message}</ErrorMessageStyle>
                  )}
                />
              </Grid>
              <Grid
                item
                container
                justifyContent='space-between'
                alignItems='center'
                xs={12}
              >
                <LinkStyle to='/forgot-password'>Quên mật khẩu</LinkStyle>
                <Button variant='contained' color='primary' type='submit'>
                  Đăng Nhập
                </Button>
              </Grid>
              <Divider style={{ margin: "2rem 0" }} />
              <Grid
                item
                container
                justifyContent='space-between'
                alignItems='center'
                xs={12}
              >
                <LinkStyle to='/register'>
                  Đăng ký nếu chưa có tài khoản
                </LinkStyle>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </LoginPaper>
    </PageWrapper>
  );
};

export default Login;
