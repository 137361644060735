import {
  Paper,
  TextField
} from "@material-ui/core";
import { Link } from "react-router-dom";

import styled from 'styled-components/macro';
const PageWrapper = styled("div")`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
` as any;

const ResetPasswordPaper= styled(Paper)`
  padding: 2rem;
    min-width: 400px;
` as any;
const TextFieldStyle= styled(TextField)`
  width:100%;
` as any;
const LinkStyle= styled(Link)`
    color: #606060;
    font-size: 0.8rem;
` as any;

export { PageWrapper, ResetPasswordPaper,TextFieldStyle,LinkStyle };
