import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import apiConfig from "@Configs/api/api.config";
import { Api } from "@Configs/api/api";
import {IResetPasswordCredentials} from "@Interfaces/credentials"

import { API_RESET_PASSWORD } from "./endpoint";

class BaseApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config);
    this.resetPassword = this.resetPassword.bind(this);
  }
  public resetPassword(reCaptchaToken: string, credentials: IResetPasswordCredentials): Promise<any> {    
    return this.patch<any>(API_RESET_PASSWORD, JSON.stringify(credentials), {
      headers: {
        "x-recaptcha-token": reCaptchaToken,
      },
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }
}

 const baseApi = new BaseApi(apiConfig);
 export default baseApi ;
