export const API_ADD_EVENT = "/event-service/events/add"
export const API_GET_LIST_EVENT = "/event-service/events"
export const API_GET_LIST_EVENT_EMBED = "/event-service/events/list-embed"
export const API_GET_DETAIL_EVENT = "/event-service/events"
export const API_ADD_GET_DETAIL_EVENT = "/event-service/events-add"
export const API_UPDATE_EVENT = "/event-service/events"
export const API_DELETE_EVENT = "/event-service/events" 
export const API_UPDATE_CHECK_IN_EVENT = "/event-service/user-event" 
export const API_GET_LIST_USER_EVENT = "/event-service/user-event/list-user"
export const API_ADD_USER_EVENT = "/event-service/user-event/add"
export const API_DELETE_USER_EVENT = "/event-service/user-event" 
export const API_GET_DETAIL_USER_EVENT = "/event-service/user-event"
export const API_GET_LIST_DELETE_EVENT = "/event-service/events/deleted"
export const API_RESTORE_EVENT = "/event-service/events/restore" 
export const API_PUBLISH_EVENT = "/event-service/events/publish" 
export const API_GET_LIST_SHARE_EVENT = "/event-service/events/list-share"
export const API_SEND_EMAIL_REGISTER_EVENT = "/event-service/send-email"
export const API_SEND_EMAIL_CREATE_EVENT = "/event-service/send-email/admin"
export const API_UPLOAD_EXCEL = "/event-service/events/upload-excel"
export const API_INVITE_EMAIL = "/event-service/send-email/invites"