import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Radio,
  TextField,
  ButtonGroup,
  Checkbox,
  InputAdornment
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import "./style.scss";
import { Tab, Tabs } from '@material-ui/core';
import baseApi from "@Apis/Event/api";
import { useDispatch } from "react-redux";
import * as action from "@Actions/Event/action";
import { Box, Typography } from '@material-ui/core';
import CloseIcon from "@Assets/icons/event/closeIcon.svg";
import ChangeSortIcon from "@Assets/icons/event/ChangeSort.svg";
import deleteGrayIcon from "@Assets/icons/event/deleteGray.svg";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined"
import UserBlueIcon from "@Assets/icons/event/UserBlue.svg";
import PhoneBlueIcon from "@Assets/icons/event/PhoneBlue.svg";
import GmailBlueIcon from "@Assets/icons/event/GmailBlue.svg";
import noteBlueIcon from "@Assets/icons/event/noteBlue.svg";
import ParagraphBlueIcon from "@Assets/icons/event/ParagraphBlue.svg";
import StarBlueIcon from "@Assets/icons/event/StarBlue.svg";
import addressBlueIcon from "@Assets/icons/event/addressBlue.svg";
import PlusBlueIcon from "@Assets/icons/event/PlusBlueIcon.svg";
import PlusIconWhite from "@Assets/icons/event/PlusIconWhite.svg";
import CheckedIcon from "@Assets/icons/event/Checked.svg";
import TextIcon from "@Assets/icons/event/Text.svg";
import TextShortIcon from "@Assets/icons/event/TextShort.svg";
import CheckBoxIcon from "@Assets/icons/event/CheckBox.svg";
import dragIcon from "@Assets/icons/event/dragIcon.svg";
import { ReactSortable } from "react-sortablejs";
import { notificationOpenAction } from "@Actions/Notification/action";
import { NOTIFICATION_TYPE } from "@Constants/common";
import { ENTERPRISE_URL } from "@Constants/localStorage";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`listuser-tabpanel-register`}
      aria-labelledby={`listuser-tabpanel-register`}
      {...other}
    >
      {value === index && <Box p={3} className="tabpanel-content">{children}</Box>}
    </Typography>
  );
}


const ModelRegister = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [focusIndex, setFocusIndex]: any = useState(null)
  const [originData,setOriginData]:any = useState({})
  const [formRegister, setFormRegister]: any = useState(
    [
      {
        required: true,
        type: "text",
        title: "Họ và tên",
        name: "name",
        format: "text",
        img: UserBlueIcon
      },
      {
        required: true,
        type: "number",
        title: "Số điện thoại",
        name: "phone",
        format: "text",
        img: PhoneBlueIcon
      },
      {
        required: true,
        type: "email",
        title: "Email",
        name: "email",
        format: "text",
        img: GmailBlueIcon
      },
      {
        required: false,
        type: "text",
        title: "Địa chỉ",
        name: "address",
        format: "text",
        img: addressBlueIcon
      },
      {
        required: false,
        type: "text",
        title: "Ghi chú",
        name: "note",
        format: "text",
        img: noteBlueIcon,
        multiline: true
      }
    ]
  )
  const [refData, setRefData]: any = useState(false)
  const [openGroupBtn, setOpenGroupBtn]: any = useState(false)
  const [saved, setSaved] = useState(false)
  const sortDataRef: any = React.useRef(null)
  const requireds = ["name", "phone", "note", "email", "address"]
  const typingTimeoutRef: any = React.useRef(null)

  // const handleArrangeData = (index: any) => {
  //   setFocusIndex(index)
  //   setFormRegister(sortDataRef.current)
  // };

  const reorder = (startIndex:any, endIndex:any) => {
    const result = formRegister
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const handleArrangeData = (result:any) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const items = reorder(
      result.source.index,
      result.destination.index
    );

    setFormRegister(items)
    setFocusIndex(result.destination.index)
  };

  const handleRequired = (status: any, index: any) => {
    const required = ["name", "phone", "email"]
    let getRequired = formRegister[index]
    if (!required.includes(getRequired?.name)) {
      getRequired.required = status
      formRegister.splice(index, 1, getRequired)
      setFormRegister(formRegister)
      setRefData(!refData)
    }
  }

  useEffect(() => {

  }, [refData])

  const handleOpenGroup = (type: any) => {
    let result = []
    let number = 0
    switch (type) {
      case "text":
        result = formRegister.filter((e: any) => e.name.includes("text-add"))
        number = result.length + 1
        formRegister.push({
          required: true,
          type: "text",
          title: "Nhập nội dung",
          name: `text-add-${number}`,
          format: "paragraph",
          img: ParagraphBlueIcon,
          multiline: true
        })
        break;
      case "short-text":
        result = formRegister.filter((e: any) => e.name.includes("short-add"))
        number = result.length + 1
        formRegister.push({
          required: true,
          type: "text",
          title: "Nhập nội dung",
          name: `short-add-${number}`,
          format: "short-text",
          img: noteBlueIcon
        })
        break;
      case "radio":
        result = formRegister.filter((e: any) => e.name.includes("radio-add"))
        number = result.length + 1
        formRegister.push({
          required: true,
          type: "text",
          title: "Nhập nội dung",
          name: `radio-add-${number}`,
          format: "radio",
          img: StarBlueIcon,
          question: ["Lựa chọn 1", "Lựa chọn 2"]
        })
        break;
      case "check-box":
        result = formRegister.filter((e: any) => e.name.includes("check-box-add"))
        number = result.length + 1
        formRegister.push({
          required: true,
          type: "text",
          title: "Nhập nội dung",
          name: `check-box-add-${number}`,
          format: "check-box",
          img: StarBlueIcon,
          question: ["Lựa chọn 1", "Lựa chọn 2"]
        })
        break;
      default:
        break;
    }
    setOpenGroupBtn(!openGroupBtn)
  }
  const handleDelete = (index: any, name: any) => {
    if (!requireds.includes(name)) {
      formRegister.splice(index, 1)
      setFormRegister(formRegister)
      setRefData(!refData)
    }
  }

  const addItemlist = (index: any, type: any) => {
    const getLength = formRegister[index]?.question.length
    if (getLength < 4) {
      if (type === "other") {
        const checkOther = (formRegister[index]?.question).filter((e: any) => typeof e === "object");
        if (checkOther.length === 0) {
          formRegister[index]?.question.push({ other: "Khác" })
        }
      }
      else formRegister[index]?.question.push(`Lựa chọn ${getLength + 1}`)
      setFormRegister(formRegister)
      setRefData(!refData)
    }
  }

  const deleteList = (indexParent: any, index: any) => {
    formRegister[indexParent]?.question.splice(index, 1)
    setFormRegister(formRegister)
    setRefData(!refData)
  }

  const changeDataParent = (index: any, data: any) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      formRegister[index].title = data.trim()
      setFormRegister(formRegister)
      setRefData(!refData)
    }, 300)
  }

  const changeDataItem = (indexParent: any, index: any, data: any) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      formRegister[indexParent].question[index] = data.trim()
      setFormRegister(formRegister)
      setRefData(!refData)
    }, 300)
  }

  const callbackSuccess = (data: any) => {
    dispatch(notificationOpenAction({
      isNotification: true,
      typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
      message: "Thành Công",
      description: "Lưu thành công ! ",
    }))
    setSaved(true)
  }
  const callbackError = () => {
    dispatch(notificationOpenAction({
      isNotification: true,
      typeNotification: NOTIFICATION_TYPE.ERROR as "error",
      message: "Thất bại",
      description: "Lưu thất bại ! ",
    }))
  };
  const id = window.location.pathname.split("/")[3]

  const handleSubmit =()=>{
    try {
      if(id) dispatch(action.updateEventAction({requiredRegister:formRegister},id, callbackSuccess, callbackError))
    } catch (error) {
      callbackError()
    }
  }

  useEffect(()=>{
    if(formRegister.length>5){
      localStorage.setItem("FORM_REGISTER_EVENT", JSON.stringify([
        ...formRegister
      ]));
    }
  })

  const fetchData = async () => {
    try {
      const { result }: any = await baseApi.getDetailEvent(id)
      setFormRegister(result?.requiredRegister || formRegister)
      // console.log(result?.requiredRegister);
      
      setOriginData(result?.requiredRegister || formRegister)
    } catch (error) {
      console.log("error fetchData event detail", error);
    }
  }

  useEffect(() => {
    if(localStorage.getItem("FORM_REGISTER_EVENT")){
      const result= JSON.parse(localStorage.getItem("FORM_REGISTER_EVENT") as any);
      setFormRegister([...result])
    }else{
      fetchData()
      setSaved(false)
    }
    
  }, [])

  return (
    <div className="add-event-wrapper">
      <Grid container direction="row" alignItems="center" justifyContent="space-between" className="header-add-event">
        <Grid item xs={2}>
          <div className="exit-page">
            <IconButton
              onClick={() => {
                history.goBack();
              }}
              className="exit-btn"
              disableFocusRipple
              disableRipple
            >
              <img src={CloseIcon} alt={CloseIcon} />
              <span>Thoát</span>
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={8}>
          <div className="event-header">
            <Tabs
              value={0}
              indicatorColor="primary"
            >
              <Tab label="Mẫu đăng ký" className="tab" />
            </Tabs>
          </div>
        </Grid>
        <Grid item xs={2}>
        </Grid>
      </Grid>

      {/* header add event mobile */}
      <Grid container direction="row" alignItems="center" justifyContent="space-between" className="header-add-event-mobile">
        <Grid item xs={3}>
          <div className="exit-page">
            <IconButton
              onClick={() =>{
                history.goBack()
                // history.goBack()
                }
              }
              className="exit-btn"
            >
              <img src={CloseIcon} alt={CloseIcon} />
              <span>Thoát</span>
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={3}>
        <div className="exit-page">
            <IconButton
              onClick={() => history.push(`/${localStorage.getItem(ENTERPRISE_URL)}/event/${id}/drag-register`)}
              className="exit-btn"
            >
              <img src={ChangeSortIcon} alt={ChangeSortIcon} />
            </IconButton>
          </div>
        </Grid>
      </Grid>

      <TabPanel value={0} index={0} className="ev-register-container">
        <Grid item xs={12}>
          <div className="box-event model-register">
            <div className="box-event-wrap">
              <div className="add-event-content add-event-content-register">
                <div className="content-header">Mẫu đăng ký sự kiện</div>
                {
                  <DragDropContext onDragEnd={handleArrangeData} onDragStart={()=>{
                    // if (window.navigator.vibrate) {
                    //   window.navigator.vibrate(100);
                    // }
                  }}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {formRegister.map((item:any, index:any) => (
                            <Draggable key={item.name + `${index}`} draggableId={item.name + `${index}`} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className={`field-item ${index === focusIndex ? "active" : ""} ${item.format !== "text" ? "new" : ""}`} onClick={() => (index !== focusIndex) && setFocusIndex(index)}>
                                  {
                                  (item.format !== "text")? <div className="focus-header">
                                      <img src={dragIcon} alt={dragIcon} />
                                      <IconButton size="small" onClick={() => handleDelete(index, item.name)}>
                                        <img src={deleteGrayIcon} alt={deleteGrayIcon} />
                                      </IconButton>
                                    </div>:""
                                  }
                                  <div className="wrap-content">
                                    {
                                      (item.format === "text")
                                        ? (item?.multiline)
                                          ? <div className="field-item-content">
                                            <img className="field-item-img" src={item.img} alt={item.img} />
                                            <TextField
                                              placeholder={item.title}
                                              fullWidth
                                              multiline
                                              minRows={4}
                                              size="small"
                                              variant="standard"
                                              style={{
                                                background: "#F6F7FA",
                                                borderRadius: "4px",
                                                padding: "8px 4px 8px 16px"
                                              }}
                                              InputProps={{
                                                style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                                readOnly: requireds.includes(item.name) ? true : false,
                                                disableUnderline: true
                                              }}
                                            />
                                          </div>
                                          : <div className="field-item-content">
                                            <img className="field-item-img" src={item.img} alt={item.img} />
                                            <TextField
                                              placeholder={item.title}
                                              fullWidth
                                              size="small"
                                              InputProps={{
                                                style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                                readOnly: (requireds.includes(item.name) ? true : false),
                                              }}
                                            />
                                          </div>
                                        : (item.format === "short-text")
                                          ? <div className="field-item-content">
                                            <img className="field-item-img" src={item.img} alt={item.img} />
                                            <TextField
                                              // defaultValue={item.title}
                                              onChange={(e) => changeDataParent(index, e.target.value)}
                                              placeholder={item.title}
                                              fullWidth
                                              size="small"
                                              InputProps={{
                                                style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' }
                                              }}
                                            />
                                          </div>
                                          : (item.format === "radio")
                                            ? <>
                                              <div className="field-item-content radio-wrap">
                                                <img className="field-item-img" src={item.img} alt={item.img} />
                                                <TextField
                                                  // defaultValue={item.title}
                                                  onChange={(e) => changeDataParent(index, e.target.value)}
                                                  placeholder={item.title}
                                                  fullWidth
                                                  size="small"
                                                  InputProps={{
                                                    style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' }
                                                  }}
                                                />
                                              </div>
                                              {
                                                item.question.map((value: any, key: any) => (
                                                  <div className="radio-items" key={value.name + `${key}`}>
                                                    <Radio className="radio-item" checked={false} size="small" />
                                                    <TextField
                                                      onChange={(e) => changeDataItem(index, key, e.target.value)}
                                                      placeholder={(typeof value !== "object") ? value : "Khác"}
                                                      fullWidth
                                                      size="small"
                                                      InputProps={{
                                                        style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                                        endAdornment: (
                                                          <InputAdornment position="end">
                                                            <CloseOutlinedIcon onClick={() => deleteList(index, key)} style={{ cursor: "pointer" }} />
                                                          </InputAdornment>
                                                        ),
                                                        readOnly: (typeof value === "object")
                                                      }}
                                                    />
                                                  </div>
                                                ))
                                              }
                                              <div className="radio-items">
                                                <Radio className="radio-item" checked={false} size="small" />
                                                <div className="add-field">
                                                  <span onClick={() => addItemlist(index, "")}>Thêm lựa chọn</span>
                                                  &nbsp;hoặc &nbsp;
                                                  <span onClick={() => addItemlist(index, "other")}>Khác</span>
                                                </div>
                                              </div>
                                            </>
                                            : (item.format === "check-box")
                                              ? <>
                                                <div className="field-item-content radio-wrap">
                                                  <img className="field-item-img" src={item.img} alt={item.img} />
                                                  <TextField
                                                    // defaultValue={item.title}
                                                    onChange={(e) => changeDataParent(index, e.target.value)}
                                                    placeholder={item.title}
                                                    fullWidth
                                                    size="small"
                                                    InputProps={{
                                                      style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' }
                                                    }}
                                                  />
                                                </div>
                                                {
                                                  item.question.map((value: any, key: any) => (
                                                    <div className="radio-items" key={value.name + `${key}`}>
                                                      <Checkbox className="radio-item" checked={false} size="small" />
                                                      <TextField
                                                        // defaultValue={item.title}
                                                        onChange={(e) => changeDataItem(index, key, e.target.value)}
                                                        placeholder={(typeof value !== "object") ? value : "Khác"}
                                                        fullWidth
                                                        size="small"
                                                        InputProps={{
                                                          style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                                          endAdornment: (
                                                            <InputAdornment position="end">
                                                              <CloseOutlinedIcon onClick={() => deleteList(index, key)} style={{ cursor: "pointer" }} />
                                                            </InputAdornment>
                                                          ),
                                                          readOnly: (typeof value === "object")
                                                        }}
                                                      />
                                                    </div>
                                                  ))
                                                }
                                                <div className="radio-items">
                                                  <Checkbox className="radio-item" checked={false} size="small" />
                                                  <div className="add-field">
                                                    <span onClick={() => addItemlist(index, "")}>Thêm lựa chọn</span>
                                                    &nbsp;hoặc &nbsp;
                                                    <span onClick={() => addItemlist(index, "other")}>Khác</span>
                                                  </div>
                                                </div>
                                              </>
                                              : (item.format === "paragraph")
                                                ? <div className="field-item-content">
                                                  <img className="field-item-img" src={item.img} alt={item.img} />
                                                  <TextField
                                                    onChange={(e) => changeDataParent(index, e.target.value)}
                                                    placeholder={item.title}
                                                    multiline
                                                    fullWidth
                                                    size="small"
                                                    InputProps={{
                                                      style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                                    }}
                                                  />
                                                </div>
                                                : ""
                                    }
                                  </div>
                                  {
                                  (item.format !== "text")?<>
                                  {item.format === "short-text"? <div className="sub-text">Trả lời ngắn</div>:""}
                                  {item.format === "paragraph"? <div className="sub-text">Đoạn văn bản</div>:""}
                                  <FormControlLabel
                                    className="item-require"
                                    labelPlacement="start"
                                    control={<Switch color="primary" checked={item.required} />}
                                    label="Bắt buộc"
                                    onChange={(e: any) => handleRequired(e.target.checked, index)}
                                  />
                                  </>:""
                                }
                              </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
//                   <ReactSortable
//                     list={formRegister}
//                     setList={(newState) => { sortDataRef.current = newState }}
//                     onEnd={(e) => {
//                       handleArrangeData(e.newIndex)
//                     }}
//                   >
//                     {
//                       formRegister.map((item: any, index: any) => {
//                         return (
//                           <div key={item.name + `${index}`}>
//                             {
//                               <div className={`field-item ${index === focusIndex ? "active" : ""} ${item.format !== "text" ? "new" : ""}`} onClick={() => (index !== focusIndex) && setFocusIndex(index)}>
//                                 {
//                                  (item.format !== "text")? <div className="focus-header">
//                                     <img src={dragIcon} alt={dragIcon} />
//                                     <IconButton size="small" onClick={() => handleDelete(index, item.name)}>
//                                       <img src={deleteGrayIcon} alt={deleteGrayIcon} />
//                                     </IconButton>
//                                   </div>:""
//                                 }
// {/* {
//                                   index === focusIndex && <div className="focus-header">
//                                     <img src={dragIcon} alt={dragIcon} />
//                                     <IconButton size="small" onClick={() => handleDelete(index, item.name)}>
//                                       <img src={deleteGrayIcon} alt={deleteGrayIcon} />
//                                     </IconButton>
//                                   </div>
//                                 } */}
//                                 <div className="wrap-content">
//                                   {
//                                     (item.format === "text")
//                                       ? (item?.multiline)
//                                         ? <div className="field-item-content">
//                                           <img className="field-item-img" src={item.img} alt={item.img} />
//                                           <TextField
//                                             placeholder={item.title}
//                                             fullWidth
//                                             multiline
//                                             minRows={4}
//                                             size="small"
//                                             variant="standard"
//                                             style={{
//                                               background: "#F6F7FA",
//                                               borderRadius: "4px",
//                                               padding: "8px 4px 8px 16px"
//                                             }}
//                                             InputProps={{
//                                               style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
//                                               readOnly: requireds.includes(item.name) ? true : false,
//                                               disableUnderline: true
//                                             }}
//                                           />
//                                         </div>
//                                         : <div className="field-item-content">
//                                           <img className="field-item-img" src={item.img} alt={item.img} />
//                                           <TextField
//                                             placeholder={item.title}
//                                             fullWidth
//                                             size="small"
//                                             InputProps={{
//                                               style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
//                                               readOnly: (requireds.includes(item.name) ? true : false),
//                                             }}
//                                           />
//                                         </div>
//                                       : (item.format === "short-text")
//                                         ? <div className="field-item-content">
//                                           <img className="field-item-img" src={item.img} alt={item.img} />
//                                           <TextField
//                                             // defaultValue={item.title}
//                                             onChange={(e) => changeDataParent(index, e.target.value)}
//                                             placeholder={item.title}
//                                             fullWidth
//                                             size="small"
//                                             InputProps={{
//                                               style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' }
//                                             }}
//                                           />
//                                         </div>
//                                         : (item.format === "radio")
//                                           ? <>
//                                             <div className="field-item-content radio-wrap">
//                                               <img className="field-item-img" src={item.img} alt={item.img} />
//                                               <TextField
//                                                 // defaultValue={item.title}
//                                                 onChange={(e) => changeDataParent(index, e.target.value)}
//                                                 placeholder={item.title}
//                                                 fullWidth
//                                                 size="small"
//                                                 InputProps={{
//                                                   style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' }
//                                                 }}
//                                               />
//                                             </div>
//                                             {
//                                               item.question.map((value: any, key: any) => (
//                                                 <div className="radio-items" key={value.name + `${key}`}>
//                                                   <Radio className="radio-item" checked={false} size="small" />
//                                                   <TextField
//                                                     onChange={(e) => changeDataItem(index, key, e.target.value)}
//                                                     placeholder={(typeof value !== "object") ? value : "Khác"}
//                                                     fullWidth
//                                                     size="small"
//                                                     InputProps={{
//                                                       style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
//                                                       endAdornment: (
//                                                         <InputAdornment position="end">
//                                                           <CloseOutlinedIcon onClick={() => deleteList(index, key)} style={{ cursor: "pointer" }} />
//                                                         </InputAdornment>
//                                                       ),
//                                                       readOnly: (typeof value === "object")
//                                                     }}
//                                                   />
//                                                 </div>
//                                               ))
//                                             }
//                                             <div className="radio-items">
//                                               <Radio className="radio-item" checked={false} size="small" />
//                                               <div className="add-field">
//                                                 <span onClick={() => addItemlist(index, "")}>Thêm lựa chọn</span>
//                                                 &nbsp;hoặc &nbsp;
//                                                 <span onClick={() => addItemlist(index, "other")}>Khác</span>
//                                               </div>
//                                             </div>
//                                           </>
//                                           : (item.format === "check-box")
//                                             ? <>
//                                               <div className="field-item-content radio-wrap">
//                                                 <img className="field-item-img" src={item.img} alt={item.img} />
//                                                 <TextField
//                                                   // defaultValue={item.title}
//                                                   onChange={(e) => changeDataParent(index, e.target.value)}
//                                                   placeholder={item.title}
//                                                   fullWidth
//                                                   size="small"
//                                                   InputProps={{
//                                                     style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' }
//                                                   }}
//                                                 />
//                                               </div>
//                                               {
//                                                 item.question.map((value: any, key: any) => (
//                                                   <div className="radio-items" key={value.name + `${key}`}>
//                                                     <Checkbox className="radio-item" checked={false} size="small" />
//                                                     <TextField
//                                                       // defaultValue={item.title}
//                                                       onChange={(e) => changeDataItem(index, key, e.target.value)}
//                                                       placeholder={(typeof value !== "object") ? value : "Khác"}
//                                                       fullWidth
//                                                       size="small"
//                                                       InputProps={{
//                                                         style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
//                                                         endAdornment: (
//                                                           <InputAdornment position="end">
//                                                             <CloseOutlinedIcon onClick={() => deleteList(index, key)} style={{ cursor: "pointer" }} />
//                                                           </InputAdornment>
//                                                         ),
//                                                         readOnly: (typeof value === "object")
//                                                       }}
//                                                     />
//                                                   </div>
//                                                 ))
//                                               }
//                                               <div className="radio-items">
//                                                 <Checkbox className="radio-item" checked={false} size="small" />
//                                                 <div className="add-field">
//                                                   <span onClick={() => addItemlist(index, "")}>Thêm lựa chọn</span>
//                                                   &nbsp;hoặc &nbsp;
//                                                   <span onClick={() => addItemlist(index, "other")}>Khác</span>
//                                                 </div>
//                                               </div>
//                                             </>
//                                             : (item.format === "paragraph")
//                                               ? <div className="field-item-content">
//                                                 <img className="field-item-img" src={item.img} alt={item.img} />
//                                                 <TextField
//                                                   onChange={(e) => changeDataParent(index, e.target.value)}
//                                                   placeholder={item.title}
//                                                   multiline
//                                                   fullWidth
//                                                   size="small"
//                                                   InputProps={{
//                                                     style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
//                                                   }}
//                                                 />
//                                               </div>
//                                               : ""
//                                   }
//                                 </div>
//                                 {
//                                   (item.format !== "text")?<>
//                                   {item.format === "short-text"? <div className="sub-text">Trả lời ngắn</div>:""}
//                                   {item.format === "paragraph"? <div className="sub-text">Đoạn văn bản</div>:""}
//                                   <FormControlLabel
//                                     className="item-require"
//                                     labelPlacement="start"
//                                     control={<Switch color="primary" checked={item.required} />}
//                                     label="Bắt buộc"
//                                     onChange={(e: any) => handleRequired(e.target.checked, index)}
//                                   />
//                                   </>:""
//                                 }
//                                 {/* {
//                                   index === focusIndex && <FormControlLabel
//                                     className="item-require"
//                                     labelPlacement="start"
//                                     control={<Switch color="primary" checked={item.required} />}
//                                     label="Bắt buộc"
//                                     onChange={(e: any) => handleRequired(e.target.checked, index)}
//                                   />
//                                 } */}
//                               </div>
//                             }
//                           </div>
//                         );
//                       })
//                     }
//                   </ReactSortable>
                }
                <div className="action-add-register">
                  {
                    !openGroupBtn
                      ? <Button
                        className="action-add-btn"
                        onClick={() => handleOpenGroup("")}
                        endIcon={<img src={PlusBlueIcon} alt={PlusBlueIcon} />}
                        color="primary"
                        variant="outlined"
                      >
                        Thêm
                      </Button>
                      : <ButtonGroup color="primary" aria-label="outlined primary button group">
                        <Button
                          className="action-add-btn hidden"
                          endIcon={<img src={PlusIconWhite} alt={PlusIconWhite} />}
                          color="primary"
                          variant="contained"
                        >
                          Thêm
                        </Button>
                        <Button
                          className="btn-group"
                          onClick={() => handleOpenGroup("short-text")}
                          variant="outlined"
                          startIcon={<img src={TextShortIcon} alt={TextShortIcon} />}
                        >
                          Trả lời ngắn
                        </Button>
                        <Button
                          className="btn-group"
                          onClick={() => handleOpenGroup("text")}
                          variant="outlined"
                          startIcon={<img src={TextIcon} alt={TextIcon} />}
                        >
                          Văn bản
                        </Button>
                        <Button
                          className="btn-group"
                          onClick={() => handleOpenGroup("radio")}
                          variant="outlined"
                          startIcon={<img src={CheckedIcon} alt={CheckedIcon} />}
                        >
                          Lựa chọn
                        </Button>
                        <Button
                          className="btn-group"
                          onClick={() => handleOpenGroup("check-box")}
                          variant="outlined"
                          startIcon={<img src={CheckBoxIcon} alt={CheckBoxIcon} />}
                        >
                          Hộp kiểm
                        </Button>
                      </ButtonGroup>
                  }

                </div>
                <div className="save-register">
                  {!saved ? <Button
                    className="action-save"
                    color="primary"
                    variant="contained"
                    onClick={() => handleSubmit()}
                  >
                    Lưu
                  </Button>
                    : <Button
                      className="action-save"
                      color="primary"
                      variant="outlined"
                    //disabled
                    //onClick={() => handleSubmit()}
                    >
                      Đã lưu
                    </Button>}
                </div>
              </div>
              <div className="add-event-content add-event-content-register add-event-content-register--sp">
                <div className="content-header">Mẫu đăng ký sự kiện</div>
                {
                      formRegister.map((item: any, index: any) => {
                        return (
                          <div key={item.name + `${index}`}>
                            {
                              <div className={`field-item ${index === focusIndex ? "active" : ""} ${item.format !== "text" ? "new" : ""}`} onClick={() => (index !== focusIndex) && setFocusIndex(index)}>
                                {
                                 (item.format !== "text")? <div className="focus-header">
                                    <IconButton size="small" onClick={() => handleDelete(index, item.name)}>
                                      <img src={deleteGrayIcon} alt={deleteGrayIcon} />
                                    </IconButton>
                                  </div>:""
                                }
                                <div className="wrap-content">
                                  {
                                    (item.format === "text")
                                      ? (item?.multiline)
                                        ? <div className="field-item-content">
                                          <img className="field-item-img" src={item.img} alt={item.img} />
                                          <TextField
                                            placeholder={item.title}
                                            fullWidth
                                            multiline
                                            minRows={4}
                                            size="small"
                                            variant="standard"
                                            style={{
                                              background: "#F6F7FA",
                                              borderRadius: "4px",
                                              padding: "8px 4px 8px 16px"
                                            }}
                                            InputProps={{
                                              style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                              readOnly: requireds.includes(item.name) ? true : false,
                                              disableUnderline: true
                                            }}
                                          />
                                        </div>
                                        : <div className="field-item-content">
                                          <img className="field-item-img" src={item.img} alt={item.img} />
                                          <TextField
                                            placeholder={item.title}
                                            fullWidth
                                            size="small"
                                            InputProps={{
                                              style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                              readOnly: (requireds.includes(item.name) ? true : false),
                                            }}
                                          />
                                        </div>
                                      : (item.format === "short-text")
                                        ? <div className="field-item-content">
                                          <img className="field-item-img" src={item.img} alt={item.img} />
                                          <TextField
                                            // defaultValue={item.title}
                                            onChange={(e) => changeDataParent(index, e.target.value)}
                                            placeholder={item.title}
                                            fullWidth
                                            size="small"
                                            InputProps={{
                                              style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' }
                                            }}
                                          />
                                        </div>
                                        : (item.format === "radio")
                                          ? <>
                                            <div className="field-item-content radio-wrap">
                                              <img className="field-item-img" src={item.img} alt={item.img} />
                                              <TextField
                                                // defaultValue={item.title}
                                                onChange={(e) => changeDataParent(index, e.target.value)}
                                                placeholder={item.title}
                                                fullWidth
                                                size="small"
                                                InputProps={{
                                                  style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' }
                                                }}
                                              />
                                            </div>
                                            {
                                              item.question.map((value: any, key: any) => (
                                                <div className="radio-items" key={value.name + `${key}`}>
                                                  <Radio className="radio-item" checked={false} size="small" />
                                                  <TextField
                                                    onChange={(e) => changeDataItem(index, key, e.target.value)}
                                                    placeholder={(typeof value !== "object") ? value : "Khác"}
                                                    fullWidth
                                                    size="small"
                                                    InputProps={{
                                                      style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                                      endAdornment: (
                                                        <InputAdornment position="end">
                                                          <CloseOutlinedIcon onClick={() => deleteList(index, key)} style={{ cursor: "pointer" }} />
                                                        </InputAdornment>
                                                      ),
                                                      readOnly: (typeof value === "object")
                                                    }}
                                                  />
                                                </div>
                                              ))
                                            }
                                            <div className="radio-items">
                                              <Radio className="radio-item" checked={false} size="small" />
                                              <div className="add-field">
                                                <span onClick={() => addItemlist(index, "")}>Thêm lựa chọn</span>
                                                &nbsp;hoặc &nbsp;
                                                <span onClick={() => addItemlist(index, "other")}>Khác</span>
                                              </div>
                                            </div>
                                          </>
                                          : (item.format === "check-box")
                                            ? <>
                                              <div className="field-item-content radio-wrap">
                                                <img className="field-item-img" src={item.img} alt={item.img} />
                                                <TextField
                                                  // defaultValue={item.title}
                                                  onChange={(e) => changeDataParent(index, e.target.value)}
                                                  placeholder={item.title}
                                                  fullWidth
                                                  size="small"
                                                  InputProps={{
                                                    style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' }
                                                  }}
                                                />
                                              </div>
                                              {
                                                item.question.map((value: any, key: any) => (
                                                  <div className="radio-items" key={value.name + `${key}`}>
                                                    <Checkbox className="radio-item" checked={false} size="small" />
                                                    <TextField
                                                      // defaultValue={item.title}
                                                      onChange={(e) => changeDataItem(index, key, e.target.value)}
                                                      placeholder={(typeof value !== "object") ? value : "Khác"}
                                                      fullWidth
                                                      size="small"
                                                      InputProps={{
                                                        style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                                        endAdornment: (
                                                          <InputAdornment position="end">
                                                            <CloseOutlinedIcon onClick={() => deleteList(index, key)} style={{ cursor: "pointer" }} />
                                                          </InputAdornment>
                                                        ),
                                                        readOnly: (typeof value === "object")
                                                      }}
                                                    />
                                                  </div>
                                                ))
                                              }
                                              <div className="radio-items">
                                                <Checkbox className="radio-item" checked={false} size="small" />
                                                <div className="add-field">
                                                  <span onClick={() => addItemlist(index, "")}>Thêm lựa chọn</span>
                                                  &nbsp;hoặc &nbsp;
                                                  <span onClick={() => addItemlist(index, "other")}>Khác</span>
                                                </div>
                                              </div>
                                            </>
                                            : (item.format === "paragraph")
                                              ? <div className="field-item-content">
                                                <img className="field-item-img" src={item.img} alt={item.img} />
                                                <TextField
                                                  onChange={(e) => changeDataParent(index, e.target.value)}
                                                  placeholder={item.title}
                                                  multiline
                                                  fullWidth
                                                  size="small"
                                                  InputProps={{
                                                    style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                                  }}
                                                />
                                              </div>
                                              : ""
                                  }
                                </div>
                                {
                                  (item.format !== "text")?<>
                                  {item.format === "short-text"? <div className="sub-text">Trả lời ngắn</div>:""}
                                  {item.format === "paragraph"? <div className="sub-text">Đoạn văn bản</div>:""}
                                  <FormControlLabel
                                    className="item-require"
                                    labelPlacement="start"
                                    control={<Switch color="primary" checked={item.required} />}
                                    label="Bắt buộc"
                                    onChange={(e: any) => handleRequired(e.target.checked, index)}
                                  />
                                  </>:""
                                }
                              </div>
                            }
                          </div>
                        );
                      })
                    }
                <div className="action-add-register">
                  {
                    !openGroupBtn
                      ? <Button
                        className="action-add-btn"
                        onClick={() => handleOpenGroup("")}
                        endIcon={<img src={PlusBlueIcon} alt={PlusBlueIcon} />}
                        color="primary"
                        variant="outlined"
                      >
                        Thêm
                      </Button>
                      : <ButtonGroup color="primary" aria-label="outlined primary button group">
                        <Button
                          className="action-add-btn hidden"
                          endIcon={<img src={PlusIconWhite} alt={PlusIconWhite} />}
                          color="primary"
                          variant="contained"
                        >
                          Thêm
                        </Button>
                        <Button
                          className="btn-group"
                          onClick={() => handleOpenGroup("short-text")}
                          variant="outlined"
                          startIcon={<img src={TextShortIcon} alt={TextShortIcon} />}
                        >
                          Trả lời ngắn
                        </Button>
                        <Button
                          className="btn-group"
                          onClick={() => handleOpenGroup("text")}
                          variant="outlined"
                          startIcon={<img src={TextIcon} alt={TextIcon} />}
                        >
                          Văn bản
                        </Button>
                        <Button
                          className="btn-group"
                          onClick={() => handleOpenGroup("radio")}
                          variant="outlined"
                          startIcon={<img src={CheckedIcon} alt={CheckedIcon} />}
                        >
                          Lựa chọn
                        </Button>
                        <Button
                          className="btn-group"
                          onClick={() => handleOpenGroup("check-box")}
                          variant="outlined"
                          startIcon={<img src={CheckBoxIcon} alt={CheckBoxIcon} />}
                        >
                          Hộp kiểm
                        </Button>
                      </ButtonGroup>
                  }

                </div>
                <div className="save-register">
                  {!saved ? <Button
                    className="action-save"
                    color="primary"
                    variant="contained"
                    onClick={() => handleSubmit()}
                  >
                    Lưu
                  </Button>
                    : <Button
                      className="action-save"
                      color="primary"
                      variant="outlined"
                    //disabled
                    //onClick={() => handleSubmit()}
                    >
                      Đã lưu
                    </Button>}
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </TabPanel>
    </div>
  );
};

export default ModelRegister;