import { Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import FileIcon from "@Assets/icons/event/FileIcon.svg";
import baseApi from "@Apis/Event/api";
import "./styles.scss";
// drag drop file component
const DragDropFile = (props: any) => {
  const { eventID, onSuccess } = props;
    // drag state
    const [dragActive, setDragActive] = useState(false);
    // ref
    const inputRef = React.useRef(null);
    
    // handle drag events
    const handleDrag = function(e:any) {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    };
    
    // triggers when file is dropped
    const handleDrop = function(e:any) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        onSuccess(e.dataTransfer.files);
      }
    };
    
    // triggers when file is selected with click
    const handleChange = async function(e:any) {
      e.preventDefault();
      if (e.target.files && e.target.files[0]) {
        let dataForm = new FormData();
        dataForm.append("file", e.target.files[0]);
        try {
          const {result}:any = await baseApi.uploadExcel(dataForm, eventID)
          onSuccess(result);
        } catch (error) {
          console.log("error",error);
        }
        
      }
    };
    
    return (
      <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
        
        <input 
        ref={inputRef} 
        type="file" 
        id="input-file-upload" 
        multiple={true} 
        onChange={handleChange} 
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
        <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
          <div>
          <Button
            variant="contained"
            component="label"
            className='btnUpload'
            // onClick={onButtonClick}
            startIcon={<img src={FileIcon} alt=""/>}
          >
            Tải tệp lên
            <input
              type="file"
              hidden
              multiple={true} 
              onChange={handleChange}
              accept=".xlsm, .xlsx, .xls, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </Button>
            <p className='text'>Kéo thả tệp hoặc thư mục của bạn vào đây</p>
          </div> 
        </label>
        { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
      </form>
    );
};
  
export default DragDropFile;