import { notificationOpenAction } from "@Actions/Notification/action";
import { changeAccountErrorAction, changeAccountSuccessAction, getAccountSuccessAction, addAccountSuccessAction, addAccountErrorAction } from "@Actions/Account/action";
import actionTypes from "@Actions/Account/action-types";
import baseApi from "@Apis/Account/api";
import { NOTIFICATION_TYPE} from "@Constants/common";
import { call, put, takeLatest } from "redux-saga/effects";

export function* changeAccount({ params, callbackSuccess }: any): any {
  try {
    const result = yield call(baseApi.changeAccount, params);
    yield put(changeAccountSuccessAction(result));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
        message: "Thành Công",
        description: "Xóa thành công ! ",
      })
    );
    yield callbackSuccess(result);
  } catch (err) {
    let temp = err as any;
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Lỗi",
        description: temp?.response?.data?.message || "Lỗi chưa xác định",
      })
    );
    yield put(changeAccountErrorAction(temp));
  }
}

export function* addAccount({
  prams,
  data,
  callbackSuccess,
  callbackError,
}: any): any {
  try {
    const result = yield call(baseApi.addAccount, prams, data);

    yield put(addAccountSuccessAction(result));
    yield callbackSuccess(result);
  } catch (err) {
    let temp = err as any;

    yield put(addAccountErrorAction(temp));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Lỗi",
        description: (temp?.response?.data?.code === "ALREADY_EXITS_ACCOUNT") ? "Tài khoản đã đăng ký" : (temp?.response?.data?.message || "Đăng ký thất bại"),
      })
    );
    yield callbackError();
  }
}

export function* getAccount({ prams, callbackSuccess }: any): any {
  try {
    const result = yield call(baseApi.getAccount, prams);
    yield put(getAccountSuccessAction(result))
    yield callbackSuccess()
  } catch (error) {
    let err = error as any
    yield put(changeAccountErrorAction(err));
    yield put(notificationOpenAction({
      isNotification: true,
      typeNotification: NOTIFICATION_TYPE.ERROR as "error",
      message: "Lỗi",
      description: err?.response?.data?.message || "Lỗi chưa xác định",
    }));
  }
}

export default function* accountSaga() {
  yield takeLatest(actionTypes?.CHANGE_ACCOUNT_START, changeAccount);
  yield takeLatest(actionTypes?.GET_ACCOUNT_START, getAccount);
}
