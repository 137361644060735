import styled from "styled-components/macro";
const FooterWrapper = styled("div")`
  /* height: 100px;
  color: #747474;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center; */
` as any;

export { FooterWrapper };
