import React, { useEffect, useRef } from "react"
import { useState } from "react"

const Tabs = ['Thông tin' , 'Kết nối']

export default function TabHeader({onChangeTab}) {
  const tabHeader = useRef()
  const [ActiveTab, setActiveTab] = useState(0)
  const [TabSize , setTabSize] = useState({left : 0 , width : 0})
  const handleSwitchTab = (e, i) => {
    setActiveTab(i)
    typeof onChangeTab === 'function' && onChangeTab(i)
    const headerLeft = tabHeader.current.getBoundingClientRect().left
    const {left, width} = e.target.getBoundingClientRect()

    setTabSize({left : left - headerLeft , width})
  }

  useEffect(() => {
    setTabSize({
      left : 0 ,
      width : tabHeader.current.querySelector('.tabs').offsetWidth
    })
  },[])
  return (
    <>
      <div ref={ref=>tabHeader.current = ref} className="tab-header" style={{'--underline-pos' : `${TabSize.left}px` , '--underline-width' : `${TabSize.width}px`}}>
        {
          Tabs.map((o, index) => <div onClick={(e)=> handleSwitchTab(e,index)} className={`tabs ${ActiveTab === index ? 'active' : ''}`} key={o}>{o}</div> )
        }
      </div>
    </>
  )
}