import React, { useCallback, useEffect } from "react"
import google from '../../assets/images/Google.png'
import teams from '../../assets/images/teams.png'
import zoom from '../../assets/images/zoom.png'
import facebook from '../../assets/images/facebook.png'
import disconnect from '../../assets/images/disconnect.png'
import { useDispatch, useSelector } from "react-redux";
import * as action from "@Actions/Account/action";
import Skeleton from '@material-ui/lab/Skeleton';
export default function Tab1() {

    const dispatch = useDispatch();
    const { linkAccount } = useSelector(
        (state) => ({
            linkAccount: state.linkAccountReducer?.get("linkAccount")?.toJS().result,
        })
    );

    useEffect(() => {
        (async () => {
            try {
                const callbackSuccess = (result) => { };
                dispatch(action.getAccountAction({}, callbackSuccess));
            } catch (error) { }
        })();
    }, [dispatch])

    const handleDisconnect = (provider)=>{
        const callbackSuccess = (result) => {};
        dispatch(action.changeAccountAction(provider, callbackSuccess));
    }

    const handleConnect = (provider) => {
        if (provider === 'google') {
            const callbackSuccess = (result) => {};
            dispatch(action.addAccountAction(provider, {}, callbackSuccess));
        }
    }

    const renderList = useCallback(() => {
        if (linkAccount) {
            return (
                <>
                    {
                        linkAccount.map((item, index) => (
                            <div className="group" key={index}>
                                <div className="calendar-icon">
                                    {
                                        item.provider === "google" ?
                                            <img
                                                src={item.profile?.photo ? item.profile?.photo : google}
                                                onError={(e) => (e.target.onerror = null, e.target.src = google)} />
                                            : item.provider === "microsoft" ?
                                                <img
                                                    src={item.profile?.photo ? item.profile?.photo : teams}
                                                    onError={(e) => (e.target.onerror = null, e.target.src = teams)} />
                                                : item.provider === "facebook" ?
                                                    <img
                                                        src={item.profile?.photo ? item.profile?.photo : facebook}
                                                        onError={(e) => (e.target.onerror = null, e.target.src = facebook)} />
                                                    : // -------------zoom-----------------
                                                    <img
                                                        src={item.profile?.photo ? item.profile?.photo : zoom}
                                                        onError={(e) => (e.target.onerror = null, e.target.src = zoom)} />
                                    }
                                </div>
                                <div className="account-info">
                                    <span className="sp-except"> {item.provider}</span>
                                    <span>{item.profile?.email}</span>
                                </div>
                                {item.connected && <div 
                                onClick={() => handleDisconnect(item.provider)} 
                                className="disconnect">
                                    <img src={disconnect} alt="" />
                                    <span className="sp-except">Ngắt kết nối</span>
                                </div>}

                                {!item.connected && <div 
                                onClick={() => handleConnect(item.provider)} 
                                className="disconnect add">
                                    <img src={disconnect} alt="" />
                                    <span className="sp-except">Kết nối</span>
                                </div>}
                            </div>
                        ))
                    }
                </>
            )
        } else {
            return <Skeleton variant="rect" height="100px"></Skeleton>
        }
    })
    return (
        <>
            <div className="connected">
                <div className="header">
                    <div className="title">Kết nối</div>
                </div>
                <div className="body">
                    {
                        renderList()
                    }
                </div>
            </div>
        </>
    )
}