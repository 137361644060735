import styled from "styled-components/macro"


const DefaultLayoutWrapper = styled("div")`
  width: 100%;
  min-height: 100%;
` as any;


export { DefaultLayoutWrapper };
