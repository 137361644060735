import {makeStyles} from '@material-ui/core/styles'

export default makeStyles(theme => ({
    root: {
        padding:"20px 20px",
        maxWidth: "438px",
         fontFamily:"Roboto-Regular",
         justifyContent:"center",
         "@media only screen and (max-width: 600px)":{
            maxWidth: "328px",
        }

    },
    titlePopover:{
        fontFamily: "Roboto-Regular",
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "28px",
        lineHeight: "34px",
        color: "#0E5B9F;",
         marginBottom:"20px"
    },
    buttonCancel:{
        color:"#999999",
        backgroundColor:"#F2F2F2",
        marginRight:"20px",
        minWidth:"90px",
        fontFamily: 'Roboto-Medium',
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "24px",
        textTransform:" capitalize",
        "&:hover":{
            color:"#646464"
        }
    },
    buttonSave:{
        marginRight:"25px",
        minWidth:"90px",
        textTransform:" capitalize",
        boxShadow: "none",
        fontFamily: 'Roboto-Medium',
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "24px",
        "&:hover":{
            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #0E5B9F",
            boxShadow: "none",
        },
        "@media only screen and (max-width: 600px)":{
            marginRight:"0",
        }
    },
    groupButtonControl:{
        display:"flex",
        justifyContent:"flex-end",
        marginTop:"50px",
        "@media only screen and (max-width: 600px)":{
            marginTop:"40px",
        }
    },
    content:{
        width:"calc(100% - 50px)",
        margin:"auto",
        "& .MuiFormControl-root":{
            width:"100%"
        },
        "@media only screen and (max-width: 600px)":{
            width: "100%",
        }
    },
    contentItem:{
        marginBottom:"20px",
        alignItems: "flex-end"

    },
    inputField:{
        width:"calc(100% - 50px)",
        margin:"auto",
        fontFamily: 'Roboto-Regular',
        fontStyle:' normal',
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        "@media only screen and (max-width: 600px)":{
            width: "100%",
        }
    },
    textField:{
        width:"100%",
        fontFamily: 'Roboto-Regular',
        fontStyle:' normal',
        fontWeight: "400",    
        fontSize: "16px",
        lineHeight: "24px",
    },
    errorMessages:{
         color:"red",
         marginTop:"10px"
    }
   
}))
  