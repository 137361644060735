import React, { useEffect, useState } from "react";
import "./style.scss";
import { useHistory } from "react-router";
import { ENTERPRISE_URL } from "@Constants/localStorage";
import {
  Button,
  Modal,
  Paper,
  Typography,
  IconButton,
  Popover,
  ListItemText,
  ListItem,
  ListItemIcon,
} from "@material-ui/core";
import WebScriptIcon from "@Assets/icons/event/embed.svg";
import CopyLinkIcon from "@Assets/icons/event/copy.svg";
import OpenInBrowserIcon from "@Assets/icons/event/Share.svg";
import AddIcon from "@Assets/icons/event/More.svg";
import personGroupIcon from "@Assets/icons/event/personGroup.svg";
import deleteIcon from "@Assets/icons/event/Delete.svg";
import { useDispatch } from "react-redux";
import * as action from "@Actions/Event/action";
import MicrosoftTeamIcon from "@Assets/icons/event/teamsEvent.svg";
import GoogleMeetImageIcon from "@Assets/icons/event/GoogleMeetEvent.svg";
import moment from "moment";
import "moment/locale/vi";
import ModalAddToWeb from "../ModalAddToWeb/ModalAddToWeb";
import processingIcon from "@Assets/icons/event/processing.svg";
import notProcessingIcon from "@Assets/icons/event/notprocessing.svg";
import finishedIcon from "@Assets/icons/event/finished.svg";
import arrowDownIcon from "@Assets/icons/event/Arrowdown.svg";
import CopiedIcon from "@Assets/icons/event/coppied.svg";
import arrowIcon from "@Assets/icons/event/arrow.svg";
import { notificationOpenAction } from "@Actions/Notification/action";
import { NOTIFICATION_TYPE } from "@Constants/common";
import { isBuffer } from "lodash";

interface IEventItem {
  data: any;
  RefreshEventData: any;
  deleteForever?: any;
  handleEventSelected?: any;
  eventSelected?: any;
}

const EventItem: React.FC<IEventItem> = (props: any) => {
  const { data, deleteForever }: any =
    props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorElDetail, setAnchorElDetail] = useState(null);
  const [anchorPublish, setAnchorPublish] = useState(null);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openAddToWeb, setOpenAddToWeb] = useState(false);
  const [copy, setCopy] = useState(false);
  const open = Boolean(anchorElDetail);
  const openPublish = Boolean(anchorPublish);
  const [url, setUrl] = useState("");
  const [active, setActive] = useState(false);
  const checkPublish = ["publish", "notpublish"];

  const handleCloseDetail = () => {
    setAnchorElDetail(null);
    setActive(false);
  };
  const handleClosePublish = () => {
    setAnchorPublish(null);
  };
  const handleOpenPublish = (event: any) => {
    // if (checkPublish.includes(data.showPublish)) {
    //   setAnchorPublish(event.currentTarget);
    // }
    if(data.status){
      setAnchorPublish(event.currentTarget);
    }
  };
  const handleOpenButton = (event: any) => {
    // if (checkPublish.includes(data.showPublish)) {
    //   setAnchorElDetail(event.currentTarget);
    //   setActive(true);
    // }
    if(data.status){
      setAnchorElDetail(event.currentTarget);
      setActive(true);
    }
  };

  const callbackSuccess = (data: any) => {
    setOpenModalConfirm(false);
    setAnchorElDetail(null);
    props.RefreshEventData();
  };

  const handleSubmitConfirm = () => {
    dispatch(action.deleteEventAction(data.id, callbackSuccess));
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
    setAnchorElDetail(null);
  };

  const handleToMeet = () => {
    if(data.eventDetail && !data.address?.address && data.onlineItems ==="google" && data.active){
      window.open(data.eventDetail?.link);
    }
  };

  const typingTimeoutRef: any = React.useRef(null);
  const getLink = () => {
    const hostName = window?.location?.origin;
    const eventUrl = localStorage.getItem(ENTERPRISE_URL) || "";
    const link = hostName + "/" + eventUrl + "/" + data.id;
    setUrl(link);
  };
  const handleCopy = async () => {
    navigator.clipboard.writeText(url);
    setCopy(true);
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(async () => {
      setCopy(false);
    }, 1000);
  };

  const handleCloseAddToWeb = () => {
    setOpenAddToWeb(false);
  };

  const callbackSuccessPublish = () => {
    props.RefreshEventData();
    handleClosePublish();
  };

  const handlePublish = (value: any) => {
    if(data.status){
      // if (checkPublish.includes(data.showPublish)) {
        dispatch(
          action.publishEventAction(
            { isPublish: value },
            data.id,
            callbackSuccessPublish
          )
        );
      // }else{
      //   dispatch(
      //   notificationOpenAction({
      //     isNotification: true,
      //     typeNotification: NOTIFICATION_TYPE.WARNING as "warning",
      //     message: "WARNING",
      //     description: "Sự kiện đã kết thúc ",
      //   })
      // )
      // }
    }
  };

  // const handleChecked = (e: any)=>{
  //   const checked = e.target.checked
  //   handleEventSelected(data.id, checked)
  //   setStatus(checked)
  // }

  useEffect(() => {
    getLink();
  }, []);

  // useEffect(()=>{
  //   if(eventSelected && eventSelected?.length===0) setStatus(false)
  // },[eventSelected])

  const handleEventDetail = () => {
    if (data?.active) {
      history.push(`/${localStorage.getItem(ENTERPRISE_URL)}/event/${data.id}`);
    }
  };

  const handleEmbedEvent = () => {
    if(data.status !== "finished"){
      if(data.showPublish === "publish"){
        history.push(
          `/${localStorage.getItem(ENTERPRISE_URL)}/event/embed/${data.id}`
        )
      }
      else{
        dispatch(notificationOpenAction({
          isNotification: true,
          typeNotification: NOTIFICATION_TYPE.ERROR as "error",
          message: "Thất bại",
          description: "Bạn phải publish sự kiện ! ",
        }))
      }
    }else{
      dispatch(notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Thất bại",
        description: "Sự kiện đã kết thúc ! ",
      }))
    }
  };

  const checkDay =
    moment(data.startDateTime).format("llll").split(",")[0].toLowerCase() ===
      "cn" || false;
  const getDay = moment(data.startDateTime)
    .format("llll")
    ?.split(",")[0]
    ?.substring(1, 2);

  const  handleShare = (e:any)=>{
    if(data.showPublish === "publish"){
      e.stopPropagation()
      window.open(url, "_blank")
      handleCloseDetail()
      // history.push(
      //   `/${localStorage.getItem(ENTERPRISE_URL)}/eventforuser/${data.id}`
      // )
    }else if(data.status === "finished"){
      dispatch(notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Thất bại",
        description: "Sự kiện đã kết thúc ! ",
      }))
    }
    else{
      dispatch(notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Thất bại",
        description: "Bạn phải publish sự kiện ! ",
      }))
    }
  }

  return (
    <React.Fragment>
      <div className={`EventItem-wrapper ${active ? "active" : ""}`}>
        <div className="EventItem-content">
          <div className="banner">
            {data?.image && data.image !== "" && (
              <img className="image" src={data.image} alt="" />
            )}
            <div className="number-person">
              <img src={personGroupIcon} alt={personGroupIcon} />
              <span>{data?.listUser?.length || 0}</span>
            </div>
            <div className="status" onClick={handleOpenPublish}>
              {!data.status?
              <>
              {data.showPublish === "publish" ? (
                <img
                  className="show-status"
                  src={processingIcon}
                  alt={processingIcon}
                />
              ) : data.showPublish === "notpublish" ? (
                <img
                  className="show-status"
                  src={notProcessingIcon}
                  alt={notProcessingIcon}
                />
              ) : (
                <img
                  className="show-status"
                  src={finishedIcon}
                  alt={finishedIcon}
                />
              )}
              </>:
              <>
              {data.isPublish? (
                <img
                  className="show-status"
                  src={processingIcon}
                  alt={processingIcon}
                />
              ) : (
                <img
                  className="show-status"
                  src={notProcessingIcon}
                  alt={notProcessingIcon}
                />
              )}
              </>}
              <img
                className="btn-show"
                src={arrowDownIcon}
                alt={arrowDownIcon}
              />
            </div>
            <div className="overlay" onClick={handleEventDetail}></div>
            {/* {
              checkPublish.includes(data.showPublish)? */}
              <IconButton className="btn-more" onClick={handleOpenButton}>
                <img src={AddIcon} alt={AddIcon} />
              </IconButton>
          </div>

          <div className="content">
            <div className="content__cont" onClick={handleEventDetail}>
            {data.status === "processing" ? (
              <p className="event-status">Đang diễn ra</p>
            ) : data.status === "notprocessing" ? (
              <p className="event-status">
                {data?.endDateTime ? (
                  moment(data.startDateTime).format("DD/MM/YYYY") ===
                  moment(data?.endDateTime).format("DD/MM/YYYY") ? (
                    <>
                      {checkDay ? "Chủ nhật" : `Thứ ${getDay}`}
                      ,&nbsp;ngày&nbsp;
                      {moment(data.startDateTime).format("DD/MM/YYYY") +
                        ", " +
                        moment(data.startDateTime)
                          .format("HH:mm")
                          .replace(":", "h") +
                        " - " +
                        moment(data?.endDateTime)
                          .format("HH:mm")
                          .replace(":", "h")}
                    </>
                  ) : (
                    <>
                      {moment(data.startDateTime)
                        .format("HH:mm")
                        .replace(":", "h") +
                        " - " +
                        moment(data.startDateTime).format("DD/MM/YYYY")}
                      <img src={arrowIcon} alt={arrowIcon} />
                      {moment(data?.endDateTime)
                        .format("HH:mm")
                        .replace(":", "h") +
                        " - " +
                        moment(data?.endDateTime).format("DD/MM/YYYY")}
                    </>
                  )
                ) : (
                  <>
                    {checkDay ? "Chủ nhật" : `Thứ ${getDay}`}
                    ,&nbsp;ngày&nbsp;
                    {data.startDateTime
                      ? moment(data.startDateTime).format("DD/MM/YYYY") +
                        ", " +
                        moment(data.startDateTime)
                          .format("HH:mm")
                          .replace(":", "h")
                      : ""}
                  </>
                )}
              </p>
            ): data.status === "finished" ? (
              <p className="event-status">Đã kết thúc</p>
            ) : (
              <p className="event-status">
                {data?.endDateTime ? (
                  <>
                    {checkDay ? "Chủ nhật" : `Thứ ${getDay}`}
                    ,&nbsp;ngày&nbsp;
                    {data?.endDateTime
                      ? moment(data?.endDateTime).format("DD/MM/YYYY") +
                        ", " +
                        moment(data?.endDateTime)
                          .format("HH:mm")
                          .replace(":", "h")
                      : ""}
                  </>
                ) : (
                  <>
                    {checkDay ? "Chủ nhật" : `Thứ ${getDay}`}
                    ,&nbsp;ngày&nbsp;
                    {data.startDateTime
                      ? moment(data.startDateTime).format("DD/MM/YYYY") +
                        ", " +
                        moment(data.startDateTime)
                          .format("HH:mm")
                          .replace(":", "h")
                      : ""}
                  </>
                )}
              </p>
            )}
            <p className="event-name">{data.eventTitle}</p>
            </div>
            <div className="event-description" onClick={!data.address?.address?handleToMeet:handleEventDetail}>
              {data.isOnline === "on" ? (
                <div className="status" style={!data.active?{cursor: "context-menu"}:{}}>
                  {data?.onlineItems === "google" ? (
                    <>
                      <img
                        src={GoogleMeetImageIcon}
                        alt="GoogleMeetImageIcon"
                      />
                      <span>Google Meet</span>
                    </>
                  ) : (
                    <>
                      <img src={MicrosoftTeamIcon} alt="TeamIcon" />
                      <span>Microsoft Team</span>
                    </>
                  )}
                </div>
              ) : (
                <div className="place">
                  <p>{data.address?.address}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Popover
        open={open}
        anchorEl={anchorElDetail}
        onClose={handleCloseDetail}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            width: "200px",
            background: "rgba(0, 0, 0, 0.6)",
            borderRadius: "4px",
            marginLeft: "20px",
          },
        }}
        className="popover-time"
      >
        <ListItem
          className="list-item-btn"
          onClick={handleShare}
        >
          <ListItemIcon className="list-item-icon">
            <img src={OpenInBrowserIcon} alt={OpenInBrowserIcon} />
          </ListItemIcon>
          <ListItemText className="list-item-text">
            Chia sẻ sự kiện
          </ListItemText>
        </ListItem>
        <ListItem className="list-item-btn" onClick={handleEmbedEvent}>
          <ListItemIcon className="list-item-icon">
            <img src={WebScriptIcon} alt={WebScriptIcon} />
          </ListItemIcon>
          <ListItemText className="list-item-text">
            Copy embed code
          </ListItemText>
        </ListItem>
        {copy ? (
          <ListItem className="list-item-btn">
            <ListItemIcon className="list-item-icon">
              {/* <CheckIcon /> */}
              <img src={CopiedIcon} alt={CopiedIcon} />
            </ListItemIcon>
            <ListItemText className="list-item-text">Copied</ListItemText>
          </ListItem>
        ) : (
          <ListItem className="list-item-btn" onClick={handleCopy}>
            <ListItemIcon className="list-item-icon">
              <img src={CopyLinkIcon} alt={CopyLinkIcon} />
            </ListItemIcon>
            <ListItemText className="list-item-text">Copy link</ListItemText>
          </ListItem>
        )}

        {!deleteForever && (
          <ListItem
            className="list-item-btn"
            onClick={() => setOpenModalConfirm(true)}
          >
            <ListItemIcon className="list-item-icon">
              <img src={deleteIcon} alt={deleteIcon} />
            </ListItemIcon>
            <ListItemText className="list-item-text">Xoá sự kiện</ListItemText>
          </ListItem>
        )}
      </Popover>

      <Popover
        open={openPublish}
        anchorEl={anchorPublish}
        onClose={handleClosePublish}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            width: "200px",
            background: "rgba(0, 0, 0, 0.6)",
            borderRadius: "4px",
            marginLeft: "30px",
          },
        }}
      >
        <ListItem className="list-item-btn" onClick={() => handlePublish(true)}>
          <ListItemIcon className="item-icon-publish list-item-icon">
            <img src={processingIcon} alt={processingIcon} />
          </ListItemIcon>
          <ListItemText className="list-item-text">Công bố</ListItemText>
        </ListItem>
        <ListItem
          className="list-item-btn"
          onClick={() => handlePublish(false)}
        >
          <ListItemIcon className="item-icon-publish list-item-icon">
            <img src={notProcessingIcon} alt={notProcessingIcon} />
          </ListItemIcon>
          <ListItemText className="list-item-text">Không công bố</ListItemText>
        </ListItem>
        <ListItem
          className="list-item-btn"
          onClick={() => setOpenModalConfirm(true)}
        >
          <ListItemIcon className="item-icon-publish list-item-icon">
            <img src={finishedIcon} alt={finishedIcon} />
          </ListItemIcon>
          <ListItemText className="list-item-text">Xoá</ListItemText>
        </ListItem>
      </Popover>

      {openModalConfirm && (
        <Modal
          open={true}
          onClose={handleCloseModalConfirm}
          className="modal-confirm"
        >
          <Paper style={{ padding: "20px 40px" }}>
            <Typography
              variant="h5"
              color="primary"
              style={{
                marginBottom: "3rem",
                textAlign: "center",
                fontFamily: "Roboto-Regular",
              }}
            >
              Xác nhận xoá
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="contained"
                color="default"
                onClick={handleCloseModalConfirm}
                style={{ textTransform: "none", fontFamily: "Roboto-Regular" }}
              >
                Hủy
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginLeft: "1rem",
                  textTransform: "none",
                  fontFamily: "Roboto-Regular",
                }}
                onClick={handleSubmitConfirm}
              >
                Xác Nhận
              </Button>
            </div>
          </Paper>
        </Modal>
      )}
      <ModalAddToWeb
        url={url}
        openAddToWeb={openAddToWeb}
        handleCloseAddToWeb={handleCloseAddToWeb}
      />
    </React.Fragment>
  );
};

export default EventItem;
