//Component Custom
//Image-Icon
//Action
import { resetPasswordAction } from "@Actions/ResetPassword/action";
import logo from "@Assets/images/RegisterLogin/logo.svg";
import ErrorMessageStyle from "@Components/common/ErrorMessageStyle/ErrorMessageStyle";
import getAllUrlParams from "@Helpers/getAllUrlParams";
//Hocs
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
//Component Material
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@material-ui/core";
//Icon Material
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
//Lib
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import {
  LinkStyle,
  PageWrapper,
  ResetPasswordPaper,
  TextFieldStyle,
} from "./styled";
//Style
const RECAPTCHA_TOKEN =
  process.env.REACT_APP_RECAPTCHA_TOKEN || process.env.RECAPTCHA_TOKEN;

const ResetPassword: React.FC = (props) => {
  //Redux Connect
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParam = getAllUrlParams(window.location.href);

  //Const
  //State Hooks
  const [showPassword, setShowPassword] = useState(false);
  //Function
  const handleSubmitForm = (values: any) => {
    if (window.grecaptcha && window.grecaptcha.ready) {
      window.grecaptcha.ready((_: any) => {
        window.grecaptcha
          .execute(`${RECAPTCHA_TOKEN}`, { action: "resetPassword" })
          .then((token: any) => {
            const callbackSuccess = () => {
              history.push("/login");
            };
            const callbackError = () => {};
            dispatch(
              resetPasswordAction(
                token,
                { ...values, token: queryParam?.token },
                callbackSuccess,
                callbackError
              )
            );
          });
      });
    }
  };
  const handleClickToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  //Form
  const schema = yup.object().shape({
    password: yup.string().required("Vui lòng nhập mật khẩu"),
    confirmPassword: yup
      .string()
      .required("Vui lòng nhập  mật khẩu ")
      .oneOf([yup.ref("password")], "Mật khẩu không trùng khớp !"),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  //Effect Hooks
  return (
    <PageWrapper>
      <ResetPasswordPaper>
        <Grid container direction='column' justify='center' alignItems='center'>
          <Avatar src={logo} alt='Logo' />
          <Typography> Đổi mật khẩu</Typography>
        </Grid>

        <Grid container>
          <form
            style={{ width: "100%" }}
            onSubmit={handleSubmit(handleSubmitForm)}
          >
            <Grid container direction={"column"} spacing={1}>
              <Grid item xs={12}>
                <TextFieldStyle
                  name='password'
                  type={showPassword ? "text" : "password"}
                  label='Mật khẩu'
                  inputProps={{}}
                  inputRef={register}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <VpnKeyOutlinedIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickToggleShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name='password'
                  render={({ message }) => (
                    <ErrorMessageStyle>{message}</ErrorMessageStyle>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <TextFieldStyle
                  name='confirmPassword'
                  type={showPassword ? "text" : "password"}
                  label='Nhập lại mật khẩu '
                  inputProps={{}}
                  inputRef={register}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <VpnKeyOutlinedIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickToggleShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name='confirmPassword'
                  render={({ message }) => (
                    <ErrorMessageStyle>{message}</ErrorMessageStyle>
                  )}
                />
              </Grid>

              <Grid
                item
                container
                justifyContent='space-between'
                alignItems='center'
                xs={12}
              >
                <LinkStyle to='/login'>Đăng Nhập</LinkStyle>
                <Button variant='contained' color='primary' type='submit'>
                  Xác Nhận
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </ResetPasswordPaper>
    </PageWrapper>
  );
};

export default ResetPassword;
