//Component Custom
//Image-Icon
//Action
import { forgotPasswordAction } from "@Actions/ForgotPassword/action";
import logo from "@Assets/images/RegisterLogin/logo.svg";
import ErrorMessageStyle from "@Components/common/ErrorMessageStyle/ErrorMessageStyle";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { IForgotPasswordCredentials } from "@Interfaces/credentials";
//Component Material
import {
  Avatar,
  Button,
  Grid,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
//Lib
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import { ForgotPasswordPaper, PageWrapper, TextFieldStyle } from "./styled";

//Style
const RECAPTCHA_TOKEN =
  process.env.REACT_APP_RECAPTCHA_TOKEN || process.env.RECAPTCHA_TOKEN;

const ForgotPassword: React.FC = () => {
  //Redux Connect
  const dispatch = useDispatch();
  const history = useHistory();
  //Const
  //State Hooks
  // const [showPassword, setShowPassword] = useState(false);
  //Function
  const handleSubmitForm = (values: IForgotPasswordCredentials) => {
    if (window.grecaptcha && window.grecaptcha.ready) {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(`${RECAPTCHA_TOKEN}`, { action: "forgotPassword" })
          .then((token: string) => {
            const callbackSuccess = () => {
              history.push("/");
            };
            const callbackError = () => {};
            dispatch(
              forgotPasswordAction(
                token,
                values,
                callbackSuccess,
                callbackError
              )
            );
          });
      });
    }
  };

  //Form
  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Vui lòng nhập email")
      .email("Email không hợp lệ !"),
  });

  const {
    register,
    handleSubmit,
    // getValues,
    // reset,
    // watch,
    // clearErrors,
    errors,
  } = useForm({ resolver: yupResolver(schema) });
  //Effect Hooks
  return (
    <PageWrapper>
      <ForgotPasswordPaper>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
        >
          <Avatar src={logo} alt='Logo' />
          <Typography> Quên mật khẩu</Typography>
        </Grid>

        <Grid container>
          <form
            style={{ width: "100%" }}
            onSubmit={handleSubmit(handleSubmitForm)}
          >
            <Grid container direction={"column"} spacing={1}>
              <Grid item xs={12}>
                <TextFieldStyle
                  name='email'
                  label='Email'
                  inputProps={{}}
                  inputRef={register}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <EmailOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <ErrorMessage
                  errors={errors}
                  name='email'
                  render={({ message }) => (
                    <ErrorMessageStyle>{message}</ErrorMessageStyle>
                  )}
                />
              </Grid>

              <Grid
                item
                container
                justifyContent='space-between'
                alignItems='center'
                xs={12}
              >
                <Link
                  style={{ color: "#606060", fontSize: "0.8rem" }}
                  to='/login'
                >
                  Đăng Nhập
                </Link>
                <Button variant='contained' color='primary' type='submit'>
                  Xác Nhận
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </ForgotPasswordPaper>
    </PageWrapper>
  );
};

export default ForgotPassword;
