import React, { useState, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const DetailDescription = (props:any) => {
    const {value, onChange, placeholder, index}:any = props
    const [openText,setOpenText]=useState(true)
    const wrapperRef = useRef(null);
    const useOutsideAlerter = (ref:any) => {
        useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
            setOpenText(true)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        }, [ref]);
    }
    useOutsideAlerter(wrapperRef);
    const modules = {
        toolbar: [
        ['bold', 'italic', 'underline','strike'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'},{ 'align': [] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] },'clean']
        ],
    }
 
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet', 'indent',
        'align'
    ]//'image', 

    return (
        <div 
            className='editor-wrapper'
            onClick={()=>setOpenText(false)} 
            ref={wrapperRef}
        >
            <ReactQuill 
                theme={openText ? "bubble" :"snow"}
                readOnly={openText}
                value={value}
                onChange={(e)=>onChange(e,index,"note")} 
                modules={modules}
                formats={formats}
                className="editor"
                placeholder={placeholder}
            />
        </div>
    );
};

export default DetailDescription;