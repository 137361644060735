import { call, put, takeLatest } from "redux-saga/effects";
import baseApi from "@Apis/ForgotPassword/api";
import {
  forgotPasswordErrorAction,
  forgotPasswordSuccessAction,
} from "@Actions/ForgotPassword/action";
import { notificationOpenAction } from "@Actions/Notification/action";
import actionTypes from "@Actions/ForgotPassword/action-types";
import { NOTIFICATION_TYPE } from "@Constants/common";

export function* forgotPassword({
  reCaptchaToken,
  credentials,
  callbackSuccess,
  callbackError,
}: any): any {
  try {
    const result = yield call(
      baseApi.forgotPasswordUser,
      reCaptchaToken,
      credentials
    );
    // console.log(result, "result");

    yield put(forgotPasswordSuccessAction(result));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
        message: "Thành Công",
        description: "Kiểm tra email để thay đổi mật khẩu",
      })
    );
    yield callbackSuccess();
  } catch (err) {
    let temp = err as any;
    yield put(forgotPasswordErrorAction(err));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
        message: "Lỗi",
        description: temp?.response?.data?.message || "Lỗi chưa xác định",
      })
    );
    yield callbackError();
  }
}

export default function* forgotPasswordSaga() {
  yield takeLatest(actionTypes?.FORGOT_START, forgotPassword);
}
