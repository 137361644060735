import React, { useState } from "react";
import { Button, Modal, TextField, Paper, Popover} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import inlineIcon from "@Assets/icons/event/inline.svg";
import textPopupIcon from "@Assets/icons/event/textPopup.svg";
import widgetPopupIcon from "@Assets/icons/event/widgetPopup.svg";
import { SketchPicker } from "react-color";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';


const presetColors = [
    "rgb(0,0,0)",
    "rgb(0,0,255)",
    "rgb(0,255,0)",
    "rgb(255,0,0)",
    "rgb(255,255,255)"
  ];

const ModalAddToWeb = ({openAddToWeb,handleCloseAddToWeb,url}:any)=>{
    const [active,setActive] = useState("")
    const [isContinue,setIsContinue] = useState(false)
    const typingTimeoutRef: any = React.useRef(null)
    const typingCopyRef: any = React.useRef(null)
    const [color,setColor] = useState("#0069ff")
    const [colorText,setColorText] = useState("#ffffff")
    const [openChooseColor,setOpenChooseColor] = useState(false)
    const [openChooseColor2,setOpenChooseColor2] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [linkText,setLinkText] = useState("Nine Event")
    const [buttonText,setButtonText] = useState("Nine Event")
    const [copy,setCopy] = useState(false)
    const open = Boolean(anchorEl);
    const [anchorE2, setAnchorE2] = useState(null);
    const open2 = Boolean(anchorE2);
    const linkOrigin = window.location.origin || "https://event-app.9code.vn"
    const linkCss = `${linkOrigin}/external/widget.css`
    const linkJs = `${linkOrigin}/external/widget.js`
  
    const handleClose = ()=>{
      setActive("")
      setIsContinue(false)
      setColor("#0069ff")
      setColorText("#ffffff")
      setLinkText("Nine Event")
      setButtonText("Nine Event")
    }
  
    const getColor = (e:any,option:any)=>{
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current)
      }
      typingTimeoutRef.current = setTimeout(async () => 
      {
        if(option==="text") setColorText(e.hex)
        else setColor(e.hex)
      }, 300)
    }
  
    const handleCloseAnchor = () => {
      setAnchorEl(null);
      setOpenChooseColor(!openChooseColor)
    };
  
    const handleOpenAnchor = (event:any)=>{
      setAnchorEl(event.currentTarget);
      setOpenChooseColor(!openChooseColor)
    }
  
    const handleCloseAnchor2 = () => {
      setAnchorE2(null);
      setOpenChooseColor2(!openChooseColor2)
    };
  
    const handleOpenAnchor2 = (event:any)=>{
      setAnchorE2(event.currentTarget);
      setOpenChooseColor2(!openChooseColor2)
    }
  
    const handleCopy = ()=>{
      const copyText: any = document.getElementById("share-add-to-web");
      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value)
      setCopy(true)
      if (typingCopyRef.current) {
        clearTimeout(typingCopyRef.current)
      }
      typingCopyRef.current = setTimeout(async () => {
        setCopy(false)
      }, 1000)
    }
  
    return(
      <Modal
        open={openAddToWeb}
        onClose={()=>{
          handleCloseAddToWeb();
          handleClose()
        }}
        className="modal-confirm"
      >
        <Paper className="add-to-web">
          <h3 className="header">Copy embed codesite</h3>
          {
            isContinue
            ? <>
                <div className="embed-wrap">
                  <section className="embed-left embed-content">
                    <div className="group-name-header">
                      <h3 className="">{active}</h3>
                      <Button 
                        variant="text" 
                        color="primary" 
                        className="btn-style"
                        onClick={()=>setIsContinue(false)}
                      >
                        Change
                      </Button>
                    </div>
                    {
                      (active==="Popup Widget") && <div className="group-left-content">
                        <h3 className="left-content">{active} Settings</h3>
                        <p className="describe">Customize the display of the floating button that will launch your Event page.</p>
                        <div className="text-input">
                          <div className="title-input">Button Text</div>
                          <TextField
                            type='text'
                            variant="outlined"
                            onChange={(e)=>setButtonText(e.target.value)}
                            value={buttonText}
                            fullWidth
                            size="small"
                          />
                        </div>
                        <div className="group-choose-color">
                          <div>Button Background Color</div>
                          <Button 
                            onClick={handleOpenAnchor}
                            variant="outlined" 
                            size="small" 
                            endIcon={openChooseColor?<ArrowDropUpIcon/>:<ArrowDropDownIcon />}
                          >
                            <span className="show-color" style={{backgroundColor: color}}></span>
                          </Button>
                        </div>
                        <div className="group-choose-color">
                          <div>Button Text Color</div>
                          <Button 
                            onClick={handleOpenAnchor2}
                            variant="outlined" 
                            size="small" 
                            endIcon={openChooseColor2?<ArrowDropUpIcon/>:<ArrowDropDownIcon />}
                          >
                            <span className="show-color" style={{backgroundColor: colorText}}></span>
                          </Button>
                        </div>
                        <Popover
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleCloseAnchor}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        >
                          <div className="choose-color">
                            <SketchPicker 
                              presetColors={presetColors} 
                              color={color}
                              onChangeComplete={(e)=>getColor(e,"background")}
                            />
                          </div>
                        </Popover>
                        <Popover
                          open={open2}
                          anchorEl={anchorE2}
                          onClose={handleCloseAnchor2}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        >
                          <div className="choose-color">
                            <SketchPicker 
                              presetColors={presetColors} 
                              color={colorText}
                              onChangeComplete={(e)=>getColor(e,"text")}
                            />
                          </div>
                        </Popover>
                      </div>
                    }
                    {
                      (active==="Popup Text") && <div className="group-left-content">
                      <h3 className="left-content">{active} Settings</h3>
                      <p className="describe">Customize the link visitors will click to launch your Event page.</p>
                      <div className="text-input">
                        <div className="title-input">Link Text</div>
                        <TextField
                          type='text'
                          variant="outlined"
                          onChange={(e)=>setLinkText(e.target.value)}
                          value={linkText}
                          fullWidth
                          size="small"
                        />
                      </div>
                    </div>
                    }
                  </section>
                  <section className="embed-right embed-content">
                    <h3 className="header">Embed Code</h3>
                    <p className="sub-header">Place this code in your HTML where you want your eventing link to appear. When a visitor clicks the link, your Nine Event page will load as a popup.</p>
                    <TextField
                      id="share-add-to-web"
                      type='text'
                      multiline
                      rows={12}
                      InputProps={{
                        readOnly: true,
                        disableUnderline: true
                      }}
                      // variant="outlined"
                      value={
                        (active==="Popup Text")
                        ? `<!-- Text popup -->
                          <link href="${linkCss}" rel="stylesheet">
                          <script src="${linkJs}" type="text/javascript" async></script>
                          <a href="" onclick="textPopup('${url}');return false;">${linkText}</a>
                          <!-- Text popup -->`
                        : (
                            (active==="Popup Widget")
                            ? `<!-- Widget popup -->
                              <link href="${linkCss}" rel="stylesheet">
                              <script src="${linkJs}" type="text/javascript" async></script>
                              <script type="text/javascript">window.onload = function() { widgetPopup({ url: '${url}', text: '${buttonText}', color: '${color}', textColor: '${colorText}', branding: true });}</script>
                              <!-- Widget popup -->`
                            : `<!-- Event inline widget begin -->
                              <div class="event-inline-widget" data-url="${url}" style="min-width:320px;height:1000px;"></div>
                              <script src="${linkJs}" type="text/javascript" async></script>
                              <!-- Event inline widget end -->`
                          )
                        
                        }
                      fullWidth
                      className="text-embed-code"
                    />
                    <div className="btn-action">
                      <Button
                        variant="contained"
                        color="default"
                        onClick={()=>{
                          handleCloseAddToWeb();
                          handleClose()
                        }}
                        className="btn-style"
                      >
                        Hủy
                      </Button>
                      {
                        copy
                        ? <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: "1rem" }}
                            className="btn-style"
                            startIcon={<CheckIcon/>}
                          >
                            Copied
                          </Button>
                        : <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: "1rem" }}
                            className="btn-style"
                            onClick={handleCopy}
                          >
                            Copy Code
                          </Button>
                      }
                      
                    </div>
                  </section>
                </div>
              </>
            : <>
                <div className="sub-header">How do you want to add Nine Event to your site?</div>
                <div className={`btn-wrap ${(active==="Inline Embed") ? "active":""}`} onClick={()=>setActive("Inline Embed")}>
                  <img src={inlineIcon} alt={inlineIcon}/>
                  <div className="content">
                    <div className="header-content">Embed inline</div>
                    <p>Add a scheduling page to your site</p>
                  </div>
                </div>
                <div className={`btn-wrap ${(active==="Popup Widget") ? "active":""}`} onClick={()=>setActive("Popup Widget")}>
                  <img src={widgetPopupIcon} alt={widgetPopupIcon}/>
                  <div className="content">
                    <div className="header-content">Widget popup</div>
                    <p>Add a floating button that opens a popup</p>
                  </div>
                </div>
                <div className={`btn-wrap ${(active==="Popup Text") ? "active":""}`} onClick={()=>setActive("Popup Text")}>
                  <img src={textPopupIcon} alt={textPopupIcon}/>
                  <div className="content">
                    <div className="header-content">Text popup</div>
                    <p>Add a text link that opens a popup</p>
                  </div>
                </div>
                <div className="btn-action">
                  <Button
                    variant="contained"
                    color="default"
                    onClick={()=>{
                      handleCloseAddToWeb();
                      handleClose()
                    }}
                    className="btn-style"
                  >
                    Hủy
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: "1rem" }}
                    className="btn-style"
                    onClick={()=>setIsContinue(true)}
                    disabled={(active==="")? true:false}
                  >
                    Tiếp tục
                  </Button>
                </div>
              </>
          }
          
        </Paper>
      </Modal>
    )
  }

export default ModalAddToWeb;