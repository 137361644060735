import FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const exportToXlsx = (csvData, fileName, wscols) => {
    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const Heading = [];
    const ws = XLSX.utils.json_to_sheet(Heading, {
      header: [],
      skipHeader: 1,
      origin: 0
    });
    ws["!cols"] = wscols;
    XLSX.utils.sheet_add_json(ws, csvData, {
      header: [],
      skipHeader: 1,
      origin: -1
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    return 
};