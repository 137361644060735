import ActionType from "./action-types";
import { IRegisterCredentials } from "@Interfaces/credentials";
export const registerAction = (
  reCaptchaToken: string,
  credentials: IRegisterCredentials,
  callbackSuccess: Function,
  callbackError: Function
) => ({
  type: ActionType.REGISTER_START,
  reCaptchaToken,
  credentials,
  callbackSuccess,
  callbackError,
  payload: null,
  error: null,
});
export const registerSuccessAction = (result: number) => ({
  type: ActionType.REGISTER_SUCCESS,
  payload: result,
  error: null,
});
export const registerErrorAction = (error: unknown) => ({
  type: ActionType.REGISTER_ERROR,
  payload: null,
  error: error,
});
