//Common
import loginReducer from "@Reduxs/reducers/loginReducer";
import notificationReducer from "@Reduxs/reducers/notificationReducer";
import enterpriseReducer from "@Reduxs/reducers/enterpriseReducer";
import linkAccountReducer from "@Reduxs/reducers/linkAccountReducer";
import userReducer from "@Reduxs/reducers/userReducer";
import userNotificationReducer from "@Reduxs/reducers/userNotificationReducer";
import socketReducer from "@Reduxs/reducers/socketReducer";
import userEventReducer from "@Reduxs/reducers/userEventReducer";

import toastReducer from "../lib/Toast/reducer";
//
import { combineReducers } from "redux";

export default combineReducers({
  loginReducer,
  notificationReducer,
  linkAccountReducer,
  enterpriseReducer,
  userReducer,
  userEventReducer,
  userNotificationReducer,
  socketReducer,
  toastReducer,
});
