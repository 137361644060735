import { notificationOpenAction } from "@Actions/Notification/action";
import {
  addUserErrorAction, addUserSuccessAction, getListRoleErrorAction, getListRoleSuccessAction, getListUserErrorAction, getListUserSuccessAction, getPermissionErrorAction
} from "@Actions/User/action";
import actionTypes from "@Actions/User/action-types";
import baseApi from "@Apis/User/api";
import { NOTIFICATION_TYPE, TYPE_REFRESH_DATA } from "@Constants/common";
import { call, put, takeLatest } from "redux-saga/effects";
import { deleteUserSuccessAction, updateUserErrorAction, updateUserSuccessAction, deleteUserErrorAction, getPermissionSuccessAction } from './../actions/User/action';

export function* addUser({
  data,
  callbackSuccess,
  callbackError,
}: any): any {
  try {
    const result = yield call(baseApi.addUser, data);
    yield put(addUserSuccessAction(result));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
        message: "Thành Công",
        typeRefreshData: TYPE_REFRESH_DATA.ADD_USER_REFRESH,
        description: "Tạo thành công ! ",
      })
    );
    yield callbackSuccess();
  } catch (err : any) {
    yield put(addUserErrorAction(err));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Lỗi",
        description: err?.response?.data?.message || "Lỗi chưa xác định",
      })
    );
    yield callbackError();
  }
}

export function* getListUser({
  prams,
  callbackSuccess,
}: any): any {
  try {
    const result = yield call(baseApi.getListUser, prams);
    yield put(getListUserSuccessAction(result));
    yield callbackSuccess(result);
  } catch (err : any) {
    yield put(getListUserErrorAction(err));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Lỗi",
        description: err?.response?.data?.message || "Lỗi chưa xác định",
      })
    );
  }
}

export function* getListRole({
  prams, callbackSuccess,
}: any): any {
  try {
    const result = yield call(baseApi.getListRole, prams)
    yield put(getListRoleSuccessAction(result))
    yield callbackSuccess(result)
  } catch (err : any) {
    yield put(getListRoleErrorAction(err));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Lỗi",
        description: err?.response?.data?.message || "Lỗi chưa xác định",
      })
    );
  }
}

export function* updateUser({
  selectedUserID, selectedRole, callbackSuccess
}: any): any {
  try {
    const result = yield call(baseApi.updateUser, selectedUserID, selectedRole)
    yield put(updateUserSuccessAction(result))
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
        message: "Thành Công",
        typeRefreshData: TYPE_REFRESH_DATA.UPDATE_USER_REFRESH,
        description: "Cập nhật thành công ! ",
      }))
    yield callbackSuccess(result)
  } catch (err : any) {
    yield put(updateUserErrorAction(err));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Lỗi",
        description: err?.response?.data?.message || "Lỗi chưa xác định",
      })
    );
  }
}

export function* deleteUser({
  selectedUserID, callbackSuccess
}: any): any {
  try {
    const result = yield call(baseApi.deleteUser, selectedUserID)
    yield put(deleteUserSuccessAction(result))
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
        message: "Thành Công",
        typeRefreshData: TYPE_REFRESH_DATA.DELETE_USER_REFRESH,
        description: "Xoá thành công ! ",
      }))
    yield callbackSuccess(result)
  } catch (err : any) {
    yield put(deleteUserErrorAction(err));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Lỗi",
        description: err?.response?.data?.message || "Lỗi chưa xác định",
      })
    );
  }
}


export function* getListPermission({
  prams, callbackSuccess,
}: any): any {
  try {
    const result = yield call(baseApi.getPermission, prams)
    yield put(getPermissionSuccessAction(result))
    yield callbackSuccess(result)
  } catch (err : any) {
    yield put(getPermissionErrorAction(err));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Lỗi",
        description: err?.response?.data?.message || "Lỗi chưa xác định",
      })
    );
  }
}



export default function* UserSaga() {
  yield takeLatest(actionTypes?.ADD_USER_START, addUser);
  yield takeLatest(actionTypes?.GET_LIST_USER_START, getListUser);
  yield takeLatest(actionTypes?.GET_LIST_ROLE_START, getListRole);
  yield takeLatest(actionTypes?.UPDATE_USER_START, updateUser);
  yield takeLatest(actionTypes?.DELETE_USER_START, deleteUser);
  yield takeLatest(actionTypes?.GET_PERMISSION_START, getListPermission);
}


