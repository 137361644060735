import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const DetailDescription = (props:any) => {
    const text = props?.text || ""
    return (
        <div >
            <ReactQuill 
                theme="bubble"
                readOnly={true}
                value={text}
                className="general-editor"
            />
        </div>
    );
};

export default DetailDescription;