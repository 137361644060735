import { IAction } from "@Interfaces/common";
import ActionType from "@Actions/Notification/action-type";
import { fromJS } from 'immutable';

const initialState = fromJS({
  type: null,
  isNotification: false,
  typeNotification: "success",
  message: "",
  description: "",
  time: "",
  typeRefreshData: "",

});
export default (state: any = initialState, action: IAction) => {
  switch (action.type) {
    case ActionType.NOTIFICATION_CLOSE:
      return state
        .set('type', action.type)
        .set('isNotification', false)
        .set('typeNotification', "success")
        .set('message', "")
        .set('description', "")
        .set("time", "")
        .set("typeRefreshData", "")
    case ActionType.NOTIFICATION_OPEN:
      return state
        .set('type', action.type)
        .set('isNotification', true)
        .set('typeNotification', action?.payload?.typeNotification)
        .set('message', action?.payload?.message)
        .set('description', action?.payload?.description)
        .set("time", action?.payload?.time)
        .set("typeRefreshData", action?.payload?.typeRefreshData)

    default:
      return state;
  }
};
