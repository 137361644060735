import React, { useState, useEffect } from 'react'
import { Box, Button, TextField, Modal, Chip, Avatar, Paper, Typography, Tabs, Tab, Grid, IconButton, InputAdornment } from '@material-ui/core';
import { InviteModalPropsInterface } from './types';
import PropTypes from 'prop-types';
import CloseIcon from "@material-ui/icons/Close"
import UploadIcon from "@Assets/icons/event/Upload.svg";
import styles from './style.module.scss';
import { useForm } from 'react-hook-form';
import UploadListUser from "@Components/Event/UploadListUser";
import baseApi from "@Apis/Event/api";
import { ENTERPRISE_ID, ENTERPRISE_URL } from "@Constants/localStorage";
import { notificationOpenAction } from "@Actions/Notification/action";
import { NOTIFICATION_TYPE } from "@Constants/common";
import { useDispatch, useSelector } from "react-redux";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`listuser-tabpanel-${index}`}
      aria-labelledby={`listuser-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3} className={styles.tabpanelContent}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const { List } = require("immutable");

const InviteModal = (props: InviteModalPropsInterface) => {
  const { onCloseInviteModal, id, openInviteModal, onSuccess } = props;
  const [tab, setTab] = useState(0)
  const [errMess, setErrMess] = useState("")
  const [firstAdd, setFirstAdd] = useState(true)
  const [mail, setMail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [uploadListUser, setUploadListUser]: any = useState(false)
  const dispatch = useDispatch();

  const [dataSubmitEmail, setDataSubmitEmail]: any = useState([]);
  const [dataSubmitPhone, setDataSubmitPhone]: any = useState([]);
  const { handleSubmit: handleSubmitEmail, register: registerEmail } = useForm({
    shouldUnregister: false
  })
  const { handleSubmit: handleSubmitPhone, register: registerPhone } = useForm({
    shouldUnregister: false
  })
  const regexSring = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const regexPhoneString = /^[0-9\(\)\/\+ \-]{9,12}$/;


  const handleTabChange = (event: any, tab: any) => {
    setTab(tab)
    setFirstAdd(true)
  }

  const handleChangeInputEmail = (event: any) => {
    if (!firstAdd) {
      const validString = new RegExp(regexSring);
      if (!event.target.value) {
        setErrMess("Vui lòng nhập email !")
      } else
        if (!validString.test(event.target.value)) {
          setErrMess("Email không đúng !")
        } else {
          setErrMess("")
        }
    }
    setMail(event.target.value);
  }

  const handleChangeInputPhone = (event: any) => {
    if (!firstAdd) {
      const validString = new RegExp(regexPhoneString);
      if (!event.target.value) {
        setErrMess("Vui lòng nhập Số điện thoại !")
      } else
        if (!validString.test(event.target.value)) {
          setErrMess("Phone không đúng !")
        } else {
          setErrMess("")
        }
    }
    setPhone(event.target.value);
  }

  const handleAddEmail = () => {
    setFirstAdd(false)
    if (mail) {
      const validString = new RegExp(regexSring);
      if (!validString.test(mail)) {
        setErrMess("Email không đúng !")
      } else {
        if (!dataSubmitEmail.includes(mail)) {
          dataSubmitEmail.push(mail)
          setDataSubmitEmail(dataSubmitEmail)
          setFirstAdd(true)
        }
        setMail("")
      }
    }
  }
  const handleAddPhone = () => {
    setFirstAdd(false)
    if (phone) {
      const validString = new RegExp(regexPhoneString);
      if (!validString.test(phone)) {
        setErrMess("Số điện thoại không đúng !")
      } else {
        if (!dataSubmitPhone.includes(phone)) {
          dataSubmitPhone.push(phone)
          setDataSubmitPhone(dataSubmitPhone)
          setFirstAdd(true)
        }
        setPhone("")
      }
    }
  }

  const removeItemEmail = (index: any) => {
    setDataSubmitEmail(dataSubmitEmail.filter((item: any, i: any) => {
      return i !== index
    }))
  }
  const removeItemPhone = (index: any) => {
    setDataSubmitPhone(dataSubmitPhone.filter((item: any, i: any) => {
      return i !== index
    }))
  }

  const hostName = window?.location?.origin;
  const eventUrl = localStorage.getItem(ENTERPRISE_URL) || "";
  let link = hostName + "/" + eventUrl + "/" + id;

  const onSubmitEmail = async () => {
    try {
      const sendMail = await baseApi.sendEmailInvites({ listMail: dataSubmitEmail, link }, id)
      onCloseInviteModal()
      onSuccess()
    } catch (error) {
      let temp:any = error
      dispatch(notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Thất bại",
        description: temp?.response?.data?.message || "Gửi thất bại ! ",
      }))
    }
  }

  const onSubmitPhone = () => {
    console.log(dataSubmitPhone);
    onCloseInviteModal()
    onSuccess()
  }

  const handleGetMail = async (data: any) => {
    if (data) {
      if (Array.isArray(data) && data?.length > 0) {
        const getMail: any = data.concat(dataSubmitEmail)
        const uniqueMail = getMail.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
        setDataSubmitEmail(uniqueMail)
      }
    }
    setUploadListUser(false);
  }

  return (
    <>
      <Modal
        open={openInviteModal}
        onClose={onCloseInviteModal}
      >
        <Paper
          classes={{
            root: styles.InvitePaper,
          }}>
          <h2 className={styles.title}>
            Gửi sự kiện qua email
            <IconButton className={styles.closeBtn} onClick={onCloseInviteModal}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </h2>

          <div className={styles.tabHeader}>
            {/* <Tabs
              value={tab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              style={{ marginBottom: "14px" }}
              classes={{
                indicator: styles.tabIndicator
              }}
            >
              <Tab label="Email" className={styles.tab} {...a11yProps(0)} />
            </Tabs> */}
          </div>
          <div className={styles.content}>
              <form className={styles.form} onSubmit={handleSubmitEmail(onSubmitEmail)}>
                <Grid
                  container
                  direction='column'
                  alignItems='center'
                >
                  <div className={styles.addField}
                  >
                    <TextField
                      name='email'
                      fullWidth
                      value={mail}
                      placeholder='Nhập email người tham gia'
                      onChange={handleChangeInputEmail}
                      inputRef={registerEmail}
                      error={Boolean(errMess)}
                      helperText={errMess}
                      InputProps={{
                        classes: {
                          input: styles.addFieldInput,
                        },
                        endAdornment: <InputAdornment position="end">
                          {mail ? <Button onClick={handleAddEmail} className={styles.addBtn}> + Thêm</Button> : ""}
                        </InputAdornment>,
                      }}
                    />
                    <Button className={styles.uploadBtn} onClick={() => setUploadListUser(true)} startIcon={<img src={UploadIcon} alt="" />}>Tải tệp lên</Button>
                  </div>
                  <Grid
                    item
                    container
                    xs={12}
                    className={styles.dataContainer}
                  >
                    {dataSubmitEmail?.map((element: any, index: any) => {
                      return (
                        <div className={styles.emailLine} key={index}>
                          <Avatar className={styles.avatar}>{element?.charAt(0) || ""}</Avatar>
                          <div className={styles.emailLine_Text}>{element}</div>
                          <IconButton className={styles.emailLine_Btn} onClick={() => { removeItemEmail(index) }}>
                            <CloseIcon />
                          </IconButton>
                        </div>
                      );
                    })}
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent="space-between"
                    className={styles.groupBtn}
                  >
                    <Grid item >
                      <span className={styles.total}>{dataSubmitEmail.length || 0} Email</span>
                    </Grid>
                    <Grid item>
                      <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        className={styles.submitBtn}
                        disabled={!(dataSubmitEmail?.length)}
                      >
                        Gửi
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            {/* <TabPanel value={tab} index={1}>
              <form className={styles.form} onSubmit={handleSubmitPhone(onSubmitPhone)}>
                <Grid
                  container
                  direction='column'
                  alignItems='center'
                >
                  <div className={styles.addField}
                  >
                    <TextField
                      name='phone'
                      fullWidth
                      value={phone}
                      placeholder='Thêm số điện thoại người tham gia'
                      onChange={handleChangeInputPhone}
                      inputRef={registerPhone}
                      error={Boolean(errMess)}
                      helperText={errMess}
                      InputProps={{
                        classes: {
                          input: styles.addFieldInput,
                        },
                        endAdornment: <InputAdornment position="end">
                          {phone ? <Button onClick={handleAddPhone} className={styles.addBtn}> + Thêm</Button> : ""}
                        </InputAdornment>,
                      }}
                    />
                    <Button className={styles.uploadBtn} onClick={() => setUploadListUser(true)} startIcon={<img src={UploadIcon} alt="" />}>Tải tệp lên</Button>
                  </div>
                  <Grid
                    item
                    container
                    xs={12}
                    className={styles.dataContainer}
                  >
                    {dataSubmitPhone?.map((element: any, index: any) => {
                      return (
                        <div className={styles.emailLine} key={index}>
                          <Avatar className={styles.avatar}>N</Avatar>
                          <div className={styles.emailLine_Text}>{element}</div>
                          <IconButton className={styles.emailLine_Btn} onClick={() => { removeItemPhone(index) }}>
                            <CloseIcon />
                          </IconButton>
                        </div>
                      );
                    })}
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent="space-between"
                    className={styles.groupBtn}
                  >
                    <Grid item >
                      <span className={styles.total}>{dataSubmitPhone.length || 0} Số điện thoại</span>
                    </Grid>
                    <Grid item>
                      <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        className={styles.submitBtn}
                        disabled={!(dataSubmitPhone?.length)}
                      >
                        Gửi
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </TabPanel> */}
          </div>
        </Paper>
      </Modal>
      <UploadListUser
        eventID={id}
        onCloseUploadListUser={() => setUploadListUser(false)}
        openUploadListUser={uploadListUser}
        onSuccess={(data: any) => { handleGetMail(data) }}
      />
    </>
  )
}

export default InviteModal