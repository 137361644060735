import {Grid, Container } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import "./style.scss";
import baseApi from "@Apis/Event/api";
import { useLocation } from "react-router";
import EventItemShare from "@Components/Event/EventItemShare/EventItemShare";
import getAllUrlParams from "@Helpers/getAllUrlParams";
import Paginantion from "@Common/Pagination/Paginantion";
import { useHistory } from "react-router";
import logo from "@Assets/icons/logo/logo.png"
import axios from "axios";


const ShareAllEvent = () => {
    const history = useHistory();
    const [eventData, setEventData]:any = useState([])
    const [refEventData,setRefEventData] = useState(false)
    const location = useLocation()
    const RefreshEventData = ()=>setRefEventData(!refEventData)
    const [totalPage, setTotalPage] = useState(0);
    const queryParam = getAllUrlParams(window.location.href);
    const limit = queryParam.limit ? parseInt(queryParam.limit) : 12;
    const [page, setPage] = useState(
      queryParam.page ? parseInt(queryParam.page) : 1
    );
    const [business,setBusiness]:any = useState("")
  
    const fetchData = async()=>{
        try {
          const urlBusiness = window.location.pathname.split("/")[1]
          const {data:{result}}:any = await axios.get(`${window.location.origin}/gateway/business/${urlBusiness}/name`)
          if(result?.id){
            setBusiness(result?.id)
            const data:any = await baseApi.getListEventEmbed({business:result?.id, limit, page})
            setEventData(data?.result);
            setTotalPage(data?.meta?.total);
          }
        } catch (error) {
          console.log("error fetchData event detail",error);
        }
    }
    
    useEffect(()=>{
      fetchData()
    },[refEventData])

    const onChangePage = (page: any) => {
      setPage(page);
      history.push(`?business=${business}&limit=${limit}&page=${page}`);
      RefreshEventData();
    };
      

  return (
    <React.Fragment>
      <div className="share-event">
        <Container 
          style={{
            height: "100%",
            padding: "0",
          }}
        >
           <Grid container>
           <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              style={{margin:"30px auto"}}
              className="header-share"
            >
              {/* <img
                src={logo}
                alt={logo}
                onClick={() => history.push("/")}
                style={{ cursor: "pointer" }}
              />
              <p className="title-header">Nine Event</p> */}
            </Grid>
              <Grid item lg={12} className="list-events">
                <Grid container spacing={2}>
                  {eventData?.map((item: any, index: any) => (
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={4}
                      sm={6}
                      xs={12}
                      key={item.id}
                    >
                       <EventItemShare data={item} RefreshEventData={RefreshEventData}/>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              {totalPage > limit && (
                <Paginantion
                  count={Math.ceil(totalPage / limit)}
                  page={page}
                  onChangePage={onChangePage}
                />
              )}
            </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ShareAllEvent;