import React from "react";
// import PropTypes from "prop-types";
import "./style.scss";
import { Container, Grid } from "@material-ui/core";
import EventItem from "@Components/Event/EventItem/EventItem";

interface IEventPanel {
  value: number;
  index: number;
  RefreshEventData: any;
  deleteForever?: any;
  eventData?: any | any[];
}

const EventTabPanel: React.FC<IEventPanel> = (props:any) => {
  const { eventData, value, index, RefreshEventData, deleteForever }:any = props;

  return (
    <React.Fragment>
      {value === index && (
        <Container className='event-body'>
          <Grid container>
            <Grid item lg={12} className="list-events">
              <Grid container spacing={2}>
                {(Array.isArray(eventData) && eventData?.length>0) && eventData?.map((item: any, index: any) => (
                  <Grid item lg={4} md={4} sm={6} xs={12} key={item.id}>
                    <EventItem data={item} RefreshEventData={RefreshEventData} deleteForever={deleteForever}/>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
    </React.Fragment>
  );
};

export default EventTabPanel;
