export default {
  CHANGE_ACCOUNT_START: "CHANGE_ACCOUNT_START",
  CHANGE_ACCOUNT_SUCCESS: "CHANGE_ACCOUNT_SUCCESS",
  CHANGE_ACCOUNT_ERROR: "CHANGE_ACCOUNT_ERROR",
  GET_ACCOUNT_START: "GET_ACCOUNT_START",
  GET_ACCOUNT_SUCCESS: "GET_ACCOUNT_SUCCESS",
  GET_ACCOUNT_ERROR: "GET_ACCOUNT_ERROR",
  ADD_ACCOUNT_START: "ADD_ACCOUNT_START",
  ADD_ACCOUNT_SUCCESS: "ADD_ACCOUNT_SUCCESS",
  ADD_ACCOUNT_ERROR: "ADD_ACCOUNT_ERROR",
};
