import React, { useEffect, useState } from "react";
//import lib
import {
  Grid,
  IconButton,
  TextField,
  Button,
  Popover,
  Container,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { SketchPicker } from "react-color";
import { ENTERPRISE_ID, ENTERPRISE_URL } from "@Constants/localStorage";
import baseApi from "@Apis/Event/api";
import moment from "moment";
import "moment/locale/vi";
//import icon
import ExitIcon from "@Assets/icons/event/exitIcon.svg";
import InlineImage from "@Assets//images/Event/inlineImage.svg";
import WidgetImage from "@Assets//images/Event/widgetImage.svg";
import TextImage from "@Assets//images/Event/textImage.svg";
import InlineImage123px from "@Assets//images/Event/inlineImage123px.svg";
import WidgetImage123px from "@Assets//images/Event/widgetImage123px.svg";
import TextImage123px from "@Assets//images/Event/textImage123px.svg";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import CheckIcon from "@material-ui/icons/CheckCircle";
import processingIcon from "@Assets/icons/event/processing.svg";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import "./style.scss";

const presetColors = [
  "rgb(0,0,0)",
  "rgb(0,0,255)",
  "rgb(0,255,0)",
  "rgb(255,0,0)",
  "rgb(255,255,255)",
];

const EmbedEvent = (props: any) => {
  const [url, setUrl] = useState("");
  const id = props.match.params.id;
  const [eventDetail, setEventDetail]: any = useState({});
  const history = useHistory();
  const [active, setActive] = useState("");
  const [isContinue, setIsContinue] = useState(false);
  const typingTimeoutRef: any = React.useRef(null);
  const typingCopyRef: any = React.useRef(null);
  const [color, setColor] = useState("#0069ff");
  const [colorText, setColorText] = useState("#ffffff");
  const [openChooseColor, setOpenChooseColor] = useState(false);
  const [openChooseColor2, setOpenChooseColor2] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [linkText, setLinkText] = useState("Nine Event");
  const [buttonText, setButtonText] = useState("Nine Event");
  const [copy, setCopy] = useState(false);
  const open = Boolean(anchorEl);
  const [anchorE2, setAnchorE2] = useState(null);
  const open2 = Boolean(anchorE2);
  const [counter, setCounter] = useState(0);
  const linkOrigin = window.location.origin || "https://event-app.9code.vn"
  const linkCss = `${linkOrigin}/external/widget.css`;
  const linkJs = `${linkOrigin}/external/widget.js`;

  const [checkAll, setCheckAll] = useState(false);

  const handleClose = () => {
    setActive("");
    setIsContinue(false);
    setColor("#0069ff");
    setColorText("#ffffff");
    setLinkText("Nine Event");
    setButtonText("Nine Event");
    setCounter(0);
  };

  const onClickInline = () => {
    setActive("Embed inline");
    setIsContinue(true);
  };
  const onClickWidget = () => {
    setActive("Widget popup");
    setIsContinue(true);
  };
  const onClickText = () => {
    setActive("Embed text");
    setIsContinue(true);
  };

  const enterPriseID = localStorage.getItem(ENTERPRISE_ID);
  const getLink = () => {
    const hostName = window?.location?.origin;
    const eventUrl = localStorage.getItem(ENTERPRISE_URL) || "";
    let link = hostName + "/" + eventUrl + "/" + id;
    if (id === "all-event") link = hostName + "/" + eventUrl + `/all-event`;
    setUrl(link);
  };

  const fetchData = async () => {
    try {
      if (id === "all-event") {
        setEventDetail("");
        // const { result }: any = await baseApi.getListEventEmbed();
        // console.log("result",result)
      } else {
        const { result }: any = await baseApi.getDetailEvent(id);
        setEventDetail(result);
      }
    } catch (error) {
      console.log("error fetchData event detail", error);
    }
  };

  useEffect(() => {
    if (id === "all-event") {
      setCheckAll(true);
    }
  }, []);

  const checkDay =
    moment(eventDetail.startDateTime)
      .format("llll")
      .split(",")[0]
      .toLowerCase() === "cn" || false;
  const getDay = moment(eventDetail.startDateTime)
    .format("llll")
    ?.split(",")[0]
    ?.substring(1, 2);

  useEffect(() => {
    if (id) {
      fetchData();
    }
    getLink();
  }, []);

  const onChangeButtonText = (e: any) => {
    setButtonText(e.target.value);
    setLinkText(e.target.value);
    setCounter(e.target.value.length);
  };

  const onChangeLinkText = (e: any) => {
    setLinkText(e.target.value);
    setCounter(e.target.value.length);
  };

  const getColor = (e: any, option: any) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(async () => {
      if (option === "text") setColorText(e.hex);
      else setColor(e.hex);
    }, 300);
  };

  const handleOpenAnchor = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpenChooseColor(!openChooseColor);
  };
  const handleOpenAnchor2 = (event: any) => {
    setAnchorE2(event.currentTarget);
    setOpenChooseColor2(!openChooseColor2);
  };

  const handleCloseAnchor = () => {
    setAnchorEl(null);
    setOpenChooseColor(!openChooseColor);
  };

  const handleCloseAnchor2 = () => {
    setAnchorE2(null);
    setOpenChooseColor2(!openChooseColor2);
  };

  const handleCopy = () => {
    const copyText: any = document.getElementById("share-add-to-web");
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);
    setCopy(true);
    if (typingCopyRef.current) {
      clearTimeout(typingCopyRef.current);
    }
    typingCopyRef.current = setTimeout(async () => {
      setCopy(false);
    }, 1000);
  };

  useEffect(() => {
    setIsContinue(false);
  }, []);

  return (
    <div className="embed-event-wrapper">
      <Grid container direction="row" className="embed-header">
        <Grid item xs={2}>
          <div className="exit">
            <IconButton
            className="exit-btn"
            disableFocusRipple
            disableRipple
            onClick={() => history.push(`/${localStorage.getItem(ENTERPRISE_URL)}/event`)}>
              <img src={ExitIcon} alt={ExitIcon} />
              <p>Thoát</p>
            </IconButton>
          </div>
        </Grid>
      </Grid>

      <Grid item xs={12} className={`${active === "Embed inline" || active === "Widget popup" || active === "Embed text" ? "background-white" : ""}`}>
        <form className="box-embed ">
          <div className={`title ${active === "Embed inline" || active === "Widget popup" || active === "Embed text" ? "hidden" : ""}`}>Embed Code</div>
          <div className={`sub-title ${active === "Embed inline" || active === "Widget popup" || active === "Embed text" ? "hidden" : ""}`}>
            {id === "all-event" ?"Thêm tất cả sự kiện Nine Event trên Website của bạn:": "Thêm sự kiện Nine Event trên Website của bạn:"}
          </div>

          {isContinue ? (
            <>
              {!checkAll ? (
                <div className="embed-wrap">
                  <section
                    className="embed-left embed-content"
                    style={{ paddingBottom: "16px" }}
                  >
                    <div className="container-title">
                      <div className="time-embed">
                        {
                          eventDetail?.endDateTime ? (
                            moment(eventDetail.startDateTime).format("DD/MM/YYYY") ===
                              moment(eventDetail?.endDateTime).format("DD/MM/YYYY") ? (
                              <>
                                {checkDay ? "Chủ nhật" : `Thứ ${getDay}`}
                                &nbsp;,ngày&nbsp;
                                {moment(eventDetail.startDateTime).format("DD/MM/YYYY") +
                                  ", " +
                                  moment(eventDetail.startDateTime)
                                    .format("HH:mm")
                                    .replace(":", "h") +
                                  " - " +
                                  moment(eventDetail?.endDateTime)
                                    .format("HH:mm")
                                    .replace(":", "h")}
                              </>
                            ) : (
                              <>
                                {
                                  moment(eventDetail.startDateTime)
                                    .format("HH:mm")
                                    .replace(":", "h") +
                                  " - " +
                                  moment(eventDetail.startDateTime).format("DD/MM/YYYY")
                                }
                                {
                                  " - " +
                                  moment(eventDetail?.endDateTime)
                                    .format("HH:mm")
                                    .replace(":", "h") +
                                  " - " +
                                  moment(eventDetail?.endDateTime).format("DD/MM/YYYY")
                                }
                              </>
                            )
                          ) : (
                            <>
                              {checkDay ? "Chủ nhật" : `Thứ ${getDay}`}
                              &nbsp;,ngày&nbsp;
                              {eventDetail.startDateTime
                                ? moment(eventDetail.startDateTime).format("DD/MM/YYYY") +
                                ", " +
                                moment(eventDetail.startDateTime)
                                  .format("HH:mm")
                                  .replace(":", "h")
                                : ""}
                            </>
                          )
                        }
                      </div>
                      <div className="title-event">
                        {eventDetail.eventTitle}
                      </div>
                    </div>
                  </section>
                  <section className="embed-right embed-content">
                    <div className="container-title">
                      <div className="img-publish">
                        <img src={processingIcon} alt={processingIcon} />
                        Công bố
                      </div>
                      <div className="online">Online: &nbsp;
                      {eventDetail.onlineItems === "team" ? "Microsoft Team": ""}
                      {eventDetail.onlineItems === "google" ? "Google Meet": ""}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
              <div className="embed-wrap">
                <section
                  className={`embed-left embed-content ${active === "Embed inline" ? "inline-embed-css" : ""
                    }`}
                >
                  <h3 className="header">{active}</h3>
                  <p className="sub-header">
                    Copy và chèn code này vào HTML trang web của bạn, vị trí mà
                    bạn muốn Nine Event xuất hiện trên website
                  </p>
                  <TextField
                    id="share-add-to-web"
                    type="text"
                    multiline
                    rows={8}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    // variant="outlined"
                    value={
                      active === "Embed text"
                        ? `<!-- Embed text -->
                          <link href="${linkCss}" rel="stylesheet">
                          <script src="${linkJs}" type="text/javascript" async></script>
                          <a href="" onclick="textPopup('${url}');return false;">${linkText}</a>
                          <!-- Embed text -->`
                        : active === "Widget popup"
                          ? `<!-- Widget popup -->
                              <link href="${linkCss}" rel="stylesheet">
                              <script src="${linkJs}" type="text/javascript" async></script>
                              <script type="text/javascript">window.onload = function() { widgetPopup({ url: '${url}', text: '${buttonText}', color: '${color}', textColor: '${colorText}', branding: true });}</script>
                              <!-- Widget popup -->`
                          : `<!-- Event inline widget begin -->
                              <div class="event-inline-widget" data-url="${url}" style="min-width:320px;height:1000px;"></div>
                              <script src="${linkJs}" type="text/javascript" async></script>
                              <!-- Event inline widget end -->`
                    }
                    fullWidth
                    className="text-embed-code"
                  />
                  <div className="btn-action">
                    <Button
                      variant="contained"
                      color="default"
                      onClick={() => handleClose()}
                      className="btn-cancel"
                      startIcon={<ArrowBackIosIcon />}
                    >
                      Quay lại
                    </Button>
                    {copy ? (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: "1rem" }}
                        className="btn-copy"
                        startIcon={<CheckIcon />}
                      >
                        Copied
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: "1rem" }}
                        className="btn-copy"
                        onClick={handleCopy}
                      >
                        Copy Code
                      </Button>
                    )}
                  </div>
                </section>
                <section
                  className={`embed-right embed-content ${active === "Embed inline" ? "embed-right-hidden" : ""
                    }`}
                >
                  {active === "Widget popup" && (
                    <div className="group-right-content">
                      <h3 className="right-content">Thiết lập hiển thị</h3>
                      <p className="describe">
                        Tuỳ chỉnh hiển thị phù hợp với website
                      </p>
                      <div className="text-input">
                        {/* <div className="title-input">Button Text</div> */}
                        <TextField
                          type="text"
                          onChange={onChangeButtonText}
                          value={buttonText}
                          fullWidth
                          size="small"
                          placeholder="Nhập nội dung"
                          inputProps={{ maxLength: 30 }}
                        />
                        <div className="count-number">{counter}/30</div>
                      </div>
                      <div className="group-choose-color">
                        {/* <div>Button Background Color</div> */}
                        <Button
                          onClick={handleOpenAnchor}
                          endIcon={
                            openChooseColor ? (
                              <ArrowDropUpIcon />
                            ) : (
                              <ArrowDropDownIcon />
                            )
                          }
                        >
                          <span
                            className="show-color"
                            style={{ backgroundColor: color }}
                          ></span>
                          <div className="text">Màu nền</div>
                        </Button>
                      </div>
                      <div className="group-choose-color">
                        {/* <div>Button Text Color</div> */}
                        <Button
                          onClick={handleOpenAnchor2}
                          endIcon={
                            openChooseColor2 ? (
                              <ArrowDropUpIcon />
                            ) : (
                              <ArrowDropDownIcon />
                            )
                          }
                        >
                          <span
                            className="show-color"
                            style={{ backgroundColor: colorText }}
                          ></span>
                          <div className="text">Màu chữ</div>
                        </Button>
                      </div>
                      <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleCloseAnchor}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <div className="choose-color">
                          <SketchPicker
                            presetColors={presetColors}
                            color={color}
                            onChangeComplete={(e) => getColor(e, "background")}
                          />
                        </div>
                      </Popover>
                      <Popover
                        open={open2}
                        anchorEl={anchorE2}
                        onClose={handleCloseAnchor2}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <div className="choose-color">
                          <SketchPicker
                            presetColors={presetColors}
                            color={colorText}
                            onChangeComplete={(e) => getColor(e, "text")}
                          />
                        </div>
                      </Popover>
                    </div>
                  )}
                  {active === "Embed text" && (
                    <div className="group-right-content">
                      <h3 className="right-content">Thiết lập hiển thị</h3>
                      <p className="describe">
                        Tuỳ chỉnh hiển thị phù hợp với website
                      </p>
                      <div className="text-input">
                        {/* <div className="title-input">Link Text</div> */}
                        <TextField
                          type="text"
                          onChange={onChangeLinkText}
                          value={linkText}
                          fullWidth
                          placeholder="Nhập nội dung"
                          inputProps={{ maxLength: 30 }}
                        />
                        <div className="count-number">{counter}/30</div>
                      </div>
                    </div>
                  )}
                </section>
              </div>

              {/* Inline Widget Text mobile */}
              <div className="embed-wrap-mobile">
                <section
                  className={`embed-content ${checkAll ? "hidden" : ""}`}
                  style={{ paddingBottom: "16px" }}
                >
                  <div className="container-title">
                    <div className="time-embed">
                    {
                          eventDetail?.endDateTime ? (
                            moment(eventDetail.startDateTime).format("DD/MM/YYYY") ===
                              moment(eventDetail?.endDateTime).format("DD/MM/YYYY") ? (
                              <>
                                {checkDay ? "Chủ nhật" : `Thứ ${getDay}`}
                                &nbsp;,ngày&nbsp;
                                {moment(eventDetail.startDateTime).format("DD/MM/YYYY") +
                                  ", " +
                                  moment(eventDetail.startDateTime)
                                    .format("HH:mm")
                                    .replace(":", "h") +
                                  " - " +
                                  moment(eventDetail?.endDateTime)
                                    .format("HH:mm")
                                    .replace(":", "h")}
                              </>
                            ) : (
                              <>
                                {
                                  moment(eventDetail.startDateTime)
                                    .format("HH:mm")
                                    .replace(":", "h") +
                                  " - " +
                                  moment(eventDetail.startDateTime).format("DD/MM/YYYY")
                                }
                                {
                                  " - " +
                                  moment(eventDetail?.endDateTime)
                                    .format("HH:mm")
                                    .replace(":", "h") +
                                  " - " +
                                  moment(eventDetail?.endDateTime).format("DD/MM/YYYY")
                                }
                              </>
                            )
                          ) : (
                            <>
                              {checkDay ? "Chủ nhật" : `Thứ ${getDay}`}
                              &nbsp;,ngày&nbsp;
                              {eventDetail.startDateTime
                                ? moment(eventDetail.startDateTime).format("DD/MM/YYYY") +
                                ", " +
                                moment(eventDetail.startDateTime)
                                  .format("HH:mm")
                                  .replace(":", "h")
                                : ""}
                            </>
                          )
                        }
                    </div>
                    <div className="title-event">
                      {eventDetail.eventTitle}
                    </div>
                  </div>
                </section>
                <section
                  className={`embed-content ${active === "Embed inline" ? "inline-embed-css" : ""
                    }`}
                >
                  <h3 className="header">{active}</h3>
                  <p className="sub-header">
                    Copy và chèn code này vào HTML trang web của bạn, vị trí mà
                    bạn muốn Nine Event xuất hiện trên website
                  </p>
                  <TextField
                    id="share-add-to-web"
                    type="text"
                    multiline
                    rows={8}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    // variant="outlined"
                    value={
                      active === "Embed text"
                        ? `<!-- Embed text -->
                          <link href="${linkCss}" rel="stylesheet">
                          <script src="${linkJs}" type="text/javascript" async></script>
                          <a href="" onclick="textPopup('${url}');return false;">${linkText}</a>
                          <!-- Embed text -->`
                        : active === "Widget popup"
                          ? `<!-- Widget popup -->
                              <link href="${linkCss}" rel="stylesheet">
                              <script src="${linkJs}" type="text/javascript" async></script>
                              <script type="text/javascript">window.onload = function() { widgetPopup({ url: '${url}', text: '${buttonText}', color: '${color}', textColor: '${colorText}', branding: true });}</script>
                              <!-- Widget popup -->`
                          : `<!-- Event inline widget begin -->
                              <div class="event-inline-widget" data-url="${url}" style="min-width:320px;height:1000px;"></div>
                              <script src="${linkJs}" type="text/javascript" async></script>
                              <!-- Event inline widget end -->`
                    }
                    fullWidth
                    className="text-embed-code"
                  />

                </section>
                <section
                  className={`embed-content ${active === "Embed inline" ? "embed-right-hidden" : ""
                    }`}
                >
                  {active === "Widget popup" && (
                    <div className="group-right-content">
                      <h3 className="right-content">Thiết lập hiển thị</h3>
                      <p className="describe">
                        Tuỳ chỉnh hiển thị phù hợp với website
                      </p>
                      <div className="text-input">
                        {/* <div className="title-input">Button Text</div> */}
                        <TextField
                          type="text"
                          onChange={onChangeButtonText}
                          value={buttonText}
                          fullWidth
                          size="small"
                          placeholder="Nhập nội dung"
                          inputProps={{ maxLength: 30 }}
                        />
                        <div className="count-number">{counter}/30</div>
                      </div>
                      <div className="group-choose-color">
                        {/* <div>Button Background Color</div> */}
                        <Button
                          onClick={handleOpenAnchor}
                          endIcon={
                            openChooseColor ? (
                              <ArrowDropUpIcon />
                            ) : (
                              <ArrowDropDownIcon />
                            )
                          }
                        >
                          <span
                            className="show-color"
                            style={{ backgroundColor: color }}
                          ></span>
                          <div className="text">Màu nền</div>
                        </Button>
                      </div>
                      <div className="group-choose-color">
                        {/* <div>Button Text Color</div> */}
                        <Button
                          onClick={handleOpenAnchor2}
                          endIcon={
                            openChooseColor2 ? (
                              <ArrowDropUpIcon />
                            ) : (
                              <ArrowDropDownIcon />
                            )
                          }
                        >
                          <span
                            className="show-color"
                            style={{ backgroundColor: colorText }}
                          ></span>
                          <div className="text">Màu chữ</div>
                        </Button>
                      </div>
                      <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleCloseAnchor}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <div className="choose-color">
                          <SketchPicker
                            presetColors={presetColors}
                            color={color}
                            onChangeComplete={(e) => getColor(e, "background")}
                          />
                        </div>
                      </Popover>
                      <Popover
                        open={open2}
                        anchorEl={anchorE2}
                        onClose={handleCloseAnchor2}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <div className="choose-color">
                          <SketchPicker
                            presetColors={presetColors}
                            color={colorText}
                            onChangeComplete={(e) => getColor(e, "text")}
                          />
                        </div>
                      </Popover>
                    </div>
                  )}
                  {active === "Embed text" && (
                    <div className="group-right-content">
                      <h3 className="right-content">Thiết lập hiển thị</h3>
                      <p className="describe">
                        Tuỳ chỉnh hiển thị phù hợp với website
                      </p>
                      <div className="text-input">
                        {/* <div className="title-input">Link Text</div> */}
                        <TextField
                          type="text"
                          onChange={onChangeLinkText}
                          value={linkText}
                          fullWidth
                          placeholder="Nhập nội dung"
                          inputProps={{ maxLength: 30 }}
                        />
                        <div className="count-number">{counter}/30</div>
                      </div>
                    </div>
                  )}
                </section>
                <div className="btn-action">
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => handleClose()}
                    className="btn-cancel"
                    startIcon={<ArrowBackIosIcon />}
                  >
                    Quay lại
                  </Button>
                  {copy ? (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: "1rem" }}
                      className="btn-copy"
                      startIcon={<CheckIcon />}
                    >
                      Copied
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: "1rem" }}
                      className="btn-copy"
                      onClick={handleCopy}
                    >
                      Copy Code
                    </Button>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <Container component="section" maxWidth="lg">
                <Grid
                  container
                  justifyContent="center"
                  spacing={3}
                  className="container-embed"
                >
                  <Grid item xs={9} sm={5} md={3}>
                    <div className="btn-wrap" onClick={onClickInline}>
                      <img src={InlineImage} alt={InlineImage} />
                      <div className="content">
                        <div className="header-content">Embed inline</div>
                        <p>Chèn các thẻ sự kiện</p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={9} sm={5} md={3}>
                    <div className="btn-wrap" onClick={onClickWidget}>
                      <img src={WidgetImage} alt={WidgetImage} />
                      <div className="content">
                        <div className="header-content">Widget popup</div>
                        <p>Chèn nút bấm</p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={9} sm={5} md={3}>
                    <div className="btn-wrap" onClick={onClickText}>
                      <img src={TextImage} alt={TextImage} />
                      <div className="content">
                        <div className="header-content">Embed text</div>
                        <p>Chèn link</p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                {/* embed-mobile */}
                <Grid
                  container
                  justifyContent="center"
                  className="container-embed-mobile"
                >
                  <Grid item style={{ width: "100%", marginBottom: "24px" }}>
                    <div className="btn-wrap" onClick={onClickInline}>
                      <img src={InlineImage123px} alt={InlineImage123px} />
                      <div className="content">
                        <div className="header-content">Embed inline</div>
                        <p>Chèn các thẻ sự kiện</p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item style={{ width: "100%", marginBottom: "24px" }}>
                    <div className="btn-wrap" onClick={onClickWidget}>
                      <img src={WidgetImage123px} alt={WidgetImage123px} />
                      <div className="content">
                        <div className="header-content">Widget popup</div>
                        <p>Chèn nút bấm</p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item style={{ width: "100%" }}>
                    <div className="btn-wrap" onClick={onClickText}>
                      <img src={TextImage123px} alt={TextImage123px} />
                      <div className="content">
                        <div className="header-content">Embed text</div>
                        <p>Chèn link</p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </>
          )}
        </form>
      </Grid>
    </div >
  );
};

export default EmbedEvent;
