import {
  Button,
  Grid,
  TextField,
  Modal,
  InputAdornment
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "./style.scss";
import { ENTERPRISE_URL } from "@Constants/localStorage"
import { useDispatch } from "react-redux";
import * as action from "@Actions/Event/action"
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import DownloadIcon from "@Assets/icons/event/DownloadIcon.svg";
import DownloadIconBlue from "@Assets/icons/event/DownloadIconBlue.svg";
import UploadIcon from "@Assets/icons/event/UploadIcon.svg";
import UploadIconBlue from "@Assets/icons/event/UploadIconBlue.svg";
import qrIcon from "@Assets/icons/event/qrIcon.svg";
import SearchIcon from "@Assets/icons/event/Search.svg";
import QrReader from 'react-qr-reader'
import moment from "moment";
import Paginantion from "@Common/Pagination/Paginantion";
import getAllUrlParams from "@Helpers/getAllUrlParams";
import baseApi from "@Apis/Event/api";
import UserIcon from "@Assets/icons/event/user.svg";
import PhoneIcon from "@Assets/icons/event/Phone.svg";
import GmailIcon from "@Assets/icons/event/Gmail.svg";
import { exportToXlsx } from "@Helpers/exportFile/exportXlsx";
import qs from "qs"
import DialogDataRegister from "./DialogDataRegister";
import UploadListUser from "@Components/Event/UploadListUser";

const ListUser = (props: any) => {
  const dispatch = useDispatch();
  const [userList, setUserList]: any = useState([]) //props.listUser.listdata || []
  const [userId, setUserId]: any = useState("")
  const [openCheckIn, setOpenCheckIn]: any = useState(false)
  const [openScan, setOpenScan]: any = useState(false)
  const [verifyScan, setVerifyScan]: any = useState(null)
  const [showError, setShowError]: any = useState(false)
  const [dataInfor, setDataInfor]: any = useState({})
  const [dataUser, setDataUser]: any = useState({})
  const history = useHistory();
  const typingTimeoutRef: any = React.useRef(null)
  const queryParam = getAllUrlParams(window.location.href);
  const search = queryParam?.search;
  const limit = queryParam.limit ? parseInt(queryParam.limit) : 10;
  const [page, setPage] = useState(
    queryParam.page ? parseInt(queryParam.page) : 1
  );
  const [refData, setRefData]: any = useState(false)
  const [totalPage, setTotalPage] = useState(0)
  const [openDataRegister, setOpenDataRegister]: any = useState(false)
  const [data, setData]: any = useState(null)
  const [uploadListUser, setUploadListUser]: any = useState(false)

  const handleOpenDialogData = (data:any)=>{
    setOpenDataRegister(true)
    setData(data)
  }

  const handleCloseDialogData = ()=>{
    setOpenDataRegister(false)
  }

  const handleOpenAddPopover = (event: any, id: any, data: any) => {
    setOpenCheckIn(true)
    setUserId(id)
    setDataUser(data)
  };
  const handleCloseAddPopover = () => {
    setOpenCheckIn(false)
  };

  const callbackSuccess = (data: any) => {
    setRefData(!refData)
    // props.refreshData()
    setOpenCheckIn(false)
  };
  const callbackError = () => { };

  const handleCheckIn = async () => {
    dispatch(action.updateCheckInEventAction({ checkIn: true, eventID: props.id }, userId, callbackSuccess, callbackError))
  }

  const handleCloseScan = () => {
    setOpenScan(false)
  }

  const callbackSuccessScan = (data: any) => {
    setDataInfor(data.result)
    setVerifyScan("verifed")
    setRefData(!refData)
  };

  const callbackErrorScan = () => {
    setShowError(true)
  };
  const urlParams = localStorage.getItem(ENTERPRISE_URL);
  const onChangePage = (page: any) => {
    setPage(page);
    let queryData:any = {limit, page}
    if(search && search!=="") queryData.search = search
    const queryStr = qs.stringify(queryData)
    history.push(`/${urlParams}/event/${props.id}?${queryStr}`);
    setRefData(!refData)
  };

  const handleScan = (data: any)=>{
    if(data){
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
      typingTimeoutRef.current = setTimeout(async () => {
        const getInfor:any = data.split("/").pop()
        const eventID = getInfor.split("?")[0]
        const userId = getInfor.split("=")[1]
        if(eventID===props.id){
          dispatch(action.updateCheckInEventAction({checkIn:true, eventID},userId, callbackSuccessScan, callbackErrorScan))
        }
        else{
          callbackErrorScan()
        }
      }, 200);
    }
  }

  const handleOpenScan = () => {
    setOpenScan(true)
    setVerifyScan(null)
    setShowError(false)
  }

  const handleRegister = () => {
    history.push(
      `/${localStorage.getItem(ENTERPRISE_URL)}/view-event/${props.id}`
    );
  }

  const fetchListUser = async () => {
    const { result, meta }: any = await baseApi.getListUserEvent(props.id, { limit, page, search })
    setUserList(result)
    setTotalPage(meta?.total || result.length)
  }

  useEffect(() => {
    if (props.id) fetchListUser()
  }, [refData])

  //export xlsx
  const exportXlsx = async()=>{
    const dataExport:any = props.listUser.listdata || []
    const inforUser:any = [];
    for(let i in dataExport){
      inforUser[i] = {
        name: dataExport[i].name,
        email: dataExport[i].email,
        phone: dataExport[i].phone,
        joinWith: dataExport[i]?.participationForm ? dataExport[i]?.participationForm : "Mẫu đăng ký",
        checkIn: dataExport[i].checkIn ? "Đã check in" : "Check in",
      }
    }
    const wscols = [
      { wch: Math.max(...dataExport.map((data:any) => data.name.length + 2 )) },
      { wch: Math.max(...dataExport.map((data:any) => data.email.length +2)) },
      { wch: Math.max(...dataExport.map((data:any) => data.phone.length + 2)) },
      { wch: 18 },
      { wch: 10 }
    ];

    if(dataExport.length>0) await exportToXlsx(inforUser,"Danh sách tham gia",wscols)
  }
  //export xlsx//

  //search User
  const handleSearch = (e:any)=>{
    const dataSearch = e.target.value.trim()
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(async () => {
      if(dataSearch) {
        setPage(1)
        history.push(`/${urlParams}/event/${props.id}?limit=${limit}&page=1&search=${dataSearch}`);
      }
      else{
        history.push(`/${urlParams}/event/${props.id}`);
      }
      setRefData(!refData)
    }, 300);
  }

  return (
    <div className="box-event list-user-box">
      <div className="list-user-website">
        <Grid container justifyContent="space-between" className="search-list-user">
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            {/* <img className="img-search" src={SearchIcon} alt={SearchIcon} /> */}
            <TextField
              inputProps={{
                style: {
                  fontSize: "14px",
                  fontFamily: "Roboto-Regular",
                  width: "280px"
                }
              }}
              InputLabelProps={{ style: { fontSize: "14px", fontFamily: "Roboto-Regular" } }}
              id="input-with-sx"
              //label="Nhập từ khoá tìm kiếm"
              placeholder="Nhập từ khoá tìm kiếm"
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img className="img-search" src={SearchIcon} alt={SearchIcon} />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />
          </Box>

          <div className="group-icon">
            <Button variant="outlined"
              className="down-load-list"
              onClick={() => setUploadListUser(true)}
            >
              Import
              <img src={UploadIcon} alt='' className="no-hover"/>
              <img src={UploadIconBlue} alt='' className="hover"/>
            </Button>
            <Button variant="outlined"
              className="down-load-list"
              onClick={exportXlsx}
            >
              Tải danh sách
              <img src={DownloadIcon} alt='' className="no-hover"/>
              <img src={DownloadIconBlue} alt='' className="hover"/>
            </Button>
          </div>
        </Grid>
        <TableContainer>
          <Table style={{ minWidth: 600 }} size='medium' aria-label='a dense table'>
            <TableHead>
              <TableRow>
                <TableCell className='title title-head'>Stt</TableCell>
                <TableCell className='title title-head'>Họ và tên</TableCell>
                <TableCell className='title title-head'>Email</TableCell>
                <TableCell className='title title-head'>Số điện thoại</TableCell>
                <TableCell className='title title-head'>Hình thức tham gia</TableCell>
                <TableCell className='title title-head title-status' align="right">Trạng thái</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="list-content">
              {userList?.length > 0 && userList?.map((row: any,index:any) => (
                <TableRow key={row.id} style={{cursor:"pointer"}}>
                  <TableCell onClick={()=>handleOpenDialogData(userList[index])}>
                    {
                      page>1 
                      ? (index + 1)+ page*limit
                      : index + 1
                    }
                  </TableCell>
                  <TableCell onClick={()=>handleOpenDialogData(userList[index])} component='th' scope='row'>
                    {row.name}
                  </TableCell>
                  <TableCell onClick={()=>handleOpenDialogData(userList[index])}>{row.email}</TableCell>
                  <TableCell onClick={()=>handleOpenDialogData(userList[index])}>{row.phone}</TableCell>
                  <TableCell onClick={()=>handleOpenDialogData(userList[index])}>{row?.participationForm ? row?.participationForm : "Mẫu đăng ký"} </TableCell>
                  <TableCell style={{cursor:"auto"}} className='title' align="right">
                    {
                      row.checkIn
                        ? <div className="status-check">
                          <p>Đã check in</p>
                          <span>{moment(row.updatedAt).format("HH:mm - DD/MM/yyyy")}</span>
                        </div>
                        : <Button
                          color='primary'
                          variant='contained'
                          size="small"
                          className="btn-check"
                          onClick={(e) => handleOpenAddPopover(e, row.id, row)}
                        >
                          <p>Check in</p>
                        </Button>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="list-user-mobile">
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }} className="box-search">
          {/* <img className="img-search" src={SearchIcon} alt={SearchIcon} /> */}
          <TextField
            inputProps={{
              style: {
                fontSize: "14px",
                fontFamily: "Roboto-Regular",
              }
            }}
            InputLabelProps={{ style: { fontSize: "14px", fontFamily: "Roboto-Regular" } }}
            id="input-with-sx"
            //label="Nhập từ khoá tìm kiếm"
            placeholder="Nhập từ khoá tìm kiếm"
            variant="standard"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img className="img-search" src={SearchIcon} alt={SearchIcon} />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </Box>
        <div className="list-user-mobile">
          {
            userList?.length > 0 && userList?.map((row: any, index:any) => (
              <div className="item-user" key={row.id}>
                <div className="item-user-info"  onClick={()=>handleOpenDialogData(userList[index])}>
                  <p className="user-name">{row.name}</p>
                  {/* {row?.email && <p>{row?.email}</p>} */}
                  {row?.email ?<>
                    <p className="mail">
                      <span className="ellipsis">{row?.email?.split("@")[0]}</span>
                      <span className="domain-email">@{row?.email?.split("@")[1]}</span>
                    </p>
                  </>:""}
                  {row?.phone && <p>{row?.phone}</p>}
                </div>
                <div className="item-user-btn">
                  {
                    row.checkIn
                      ? <div className="status-check">
                        <p>Đã check in</p>
                        <span>{moment(row.updatedAt).format("HH:mm - DD/MM/yyyy")}</span>
                      </div>
                      : <Button
                        color='primary'
                        variant='contained'
                        size="small"
                        className="btn-check"
                        onClick={(e) => handleOpenAddPopover(e, row.id, row)}
                      >
                        <p>Check in</p>
                      </Button>
                  }
                  {/* {
                    row.checkIn 
                    ? <Button
                        color='default'
                        variant='contained'
                        size="small"
                        className="btn-check"
                        style={{ backgroundColor: "#3a7e32", color: "#fff"}}
                      >
                        Đã tham gia
                      </Button>
                    : <Button
                        color='primary'
                        variant='contained'
                        size="small"
                        className="btn-check"
                        onClick={(e)=>handleOpenAddPopover(e,row.id,row)}
                      >
                        Check In
                      </Button>
                  } */}
                </div>
              </div>
            ))
          }
          {
            (totalPage > limit) && <div className="pagination-user-mobile">
              <Paginantion
                count={Math.ceil(totalPage / limit)}
                page={page}
                onChangePage={onChangePage}
              />
            </div>
          }
          {
            userList?.length > 0 && <div className="btn-qr">
              <Button variant="outlined"
                className="down-load-list"
                endIcon={<img src={DownloadIcon} alt='' />}
                onClick={exportXlsx}
              >
                Tải danh sách
              </Button>
              <Button
                color='primary'
                variant='contained'
                className="btn-check"
                endIcon={<img src={qrIcon} alt='' />}
                onClick={handleOpenScan}
              >
                Quét
              </Button>
            </div>
          }
        </div>
      </div>

      {
        (totalPage > limit) && <div className="pagination-user">
          <Paginantion
            count={Math.ceil(totalPage / limit)}
            page={page}
            onChangePage={onChangePage}
          />
        </div>
      }

      {/* // checkin event */}
      <Modal
        open={openCheckIn}
        onClose={handleCloseAddPopover}
        className="popover-room"
      >
        <div className="popover-wrapper">
          <div className="form-checkIn">
            <div className="title">
              <div className="title-head">Check in event</div>
              <div className="title-sub">Xác nhận đã tham gia sự kiện</div>
            </div>
            <div className="content">
              <div className="title-content">{props.listUser.evenTitle}</div>
              {dataUser?.name && <div className="title-item">
                <img src={UserIcon} alt={UserIcon} />
                {dataUser?.name}
              </div>
              }
              {dataUser?.email && <div className="title-item">
                <img src={GmailIcon} alt={GmailIcon} />
                {dataUser?.email}
              </div>
              }
              {dataUser?.phone && <div className="title-item">
                <img src={PhoneIcon} alt={PhoneIcon} />
                {dataUser?.phone}
              </div>
              }
              {/* {props.listUser.evenTitle} */}
            </div>
            {/* <p className="content">{props.listUser.evenTitle}</p> */}
            <div className="action">
              <Button
                size="small"
                onClick={handleCloseAddPopover}
                style={{ textTransform: "none", color: "#999999", width: "128px", height: "36px", background: "#F2F2F2" }}
                className="action-btn"
              >
                Đóng
              </Button>
              <Button
                color='primary'
                variant="contained"
                style={{ textTransform: "none", width: "128px", height: "36px", marginLeft: "10px" }}
                size="small"
                onClick={handleCheckIn}
                className="action-btn"
              >
                Check in
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {/* // Scan QR */}
      <Modal
        open={openScan}
        onClose={handleCloseScan}
        className="popover-room"
      >
        <div className="popover-wrapper">
          <div className={`form-checkIn ${(!verifyScan && !showError) ? "qr-scan" : ""}`}>
            {
              !verifyScan
                ? showError
                  ? <>
                    <div className="verify-scan">
                      <p className="user-name" style={{ textTransform: "none" }}>
                        Chưa đăng ký tham gia sự kiện
                      </p>
                      <p>Nhấn nút phía dưới để đăng ký</p>
                    </div>
                    <div className="btn-verified">
                      <Button
                        size="small"
                        onClick={handleCloseScan}
                        style={{ textTransform: "none", color: "#5C5C5C", marginRight: "15px" }}
                        className="action-btn"
                      >
                        Đóng
                      </Button>
                      <Button
                        color='primary'
                        variant="contained"
                        size="small"
                        onClick={handleRegister}
                        className="action-btn"
                      >
                        Đăng ký
                      </Button>
                    </div>
                  </>
                  : <div>
                    <QrReader
                      delay={300}
                      onError={(err) => console.error(err)}
                      onScan={handleScan}
                      style={{ width: '100%' }}
                      className="scanner"
                    />
                    <div className="action btn-scan">
                      <Button
                        color='primary'
                        variant="contained"
                        style={{ textTransform: "none", color: "#CEE8FF" }}
                        size="small"
                        onClick={handleCloseScan}
                        className="action-btn"
                      >
                        Đóng
                      </Button>
                    </div>
                  </div>
                : <>
                  <div className="verify-scan">
                    <p className="user-name">{dataInfor?.name}</p>
                    <p>{dataInfor?.email}</p>
                    <p>{dataInfor?.phone}</p>
                  </div>
                  <div className="btn-verified">
                    <Button
                      color='primary'
                      variant="contained"
                      size="small"
                      onClick={handleCloseScan}
                      className="action-btn"
                    >
                      Đã tham gia
                    </Button>
                  </div>
                </>
            }
          </div>
        </div>
      </Modal>

      {/* Dialog Data User Register */}
      <DialogDataRegister
      open={openDataRegister}
      handleClose={handleCloseDialogData}
      data={data}
      />
      <UploadListUser
        onCloseUploadListUser={() => setUploadListUser(false)}
        openUploadListUser={uploadListUser}
        onSuccess={(data: any) => {
          setUploadListUser(false)
          fetchListUser()
        }}
        eventID={props.id}
      />
    </div>
  )
}

export default ListUser;
