import { Api } from "@Configs/api/api";
import apiConfig from "@Configs/api/api.config";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import {
  API_ADD_USER,
  API_GET_LIST_USER,
  API_GET_LIST_ROLE,
  API_UPDATE_USER,
  API_DELETE_USER,
  API_GET_PERMISSION,
} from "./endpoint";
import { ENTERPRISE_ID } from "@Constants/localStorage";

class BaseApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config);
    this.addEventUser = this.addEventUser.bind(this);
    this.getListEventRole = this.getListEventRole.bind(this);
    this.getListEventUser = this.getListEventUser.bind(this);
    this.updateEventUser = this.updateEventUser.bind(this);
    this.deleteEventUser = this.deleteEventUser.bind(this);
    this.getEventPermission = this.getEventPermission.bind(this);
  }
  public addEventUser(data: any): Promise<any> {
    return this.post<any>(API_ADD_USER, JSON.stringify(data), {
      headers: {
        "x-business-id": localStorage.getItem(ENTERPRISE_ID),
      },
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public getListEventUser(prams: any): Promise<any> {
    return this.get<any>(API_GET_LIST_USER, {
      headers: {
        "x-business-id": localStorage.getItem(ENTERPRISE_ID),
      },
      params: prams,
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public getListEventRole(prams: any): Promise<any> {
    return this.get<any>(API_GET_LIST_ROLE, {
      headers: {},
      params: prams,
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public updateEventUser(selectedUserID: any, selectedRole: any): Promise<any> {
    return this.patch<any>(
      `${API_UPDATE_USER}/${selectedUserID}`,
      JSON.stringify({
        role: selectedRole,
      }),
      {
        headers: {
          "x-business-id": localStorage.getItem(ENTERPRISE_ID),
        },
      }
    )
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public deleteEventUser(selectedUserID: any): Promise<any> {
    return this.delete<any>(`${API_DELETE_USER}/${selectedUserID}`, {
      headers: {
        "x-business-id": localStorage.getItem(ENTERPRISE_ID),
      },
    });
  }

  // Get permision

  public getEventPermission(prams: any): Promise<any> {
    return this.get<any>(API_GET_PERMISSION, {
      headers: {
        "x-business-id": localStorage.getItem(ENTERPRISE_ID),
      },
      params: prams,
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }
}

const baseApi = new BaseApi(apiConfig);
export default baseApi;
