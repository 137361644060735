
import ActionType from "./action-types";
export const changeAccountAction = (params:any, callbackSuccess:any) => ({
  type: ActionType.CHANGE_ACCOUNT_START,
  params,
  callbackSuccess,
  payload: null,
  error: null,
});
export const changeAccountSuccessAction = (result: any) => ({
  type: ActionType.CHANGE_ACCOUNT_SUCCESS,
  payload: result,
  error: null,
});
export const changeAccountErrorAction = (error: Error) => ({
  type: ActionType.CHANGE_ACCOUNT_ERROR,
  payload: null,
  error: error,
});
export const addAccountAction = (params:any, data: any, callbackSuccess:any) => ({
  type: ActionType.ADD_ACCOUNT_START,
  params,
  data,
  callbackSuccess,
  payload: null,
  error: null,
});
export const addAccountSuccessAction = (result: any) => ({
  type: ActionType.ADD_ACCOUNT_SUCCESS,
  payload: result,
  error: null,
});
export const addAccountErrorAction = (error: Error) => ({
  type: ActionType.ADD_ACCOUNT_ERROR,
  payload: null,
  error: error,
});
export const getAccountAction = (params: any, callbackSuccess: Function) => ({
  type: ActionType.GET_ACCOUNT_START,
  params,
  callbackSuccess,
  payload: null,
  error: null,
});
export const getAccountSuccessAction = (result: any) => ({
  type: ActionType.GET_ACCOUNT_SUCCESS,
  payload: result,
  error: null,
});
export const getAccountErrorAction = (error: Error) => ({
  type: ActionType.GET_ACCOUNT_ERROR,
  payload: null,
  error: error,
});
