import { notificationOpenAction } from "@Actions/Notification/action";
import { changeProfileErrorAction, changeProfileSuccessAction } from "@Actions/Profile/action";
import actionTypes from "@Actions/Profile/action-types";
import baseApi from "@Apis/Profile/api";
import { NOTIFICATION_TYPE, TYPE_REFRESH_DATA } from "@Constants/common";
import { call, put, takeLatest } from "redux-saga/effects";

export function* changeProfile({ dataProfile, callbackSuccess }: any): any {
  try {
    const result = yield call(
      baseApi.changeProfile,
      dataProfile,
    );
    yield put(changeProfileSuccessAction(result));
    yield put(notificationOpenAction({
      isNotification: true,
      typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
      message: "Thành công",
      typeRefreshData: TYPE_REFRESH_DATA?.CHANGE_PROFILE_REFRESH,
      time: Date.now().toString(),
      description: "Profile thay đổi thành công ! ",
    }));
    yield callbackSuccess()
  } catch (err : any) {


    yield put(changeProfileErrorAction(err));
    yield put(notificationOpenAction({
      isNotification: true,
      typeNotification: NOTIFICATION_TYPE.ERROR as "error",
      message: "Lỗi",
      description: err?.response?.data?.message || "Lỗi chưa xác định",
    }));
  }
}

// export function* getProfile({ prams, callbackSuccess }: any): any {
//   try {
//     const result = yield call(baseApi.getProfile, prams);
//     yield put(getProfileSuccessAction(result))
//     yield put(notificationOpenAction({
//       isNotification: true,
//       typeNotification: NOTIFICATION_TYPE.SUCCESS as 'success',
//       message: "Thành công",
//       time: Date.now().toString(),
//       description: "Profile thay đổi thành công ! "
//     }))
//     yield callbackSuccess()
//   } catch (error) {
//     yield put(changeProfileErrorAction(error));
//     yield put(notificationOpenAction({
//       isNotification: true,
//       typeNotification: NOTIFICATION_TYPE.ERROR as "error",
//       message: "Lỗi",
//       description: error?.response?.data?.message || "Lỗi chưa xác định",
//     }));
//   }
// }

export default function* saga() {
  yield takeLatest(actionTypes?.CHANGE_PROFILE_START, changeProfile);
  // yield takeLatest(actionTypes?.GET_PROFILE_START, getProfile);
}
