import { IAction } from "@Interfaces/common";
import ActionType from "@Actions/Socket/action-types";
import { fromJS } from 'immutable';

const initialState = fromJS({
    type: null,
    payload: null,
    error: null,
});
export default (state: any = initialState, action: IAction) => {
    switch (action.type) {
        case ActionType.SOCKET_CONNECT_START:
            return state
                .set("type", fromJS(action.type))
                .set('error', null)
        case ActionType.SOCKET_CONNECT_SUCCESS:
            return state
                .set("type", fromJS(action.type))
                .set('payload', fromJS(action.payload))
                .set('error', null)
        case ActionType.SOCKET_CONNECT_ERROR:
            return state
                .set("type", fromJS(action.type))
                .set('error', fromJS(action.error))
        default:
            return state;
    }
};
