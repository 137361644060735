import { useDispatch } from 'react-redux'
import * as actions from './actions'

export const useToast = () => {
    const dispatch = useDispatch()
    const notify = async (data) => {
        await dispatch(actions.notify(data))
    }
    return {
        notify
    }
}