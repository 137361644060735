export default {
  ADD_USER_EVENT_START: "ADD_USER_EVENT_START",
  ADD_USER_EVENT_SUCCESS: "ADD_USER_EVENT_SUCCESS",
  ADD_USER_EVENT_ERROR: "ADD_USER_EVENT_ERROR",
  GET_LIST_USER_EVENT_START: "GET_LIST_USER_EVENT_START",
  GET_LIST_USER_EVENT_SUCCESS: "GET_LIST_USER_EVENT_SUCCESS",
  GET_LIST_USER_EVENT_ERROR: "GET_LIST_USER_EVENT_ERROR",
  GET_LIST_ROLE_EVENT_START: "GET_LIST_ROLE_EVENT_START",
  GET_LIST_ROLE_EVENT_SUCCESS: "GET_LIST_ROLE_EVENT_SUCCESS",
  GET_LIST_ROLE_EVENT_ERROR: "GET_LIST_ROLE_EVENT_ERROR",
  UPDATE_USER_EVENT_START: "UPDATE_USER_EVENT_START",
  UPDATE_USER_EVENT_SUCCESS: "UPDATE_USER_EVENT_SUCCESS",
  UPDATE_USER_EVENT_ERROR: "UPDATE_USER_EVENT_ERROR",
  DELETE_USER_EVENT_START: "DELETE_USER_EVENT_START",
  DELETE_USER_EVENT_SUCCESS: "DELETE_USER_EVENT_SUCCESS",
  DELETE_USER_EVENT_ERROR: "DELETE_USER_EVENT_ERROR",
  GET_PERMISSION_EVENT_START: "GET_PERMISSION_EVENT_START",
  GET_PERMISSION_EVENT_SUCCESS: "GET_PERMISSION_EVENT_SUCCESS",
  GET_PERMISSION_EVENT_ERROR: "GET_PERMISSION_EVENT_ERROR",
};
