import { call, put, takeLatest } from "redux-saga/effects";
import baseApi from "@Apis/Media/api";
import {
  uploadImageErrorAction,
  uploadImageSuccessAction,
} from "@Actions/Media/action";
import { notificationOpenAction } from "@Actions/Notification/action";
import actionTypes from "@Actions/Media/action-types";
import { NOTIFICATION_TYPE } from "@Constants/common";

export function* uploadImage({ formData ,callbackSuccess, callbackError}: any): any {
  // console.log(formData);
  try {
    const result = yield call(baseApi.uploadImage, formData);
    const imageID=result?.result?.id;
    yield callbackSuccess(imageID)
    yield put(uploadImageSuccessAction(result));
  } catch (err : any) {
    console.log(err);
    yield put(uploadImageErrorAction(err ));
    yield callbackError()
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "success",
        message: "Lỗi",
        description: err?.response?.data?.message || "Lỗi chưa xác định",
      })
    );
  }
}

export default function* mediaSaga() {
  yield takeLatest(actionTypes?.UPLOAD_IMAGE_START, uploadImage);
}
