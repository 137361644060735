import * as action from "@Actions/Enterprise/action";
import * as enterpriseAction from "@Actions/Enterprise/action";
// const { Title } = Typography;
import add from "@Assets/icons/enterprise/add.svg";
import dropdownEnterpriseIcon from "@Assets/icons/enterprise/dropdownEnterpriseIcon.svg";
import { Div, Img, P } from "@Common/TagAntdNotSupport/TagAntdNotSupport";
// import { PERMISSIONS } from "@Constants/common";
import { ENTERPRISE_ID, ENTERPRISE_NAME, ENTERPRISE_URL } from "@Constants/localStorage";
// import WithPermission from "@Hocs/withPermission";
// import { Button, Divider, Popover, Typography } from "antd"
import { Button, Divider, Grid, Popover, Typography, Drawer, Collapse } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { RootState } from "@Reduxs/store";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "./style.scss";
import logo from "@Assets/icons/logo/logo.png"

interface IAccount {
  onSelectEnterprise: any;
}

const Account: React.FC<IAccount> = (props: any) => {
  // console.log(props, "props");
  const LOGIN_REDIRECT_URL = process.env.REACT_APP_LOGIN_REDIRECT_URL
  const { onSelectEnterprise } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [openList, setOpenList] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { profile, enterpriseList, loginReducer } = useSelector(
    (state: RootState) => ({
      profile: state.loginReducer?.get("profile")?.toJS(),
      enterpriseList: state.enterpriseReducer?.get("payload")?.toJS().result,
      users: state.userReducer?.get("payload")?.toJS().result,
      loginReducer: state.loginReducer,
    })
  );

  const  token  = loginReducer?.get("payload")?.get("result")?.get("token"); 

  const handleClick = (event: any) => {
    // console.log(event, "event");

    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  
  const handleLogout = () => {
    // dispatch(
    //   logoutAction(() => {
    //     history.push("/");
    //   })
    // );
    // localStorage.removeItem(ENTERPRISE_ID);
    // localStorage.removeItem(ENTERPRISE_URL);
    // localStorage.removeItem(ENTERPRISE_NAME);
    localStorage.clear();
    window.open(`${LOGIN_REDIRECT_URL}?logout=true&redirect=${window.location.origin}`,'_self')
  };

  // Handle show enterprise
  const handleShowEnterprise = () => {
    setOpenList(!openList)
    const elementID = document.getElementById("rc-nav-account-enterprise-id");
    if (elementID) {
      elementID.classList.toggle("rc-class-expand");
    }
  };

  // Update list enterprise
  useEffect(() => {
    (async () => {
      try {
        const callbackSuccess = (result: any) => {
          if(result?.result?.length===0) history.push('/enterprise-events/add')
        }
        dispatch(action.getListEnterpriseAction({}, callbackSuccess));
      } catch (error) {
          localStorage.removeItem(ENTERPRISE_ID);
          localStorage.removeItem(ENTERPRISE_URL);
          localStorage.removeItem(ENTERPRISE_NAME);
          history.push('/enterprise-events/add')
      }
    })();
  }, [dispatch]);

  // Handle select enterprise
  const handleSelecteEnterprise = (value: any) => {
    history.push(`/${value.url}/event`);
    handleClose();
    localStorage.setItem(ENTERPRISE_ID, value.id);
    localStorage.setItem(ENTERPRISE_NAME, value.name);
    localStorage.setItem(ENTERPRISE_URL, value.url);
    const callbackSuccess = (result: any) => {
      onSelectEnterprise(result);
    };
    dispatch(enterpriseAction.getInfoEnterpriseAction({}, callbackSuccess));
    setOpenList(!openList)
  };

  return !profile ? null : (
    <Div className='rc-nav-account'>
      <Div className='rc-nav-account-info' onClick={handleClick}>
        <Div className='rc-nav-account-info-icon'>
          {profile?.result?.photo ? (
            <Img src={`/media/images/${profile?.result?.photo}`} />
          ) : null}
        </Div>
        {/* <P>{profile?.result?.firstName}</P> */}
      </Div>
      <Drawer
            className="sp-only"
            anchor="right"
            open={open}
            onClose={handleClose}
          >
            <Div className='rc-nav-account-popover'>
          <Div className='rc-nav-account-popover-info'>
            <Div className='rc-nav-account-popover-info-avatar'>
              {profile?.result?.photo && (
                <Img src={`/media/images/${profile?.result?.photo}`} />
              )}
            </Div>
            <Div className='rc-nav-account-popover-info-detail'>
              <Typography variant={"h3"}>
                {`${profile?.result?.firstName || "---"}  
                    ${profile?.result?.lastName || "---"}
                  `}
              </Typography>
              <p className="rc-nav-account-popover-info-detail-mail" style={{ fontFamily: "Roboto-Regular" }}>
                {profile?.result?.email}
              </p>
              <Link to={{ pathname: `${process.env.REACT_APP_LOGIN_REDIRECT_URL}/profile?link_app=${window.location.origin}&token=${token}` }} target="_blank" className="link-info">
                <Button 
                  className="link-info__view-profile"
                  variant='contained' 
                  style={{
                    color: '#fff',
                    backgroundColor: 'var(--clr-primary)'
                  }}>
                  Xem hồ sơ
                </Button>
              </Link>
            </Div>
          </Div>

          <Divider />
          <Div
            className='rc-nav-account-popover-item'
            style={{ flexDirection: "column" }}
          >
            <Grid container justifyContent='space-between' alignItems='center'>
              <Div
                className='rc-nav-account-popover-item-link'
                onClick={() => setOpenList(!openList)}
              >
                <Img src={dropdownEnterpriseIcon} />
                <Typography variant={"h5"}>Doanh nghiệp</Typography>
              </Div>
              {
                openList
                ? <ArrowUpIcon onClick={handleShowEnterprise} color='disabled'/>
                : <ArrowDownIcon onClick={handleShowEnterprise} color='disabled'/>
              }
              {/* <ArrowForwardIosIcon
                onClick={handleShowEnterprise}
                color='disabled'
              /> */}
            </Grid>

            <Div
              className='rc-nav-account-enterprise'
              id='rc-nav-account-enterprise-id'
            >
              <Collapse in={openList} timeout="auto" unmountOnExit>
                {enterpriseList?.map((element: any, index: any) => {
                  return (
                    <Div
                      key={element.id}
                      className='rc-nav-account-enterprise-item'
                      onClick={() => handleSelecteEnterprise(element)}
                    >
                      <img
                        src={logo ||'http://ninecode.vn/static/media/logo.1d8061de.png'}
                        alt=''
                        className='logo'
                      />
                      <P>{element?.name}</P>
                    </Div>
                  );
                })}
              </Collapse>
            </Div>
            <Div className='action-wrapper'>
              <img src={add} alt={add} />
              <Button
                className='add'
                onClick={() => history.push("/enterprise-events/add")}
              >
                Thêm
              </Button>
            </Div>
          </Div>
          <Divider className="divider-last"/>
          <Div className='rc-nav-account-popover-item last' onClick={handleLogout}>
            <Typography variant={"h5"}>Đăng xuất</Typography>
            <ExitToAppIcon color='disabled' />
          </Div>
        </Div>
      </Drawer>

      <Popover
        className="sp-except"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Div className='rc-nav-account-popover'>
          <Div className='rc-nav-account-popover-info'>
            {/* <Div className='rc-nav-account-popover-info-avatar'>
              {profile?.result?.photo && (
                <Img src={`/media/images/${profile?.result?.photo}`} />
              )}
            </Div> */}
            <div className='rc-nav-account-popover-info-avatar'
              style={{
                  backgroundImage:`url(/media/images/${profile?.result?.photo})`,
                  backgroundPosition: "center center",
                  backgroundSize: "cover"
              }}
            >
            </div>
            <Div className='rc-nav-account-popover-info-detail'>
              <Typography variant={"h3"}>
                {`${profile?.result?.firstName || "---"}  
                    ${profile?.result?.lastName || "---"}
                  `}
              </Typography>
              <p className="rc-nav-account-popover-info-detail-mail" style={{ fontFamily: "Roboto-Regular" }}>
                {profile?.result?.email}
              </p>
              <Link to={{ pathname: `${process.env.REACT_APP_LOGIN_REDIRECT_URL}/profile?link_app=${window.location.origin}&token=${token}` }} target="_blank" className="link-info">
                <Button 
                  className="link-info__view-profile"
                  variant='contained' 
                  style={{
                    color: '#fff',
                    backgroundColor: 'var(--clr-primary)'
                  }}
                  >
                  Xem hồ sơ
                </Button>
              </Link>
            </Div>
          </Div>

          <Divider />
          {/*  */}
          <Div
            className='rc-nav-account-popover-item'
            style={{ flexDirection: "column" }}
          >
            <Grid container justifyContent='space-between' alignItems='center'>
              <Div
                className='rc-nav-account-popover-item-link'
                onClick={() => setOpenList(!openList)}
              >
                <Img src={dropdownEnterpriseIcon} />
                <Typography variant={"h5"}>Doanh nghiệp</Typography>
              </Div>
              {
                openList
                ? <ArrowUpIcon onClick={handleShowEnterprise} color='disabled'/>
                : <ArrowDownIcon onClick={handleShowEnterprise} color='disabled'/>
              }
              {/* <ArrowForwardIosIcon
                onClick={handleShowEnterprise}
                color='disabled'
              /> */}
            </Grid>

            <Div
              className='rc-nav-account-enterprise'
              id='rc-nav-account-enterprise-id'
            >
              <Collapse in={openList} timeout="auto" unmountOnExit>
                {enterpriseList?.map((element: any, index: any) => {
                  return (
                    <Div
                      key={element.id}
                      className='rc-nav-account-enterprise-item'
                      onClick={() => handleSelecteEnterprise(element)}
                    >
                      <img
                        src={logo||'http://ninecode.vn/static/media/logo.1d8061de.png'}
                        alt=''
                        className='logo'
                      />
                      <P>{element?.name}</P>
                    </Div>
                  );
                })}
              </Collapse>
            </Div>
            <Div className='action-wrapper'>
              <img src={add} alt={add} />
              <Button
                className='add'
                // onClick={() => history.push("/enterprise/add")}
                onClick={() => history.push("/enterprise-events/add")}
              >
                Thêm
              </Button>
            </Div>
          </Div>

          <Divider />
          <Div className='rc-nav-account-popover-item' onClick={handleLogout}>
            <Typography variant={"h5"}>Đăng xuất</Typography>
            <ExitToAppIcon color='disabled' />
          </Div>
        </Div>
      </Popover>
    </Div>
  );
};

export default Account;
