import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Div, Span, Img, P } from "@Common/TagAntdNotSupport/TagAntdNotSupport";
import { Grid,  Typography } from "@material-ui/core";
import logo from "@Assets/icons/logo/logoLarge.png";
import { changeInfoEnterpriseAction, getInfoEnterpriseAction } from "@Actions/Enterprise/action";
import { useDispatch, useSelector } from "react-redux";
import FormContentInFo from "@Components/Profile/FormContentInFo";
import { profileConfigRender } from "./const";
import ImageUploading from "react-images-uploading";
import { uploadImageAction } from "@Actions/Media/action";
import "./style.scss";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from "react-router";
import {
    ENTERPRISE_ID,
    ENTERPRISE_NAME,
    ENTERPRISE_URL,
} from "@Constants/localStorage";

Setting.propTypes = {
    
};

function Setting(props) {
    
  const [avatars, setAvatars] = useState([]);
  const [avatarID, setAvatarID] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
    const { infoEnterprise } = useSelector((state) => ({
        infoEnterprise: state.enterpriseReducer?.get("infoEnterprise")?.toJS()
            ?.result,
    }));
    const onChangeAvatar = async (imageList) => {
        setAvatars(imageList);
        let dataForm = new FormData();
        dataForm.append("photo", imageList[0]?.file);
    
        const callbackSuccess = (imageID) => {
          if (imageID) {
            onSubmitProfile({ logo: imageID })
            // console.log(imageID);
          }
        }
        await dispatch(uploadImageAction(dataForm, callbackSuccess));
    
      };
      useEffect(() => {
        if(infoEnterprise?.companyDetail?.logo){
            setAvatarID(infoEnterprise?.companyDetail?.logo)
        }
      }, [infoEnterprise?.companyDetail?.logo])
    

    const dataInfoGrid = {
        email: {
          value: infoEnterprise?.companyDetail?.email? infoEnterprise?.companyDetail.email : "-----------",
          emailDefault: infoEnterprise?.companyDetail?.email? infoEnterprise?.companyDetail.email : "",
        },
        name: {
          value: infoEnterprise?.name || "-------",
          nameDefault: infoEnterprise?.name || "",
        },
        tax: {
          value: infoEnterprise?.companyDetail?.tax? infoEnterprise?.companyDetail.tax : "------- - ---",
          taxDefault: infoEnterprise?.companyDetail?.tax? infoEnterprise?.companyDetail.tax : "",
        },
        phone: {
          value: infoEnterprise?.companyDetail?.phone? infoEnterprise?.companyDetail.phone : "---- --- ---",
          phoneDefault: infoEnterprise?.companyDetail?.phone? infoEnterprise?.companyDetail.phone : "",
        },
        address: {
          value: infoEnterprise?.companyDetail?.address?.address ? infoEnterprise?.companyDetail?.formattedAddress :  "--- , --- , ---",
            // (infoEnterprise?.companyDetail?.address?.address +
            //   ", " +
            //   infoEnterprise?.companyDetail?.address?.districtDetail?.name +
            //   ", " +
            //   infoEnterprise?.companyDetail?.address?.provinceDetail?.name) : "--- , --- , ---",
          addressDefault: infoEnterprise?.companyDetail?.address?.address || "",
          districtIDDefault: infoEnterprise?.companyDetail?.address?.district, //districtDetail?.id,
          provinceIDDefault: infoEnterprise?.companyDetail?.address?.province //provinceDetail?.id
    
        },
      };

      const onSubmitProfile = (values) => {
        const callback = ({result})=>{
            if(result?.name) {
                localStorage.setItem(ENTERPRISE_NAME, result?.name);
                history.push("/"+result?.url+"/setting")
            }
        }
        const callbackSuccess = () => {
          dispatch(getInfoEnterpriseAction({},callback));
        }
        dispatch(changeInfoEnterpriseAction(values, callbackSuccess));
      }
    return (
        <>
        <Grid className="Profile-wrapper">
          <Div className="main ">
            <Div className="head">
            <Grid container className="container header-content">
                <Grid item xs={12} sm={3}>
                </Grid>
                <Grid item xs={12} sm={9} className="header-content__main">
                    <ImageUploading
                        value={avatars}
                        onChange={onChangeAvatar}
                        dataURLKey="data_url"
                    >
                        {({
                            imageList,
                            onImageUpload,
                            onImageUpdate,
                            isDragging,
                            dragProps,
                        }) => (
                            // write your building UI
                            <Div className="avatar">
                                {imageList.length === 0 && !avatarID ? (
                                    <Div
                                        className="avatar-add-item"
                                        style={isDragging ? { color: "red" } : null}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                    >
                                        <AddIcon style={{ color: "gray", fontSize: "20px" }} />
                                        <P className="rc-avatar-text">Thêm logo</P>
                                    </Div>
                                ) : avatarID ? (
                                    <Div className="avatar-main-item">
                                        <Img
                                            src={
                                                imageList[0]?.data_url ||
                                                `/media/images/${avatarID}`
                                            }
                                            height="100%"
                                            width="100%"
                                        />
                                        <Div className="avatar-control-edit">
                                            <EditIcon style={{ color: "white", fontSize: "20px" }} onClick={() => onImageUpdate(0)} />
                                        </Div>
                                    </Div>
                                ) : (
                                    imageList?.map((image, index) => (
                                        <Div key={index} className="avatar-main-item">
                                            <Img src={image?.data_url} alt="" width="100%" />
                                            <Div className="avatar-control-edit" >
                                                <EditIcon style={{ color: "white", fontSize: "20px" }} onClick={() => onImageUpdate(index)} />
                                            </Div>
                                        </Div>
                                    ))
                                )}
                            </Div>
                        )}
                    </ImageUploading>
                    <Div className="name-tab">
                        Thông tin doanh nghiệp
                    </Div>
                </Grid>
            </Grid>
            </Div>
                    <div className="container">
                        <div className="content">
                            <Div
                                className="info-account"
                            >
                                <Grid container>
                                    <Grid item xs={12} md={3}>
                                        <Div className="name-profile">{profileConfigRender.title}</Div>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <Div className="father-profile">
                                            {profileConfigRender?.rowConfig?.map(
                                                (rowConfigItem, indexItem) => {
                                                    return (
                                                        <Div className="right-profile" key={indexItem}>
                                                            <Grid container>
                                                                <Grid item xs={3} sm={3}>
                                                                    <Div className="title">
                                                                        <Span className="title-content">
                                                                            {rowConfigItem.name}
                                                                        </Span>
                                                                    </Div>
                                                                </Grid>
                                                                <Grid item xs={7} sm={7} className="value-container">
                                                                    <FormContentInFo
                                                                        dataInfoGrid={dataInfoGrid}
                                                                        onSubmitProfile={onSubmitProfile}
                                                                        rowConfigItem={rowConfigItem}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Div>
                                                    );
                                                }
                                            )}
                                        </Div>
                                    </Grid>
                                </Grid>
                            </Div>
                        </div>
                    </div>
          </Div>
        </Grid>
  
      </>
    );
}

export default Setting;