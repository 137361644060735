
const GOOGLE_CLIENT_ID=process.env.REACT_APP_GOOGLE_CLIENT_ID
const GOOGLE_REDIRECT_URL=process.env.REACT_APP_GOOGLE_REDIRECT_URL
const GOOGLE_SCOPES=process.env.REACT_APP_GOOGLE_SCOPES

export {
    GOOGLE_CLIENT_ID,
    GOOGLE_REDIRECT_URL,
    GOOGLE_SCOPES
}
