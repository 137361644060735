export default {
    GET_USER_NOTIFICATION_LIST_START: 'GET_USER_NOTIFICATION_LIST_START',
    GET_USER_NOTIFICATION_LIST_SUCCESS: 'GET_USER_NOTIFICATION_LIST_SUCCESS',
    GET_USER_NOTIFICATION_LIST_ERROR: 'GET_USER_NOTIFICATION_LIST_ERROR',
    MARK_AS_READ_START: 'MARK_AS_READ_START',
    MARK_AS_READ_SUCCESS: 'MARK_AS_READ_SUCCESS',
    MARK_AS_READ_ERROR: 'MARK_AS_READ_ERROR',
    REMOVE_NOTIFICATION_START: 'REMOVE_NOTIFICATION_START',
    REMOVE_NOTIFICATION_SUCCESS: 'REMOVE_NOTIFICATION_SUCCESS',
    REMOVE_NOTIFICATION_ERROR: 'REMOVE_NOTIFICATION_ERROR',
}