import ActionType from "./action-types";
import { ILoginCredentials, ILoginSuccess } from "@Interfaces/credentials";
export const loginAction = (
  reCaptchaToken: string,
  credentials: ILoginCredentials,
  callbackSuccess: Function,
  callbackError: Function
) => ({
  type: ActionType.LOGIN_START,
  reCaptchaToken,
  credentials,
  callbackSuccess,
  callbackError,
  payload: null,
  error: null,
});
export const loginActiontest = (
  result:any,
) => ({
  type: ActionType.LOGIN,
  result,
  payload: null,
  error: null,
});
export const loginSuccessAction = (result: ILoginSuccess) => ({
  type: ActionType.LOGIN_SUCCESS,
  payload: result,
  error: null,
});
export const loginErrorAction = (error: unknown) => ({
  type: ActionType.LOGIN_ERROR,
  payload: null,
  error: error,
});
//===================
export const getProfileAction = (token: string, uid: string) => ({
  type: ActionType.GET_PROFILE_START,
  token,
  uid,
  payload: null,
  error: null,
});
export const getProfileSuccessAction = (result: any) => ({
  type: ActionType.GET_PROFILE_SUCCESS,
  payload: result,
  error: null,
});
export const getProfileErrorAction = (error: unknown) => ({
  type: ActionType.GET_PROFILE_ERROR,
  payload: null,
  error: error,
});

//===========
export const logoutAction = (result: any) => ({
  type: ActionType.LOGOUT_START,
  payload: null,
  error: null,
});
export const logoutSuccessAction = (result: any) => ({
  type: ActionType.LOGOUT_SUCCESS,
  payload: result,
  error: null,
});
export const logoutErrorAction = (error: unknown) => ({
  type: ActionType.LOGOUT_ERROR,
  payload: null,
  error: error,
});
