//resource
import common_vn from "../../lang/vn/common.json";
import common_en from "../../lang/en/common.json";
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
const langType = localStorage.getItem('lang');

// not like to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  //   // detect user language
  //   // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  // .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: true,
    lng: langType ? langType : 'en',                              // language to use
    resources: {
      en: {
        common: common_en               // 'common' is our custom namespace
      },
      vn: {
        common: common_vn
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;