import React from "react";
import { Modal, Grid } from "@material-ui/core";
import "./styles.scss";

interface IProps {
  open?: any;
  onClose?: any;
  children: any;
  styles?: any;
  onClick?: any;
  isFullScreen?: boolean;
}
const ModalWrapperOpenProp = (props: IProps) => {
  const { open, onClose, children, styles, isFullScreen } = props;
  return (
    <Modal
      open={open}
      onClose={onClose}
      className={
        isFullScreen
          ? "ModalWrapperOpenProp-modal ModalWrapperOpenProp-modal-fullscreen"
          : "ModalWrapperOpenProp-modal"
      }>
      <Grid container className="ModalWrapperOpenProp-wrapper" style={styles}>
        {children}
      </Grid>
    </Modal>
  );
};

export default ModalWrapperOpenProp;