import ActionType from './action-types';


export const getUserNotificationListAction = (params: any) => ({
  type: ActionType.GET_USER_NOTIFICATION_LIST_START,
  params,
  payload: null,
  error: null,
});
export const getUserNotificationListSuccessAction = (result: any) => ({
  type: ActionType.GET_USER_NOTIFICATION_LIST_SUCCESS,
  payload: result,
  error: null,
});
export const getUserNotificationListErrorAction = (error: Error) => ({
  type: ActionType.GET_USER_NOTIFICATION_LIST_ERROR,
  payload: null,
  error: error,
});
//MARK READ
export const markAsReadAction = (id: any) => ({
  type: ActionType.MARK_AS_READ_START,
  id,
  payload: null,
  error: null,
});
export const markAsReadSuccessAction = (result: any) => ({
  type: ActionType.MARK_AS_READ_SUCCESS,
  payload: result,
  error: null,
});
export const markAsReadErrorAction = (error: Error) => ({
  type: ActionType.MARK_AS_READ_ERROR,
  payload: null,
  error: error,
});
// REMOVE
export const removeNotificationAction = (id: any) => ({
  type: ActionType.REMOVE_NOTIFICATION_START,
  id,
  payload: null,
  error: null,
});
export const removeNotificationSuccessAction = (result: any) => ({
  type: ActionType.REMOVE_NOTIFICATION_SUCCESS,
  payload: result,
  error: null,
});
export const removeNotificationErrorAction = (error: Error) => ({
  type: ActionType.REMOVE_NOTIFICATION_ERROR,
  payload: null,
  error: error,
});