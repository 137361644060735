import {
    Button,
    FormControlLabel,
    Grid,
    IconButton,
    Switch,
    Radio,
    TextField,
    ButtonGroup,
    Checkbox,
    InputAdornment
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import "./style.scss";
import { Tab, Tabs } from '@material-ui/core';
import baseApi from "@Apis/Event/api";
import { useDispatch } from "react-redux";
import * as action from "@Actions/Event/action";
import { Box, Typography } from '@material-ui/core';
import CloseIcon from "@Assets/icons/event/closeIcon.svg";
import ChangeSortIcon from "@Assets/icons/event/ChangeSort.svg";
import deleteGrayIcon from "@Assets/icons/event/deleteGray.svg";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined"
import UserBlueIcon from "@Assets/icons/event/UserBlue.svg";
import PhoneBlueIcon from "@Assets/icons/event/PhoneBlue.svg";
import GmailBlueIcon from "@Assets/icons/event/GmailBlue.svg";
import noteBlueIcon from "@Assets/icons/event/noteBlue.svg";
import ParagraphBlueIcon from "@Assets/icons/event/ParagraphBlue.svg";
import StarBlueIcon from "@Assets/icons/event/StarBlue.svg";
import addressBlueIcon from "@Assets/icons/event/addressBlue.svg";
import dragIcon from "@Assets/icons/event/dragIcon.svg";
import { ReactSortable } from "react-sortablejs";
import { notificationOpenAction } from "@Actions/Notification/action";
import { NOTIFICATION_TYPE } from "@Constants/common";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`listuser-tabpanel-register`}
            aria-labelledby={`listuser-tabpanel-register`}
            {...other}
        >
            {value === index && <Box p={3} className="tabpanel-content">{children}</Box>}
        </Typography>
    );
}


const DragRegister = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [focusIndex, setFocusIndex]: any = useState(null)
    const [formRegister, setFormRegister]: any = useState(
        [
            {
                required: true,
                type: "text",
                title: "Họ và tên",
                name: "name",
                format: "text",
                img: UserBlueIcon
            },
            {
                required: true,
                type: "number",
                title: "Số điện thoại",
                name: "phone",
                format: "text",
                img: PhoneBlueIcon
            },
            {
                required: true,
                type: "email",
                title: "Email",
                name: "email",
                format: "text",
                img: GmailBlueIcon
            },
            {
                required: false,
                type: "text",
                title: "Địa chỉ",
                name: "address",
                format: "text",
                img: addressBlueIcon
            },
            {
                required: false,
                type: "text",
                title: "Ghi chú",
                name: "note",
                format: "text",
                img: noteBlueIcon,
                multiline: true
            }
        ]
    )
    const [refData, setRefData]: any = useState(false)
    const [saved, setSaved] = useState(false)
    const sortDataRef: any = React.useRef(null)
    const requireds = ["name", "phone", "note", "email", "address"]
    const typingTimeoutRef: any = React.useRef(null)

    // const handleArrangeData = (index: any) => {
    //     setFocusIndex(index)
    //     setFormRegister(sortDataRef.current)
    // };

    
  const reorder = (startIndex:any, endIndex:any) => {
    const result = formRegister
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const handleArrangeData = (result:any) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const items = reorder(
      result.source.index,
      result.destination.index
    );

    setFormRegister(items)
    setFocusIndex(result.destination.index)
  };

    const handleRequired = (status: any, index: any) => {
        const required = ["name", "phone", "email"]
        let getRequired = formRegister[index]
        if (!required.includes(getRequired?.name)) {
            getRequired.required = status
            formRegister.splice(index, 1, getRequired)
            setFormRegister(formRegister)
            setRefData(!refData)
        }
    }

    useEffect(() => {

    }, [refData])

    const callbackSuccess = (data: any) => {
        dispatch(notificationOpenAction({
            isNotification: true,
            typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
            message: "Thành Công",
            description: "Lưu thành công ! ",
        }))
        setSaved(true)
    }
    const callbackError = () => {
        dispatch(notificationOpenAction({
            isNotification: true,
            typeNotification: NOTIFICATION_TYPE.ERROR as "error",
            message: "Thất bại",
            description: "Lưu thất bại ! ",
        }))
    };
    const id = window.location.pathname.split("/")[3]

    const handleSubmit = () => {
        try {
            if (id) dispatch(action.updateEventAction({ requiredRegister: formRegister }, id, callbackSuccess, callbackError))
        } catch (error) {
            callbackError()
        }
    }

    const fetchData = async () => {
        try {
            const { result }: any = await baseApi.getDetailEvent(id)
            setFormRegister(result?.requiredRegister || formRegister)
        } catch (error) {
            console.log("error fetchData event detail", error);
        }
    }

    // useEffect(()=>{
    //     if(formRegister.length>5){
    //       localStorage.setItem("FORM_REGISTER_EVENT", JSON.stringify([
    //         ...formRegister
    //       ]));
    //     }
    //   })

    useEffect(() => {
        if(localStorage.getItem("FORM_REGISTER_EVENT")){
            const result= JSON.parse(localStorage.getItem("FORM_REGISTER_EVENT") as any);
            setFormRegister([...result])
          }else{
            fetchData()
            setSaved(false)
          }
    }, [])

    return (
        <div className="add-event-wrapper">
            {/* header add event mobile */}
            <Grid container direction="row" alignItems="center" justifyContent="space-between" className="header-add-event-mobile">
                <Grid item xs={3}>
                    <div className="exit-page">
                        <IconButton
                            onClick={() =>
                                history.goBack()
                                // history.goBack()
                            }
                            className="exit-btn"
                        >
                            <img src={CloseIcon} alt={CloseIcon} />
                            <span>Thoát</span>
                        </IconButton>
                    </div>
                </Grid>
                <Grid item xs={3}>
                </Grid>
            </Grid>

            <TabPanel value={0} index={0} className="ev-register-container">
                <Grid item xs={12}>
                    <div className="box-event model-register">
                        <div className="box-event-wrap">
                            <div className="add-event-content add-event-content-register drag-mobile">
                                <div className="content-header">Mẫu đăng ký sự kiện</div>
                                {
                                    <DragDropContext onDragEnd={handleArrangeData} onDragStart={()=>{
                                        // if (window.navigator.vibrate) {
                                        //   window.navigator.vibrate(100);
                                        // }
                                      }}>
                                        <Droppable droppableId="droppable">
                                          {(provided, snapshot) => (
                                            <div
                                              {...provided.droppableProps}
                                              ref={provided.innerRef}
                                            >
                                              {formRegister.map((item:any, index:any) => (
                                                <Draggable key={item.name + `${index}`} draggableId={item.name + `${index}`} index={index}>
                                                  {(provided, snapshot) => (
                                                    <div
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                    >
                                                      <div className={`field-item new ${index === focusIndex ? "active" : ""} `} onClick={() => (index !== focusIndex) && setFocusIndex(index)}>
                                                                <div className="focus-header">
                                                                    <img src={dragIcon} alt={dragIcon} />
                                                                </div>
                                                                <div className="wrap-content">
                                                                    {
                                                                        (item.format === "text")
                                                                            ? (item?.multiline)
                                                                                ? <div className="field-item-content">
                                                                                    <img className="field-item-img" src={item.img} alt={item.img} />
                                                                                    <TextField
                                                                                        placeholder={item.title}
                                                                                        fullWidth
                                                                                        multiline
                                                                                        minRows={4}
                                                                                        size="small"
                                                                                        variant="standard"
                                                                                        style={{
                                                                                            background: "#F6F7FA",
                                                                                            borderRadius: "4px",
                                                                                            padding: "8px 4px 8px 16px"
                                                                                        }}
                                                                                        InputProps={{
                                                                                            style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                                                                            readOnly: true,
                                                                                            disableUnderline: true
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                : <div className="field-item-content">
                                                                                    <img className="field-item-img" src={item.img} alt={item.img} />
                                                                                    <TextField
                                                                                        placeholder={item.title}
                                                                                        fullWidth
                                                                                        size="small"
                                                                                        InputProps={{
                                                                                            style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                                                                            readOnly: true,
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            : (item.format === "short-text")
                                                                                ? <div className="field-item-content">
                                                                                    <img className="field-item-img" src={item.img} alt={item.img} />
                                                                                    <TextField
                                                                                        placeholder={item.title}
                                                                                        fullWidth
                                                                                        size="small"
                                                                                        InputProps={{
                                                                                            style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                                                                            readOnly: true,
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                : (item.format === "radio")
                                                                                    ? <>
                                                                                        <div className="field-item-content radio-wrap">
                                                                                            <img className="field-item-img" src={item.img} alt={item.img} />
                                                                                            <TextField
                                                                                                placeholder={item.title}
                                                                                                fullWidth
                                                                                                size="small"
                                                                                                InputProps={{
                                                                                                    style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                                                                                    readOnly: true,
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        {
                                                                                            item.question.map((value: any, key: any) => (
                                                                                                <div className="radio-items" key={value.name + `${key}`}>
                                                                                                    <Radio className="radio-item" checked={false} size="small" />
                                                                                                    <TextField
                                                                                                        placeholder={(typeof value !== "object") ? value : "Khác"}
                                                                                                        fullWidth
                                                                                                        size="small"
                                                                                                        InputProps={{
                                                                                                            style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                                                                                            readOnly: true,
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            ))
                                                                                        }
                                                                                        <div className="radio-items">
                                                                                            <Radio className="radio-item" checked={false} size="small" />
                                                                                            <div className="add-field">
                                                                                                <span>Thêm lựa chọn</span>
                                                                                                &nbsp;hoặc &nbsp;
                                                                                                <span>Khác</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                    : (item.format === "check-box")
                                                                                        ? <>
                                                                                            <div className="field-item-content radio-wrap">
                                                                                                <img className="field-item-img" src={item.img} alt={item.img} />
                                                                                                <TextField
                                                                                                    placeholder={item.title}
                                                                                                    fullWidth
                                                                                                    size="small"
                                                                                                    InputProps={{
                                                                                                        readOnly: true,
                                                                                                        
                                                                                                        style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' }
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                            {
                                                                                                item.question.map((value: any, key: any) => (
                                                                                                    <div className="radio-items" key={value.name + `${key}`}>
                                                                                                        <Checkbox className="radio-item" checked={false} size="small" />
                                                                                                        <TextField
                                                                                                            placeholder={(typeof value !== "object") ? value : "Khác"}
                                                                                                            fullWidth
                                                                                                            size="small"
                                                                                                            InputProps={{
                                                                                                                style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                                                                                                readOnly: true,
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <div className="radio-items">
                                                                                                <Checkbox className="radio-item" checked={false} size="small" />
                                                                                                <div className="add-field">
                                                                                                    <span>Thêm lựa chọn</span>
                                                                                                    &nbsp;hoặc &nbsp;
                                                                                                    <span>Khác</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                        : (item.format === "paragraph")
                                                                                            ? <div className="field-item-content">
                                                                                                <img className="field-item-img" src={item.img} alt={item.img} />
                                                                                                <TextField
                                                                                                    placeholder={item.title}
                                                                                                    multiline
                                                                                                    fullWidth
                                                                                                    size="small"
                                                                                                    InputProps={{
                                                                                                        style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                                                                                        readOnly: true,
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                            : ""
                                                                    }
                                                                </div>
                                                                { (item.format === "short-text" || item.format === "text") && item.name !=="note" ? <div className="sub-text">Trả lời ngắn</div> : "" }
                                                                {item.format === "paragraph" ? <div className="sub-text">Đoạn văn bản</div> : ""}
                                                            </div>
                                                    </div>
                                                  )}
                                                </Draggable>
                                              ))}
                                              {provided.placeholder}
                                            </div>
                                          )}
                                        </Droppable>
                                      </DragDropContext>
                                    // <ReactSortable
                                    //     list={formRegister}
                                    //     delayOnTouchStart={true}
                                    //     delay={200}
                                    //     setList={(newState) => { sortDataRef.current = newState }}
                                    //     onEnd={(e) => {
                                    //         handleArrangeData(e.newIndex)
                                    //     }}
                                    // >
                                    //     {
                                    //         formRegister.map((item: any, index: any) => {
                                    //             return (
                                    //                 <div key={item.name + `${index}`}>
                                    //                     {
                                    //                         <div className={`field-item new ${index === focusIndex ? "active" : ""} `} onClick={() => (index !== focusIndex) && setFocusIndex(index)}>
                                    //                             <div className="focus-header">
                                    //                                 <img src={dragIcon} alt={dragIcon} />
                                    //                             </div>
                                    //                             <div className="wrap-content">
                                    //                                 {
                                    //                                     (item.format === "text")
                                    //                                         ? (item?.multiline)
                                    //                                             ? <div className="field-item-content">
                                    //                                                 <img className="field-item-img" src={item.img} alt={item.img} />
                                    //                                                 <TextField
                                    //                                                     placeholder={item.title}
                                    //                                                     fullWidth
                                    //                                                     multiline
                                    //                                                     minRows={4}
                                    //                                                     size="small"
                                    //                                                     variant="standard"
                                    //                                                     style={{
                                    //                                                         background: "#F6F7FA",
                                    //                                                         borderRadius: "4px",
                                    //                                                         padding: "8px 4px 8px 16px"
                                    //                                                     }}
                                    //                                                     InputProps={{
                                    //                                                         style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                    //                                                         readOnly: true,
                                    //                                                         disableUnderline: true
                                    //                                                     }}
                                    //                                                 />
                                    //                                             </div>
                                    //                                             : <div className="field-item-content">
                                    //                                                 <img className="field-item-img" src={item.img} alt={item.img} />
                                    //                                                 <TextField
                                    //                                                     placeholder={item.title}
                                    //                                                     fullWidth
                                    //                                                     size="small"
                                    //                                                     InputProps={{
                                    //                                                         style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                    //                                                         readOnly: true,
                                    //                                                     }}
                                    //                                                 />
                                    //                                             </div>
                                    //                                         : (item.format === "short-text")
                                    //                                             ? <div className="field-item-content">
                                    //                                                 <img className="field-item-img" src={item.img} alt={item.img} />
                                    //                                                 <TextField
                                    //                                                     placeholder={item.title}
                                    //                                                     fullWidth
                                    //                                                     size="small"
                                    //                                                     InputProps={{
                                    //                                                         style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                    //                                                         readOnly: true,
                                    //                                                     }}
                                    //                                                 />
                                    //                                             </div>
                                    //                                             : (item.format === "radio")
                                    //                                                 ? <>
                                    //                                                     <div className="field-item-content radio-wrap">
                                    //                                                         <img className="field-item-img" src={item.img} alt={item.img} />
                                    //                                                         <TextField
                                    //                                                             placeholder={item.title}
                                    //                                                             fullWidth
                                    //                                                             size="small"
                                    //                                                             InputProps={{
                                    //                                                                 style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                    //                                                                 readOnly: true,
                                    //                                                             }}
                                    //                                                         />
                                    //                                                     </div>
                                    //                                                     {
                                    //                                                         item.question.map((value: any, key: any) => (
                                    //                                                             <div className="radio-items" key={value.name + `${key}`}>
                                    //                                                                 <Radio className="radio-item" checked={false} size="small" />
                                    //                                                                 <TextField
                                    //                                                                     placeholder={(typeof value !== "object") ? value : "Khác"}
                                    //                                                                     fullWidth
                                    //                                                                     size="small"
                                    //                                                                     InputProps={{
                                    //                                                                         style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                    //                                                                         readOnly: true,
                                    //                                                                     }}
                                    //                                                                 />
                                    //                                                             </div>
                                    //                                                         ))
                                    //                                                     }
                                    //                                                     <div className="radio-items">
                                    //                                                         <Radio className="radio-item" checked={false} size="small" />
                                    //                                                         <div className="add-field">
                                    //                                                             <span>Thêm lựa chọn</span>
                                    //                                                             &nbsp;hoặc &nbsp;
                                    //                                                             <span>Khác</span>
                                    //                                                         </div>
                                    //                                                     </div>
                                    //                                                 </>
                                    //                                                 : (item.format === "check-box")
                                    //                                                     ? <>
                                    //                                                         <div className="field-item-content radio-wrap">
                                    //                                                             <img className="field-item-img" src={item.img} alt={item.img} />
                                    //                                                             <TextField
                                    //                                                                 placeholder={item.title}
                                    //                                                                 fullWidth
                                    //                                                                 size="small"
                                    //                                                                 InputProps={{
                                    //                                                                     readOnly: true,
                                                                                                        
                                    //                                                                     style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' }
                                    //                                                                 }}
                                    //                                                             />
                                    //                                                         </div>
                                    //                                                         {
                                    //                                                             item.question.map((value: any, key: any) => (
                                    //                                                                 <div className="radio-items" key={value.name + `${key}`}>
                                    //                                                                     <Checkbox className="radio-item" checked={false} size="small" />
                                    //                                                                     <TextField
                                    //                                                                         placeholder={(typeof value !== "object") ? value : "Khác"}
                                    //                                                                         fullWidth
                                    //                                                                         size="small"
                                    //                                                                         InputProps={{
                                    //                                                                             style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                    //                                                                             readOnly: true,
                                    //                                                                         }}
                                    //                                                                     />
                                    //                                                                 </div>
                                    //                                                             ))
                                    //                                                         }
                                    //                                                         <div className="radio-items">
                                    //                                                             <Checkbox className="radio-item" checked={false} size="small" />
                                    //                                                             <div className="add-field">
                                    //                                                                 <span>Thêm lựa chọn</span>
                                    //                                                                 &nbsp;hoặc &nbsp;
                                    //                                                                 <span>Khác</span>
                                    //                                                             </div>
                                    //                                                         </div>
                                    //                                                     </>
                                    //                                                     : (item.format === "paragraph")
                                    //                                                         ? <div className="field-item-content">
                                    //                                                             <img className="field-item-img" src={item.img} alt={item.img} />
                                    //                                                             <TextField
                                    //                                                                 placeholder={item.title}
                                    //                                                                 multiline
                                    //                                                                 fullWidth
                                    //                                                                 size="small"
                                    //                                                                 InputProps={{
                                    //                                                                     style: { fontSize: 16, color: "#333333", fontFamily: 'Roboto-Regular' },
                                    //                                                                     readOnly: true,
                                    //                                                                 }}
                                    //                                                             />
                                    //                                                         </div>
                                    //                                                         : ""
                                    //                                 }
                                    //                             </div>
                                    //                             { (item.format === "short-text" || item.format === "text") && item.name !=="note" ? <div className="sub-text">Trả lời ngắn</div> : "" }
                                    //                             {item.format === "paragraph" ? <div className="sub-text">Đoạn văn bản</div> : ""}
                                    //                         </div>
                                    //                     }
                                    //                 </div>
                                    //             );
                                    //         })
                                    //     }
                                    // </ReactSortable>
                                }
                                <div className="save-register">
                                    {!saved ? <Button
                                        className="action-save"
                                        color="primary"
                                        variant="contained"
                                        onClick={() => handleSubmit()}
                                    >
                                        Lưu
                                    </Button>
                                        : <Button
                                            className="action-save"
                                            color="primary"
                                            variant="outlined"
                                        >
                                            Đã lưu
                                        </Button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </TabPanel>
        </div>
    );
};

export default DragRegister;