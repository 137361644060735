import { IAction } from "@Interfaces/common";
import ActionType from "@Actions/Enterprise/action-types";
import { fromJS } from "immutable";

const initialState = fromJS({
  type: null,
  payload: null,
  error: null,
  isLoading: false,
  infoEnterprise: null,
});
export default (state: any = initialState, action: IAction) => {
  switch (action.type) {
    case ActionType.GET_LIST_ENTERPRISE_START:
      return state
        .set("type", fromJS(action.type))
        .set("isLoading", true)
        .set("error", null);
    case ActionType.GET_LIST_ENTERPRISE_SUCCESS:
      return state
        .set("type", fromJS(action.type))
        .set("isLoading", false)
        .set("payload", fromJS(action.payload))
        .set("error", null);
    case ActionType.GET_LIST_ENTERPRISE_ERROR:
      return state
        .set("type", fromJS(action.type))
        .set("isLoading", false)
        .set("isFetched", false)
        .set("error", fromJS(action.error));

    case ActionType.GET_INFO_ENTERPRISE_START:
      return state
        .set("type", fromJS(action.type))
        .set("isLoading", true)
        .set("error", null);
    case ActionType.GET_INFO_ENTERPRISE_SUCCESS:
      return state
        .set("type", fromJS(action.type))
        .set("isLoading", false)
        .set("infoEnterprise", fromJS(action.payload))
        .set("error", null);
    // case ActionType.GET_INFO_ENTERPRISE_SUCCESS:
    //   return state
    //     .set("type", fromJS(action.type))
    //     .set("isLoading", false)
    //     .set("isFetched", false)
    //     .set("error", fromJS(action.error));
    default:
      return state;
  }
};
