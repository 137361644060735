import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import apiConfig from "@Configs/api/api.config"
import { Api } from "@Configs/api/api";
import { API_UPLOAD_IMAGE } from "./endpoint"



class BaseApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config);
    this.uploadImage = this.uploadImage.bind(this);
  }

  public uploadImage(formData: any): Promise<number> {
    return this.post<any>(API_UPLOAD_IMAGE, formData, {
      headers: {
      },
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }


}
const baseApi = new BaseApi(apiConfig);

export default baseApi;