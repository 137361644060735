import React, { useEffect } from "react";
// import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  ENTERPRISE_ID,
  ENTERPRISE_NAME,
  ENTERPRISE_URL,
} from "@Constants/localStorage";
import { useHistory } from "react-router";
import ActionType from "@Actions/Enterprise/action-types";
import { RootState } from "@Reduxs/store";
// Home.propTypes = {};

const Home: React.FC = () => {
  const history = useHistory();
  const { enterprises, type } = useSelector((state: RootState) => ({
    enterprises: state.enterpriseReducer?.get("payload")?.toJS().result,
    type: state.enterpriseReducer?.get("type"),
  }));

  useEffect(() => {
    if (type === ActionType.GET_LIST_ENTERPRISE_SUCCESS) {
      if (enterprises?.length > 0) {
        const getUrl = localStorage.getItem(ENTERPRISE_URL);
        if(getUrl) history.push(`/${getUrl}/event`);
        else {
          history.push(`/${enterprises[0].url}/event`);
          localStorage.setItem(ENTERPRISE_ID, enterprises[0].id);
          localStorage.setItem(ENTERPRISE_NAME, enterprises[0].name);
          localStorage.setItem(ENTERPRISE_URL, enterprises[0].url);
        }
      } else {
        history.push("/enterprise-events/add");
      }
    }
  }, [enterprises, history, type]);

  return <div></div>;
};

export default Home;
