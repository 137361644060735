import ActionType from "./action-types";
// Add user
export const addUserAction = (
  data: any,
  callbackSuccess: Function,
  callbackError: Function
) => ({
  type: ActionType.ADD_USER_START,
  data,
  callbackSuccess,
  callbackError,
  payload: null,
  error: null,
});
export const addUserSuccessAction = (result: any) => ({
  type: ActionType.ADD_USER_SUCCESS,
  payload: result,
  error: null,
});
export const addUserErrorAction = (error: Error) => ({
  type: ActionType.ADD_USER_ERROR,
  payload: null,
  error: error,
});
// Get list user
export const getListUserAction = (prams: any, callbackSuccess: Function) => ({
  type: ActionType.GET_LIST_USER_START,
  prams,
  callbackSuccess,
  payload: null,
  error: null,
});
export const getListUserSuccessAction = (result: any) => ({
  type: ActionType.GET_LIST_USER_SUCCESS,
  payload: result,
  error: null,
});
export const getListUserErrorAction = (error: Error) => ({
  type: ActionType.GET_LIST_USER_ERROR,
  payload: null,
  error: error,
});
// Get list role
export const getListRoleAction = (data: any, callbackSuccess: Function) => ({
  type: ActionType.GET_LIST_ROLE_START,
  data,
  callbackSuccess,
  payload: null,
  error: null,
});
export const getListRoleSuccessAction = (result: any) => ({
  type: ActionType.GET_LIST_ROLE_SUCCESS,
  payload: result,
  error: null,
});
export const getListRoleErrorAction = (error: Error) => ({
  type: ActionType.GET_LIST_ROLE_ERROR,
  payload: null,
  error: error,
});
// Update User
export const updateUserAction = (
  selectedUserID: any,
  selectedRole: any,
  callbackSuccess: Function
) => ({
  type: ActionType.UPDATE_USER_START,
  selectedUserID,
  selectedRole,
  callbackSuccess,
  payload: null,
  error: null,
});
export const updateUserSuccessAction = (result: any) => ({
  type: ActionType.UPDATE_USER_SUCCESS,
  payload: result,
  error: null,
});
export const updateUserErrorAction = (error: Error) => ({
  type: ActionType.UPDATE_USER_ERROR,
  payload: null,
  error: error,
});
// Delete User
export const deleteUserAction = (
  selectedUserID: any,
  callbackSuccess: Function
) => ({
  type: ActionType.DELETE_USER_START,
  selectedUserID,
  callbackSuccess,
  payload: null,
  error: null,
});
export const deleteUserSuccessAction = (result: any) => ({
  type: ActionType.DELETE_USER_SUCCESS,
  payload: result,
  error: null,
});
export const deleteUserErrorAction = (error: Error) => ({
  type: ActionType.DELETE_USER_ERROR,
  payload: null,
  error: error,
});
// Get permission
export const getPermissionAction = (prams: any, callbackSuccess: Function) => ({
  type: ActionType.GET_PERMISSION_START,
  prams,
  callbackSuccess,
  payload: null,
  error: null,
});
export const getPermissionSuccessAction = (result: any) => ({
  type: ActionType.GET_PERMISSION_SUCCESS,
  payload: result,
  error: null,
});
export const getPermissionErrorAction = (error: Error) => ({
  type: ActionType.GET_PERMISSION_ERROR,
  payload: null,
  error: error,
});
