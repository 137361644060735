import ActionType from "./action-types";

export const addEventAction = (
  data: any,
  callbackSuccess: Function,
  callbackError: Function
) => ({
  type: ActionType.ADD_EVENT_START,
  data,
  callbackSuccess,
  callbackError,
  payload: null,
  error: null,
});
export const addEventSuccessAction = (result: any) => ({
  type: ActionType.ADD_EVENT_SUCCESS,
  payload: result,
  error: null,
});
export const addEventErrorAction = (error: unknown) => ({
  type: ActionType.ADD_EVENT_ERROR,
  payload: null,
  error: error,
});

export const getListEventAction = (
  callbackSuccess: Function
) => ({
  type: ActionType.GET_LIST_EVENT_START,
  callbackSuccess,
  payload: null,
  error: null,
});
export const getListEventSuccessAction = (result: any) => ({
  type: ActionType.GET_LIST_EVENT_SUCCESS,
  payload: result,
  error: null,
});
export const getListEventErrorAction = (error: unknown) => ({
  type: ActionType.GET_LIST_EVENT_ERROR,
  payload: null,
  error: error,
});
export const getDetailEventAction = (
  params: any,
  callbackSuccess: Function
) => ({
  type: ActionType.GET_DETAIL_EVENT_START,
  params,
  callbackSuccess,
  payload: null,
  error: null,
});
export const getDetailEventSuccessAction = (result: any) => ({
  type: ActionType.GET_DETAIL_EVENT_SUCCESS,
  payload: result,
  error: null,
});
export const getDetailEventErrorAction = (error: unknown) => ({
  type: ActionType.GET_DETAIL_EVENT_ERROR,
  payload: null,
  error: error,
});

export const updateEventAction = (
    data: any,
    params: any,
    callbackSuccess: Function,
    callbackError: Function
) => ({
    type: ActionType.UPDATE_EVENT_START,
    data,
    params,
    callbackSuccess,
    callbackError,
    payload: null,
    error: null
});
export const updateEventSuccessAction = (result: any) => ({
    type: ActionType.UPDATE_EVENT_SUCCESS,
    payload: result,
    error: null,
});
export const updateEventErrorAction = (error: unknown) => ({
    type: ActionType.UPDATE_EVENT_ERROR,
    payload: null,
    error: error,
});
export const deleteEventAction = (
    params: any,
    callbackSuccess: Function,
    ) => ({
    type: ActionType.DELETE_EVENT_START,
    params,
    callbackSuccess,
    payload: null,
});
export const deleteEventSuccessAction = (result: any) => ({
    type: ActionType.DELETE_EVENT_SUCCESS,
    payload: result,
    error: null,
});
export const deleteEventErrorAction = (error: unknown) => ({
    type: ActionType.DELETE_EVENT_ERROR,
    payload: null,
    error: error,
});
export const restoreEventAction = (
  params: any,
  callbackSuccess: Function,
  ) => ({
  type: ActionType.RESTORE_EVENT_START,
  params,
  callbackSuccess,
  payload: null,
});
export const restoreEventSuccessAction = (result: any) => ({
  type: ActionType.RESTORE_EVENT_SUCCESS,
  payload: result,
  error: null,
});
export const restoreEventErrorAction = (error: unknown) => ({
  type: ActionType.RESTORE_EVENT_ERROR,
  payload: null,
  error: error,
});
export const publishEventAction = (
  data: any,
  params: any,
  callbackSuccess: Function,
  ) => ({
  type: ActionType.PUBLISH_EVENT_START,
  data,
  params,
  callbackSuccess,
  payload: null,
});
export const publishEventSuccessAction = (result: any) => ({
  type: ActionType.PUBLISH_EVENT_SUCCESS,
  payload: result,
  error: null,
});
export const publishEventErrorAction = (error: unknown) => ({
  type: ActionType.PUBLISH_EVENT_ERROR,
  payload: null,
  error: error,
});
export const updateCheckInEventAction = (
  data: any,
  params: any,
  callbackSuccess: Function,
  callbackError: Function
) => ({
  type: ActionType.UPDATE_CHECK_IN_EVENT_START,
  data,
  params,
  callbackSuccess,
  callbackError,
  payload: null,
  error: null
});
export const updateCheckInEventSuccessAction = (result: any) => ({
  type: ActionType.UPDATE_CHECK_IN_EVENT_SUCCESS,
  payload: result,
  error: null,
});
export const updateCheckInEventErrorAction = (error: unknown) => ({
  type: ActionType.UPDATE_CHECK_IN_EVENT_ERROR,
  payload: null,
  error: error,
});

export const getListUserEventAction = (
  params: any,
  callbackSuccess: Function
) => ({
  type: ActionType.GET_LIST_EVENT_USER_START,
  params,
  callbackSuccess,
  payload: null,
  error: null,
});
export const getListUserEventSuccessAction = (result: any) => ({
  type: ActionType.GET_LIST_EVENT_USER_SUCCESS,
  payload: result,
  error: null,
});
export const getListUserEventErrorAction = (error: unknown) => ({
  type: ActionType.GET_LIST_EVENT_USER_ERROR,
  payload: null,
  error: error,
});

export const addUserEventAction = (
  data: any,
  callbackSuccess: Function,
  callbackError: Function
) => ({
  type: ActionType.ADD_USER_EVENTS_START,
  data,
  callbackSuccess,
  callbackError,
  payload: null,
  error: null,
});
export const addUserEventSuccessAction = (result: any) => ({
  type: ActionType.ADD_USER_EVENT_SUCCESS,
  payload: result,
  error: null,
});
export const addUserEventErrorAction = (error: unknown) => ({
  type: ActionType.ADD_USER_EVENT_ERROR,
  payload: null,
  error: error,
});