import pageFailed from "@Assets/images/PageNotFound/404-failed.svg";
import { Typography,Button,Avatar } from "@material-ui/core";
//Hocs
import withScrollToTop from "@Hocs/withScrollToTop";
import React from 'react';
import { useHistory } from "react-router-dom";
import {MainContent} from "./styled";

const PageNotFound = (props: any) => {
  const history = useHistory();

  return (
    <MainContent>
      <Avatar variant="square" src={pageFailed}></Avatar>
      <Typography>Trang này không hiển thị</Typography>
      <Typography>Có thể liên kết đã hỏng hoặc trang đã bị gỡ.
        Hãy kiểm tra xem liên kết mà bạn đang cố mở có chính xác không.</Typography>

      <Button variant="contained" color="primary" onClick={()=>{
        history.push("/")
      }} >Đi tới trang chủ</Button>

    </MainContent>
  );
};

export default withScrollToTop(PageNotFound);
