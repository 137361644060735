import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import apiConfig from "@Configs/api/api.config";
import { Api } from "@Configs/api/api";
import { API_REGISTER } from "./endpoint";
import { IRegisterCredentials } from "@Interfaces/credentials";

class BaseApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config);
    this.registerUser = this.registerUser.bind(this);
  }

  public registerUser(
    token: string,
    credentials: IRegisterCredentials
  ): Promise<number> {
    // console.log(token, "token");

    return this.post<number>(API_REGISTER, JSON.stringify(credentials), {
      headers: {
        "x-recaptcha-token": token,
      },
    })
      .then((registered: AxiosResponse<number>) => {
        const { status } = registered;

        return status;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }
}
const baseApi = new BaseApi(apiConfig);

export default baseApi;
