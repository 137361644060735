const MEDIA_IMAGE_URL = "/media/images"
const NOTIFICATION_TYPE = {
    SUCCESS: 'success',
    ERROR: "error",
    INFO: "info",
    WARNING: "warning",
    WARN: "warn",
    OPEN: 'open',
}

const TYPE_REFRESH_DATA = {
    CHANGE_ENTERPRISE_REFRESH: "CHANGE_ENTERPRISE_REFRESH",
    ADD_ENTERPRISE_REFRESH: "ADD_ENTERPRISE_REFRESH",
    ADD_ROOM__REFRESH: "ADD_ROOM__REFRESH",
    CHANGE_PROFILE_REFRESH: "CHANGE_PROFILE_REFRESH",
    CHANGE_ACCOUNT_REFRESH: "CHANGE_ACCOUNT_REFRESH",
    ADD_USER_REFRESH: "ADD_USER_REFRESH",
    UPDATE_USER_REFRESH: "UPDATE_USER_REFRESH",
    DELETE_USER_REFRESH: "DELETE_USER_REFRESH",
    ADD_ROOM_REFRESH: "ADD_ROOM_REFRESH",
    ADD_BOOKING_ROOM_REFRESH: "ADD_BOOKING_ROOM_REFRESH",
    DELETE_BOOKING_ROOM_REFRESH: "DELETE_BOOKING_ROOM_REFRESH",
    DELETE_ROOM_REFRESH: "DELETE_ROOM_REFRESH",
    UPDATE_ROOM_REFRESH: "UPDATE_ROOM_REFRESH"
}

const PERMISSIONS = {
    MEMBER_ACCESS: "member_access",
    ADMIN_ACCESS: "admin_access",
    BUSINESS_CREATE: "business_create",
    BUSINESS_READ: "business_read",
    BUSINESS_UPDATE: "business_update",
    BUSINESS_DELETE: "business_delete",
    IAM_ACCOUNT_CREATE: "iam_account_create",
    IAM_ACCOUNT_READ: "iam_account_read",
    IAM_ACCOUNT_UPDATE: "iam_account_update",
    IAM_ACCOUNT_DELETE: "iam_account_delete",
    MEETING_ROOM_CREATE: "meeting_room_create",
    MEETING_ROOM_READ: "meeting_room_read",
    MEETING_ROOM_UPDATE: "meeting_room_update",
    MEETING_ROOM_DELETE: "meeting_room_delete",
    ROOM_BOOKING_READ:"room_booking_read",
    ROOM_BOOKING_DELETE:"room_booking_delete",
    ROOM_BOOKING_CREATE:"book_room",
    VIEW_BOOKING_HISTORY:"view_booking_history"
}

const FILE_EXTENSIONS = {
  EXCEL: ["xlsx", "xls"],
  WORD: ["doc", "docx"],
}

export { MEDIA_IMAGE_URL, NOTIFICATION_TYPE, TYPE_REFRESH_DATA, PERMISSIONS, FILE_EXTENSIONS }