import { uploadImageAction } from "@Actions/Media/action";
import {
  Div,
  Img
} from "@Components/common/TagAntdNotSupport/TagAntdNotSupport";
import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import { useDispatch } from "react-redux";
import "./styles.scss";
import UploadIcon from "@Assets/icons/event/UploadIcon.svg";
import { IconButton, CircularProgress } from "@material-ui/core";
import ErrorIcon from '@material-ui/icons/Error';
import CropImage from "../CropImage";
import getCroppedImg from "../CropImage/Crop";

var FormData = require("form-data");

function UploadSingleImageControl(props) {
  const { onChange, imageData, onChangeLoading, loading } = props;
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [imageCrop, setImageCrop] = useState(null);
  const [error,setError] = useState(false)
  const [ShowCrop , setShowCrop] = useState(false)

  
  useEffect(() => {
    if (imageData!=="") setImage(imageData)
  }, [imageData]);


  const onChangeImage = (imageList, addUpdateIndex) => {
    // handleInputImage(imageList)
    const reader = new FileReader()
    reader.onload = (e) => {
      setImageCrop(e.target.result)
    }
    reader.readAsDataURL(imageList[0].file)
    setShowCrop(true)
  };

  const cancelCrop = () => {
    setShowCrop(false)
  }

  const finishCrop = async (photoDetail) => {
    try {
      const croppedImage = await getCroppedImg(
        imageCrop,
        photoDetail,
      )
      setShowCrop(false)
      let dataForm = new FormData();
      dataForm.append("photo", croppedImage);

      const callbackSuccess = (imageID) => {
        onChange(imageID);
        setError(false)
      }
      const callbackError = () => {
        setError(true)
      }
      dispatch(uploadImageAction(dataForm, callbackSuccess, callbackError));
      onChangeLoading()
    } catch (e) {
      console.error(e)
    }
    
  }


  return (
    <React.Fragment>
      {
        ShowCrop &&
        <CropImage 
        image={imageCrop }
        onCancel={cancelCrop}
        onFinish={finishCrop}
        />
        }
    <ImageUploading
      value={image}
      onChange={onChangeImage}
      dataURLKey="data_url"
    >
      {({ imageList, onImageUpload, onImageUpdate, isDragging, dragProps }) => (
        <Div className="rc-upload-single-image">
          {imageList.length === 0 && !image ? (
            <Div
              className="rc-upload-single-image-add-item"
              // onClick={onImageUpload}
              {...dragProps}
            >
              {
                loading 
                ? (
                    error 
                    ? <Div className="rc-upload-single-image-main-item">
                        <ErrorIcon color="error" style={{width:40,height:"auto"}}/>
                        <Div className="rc-upload-single-image-control-edit">
                          <IconButton 
                            onClick={() => {
                              onImageUpdate(0);
                              setError(false);
                              onChangeLoading()
                            }} 
                            className="btn-upload"
                          >
                            <img src={UploadIcon} alt='UploadIcon' />
                            Tải ảnh lên
                          </IconButton>
                        </Div>
                      </Div>
                    : <CircularProgress />
                    )
                : <Div className="rc-upload-single-image-control-edit">
                    <IconButton 
                      onClick={() => onImageUpdate(0)} 
                      className="btn-upload"
                    >
                      <img src={UploadIcon} alt='UploadIcon' />
                      Tải ảnh lên
                    </IconButton>
                  </Div>
              }
              
            </Div>
          ) : image ? (
            <Div className="rc-upload-single-image-main-item">
              {
                loading 
                ? (
                    error 
                    ? <ErrorIcon color="error" style={{width:40,height:"auto"}}/>
                    : <CircularProgress />
                    )
                : <Img
                    src={image || imageList[0]?.data_url}
                    height="100%"
                    width="100%"
                  />
              }
              
              <Div className="rc-upload-single-image-control-edit">
                <IconButton 
                  onClick={() => onImageUpdate(0)} 
                  className="btn-upload"
                >
                  <img src={UploadIcon} alt='UploadIcon' />
                  Tải ảnh lên
                </IconButton>
              </Div>
            </Div>
          ) : (
            imageList?.map((image, index) => (
              <Div key={index} className="rc-upload-single-image-main-item">
                <Img src={image?.data_url} alt="" width="100%" />
                <Div className="rc-upload-single-image-control-edit">
                  <IconButton onClick={() => onImageUpdate(index)}>
                    <img src={UploadIcon} alt='UploadIcon' />
                  </IconButton>
                </Div>
              </Div>
            ))
          )}
        </Div>
      )}
    </ImageUploading>
    </React.Fragment>
  );
}

export default UploadSingleImageControl;
