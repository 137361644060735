//Layout
import DefaultLayout from "@Layouts/DefaultLayout/DefaultLayout";
import EmptyLayout from "@Layouts/EmptyLayout/EmptyLayout";
//Common Page
import Enterprise from "@Pages/Enterprise/Enterprise";
import AddEvent from "@Pages/Event/AddEvent/AddEvent";
import Event from "@Pages/Event/Event";
import ViewAllEvent from "@Pages/Event/ViewAllEvent/ViewAllEvent";
import ForgotPassword from "@Pages/ForgotPassword/ForgotPassword";
import { default as About, default as Home } from "@Pages/Home/Home";
import Login from "@Pages/Login/Login";
import PageNotFound from "@Pages/PageNotFound/PageNotFound";
import Profile from "@Pages/Profile/Profile";
import Setting from "@Pages/Setting/Setting";
import Register from "@Pages/Register/Register";
import ResetPassword from "@Pages/ResetPassword/ResetPassword";
// import User from "@Pages/User/User";
import UserEvent from "@Pages/User/UserEvent/UserEvent";
// import EventDetail from "@Pages/Event/EventDetail/EventDetail";
import EventDetailUser from "@Pages/Event/EventDetailUser/EventDetailUser";
import ShareAllEvent from "@Pages/Event/ShareAllEvent/ShareAllEvent";
import CheckInEvent from "@Pages/Event/CheckInEvent/CheckInEvent";
import EmbedEvent from "@Pages/Event/EmbedEvent/EmbedEvent";
import ModelRegister from "@Pages/Event/AddEvent/Model-register";
import DragRegister from "@Pages/Event/AddEvent/DragRegister";
import CheckInGoogle from "@Pages/Event/CheckInEvent/CheckInGoogle";

export default [
  {
    label: "Register",
    path: "/register",
    exact: true,
    isPrivate: false,
    authenticatedRedirect: true, //Redirect to Home when login success
    page: Register,
    layout: EmptyLayout,
  },
  {
    label: "Login",
    path: "/login",
    exact: true,
    isPrivate: false,
    authenticatedRedirect: true, //Redirect to Home when login success
    page: Login,
    layout: EmptyLayout,
  },
  {
    label: "",
    path: "/",
    exact: true,
    isPrivate: true,
    authenticatedRedirect: false, //Redirect to Home when login success
    page: About,
    layout: DefaultLayout,
  },
  {
    label: "Forgot Password",
    path: "/forgot-password",
    exact: true,
    isPrivate: false,
    authenticatedRedirect: true, //Redirect to Home when login success
    page: ForgotPassword,
    layout: EmptyLayout,
  },
  {
    label: "Reset Password",
    path: "/reset-password",
    exact: true,
    isPrivate: false,
    authenticatedRedirect: true, //Redirect to Home when login success
    page: ResetPassword,
    layout: EmptyLayout,
  },

  {
    label: "Profile ",
    path: "/profile",
    exact: true,
    isPrivate: true,
    authenticatedRedirect: false, //Redirect to Home when login success
    page: Profile,
    layout: DefaultLayout,
  },

  {
    label: "Add Enterprise Event",
    path: "/enterprise-events/add",
    exact: true,
    authenticatedRedirect: true,
    isPrivate: true,
    page: Enterprise,
    layout: EmptyLayout,
  },

  {
    label: "View all event",
    path: "/:subdomain/event/all",
    exact: true,
    authenticatedRedirect: true,
    isPrivate: true,
    page: ViewAllEvent,
    layout: DefaultLayout,
  },

  // Calendar
  // {
  //   label: "Booking Room Meeting",
  //   path: "/:subdomain/event",
  //   exact: true,
  //   authenticatedRedirect: true, //Redirect to Home when login success
  //   isPrivate: true,
  //   page: BookingRoomMeeting,
  //   layout: DefaultLayout,
  // },

  // Event
  {
    label: "Event",
    path: "/:subdomain/event",
    exact: true,
    authenticatedRedirect: true,
    isPrivate: true,
    page: Event,
    layout: DefaultLayout,
  },

  {
    label: "Add event",
    path: "/:subdomain/event/add",
    exact: true,
    authenticatedRedirect: true,
    isPrivate: true,
    page: AddEvent,
    layout: EmptyLayout,
  },

  {
    label: "UserEvent",
    path: "/:subdomain/event/user",
    exact: true,
    authenticatedRedirect: true, //Redirect to Home when login success
    isPrivate: true,
    page: UserEvent,
    layout: DefaultLayout,
  },

  {
    label: "Add event",
    path: "/:subdomain/event/:id",
    exact: true,
    authenticatedRedirect: true,
    isPrivate: true,
    page: AddEvent,
    layout: EmptyLayout,
  },
  //share event
  {
    label: "Event detail for user",
    path: "/:subdomain/eventforuser/:id",
    exact: true,
    authenticatedRedirect: false,
    isPrivate: false,
    page: EventDetailUser,
    layout: EmptyLayout,
  },
  //share all event
  {
    label: "Event detail for user",
    path: "/:subdomain/all-event",
    exact: true,
    authenticatedRedirect: false,
    isPrivate: false,
    page: ShareAllEvent,
    layout: EmptyLayout,
  },
  //view event
  {
    label: "Event detail for user",
    path: "/:subdomain/view-event/:id",
    exact: true,
    authenticatedRedirect: true,
    isPrivate: true,
    page: EventDetailUser,
    layout: EmptyLayout,
  },
  //CheckIn event
  {
    label: "Event detail for user",
    path: "/:subdomain/checkin-event/:id",
    exact: true,
    authenticatedRedirect: false,
    isPrivate: false,
    page: CheckInEvent,
    layout: EmptyLayout,
  },
  //CheckIn event google meet
  {
    label: "Event detail for user",
    path: "/:subdomain/checkin-event/:id",
    exact: true,
    authenticatedRedirect: false,
    isPrivate: false,
    page: CheckInGoogle,
    layout: EmptyLayout,
  },
  //embed event
  {
    label: "Event detail for user",
    path: "/:subdomain/event/embed/:id",
    exact: true,
    authenticatedRedirect: true,
    isPrivate: true,
    page: EmbedEvent,
    layout: EmptyLayout,
  },
  //create model register for event
  {
    label: "Modal Register",
    path: "/:subdomain/event/:id/model-register",
    exact: true,
    authenticatedRedirect: true,
    isPrivate: true,
    page: ModelRegister,
    layout: EmptyLayout,
  },
  // Drag item for model register on mobile
  {
    label: "Drag Register",
    path: "/:subdomain/event/:id/drag-register",
    exact: true,
    authenticatedRedirect: true,
    isPrivate: true,
    page: DragRegister,
    layout: EmptyLayout,
  },

  // {
  //   label: "Participant list",
  //   path: "/:subdomain/event/:id/participant",
  //   exact: true,
  //   authenticatedRedirect: true,
  //   isPrivate: true,
  //   page: ParticipantList,
  //   layout: DefaultLayout,
  // },

  // User
  // {
  //   label: "User",
  //   path: "/user",
  //   exact: true,
  //   authenticatedRedirect: true, //Redirect to Home when login success
  //   isPrivate: true,
  //   page: User,
  //   layout: DefaultLayout,
  // },

  {
    label: "Setting",
    path: "/:subdomain/setting",
    exact: true,
    authenticatedRedirect: true, //Redirect to Home when login success
    isPrivate: true,
    page: Setting,
    layout: DefaultLayout,
  },

  //share event with short link
  {
    label: "Event detail for user",
    path: "/:subdomain/:id",
    exact: true,
    authenticatedRedirect: false,
    isPrivate: false,
    page: EventDetailUser,
    layout: EmptyLayout,
  },

  {
    label: "Home",
    path: "/home",
    exact: true,
    authenticatedRedirect: true, //Redirect to Home when login success
    isPrivate: true,
    page: Home,
    layout: DefaultLayout,
  },

  {
    label: "Page Not Found",
    path: "/",
    exact: false,
    authenticatedRedirect: false, //Redirect to Home when login success
    isPrivate: false,
    page: PageNotFound,
    layout: EmptyLayout,
  },
];
