import { ErrorMessage } from "@hookform/error-message";
import {
  Button,
  Grid,
  Grow,
  InputAdornment,
  TextField,
  Modal,
  Paper,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EmailOutlined from "@material-ui/icons/EmailOutlined";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import "./style.scss";
import { addUserAction } from "@Actions/User/action";

const { List } = require("immutable");

ModalAddUser.propTypes = {
  roles: PropTypes.array,
  triggerOpenModalAddUser: PropTypes.func,
};

function ModalAddUser(props) {
  const { roles, triggerOpenModalAddUser } = props;
  const dispatch = useDispatch();

  const rolesDefault = roles?.[0];
  const [dataSubmit, setDataSubmit] = useState(
    List([
      {
        email: "",
        role: rolesDefault?.name,
      },
    ])
  );

  const { register, handleSubmit, errors } = useForm({});

  // handle change email
  const handleChangeEmail = (event, indexArg) => {
    const inputText = event?.target?.value;
    const newDataSubmit = List(dataSubmit);
    const newMapDataSubmit = newDataSubmit?.map((element, index) => {
      return index === indexArg ? { ...element, email: inputText } : element;
    });
    const isEmptyEmail = newMapDataSubmit?.find((element) => {
      return element?.email === "";
    });

    if (!isEmptyEmail) {
      const newPushDataSubmit = newMapDataSubmit?.push({
        email: "",
        role: rolesDefault?.name,
      });
      setDataSubmit(newPushDataSubmit);
    } else if (inputText === "") {
      const newRemoveDataSubmit = newMapDataSubmit?.filter((element, index) => {
        return !(element?.email === "" && indexArg !== index);
      });
      setDataSubmit(newRemoveDataSubmit);
    } else {
      setDataSubmit(newMapDataSubmit);
    }
  };

  // handle form
  const handleSubmitForm = (values) => {
    const callbackSuccess = () => {
      triggerOpenModalAddUser();
    };
    const callbackError = () => {};
    const dataSubmitArray = dataSubmit
      ?.toIndexedSeq()
      ?.toArray()
      .filter((element) => {
        return element?.email !== "";
      });
    dispatch(addUserAction(dataSubmitArray, callbackSuccess, callbackError));
  };

  return (
    <Modal
      open={true}
      onClose={triggerOpenModalAddUser}
      className='modal-paper-add-user'
    >
      <Paper className="modal-content-user">
        <h5 className="title-header">
          Thêm thành viên
        </h5>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Grid
            container
            direction='column'
            alignItems='center'
            // className='rc-modal-add-user-main-form'
          >
            {dataSubmit?.map((element, index) => {
              return (
                <Grow in={true} timeout={500} key={index}>
                  <Grid
                    item
                    container
                    xs={12}
                    direction='column'
                    className='rc-modal-add-user-main-form-item'
                  >
                    <Grid
                      item
                      container
                      justifyContent='space-between'
                      alignItems='center'
                      xs={12}
                    >
                      <TextField
                        className='rc-modal-add-user-main-form-item-text_field'
                        name='email'
                        value={element?.email}
                        label='Nhập email'
                        onChange={(e) => handleChangeEmail(e, index)}
                        inputRef={register}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <EmailOutlined color='disabled' />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <ErrorMessage
                      errors={errors}
                      name='email'
                      render={({ message }) => (
                        <p className={"error-message"}>{message}</p>
                      )}
                    />
                  </Grid>
                </Grow>
              );
            })}
            <Grid
              item
              container
              justifyContent='flex-end'
              xs={12}
              className='rc-modal-add-user-main-form-item'
              style={{ marginTop: "3rem" }}
              spacing={2}
            >
              <Grid item className="group-btn-action">
                <Button
                  variant='contained'
                  color='default'
                  style={{ marginRight: "1rem", color:"#5C5C5C" }}
                  onClick={triggerOpenModalAddUser}
                  className="group-btn-action btn-close"
                >
                  Đóng
                </Button>
              </Grid>
              <Grid item className="group-btn-action">
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={!(dataSubmit?.size - 1)}
                  endIcon={<AddIcon />}
                  className="group-btn-action btn-add"
                >
                  Thêm {dataSubmit?.size - 1} thành viên
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Modal>
  );
}

export default ModalAddUser;
