import {
    IconButton,
    Dialog,
} from "@material-ui/core";
import React, { useCallback } from "react";
import "./style.scss";
import CloseIcon from "@Assets/icons/event/closeIcon.svg";
import UserBlueIcon from "@Assets/icons/event/user.svg";
import PhoneBlueIcon from "@Assets/icons/event/Phone.svg";
import GmailBlueIcon from "@Assets/icons/event/Gmail.svg";
import ConnectIcon from "@Assets/icons/event/Connect.svg";
import noteBlueIcon from "@Assets/icons/event/nodeBlack.svg";
import ParagraphBlueIcon from "@Assets/icons/event/Paragraph.svg";
import StarBlueIcon from "@Assets/icons/event/star.svg";
import "./style.scss";

const DialogDataRegister = (props: any) => {
    const { handleClose, open, data } = props

    const renderData = useCallback(()=>{
        return ["name", "phone", "email", "participationForm", "note", "addFormRegister"]?.map((key:any, idx:any)=>{
            switch(key){
                case "name":
                    return data?.name && <div key={idx} className='field' style={{ alignItems: "flex-start" }}>
                    <img src={UserBlueIcon} alt='' />
                    {/* <span className="label">Họ và tên:</span> */}
                    <span>{data?.name}</span>
                </div>
                case "email":
                    return data?.email && <div className='field' key={idx} style={{ alignItems: "flex-start" }}>
                    <img src={GmailBlueIcon} alt='' />
                    {/* <span className="label">Email:</span> */}
                    <span>{data?.email}</span>
                </div>
                case "phone":
                    return data?.phone && <div className='field' key={idx} style={{ alignItems: "flex-start" }}>
                    <img src={PhoneBlueIcon} alt='' />
                    {/* <span className="label">Số điện thoại:</span> */}
                    <span>{data?.phone}</span>
                </div>
                case "participationForm":
                    return data?.participationForm && <div className='field' key={idx} style={{ alignItems: "flex-start" }}>
                    <img src={ConnectIcon} alt='' />
                    <span className="label">Hình thức tham gia:</span>
                    <span>{data?.participationForm}</span>
                </div>
                case "note":
                    return data?.note && <div className='field' key={idx} style={{ alignItems: "flex-start" }}>
                    <img src={noteBlueIcon} alt='' />
                    {/* <span className="label">Ghi chú:</span> */}
                    <span>{data?.note}</span>
                </div>
                case "addFormRegister":
                    return data?.addFormRegister && Object.keys(data[key] || {})?.map((k:any, i:any)=>{
                        return <>{
                            k?.includes("short-add") && <div className='field' style={{ alignItems: "flex-start" }} key={`item${i}-${idx}`}>
                                <img src={noteBlueIcon} alt='' />
                                <div>
                                <span style={{display: "block", marginRight:"15px"}} className="label">{data[key][k].title}</span>
                                <span>{data[key][k].data}</span>
                                </div>
                            </div>}
                            {
                                k?.includes("text-add") && <div className='field' style={{ alignItems: "flex-start" }} key={`item${i}-${idx}`}>
                                    <img src={ParagraphBlueIcon} alt='' />
                                    <div>
                                    <span style={{display: "block", marginRight:"15px"}} className="label">{data[key][k].title}</span>
                                    <span>{data[key][k].data}</span>
                                    </div>
                                </div>
                            }
                            {
                                k?.includes("radio-add") &&
                                <div className='field' style={{ alignItems: "flex-start" }} key={`item${i}-${idx}`}>
                                    <img src={StarBlueIcon} alt='' />
                                    <div>
                                    <span style={{display: "block", marginRight:"15px"}} className="label">{data[key][k].title}</span>
                                    <span>{data[key][k].data}</span>
                                    </div>
                                </div>
                            }

                            {
                                k?.includes("check-box-add") &&
                                <div className='field' style={{ alignItems: "flex-start" }} key={`item${i}-${idx}`}>
                                    <img src={StarBlueIcon} alt='' />
                                    <div>
                                    <span style={{display: "block", marginRight:"15px"}} className="label">{data[key][k].title}</span>
                                    <ul>{data[key][k].data.map((vl:any, number:any)=>
                                    <li key={`item${i}-${idx}-${number}`}><span>{vl}</span></li>
                                    )}</ul>
                                    </div>
                                </div>
                            }
                        </>
                    })
                default:
                    break;
            }
        })
    },[data])

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            maxWidth='sm'
            fullWidth
            scroll="body"
        classes={{ paper: "dialogPaperData",
    container: "dialogContainerData" }}
        >
            <div className='content'>
                <div className="close-fixhead">
                    <IconButton
                        onClick={handleClose}
                        className="exit-btn"
                    >
                        <img src={CloseIcon} alt={CloseIcon} />
                    </IconButton>
                </div>
                <div className='content__title'>Chi tiết khách mời</div>
                <div className='form' >
                    {renderData()}
                </div>
            </div>
        </Dialog>
    );
};

export default DialogDataRegister;