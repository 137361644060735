import { IAction } from "@Interfaces/common";
import ActionType from "@Actions/Account/action-types";
import { fromJS } from "immutable";

const initialState = fromJS({
  type: null,
  payload: null,
  error: null,
  isLoading: false,
  linkAccount: null,
});
export default (state: any = initialState, action: IAction) => {
  switch (action.type) {
    case ActionType.GET_ACCOUNT_START:
      return state
        .set("type", fromJS(action.type))
        .set("isLoading", true)
        .set("error", null);
    case ActionType.GET_ACCOUNT_SUCCESS:
      return state
        .set("type", fromJS(action.type))
        .set("isLoading", false)
        .set("payload", fromJS(action.payload))
        .set("linkAccount", fromJS(action.payload))
        .set("error", null);
    case ActionType.GET_ACCOUNT_ERROR:
      return state
        .set("type", fromJS(action.type))
        .set("isLoading", false)
        .set("isFetched", false)
        .set("error", fromJS(action.error));
    default:
      return state;
  }
};
