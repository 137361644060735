import { call, put, takeLatest } from "redux-saga/effects";
import baseApi from "@Apis/Register/api";
import {
  registerErrorAction,
  registerSuccessAction,
} from "@Actions/Register/action";
import { notificationOpenAction } from "@Actions/Notification/action";
import actionTypes from "@Actions/Register/action-types";
import { NOTIFICATION_TYPE } from "@Constants/common";

export function* register({
  reCaptchaToken,
  credentials,
  callbackSuccess,
  callbackError,
}: any): any {
  try {
    const result = yield call(
      baseApi.registerUser,
      reCaptchaToken,
      credentials
    );
    // result = return status call API
    // console.log(result);

    yield put(registerSuccessAction(result));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.SUCCESS as "success",
        message: "Thành Công",
        description: "Đăng kí tài khoản thành công ! ",
      })
    );
    yield callbackSuccess();
  } catch (err) {
    console.log(err);
    // handle error annotation
    let temp = err as any;
    yield put(registerErrorAction(err));
    yield put(
      notificationOpenAction({
        isNotification: true,
        typeNotification: NOTIFICATION_TYPE.ERROR as "error",
        message: "Lỗi",
        description: temp?.response?.data?.message || "Lỗi chưa xác định",
      })
    );
    yield callbackError();
  }
}

export default function* registerSaga() {
  yield takeLatest(actionTypes?.REGISTER_START, register);
}
