import { notificationOpenAction } from "@Actions/Notification/action";
import {
    getUserNotificationListSuccessAction,
    getUserNotificationListErrorAction,
    markAsReadErrorAction,
    markAsReadSuccessAction,
    removeNotificationSuccessAction,
    removeNotificationErrorAction,
} from "@Actions/UserNotification/action";
import actionTypes from "@Actions/UserNotification/action-types";
import baseApi from "@Apis/UserNotification/api";
import { NOTIFICATION_TYPE } from "@Constants/common";
import { call, put, takeLatest } from "redux-saga/effects";
//FETCH
export function* getUserNotificationList({ params, callbackSuccess = () => { } }: any): any {
    try {
        const result = yield call(baseApi.getUserNotificationList, params);
        yield put(getUserNotificationListSuccessAction(result));
        yield callbackSuccess(result)
    } catch (err : any) {
        yield put(getUserNotificationListErrorAction(err));
        yield put(notificationOpenAction({
            isNotification: true,
            typeNotification: NOTIFICATION_TYPE.ERROR as "error",
            message: "Lỗi",
            description: err?.response?.data?.message || "Lỗi chưa xác định",

        }));
    }
}
export function* markAsRead({ id, callbackSuccess = () => { } }: any): any {
    try {
        const result = yield call(baseApi.markAsRead, id);
        yield put(markAsReadSuccessAction(result));
        yield callbackSuccess(result)
    } catch (err : any) {
        yield put(markAsReadErrorAction(err));
        yield put(notificationOpenAction({
            isNotification: true,
            typeNotification: NOTIFICATION_TYPE.ERROR as "error",
            message: "Lỗi",
            description: err?.response?.data?.message || "Lỗi chưa xác định",

        }));
    }
}

export function* removeNotification({ id, callbackSuccess = () => { } }: any): any {
    try {
        const result = yield call(baseApi.removeNotification, id);
        yield put(removeNotificationSuccessAction(result));
        yield callbackSuccess(result)
    } catch (err : any) {
        yield put(removeNotificationErrorAction(err));
        yield put(notificationOpenAction({
            isNotification: true,
            typeNotification: NOTIFICATION_TYPE.ERROR as "error",
            message: "Lỗi",
            description: err?.response?.data?.message || "Lỗi chưa xác định",

        }));
    }
}



export default function* saga() {
    yield takeLatest(actionTypes?.GET_USER_NOTIFICATION_LIST_START, getUserNotificationList);
    yield takeLatest(actionTypes?.MARK_AS_READ_START, markAsRead);
    yield takeLatest(actionTypes?.REMOVE_NOTIFICATION_START, removeNotification);

}
