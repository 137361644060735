import calendar from "@Assets/icons/event/timeIcon.svg";
import noteIcon3 from "@Assets/icons/event/TextIcon.svg";
import ImageUploading from "@Components/common/UploadSingleImageControl/UploadSingleImageControl";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  Modal,
  InputAdornment,
  Switch,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form"
import { useHistory } from "react-router";
import * as yup from "yup";
import "./style.scss";
import planIcon from "@Assets/icons/event/plan.svg";
import AddIcon from "@material-ui/icons/Add";
import { Tab, Tabs } from '@material-ui/core';
import { ENTERPRISE_URL } from "@Constants/localStorage";
import FormatIcon from "@Assets/icons/event/format.svg";
import ModalFormRegister from "@Components/Event/ModalFormRegister/ModalFormRegister";
import { generatorMediaURL } from "@Utils/getUrlImages";
import baseApi from "@Apis/Event/api";
import PlaceIcon from "@Assets/icons/event/place.svg";
import { useDispatch, useSelector } from "react-redux";
import * as action from "@Actions/Event/action";
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import CloseIcon from "@Assets/icons/event/closeIcon.svg";
import GoogleMeetImageIcon from "@Assets/icons/event/Google_Meet-Logo.wine.svg";
// import MicrosoftTeamIcon from "@Assets/icons/event/ms-teams-icon.svg";
import {
  MobileDatePicker,
  LocalizationProvider,
  MobileTimePicker
} from "@material-ui/pickers";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import AdapterDateFns from "@material-ui/pickers/adapter/date-fns";
import ListUser from "./ListUser";
import ProvinceUI from "@Components/common/AddressPickerMaterial"
import homeIcon from "@Assets/icons/event/Home.svg";
import FormIcon from "@Assets/icons/event/FormIcon.svg";
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CircleOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import axios from "axios";
import DetailDescription from "./DetailDescription";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import { GOOGLE_CLIENT_ID, GOOGLE_REDIRECT_URL, GOOGLE_SCOPES } from "../../../envs";
import baseApiLogin from "@Apis/Login/api";
import arrowDownIcon from "@Assets/icons/event/arrowDownGray.svg";
import deleteGrayIcon from "@Assets/icons/event/deleteGray.svg";
import UserBlueIcon from "@Assets/icons/event/UserBlue.svg";
import PhoneBlueIcon from "@Assets/icons/event/PhoneBlue.svg";
import GmailBlueIcon from "@Assets/icons/event/GmailBlue.svg";
import noteBlueIcon from "@Assets/icons/event/noteBlue.svg";
import addressBlueIcon from "@Assets/icons/event/addressBlue.svg";
import {ReactComponent as ShareIcon } from "@Assets/icons/event/ShareIcon.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import dragIcon from "@Assets/icons/event/dragIcon.svg";
import moment from "moment";
import InviteModal from "@Components/Event/InviteModal";
import InviteSuccessModal from "@Components/Event/InviteSuccessModal";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`listuser-tabpanel-${index}`}
      aria-labelledby={`listuser-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3} className="tabpanel-content">{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

AddEvent.propTypes = {};

function AddEvent(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [originData,setOriginData]:any = useState({})
  const [newData,setNewData]:any = useState({})
  const [disableBtn, setDisableBtn]: any = useState(true)
  const [inviteModal, setInviteModal]: any = useState(false)
  const [uploadListUser, setUploadListUser]: any = useState(false)
  const [inviteSuccessModal, setInviteSuccessModal]: any = useState(false)
  const [isChange,setIsChange]: any = useState(true)
  const [imageID, setImageID]: any = useState("");
  const [imageQr, setImageQr]: any = useState("");
  const dateTime = new Date();
  const getDate = dateTime.toISOString();
  const [tab, setTab] = useState(0)
  const [listUser, setListUser]: any = useState({})
  const [refData, setRefData]: any = useState(false)
  const [isEmptyTitle, setIsEmptyTitle]: any = useState(false)
  const [errorPage,setErrorPage]: any = useState(false)
  const [disableShare,setDisableShare]: any = useState(true)
  const [formRegister, setFormRegister]: any = useState(
    [
      {
        required: true,
        type: "text",
        title: "Họ và tên",
        name: "name",
        format: "text",
        img: UserBlueIcon
      },
      {
        required: true,
        type: "number",
        title: "Số điện thoại",
        name: "phone",
        format: "text",
        img: PhoneBlueIcon
      },
      {
        required: true,
        type: "email",
        title: "Email",
        name: "email",
        format: "text",
        img: GmailBlueIcon
      },
      {
        required: false,
        type: "text",
        title: "Địa chỉ",
        name: "address",
        format: "text",
        img: addressBlueIcon
      },
      {
        required: false,
        type: "text",
        title: "Ghi chú",
        name: "note",
        format: "text",
        img: noteBlueIcon,
        multiline: true
      }
    ]
  )
  const [openAddress, setOpenAddress]: any = useState(true)
  const [isEndTime, setIsEndTime]: any = useState(false)
  const [loading, setLoading] = useState(false)
  const [openModalAddress, setOpenModalAddress]: any = useState(false)
  const [address, setAddress]: any = useState({ province: "79", district: "785", address: "" })
  const [notConnected, setNotConnected] = useState(false);
  const [inforLinkAccount, setInforLinkAccount]: any = useState({})
  const payload: any = useSelector((state: any) => state.loginReducer?.get('payload').toJS())
  const [listShedule, setListShedule]: any = useState([])
  const [updateSchedule, setUpdateSchedule]: any = useState(false)
  const isSave: any = React.useRef(true)
  const typeSubmitRef: any = React.useRef("")
  const [errorDescription, setErrorDescription]: any = useState([])
  const validate: any = {
    eventTitle: yup.string()
      .required("Vui lòng nhập tên sự kiện")
      .max(200, "Vui lòng nhập tối đa 200 ký tự"), 
    note: yup.string()
      .max(300, "Vui lòng nhập tối đa 300 ký tự"),
  }

  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 600px)").matches
  )

  useEffect(() => {
    window
    .matchMedia("(max-width: 600px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);

  if (openAddress) {
    validate.address = yup.string().required("Vui lòng nhập địa chỉ")
  }

  const schema = yup.object().shape(validate);

  const { handleSubmit, reset, setValue, watch, getValues, control, formState: { errors, isDirty, dirtyFields } } = useForm({
    mode: "onChange",
    defaultValues: {
      eventTitle: '',
      startTime: moment(getDate).format("HH:mm"),
      endTime: moment(getDate).format("HH:mm"),
      startDate: getDate,
      endDate: getDate,
      isOnline: "off",
      note: '',
      address: '',
      enableEndTime: false,
      requiredRegister: formRegister,
      onlineItems: "google"
    },
    resolver: yupResolver(schema),
    shouldUnregister: false
  })

  const [openModalRegister, setOpenModalRegister] = useState(false);

  const onChangeImage = (imageID: any) => {
    setImageID(generatorMediaURL(imageID));
    setLoading(false)
  };

  const onChangeLoading = () => {
    setLoading(!loading)
  }

  const id = props.match.params.id

  //function sum start time and end date
  const changeDateTime = async (date: any, times: any) => {
    try {
      const days = new Date(date)
      const dateTime = times.split(":")
      let sumDateTime = days.setHours(parseInt(dateTime[0]), parseInt(dateTime[1]))
      let convertTime = new Date(sumDateTime).toISOString()
      return convertTime
    } catch (error) {
      console.log("convertime error",error)
      return false
    }
  }

  const callbackSuccess = async (data: any) => {
    // await baseApi.sendEmailCreateEvent({ data: data.result, email: inforLinkAccount.email })
    // localStorage.removeItem("FORM_ADD_EVENT")
    // localStorage.removeItem("FORM_REGISTER_EVENT")
    isSave.current = true
    history.push(`/${localStorage.getItem(ENTERPRISE_URL)}/event`)
  };
  const callbackError = (err: any) => {
    isSave.current = true
    if (err?.response?.data?.code === "ACCOUNT_NOT_CONNECTED") {
      setNotConnected(true);
    }
  };

  const hostName = window?.location?.origin
  const eventUrl = localStorage.getItem(ENTERPRISE_URL) || ""

  const createQrCode = async () => {
    const link = hostName + "/" + eventUrl + "/" + id
    try {
      const result: any = await baseApi.createQrCodeEvent(link)
      setImageQr(result.qrImage)
    } catch (error) {
      setImageQr("")
      console.log("err create QR code", error);
      let i = 0
      while (i < 2) {
        try {
          const result: any = await baseApi.createQrCodeEvent(link)
          setImageQr(result.qrImage)
          i = 3;
          break;
        } catch (error) {
          i++;
        }
      }
      if (i !== 3) history.push(`/${localStorage.getItem(ENTERPRISE_URL)}/event`)
    }
  }

  const onSubmit = async (values: any) => {
    if (errorDescription.length > 0) return

    values.image = imageID
    values.qrCode = imageQr
    // values.address = address
    values.address = {address:values.address}
    values.accountId = inforLinkAccount.id
    values.requiredRegister = formRegister
    values.email = inforLinkAccount?.email || ""
    //handle time
    values.startDateTime = await changeDateTime(values.startDate, values.startTime)
    if(!values.startDateTime) return
    if (isEndTime) {
      values.endDateTime = await changeDateTime(values.endDate, values.endTime)
      if(!values.endDateTime) return
    }
    //handle add time schedule detail
    if (listShedule.length > 0) {
      let eventSchedules = []
      for (let i in listShedule) {
        const tm = await changeDateTime(listShedule[i].endDateTime, listShedule[i].endTime)
        eventSchedules.push({ endDateTime: tm, note: listShedule[i].note })
      }
      values.eventSchedules = eventSchedules
    }
    else {
      values.eventSchedules = []
    }

    // const { startTime, endTime, startDate, endDate, ...values111 } = values
    if (imageQr !== "") {
      if (typeSubmitRef.current === "publish") values.isPublish = true
      const links = hostName + "/" + eventUrl + "/checkin-event/" + id;
      values.links = links;
      if(isSave.current){
        isSave.current = false;
        dispatch(action.updateEventAction(values, id, callbackSuccess, callbackError));
      }
    } 
  }

  useEffect(()=>{  
    if(watch("eventTitle") || isEmptyTitle){
      localStorage.setItem("FORM_ADD_EVENT", JSON.stringify({
        ...watch(), 
        "qrCode": imageQr,
        "image" : imageID,
        "address":{
          ...address,
        },
        "startDateTime": watch("startDate")?watch("startDate"):'',
        ...listUser,
        "eventSchedules": listShedule,
        "enableEndTime": isEndTime,
        "endDateTime": watch("endDate")?watch("endDate"):'',
      }));
    }
    if(originData?.eventTitle!==""){
      let dem = 0
      const fieldChange = Object.keys(dirtyFields)
      if(originData?.image !== imageID) dem = 1

      if(fieldChange.length>0 && dem === 0){
        for(let i in fieldChange){
          switch (fieldChange[i]) {
            case "note":
              if(originData?.note !== getValues("note")) dem = 1
              break;
            case "eventTitle":
              if(originData?.eventTitle !== getValues("eventTitle")) dem = 1
              break;
            case "enableEndTime":
              if(originData?.enableEndTime !== getValues("enableEndTime")) dem = 1
              break;
            case "isOnline":
              if(originData?.isOnline !== getValues("isOnline")) dem = 1
              break;
            case "onlineItems":
              if(originData?.isOnline==="on"){
                if(originData?.onlineItems !== getValues("onlineItems")) dem = 1
              }
              break;
            case "startTime":
              if(getValues("startTime") !== moment(originData?.startDateTime).format('HH:mm')) dem = 1
              break;
            case "startDate":
              if((new Date(getValues("startDate")).toISOString()).split("T")[0] !== originData?.startDateTime.split("T")[0]) dem = 1
              break;
            case "endTime":
              if(originData?.enableEndTime){
                if(getValues("endTime")){
                  if(getValues("endTime") !== moment(originData?.endDateTime).format('HH:mm')) dem = 1
                }
              }
              break;
            case "endDate":
              if(originData?.enableEndTime){
                if(getValues("endDate")){
                  if((new Date(getValues("endDate")).toISOString()).split("T")[0] !== originData?.endDateTime.split("T")[0]) dem = 1
                }
              }
              break;
            default:
              break;
          }
          if(dem !== 0 ) break;
        }
      }else{
        if(dem===0 && Object.keys(newData)?.length>0){
          const dataChanges:any=[
            "note",
            "eventTitle",
            "enableEndTime",
            "isOnline",
            "onlineItems",
            "startTime",
            "startDate",
            "endTime",
            "endDate"
          ]

          for(let i in dataChanges){
            if(["note","eventTitle","enableEndTime","isOnline"].includes(dataChanges[i])){
              if(originData[dataChanges[i]]!==newData[dataChanges[i]]){
                dem = 1
                break;
              }
            }else{
              if(dataChanges[i]==="startTime"){
                const startTime = newData?.startTime ? newData?.startTime : moment(newData?.startDateTime).format('HH:mm')
                if(startTime !== moment(originData?.startDateTime).format('HH:mm')){
                  dem = 1
                  break;
                }
              }
              if(dataChanges[i]==="startDate"){
                const startDate = (newData?.startDate || newData?.startDateTime).split("T")[0]
                if(startDate !== originData?.startDateTime.split("T")[0]){
                  dem = 1
                  break;
                }
              }
              if(originData?.enableEndTime === newData?.enableEndTime){
                if(originData?.enableEndTime){
                  if(dataChanges[i]==="endTime"){
                    const endTime = newData?.endTime ? newData?.endTime : moment(newData?.endDateTime).format('HH:mm')
                    if(endTime !== moment(originData?.endDateTime).format('HH:mm')){
                      dem = 1
                      break;
                    }
                  }
                  if(dataChanges[i]==="endDate"){
                    const endDate = (newData?.endDate || newData?.endDateTime).split("T")[0]
                    if(endDate !== originData?.endDateTime.split("T")[0]){
                      dem = 1
                      break;
                    }
                  }
                }
              }
            }
            
          }
        }
      }
  
      if(originData?.eventSchedules && dem ===0){
        if(originData?.eventSchedules?.length === listShedule?.length){
          for(let i in listShedule){
            if(listShedule[i]?.endTime !== moment(originData?.eventSchedules[i]?.endDateTime).format("HH:mm")){
              dem = 1
              break;
            }
            if(listShedule[i]?.endDateTime?.split("T")[0] !== originData?.eventSchedules[i]?.endDateTime.split("T")[0]){
              dem = 1 
              break;
            }
            if(listShedule[i]?.note !== originData?.eventSchedules[i]?.note){
              if(
                listShedule[i]?.note===originData?.eventSchedules[i]?.note ||
                (listShedule[i]?.note==="<p><br></p>" || listShedule[i]?.note==="") &&
                (originData?.eventSchedules[i]?.note==="<p><br></p>" || originData?.eventSchedules[i]?.note==="")
                ){
              }
              else{
                dem = 1 
                break;
              }

            }
          }
        }
        else dem =1
      }

      if(getValues("isOnline")!== "on" && dem === 0){
        if(originData?.address?.address !== address?.address) dem = 1
        if(originData?.address?.district !== address?.district) dem = 1
        if(originData?.address?.province !== address?.province) dem = 1
        if(originData?.address?.address !== getValues("address")) dem = 1
      }

      if(dem === 0){
        if(!disableBtn) setDisableBtn(true)
      }
      else{
        if(disableBtn) setDisableBtn(false)
      }
    }
    else{
      if(disableBtn) setDisableBtn(false)
    }
  })

  const fetchData = async () => {
    try {
      //get inforAccount
      const getInforAccount: any = await baseApiLogin.getProfile(payload.result?.token, payload?.result?.id)
      setInforLinkAccount({ id: getInforAccount.result._id, email: getInforAccount?.result?.linkedAccounts?.google?.profile?.email })
      //
      const { result }: any = await baseApi.getAddDetailEvent(id)
      if(!result?.eventTitle){
        setIsEmptyTitle(true);
      }else{
        setDisableShare(false)
      }
      setOriginData({...result,address:result.address})
      const { startDateTime, endDateTime } = result
      if (result?.qrCode && result?.qrCode !== "") {
        setImageQr(result?.qrCode)
      }
      else {
        createQrCode()
      }
      if (result?.isOnline === "off") setOpenAddress(true)
      if (result?.enableEndTime) {
        setIsEndTime(true)
        result.endDate = endDateTime
        result.endTime = moment(endDateTime).format('HH:mm')
      }
      else {
        delete result?.endDateTime
      }
      if (result?.eventSchedules && result?.eventSchedules?.length) {
        const dataSchedules = result?.eventSchedules
        let addDataSchedules = []
        for (let i in dataSchedules) {
          addDataSchedules.push({ ...dataSchedules[i], endTime: moment(dataSchedules[i].endDateTime).format('HH:mm') })
        }
        setListShedule(addDataSchedules)
      }
      setImageID(result?.image)
      result.startTime = moment(startDateTime).format('HH:mm')
      result.startDate = startDateTime
      if (Object.keys(result.address).length > 2) {
        setAddress(result.address)
      }
      result.address = result.address.address || ""
      reset(result)
      setListUser({ listdata: result.listUser, evenTitle: result?.eventTitle })
      setFormRegister(result?.requiredRegister || formRegister)      
    } catch (error) {
      const temp:any = error
      setDisableShare(true)
      console.log("error fetchData event detail", error);
      if(temp?.response?.data?.code ==="CAN_NOT_SEE_EVENT"){
        setErrorPage(true)
      }
    }
  }

  const handleTabChange = (event: any, tab: any) => {
    setTab(tab)
  }

  const refreshData = () => {
    setRefData(!refData)
  }

  //get data when reload page to compare
  const getDetailEvent = async()=>{
    const getData: any = await baseApi.getAddDetailEvent(id)
    if(getData?.result?.eventTitle && getData?.result?.eventTitle!==""){
      setOriginData({...getData?.result,address:getData?.result.address})
    }

    const result= JSON.parse(localStorage.getItem("FORM_ADD_EVENT") as any);
    if(result.eventTitle !== "" || !isEmptyTitle){
      setNewData(result)
      const { startDateTime, endDateTime } = result
      if (result?.qrCode && result?.qrCode !== "") {
        setImageQr(result?.qrCode)
      }
      else {
        createQrCode()
      }
      if (result?.isOnline === "off") setOpenAddress(true)
      if (result?.enableEndTime) {
        setIsEndTime(true)
        result.endDate = result?.endDate? result?.endDate : endDateTime
        result.endTime = result?.endTime? result?.endTime :  moment(endDateTime).format('HH:mm')
      }
      else {
        delete result?.endDateTime
      }
      if (result?.eventSchedules && result?.eventSchedules?.length) {
        const dataSchedules = result?.eventSchedules
        let addDataSchedules = []
        for (let i in dataSchedules) {
          addDataSchedules.push({ ...dataSchedules[i], endTime: moment(dataSchedules[i].endDateTime).format('HH:mm') })
        }
        setListShedule(addDataSchedules)
      }
      setImageID(result?.image)
      result.startTime = result?.startTime? result?.startTime : moment(startDateTime).format('HH:mm')
      result.startDate = result?.startDate? result?.startDate : startDateTime
      if (Object.keys(result.address).length > 2) {
        setAddress(result.address)
      }
      result.address = result.address.address || ""
      reset(result)
      setListUser({ listdata: result.listUser, evenTitle: result?.eventTitle })
      const register = JSON.parse(localStorage.getItem("FORM_REGISTER_EVENT") as any)
      if(register && register?.length>0){
        setFormRegister(register)
      }else setFormRegister(result?.requiredRegister || formRegister)
    }
    else{
      fetchData()
    }
  }

  useEffect(() => {
    if (id) {
      if(localStorage.getItem("FORM_ADD_EVENT")){
        getDetailEvent()
      }else{
        fetchData()
      }
    }
    
  }, [refData, id])

  //address modal
  const [validAddress, setValidAddress]: any = useState(false)
  const dataRef: any = React.useRef({ address: {} })
  const nameAddressRef: any = React.useRef("")
  const handleChangeAddress = (type: any, data: any) => {
    dataRef.current["address"][type] = data;
  };

  const handleCloseAddress = () => {
    setOpenModalAddress(false)
  }

  const submitAddress = async () => {
    const nameAddress = nameAddressRef.current.value
    if (nameAddress === "" || !nameAddress) {
      setValidAddress(true)
    }
    else {
      const province = dataRef.current["address"]["province"] || "79"
      const district = dataRef.current["address"]["district"] || "785"
      try {
        const { data: { result } }: any = await axios.get(`/core-service/provinces/${province}/districts`)
        // if (Array.isArray(result)) {
        //   const getDistrict = result.find(element => element.id === district);
        //   const fullAddress = nameAddress + " , " + getDistrict.name + " , " + getDistrict.provinceDetail.name
        //   setAddress({ province, district, address: fullAddress })
        //   setValue("address", fullAddress)
        //   setOpenModalAddress(false)
        // }
        setAddress({ province, district, address: nameAddress })
        setValue("address", nameAddress)
      } catch (error) {
        console.log("error", error)
      }
    }
  }

  const handleNameAddress = () => {
    if (nameAddressRef !== "") {
      setValidAddress(false)
    }
  }

  const handleConnect = () => {
    const REDIRECT_URL = window.location.href
    window.open(`https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_CLIENT_ID}&response_type=code&redirect_uri=${encodeURIComponent(GOOGLE_REDIRECT_URL||"")}&scope=${encodeURIComponent(GOOGLE_SCOPES||"")}&state=${encodeURIComponent(REDIRECT_URL)}&prompt=consent&access_type=offline`, '_self')
  }

  const validateEventTitle = (value : any) => {
    if(typeSubmitRef.current === "publish" && !value){
      return false;
    }
    return true;
  }
  
  //Drag item schedule
  const [focusIndex, setFocusIndex]: any = useState(null)
  const sortDataRef: any = React.useRef(null)
  // const handleArrangeData = (index: any) => {
  //   setListShedule(() => sortDataRef.current)
  //   setFocusIndex(index)
  // };

  const reorder = (startIndex:any, endIndex:any) => {
    const result = listShedule
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const handleArrangeData = (result:any) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const items = reorder(
      result.source.index,
      result.destination.index
    );

    setListShedule(items)
    setFocusIndex(result.destination.index)
  };

  const handleChangeTmp = (data: any, index: any, type: any) => {
    let getTime = listShedule[index]//sortDataRef.current[index] || l
    if (type === "date") {
      getTime.endDateTime = (new Date(data)).toISOString()
      listShedule.splice(index, 1, getTime)
    }
    else if (type === "time") {
      getTime.endTime = data
      listShedule.splice(index, 1, getTime)
    }
    else {
      getTime.note = data

      //split html to get text
      const splitLeft = data.split("<")
      let result: any = []
      for (let i in splitLeft) {
        const splitRight = splitLeft[i].split(">")
        if (splitRight.length > 1) result.push(splitRight[1])
        else result.push(splitRight[0])
      }
      //split html to get tex//
      const dataLength = result.join('')
      const getPosition = errorDescription.indexOf(`${index}`)
      if (dataLength?.length <= 1000) {
        listShedule.splice(index, 1, getTime)
        if (getPosition > -1) {
          errorDescription.splice(getPosition, 1)
          setErrorDescription(errorDescription)
        }
      }
      else {
        if (getPosition === -1) {
          errorDescription.push(`${index}`)
          setErrorDescription(errorDescription)
        }
      }
    }
    setListShedule(() => listShedule)
    setUpdateSchedule(!updateSchedule)
  }

 
  
  useEffect(() => { }, [updateSchedule])

  return (
    !errorPage && <div className="add-event-wrapper">
      <Grid container direction="row" alignItems="center" justifyContent="space-between" className="header-add-event" style={{flexWrap:"nowrap"}}>
        <Grid item>
          <div className="exit-page">
            <IconButton
              onClick={() =>
                {
                  // localStorage.removeItem("FORM_ADD_EVENT")
                  // localStorage.removeItem("FORM_REGISTER_EVENT")
                  history.push(`/${localStorage.getItem(ENTERPRISE_URL)}/event`)
                }
                
              }
              className="exit-btn"
              disableFocusRipple
              disableRipple
            >
              <img src={CloseIcon} alt={CloseIcon} />
              <span>Thoát</span>
            </IconButton>
          </div>
        </Grid>
        <Grid item style={{flex:1}}>
          <div className="event-header">
            <Tabs
              value={tab}
              onChange={handleTabChange}
              indicatorColor="primary"
            >
              <Tab label="Sự kiện" className="tab" {...a11yProps(0)} />
              <Tab label="Danh sách tham gia" className="tab" {...a11yProps(1)} />
            </Tabs>
          </div>
        </Grid>
        <Grid item>
          <Button 
            onClick={()=>setInviteModal(true)}
            startIcon={<ShareIcon />}
            classes={{
              disabled: "share-btn--disable"
            }}
            style={{
              color: 'var(--clr-primary)'
            }}
            >
              Gửi sự kiện
          </Button>
        </Grid>
        <Grid item>
          <Button
            className="setting"
            onClick={() => history.push(`/${localStorage.getItem(ENTERPRISE_URL)}/event/${id}/model-register`)}
            disableFocusRipple
            disableRipple
          >
            <img src={FormIcon} alt="" /> 
            <span className="setting_label">Tạo mẫu đăng ký</span>
          </Button>
        </Grid>
      </Grid>

      {/* header add event mobile */}
      <Grid container direction="row" alignItems="center" justifyContent="space-between" className="header-add-event-mobile">
        <Grid item xs={3}>
          <div className="exit-page">
            <IconButton
              onClick={() =>
                {
                  // localStorage.removeItem("FORM_ADD_EVENT")
                  // localStorage.removeItem("FORM_REGISTER_EVENT")
                  history.push(`/${localStorage.getItem(ENTERPRISE_URL)}/event`)
                }
              }
              className="exit-btn"
            >
              <img src={CloseIcon} alt={CloseIcon} />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className="setting">
            <Button
              onClick={() => history.push(`/${localStorage.getItem(ENTERPRISE_URL)}/event/${id}/model-register`)}
            >
              <img src={FormIcon} alt={FormIcon} />
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} className="event-header-mobile">
        <div className="event-header">
          <Tabs
            value={tab}
            onChange={handleTabChange}
            indicatorColor="primary"
          >
            <Tab label="Sự kiện" className="tab" {...a11yProps(0)} />
            <Tab label="Danh sách tham gia" className="tab" {...a11yProps(1)} />
          </Tabs>
        </div>
      </Grid>


      <TabPanel value={tab} index={0}>
        <Grid item xs={12}>
          <form className="box-event" onSubmit={handleSubmit(onSubmit)}>
            <div className="box-event-wrap">
              <div className="image-upload">
                <ImageUploading
                  onChange={onChangeImage}
                  loading={loading}
                  onChangeLoading={onChangeLoading}
                  imageData={imageID}
                />
                {/* <img className="image-qr" src={imageQr} alt="QR Img" /> */}
              </div>
              <div className="add-event-content">
                <Controller
                  name='eventTitle'
                  control={control}
                  render={({ onChange, onBlur, value }) => (
                    <TextField
                      placeholder="Tên sự kiện"
                      multiline
                      type='text'
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      fullWidth
                      error={Boolean(errors?.eventTitle)}
                      helperText={errors?.eventTitle?.message}
                      className="input-title"
                    />
                  )}
                />
                <Grid container>
                  <Grid item xs={12} className="description">
                    <img src={noteIcon3} alt="" />
                    <Controller
                      name='note'
                      control={control}
                      render={({ onChange, onBlur, value }) => (
                        <TextField
                          type="text"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          placeholder="Mô tả ngắn gọn"
                          variant="standard"
                          multiline
                          fullWidth
                          minRows={4}
                          error={Boolean(errors?.note)}
                          helperText={errors?.note?.message}
                          style={{
                            background: "#F6F7FA",
                            borderRadius: "4px",
                            padding: "8px 4px 8px 16px"
                          }}
                          InputProps={{
                            disableUnderline: true
                          }}
                          InputLabelProps={{
                            style: { fontSize: 16 }
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: "16px" }}>
                    <Grid item container xl={6} lg={6} md={6} sm={12} xs={12} spacing={1} alignItems="center" className="start-end-time">
                      <Grid item><img src={calendar} alt="" style={{ paddingTop: "6px" }} /></Grid>
                      <Grid item className="title-content"><p>Thời gian bắt đầu</p></Grid>
                      <Grid item className="time-content">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Controller
                            name='startTime'
                            control={control}
                            defaultValue={getDate}
                            render={({ onChange, onBlur, value }) => (
                              <TextField
                                onChange={onChange}
                                value={value}
                                type="time"
                                className="general-time"
                                InputProps={{
                                  style: { fontSize: 14 },
                                }}
                                size="small"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item>
                        <LocalizationProvider dateAdapter={MomentAdapter}>
                          <Controller
                            name='startDate'
                            control={control}
                            render={({ onChange, onBlur, value }) => (
                              <MobileDatePicker
                                views={['date']}
                                value={value}
                                onChange={(e)=>(onChange(e),setIsChange(!isChange))}
                                renderInput={(props) => <TextField
                                  {...props}
                                  id="startDate"
                                  className="general-time"
                                  size="small"
                                  InputProps={{
                                    style: { fontSize: 14 },
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <label htmlFor="startDate">
                                          <img src={arrowDownIcon} alt={arrowDownIcon} />
                                        </label>
                                      </InputAdornment>
                                    )
                                  }}
                                  style={{ width: 100 }} />}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    <Grid item container xl={6} lg={6} md={6} sm={12} xs={12}  spacing={1} alignItems="center" className=" start-end-time">
                      <Grid item>
                        <Controller
                          name="enableEndTime"
                          control={control}
                          render={({ onChange, onBlur, value }: any) => (
                            // <Checkbox
                            //   color='primary'
                            //   icon={<CircleOutlinedIcon />}
                            //   checkedIcon={<RadioButtonCheckedIcon />}
                            //   onChange={(e: any) => {
                            //     onChange(e.target.checked);
                            //     setIsEndTime(!isEndTime)
                            //   }}
                            //   checked={value}
                            //   style={{ padding: "9px 0px" }}
                            // />
                            <Switch 
                              color='primary' 
                              onChange={(e: any) => {
                                onChange(e.target.checked);
                                setIsEndTime(!isEndTime)
                              }}
                              checked={value}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item className="title-content title-content-left"><p>Kết thúc</p></Grid>
                      {
                        isEndTime && <>
                          <Grid item className="time-content">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Controller
                                name='endTime'
                                control={control}
                                defaultValue={moment(getDate).format('HH:mm')}
                                render={({ onChange, onBlur, value }) => (
                                  <TextField
                                    onChange={onChange}
                                    value={value}
                                    type="time"
                                    className="general-time"
                                    InputProps={{
                                      style: { fontSize: 14 },
                                    }}
                                    size="small"
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item>
                            <LocalizationProvider dateAdapter={MomentAdapter}>
                              <Controller
                                name='endDate'
                                defaultValue={getDate}
                                control={control}
                                render={({ onChange, onBlur, value }) => (
                                  <MobileDatePicker
                                    views={['date']}
                                    value={value}
                                    onChange={(e)=>(onChange(e),setIsChange(!isChange))}
                                    renderInput={(props) => <TextField
                                      {...props}
                                      id="endDate"
                                      className="general-time"
                                      size="small"
                                      InputProps={{
                                        style: { fontSize: 14 },
                                        readOnly: true,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <label htmlFor="endDate">
                                              <img src={arrowDownIcon} alt={arrowDownIcon} />
                                            </label>
                                          </InputAdornment>
                                        )
                                      }}
                                      style={{ width: 100 }}
                                    />}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </>
                      }

                    </Grid>
                  </Grid>

                  <Grid item xs={12} className="format">
                    <div className="title">
                      <img src={FormatIcon} alt="" />
                      <p>Hình thức tổ chức</p>
                    </div>
                    <Controller
                      rules={{ required: true }}
                      control={control}
                      name='isOnline'
                      render={({ onChange, onBlur, value }) => (
                        <RadioGroup
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          row
                          className="format-wrap"
                        >
                          <FormControlLabel
                            value="on"
                            control={<Radio color="primary" />}
                            // label="Online"
                            className="radio-item"
                            onChange={() => setOpenAddress(false)}
                            label={<Typography style={{ fontSize: "16px", fontFamily: 'Roboto-Regular' }}>Online</Typography>}
                          />
                          <FormControlLabel
                            value="off"
                            control={<Radio color="primary" />}
                            label={<Typography style={{ fontSize: "16px", fontFamily: 'Roboto-Regular' }}>Offline</Typography>}
                            onChange={() => setOpenAddress(true)}
                          />
                        </RadioGroup>
                      )}
                    />
                  </Grid>

                  {
                    openAddress
                      ? <Grid item xs={12} className="format address">
                        <img style={{ width: 24, height: 24, marginTop: "8px" }} src={PlaceIcon} alt=""/>
                        <Controller
                          control={control}
                          defaultValue=""
                          render={({ onChange, onBlur, value }) => (
                            <TextField
                              onChange={onChange}
                              onBlur={onBlur}
                              label='Địa điểm sự kiện'
                              fullWidth
                              value={value}
                              error={address.address === "" && Boolean(errors?.address)}
                              helperText={address.address === "" && errors?.address?.message}
                              className='field__input'
                              InputProps={{
                                // readOnly: true,
                                style: { fontSize: 14, fontFamily: 'Roboto-Regular' }
                              }}
                              InputLabelProps={{ style: { fontSize: 16, top: "-5px", fontFamily: 'Roboto-Regular' } }}
                              // onClick={() => {
                              //   setOpenModalAddress(true);
                              //   setValidAddress(false)
                              // }}
                            />
                          )}
                          name='address'
                        />
                      </Grid>
                      : <>
                        <Grid item xs={12} className="format select-online-items">
                          <Controller
                            control={control}
                            name="onlineItems"
                            defaultValue="google"
                            render={({ onChange, onBlur, value }) => (
                              <Select
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                className="select-wrap"
                              >
                                <MenuItem value="google">
                                  <div className="online-items">
                                    <img src={GoogleMeetImageIcon} alt='GoogleMeetImage' />   &ensp; Google Meet
                                  </div>
                                </MenuItem>
                                {/* <MenuItem value="team">
                                  <div className="online-items">
                                    <img src={MicrosoftTeamIcon} alt='MicrosoftTeam' />   &ensp; Microsoft Team
                                  </div>
                                </MenuItem> */}
                              </Select>
                            )}
                          />
                        </Grid>
                        {
                          notConnected && <Grid
                            xs={12}
                            spacing={2}
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            className="format select-online-items"
                            style={{ marginTop: "5px" }}
                          >
                            <Grid item xs={12} sm={9}>
                              <span style={{ fontSize: "14px" }}>
                                Tài khoản này chưa được liên kết với Google Meet. Nhấn kết nối để
                                tiếp tục
                              </span>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <Button
                                onClick={handleConnect}
                                startIcon={<CachedOutlinedIcon />}
                                color="primary"
                                variant="contained"
                                style={{ textTransform: "none", fontFamily: 'Roboto-Regular', color: "#CEE8FF" }}
                              >
                                Kết nối
                              </Button>
                            </Grid>
                          </Grid>
                        }
                      </>

                  }
                  <Grid item xs={12} className="plan">
                    <img src={planIcon} alt="" style={{ paddingTop: "8px" }} />
                    <div className="add-plan">
                      <p className="title">Lịch trình sự kiện</p>

                      {
                        <DragDropContext onDragEnd={handleArrangeData} onDragStart={()=>{
                          // if (window.navigator.vibrate) {
                          //   window.navigator.vibrate(10);
                          // }
                        }}>
                          <Droppable droppableId="droppable" >
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {listShedule.map((item:any, index:any) => (
                                  <Draggable key={item?.endTime+`${index}`} draggableId={item?.endTime+`${index}`} index={index}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <div className={`timeline-item ${index === focusIndex ? "active-item" : ""}`}
                                          onClick={() => (index !== focusIndex) && setFocusIndex(index)}
                                        >
                                          <div className="time-date">
                                            <Grid item container direction="row" justifyContent="space-between">
                                              <Grid item container xs={11} spacing={2} alignItems="center" className=" start-end-time">
                                                <Grid item>
                                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                  <TextField
                                                    id={`${index}endTime`}
                                                    onChange={(e) => handleChangeTmp(e.target.value, index, "time")}
                                                    value={item?.endTime || getDate}
                                                    type="time"
                                                    className="general-time"
                                                    InputProps={{
                                                      style: { fontSize: 14 },
                                                    }}
                                                    size="small"
                                                  />
                                                  </LocalizationProvider>
                                                </Grid>
                                                <Grid item>
                                                  <LocalizationProvider dateAdapter={MomentAdapter}>
                                                    <MobileDatePicker
                                                      views={['date']}
                                                      value={item?.endDateTime || getDate}
                                                      onChange={(e) => handleChangeTmp(e, index, "date")}
                                                      renderInput={(props) => <TextField
                                                        {...props}
                                                        id={`${index}endDateTime`}
                                                        className="general-time"
                                                        size="small"
                                                        InputProps={{
                                                          style: { fontSize: 14 },
                                                          readOnly: true,
                                                          endAdornment: (
                                                            <InputAdornment position="end">
                                                              <label htmlFor={`${index}endDateTime`}>
                                                                <img src={arrowDownIcon} alt={arrowDownIcon} />
                                                              </label>
                                                            </InputAdornment>
                                                          )
                                                        }}
                                                        style={{ width: 100 }}
                                                      />}
                                                    />
                                                  </LocalizationProvider>
                                                </Grid>
                                              </Grid>
                                              <Grid item container direction="row" xs={1} justifyContent="flex-end">
                                                <IconButton onClick={() => {
                                                  listShedule.splice(index, 1)
                                                  setListShedule(listShedule)
                                                  setUpdateSchedule(!updateSchedule)
                                                }}>
                                                  <img src={deleteGrayIcon} alt={deleteGrayIcon} style={{ margin: 0 }} />
                                                </IconButton>
                                              </Grid>
                                            </Grid>
                                          </div>
                                          <div className={`note ${errorDescription.includes(`${index}`) ? "warning-errors" : ""}`}>
                                            <DetailDescription
                                              index={index}
                                              onChange={handleChangeTmp}
                                              value={item?.note || ""}
                                              placeholder="Nội dung"
                                            />
                                            {
                                              errorDescription.includes(`${index}`) && <p className="warning-error">Vui lòng nhập tối đa 1000 ký tự</p>
                                            }
                                          </div>
                                          {
                                            index === focusIndex && <img className="drag-item" src={dragIcon} alt={dragIcon} />
                                          }
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      }
                      <Button
                        style={{ textTransform: "none", lineHeight: "24px", fontFamily: 'Roboto-Regular' }}
                        variant="outlined"
                        color="primary"
                        onClick={() => setListShedule([...listShedule, { endTime: moment(getDate).format('HH:mm'), endDateTime: getDate, note: "" }])}
                      >
                        Thêm lịch trình <AddIcon color="primary" style={{ paddingLeft: "5px" }} />
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <div className="action-add">
                  <Button 
                    disabled={disableBtn} 
                    onClick={()=> typeSubmitRef.current = ""} className="action-add-btn" type='submit' color="primary" variant="outlined"
                  >
                    Lưu nháp
                  </Button>
                  <Button 
                    disabled={disableBtn} 
                    onClick={()=> typeSubmitRef.current = "publish"} className="action-add-btn action-btn-publish" type='submit' color="primary" variant="contained"
                  >
                    Đăng
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Grid>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Grid item xs={12}>
          <div className="wrap-mobile">
            <ListUser listUser={listUser} refreshData={refreshData} id={id} />
          </div>
        </Grid>
      </TabPanel>


    
      {/* Modal register event */}
      <ModalFormRegister
        id={id}
        formRegister={formRegister}
        open={openModalRegister}
        close={() => setOpenModalRegister(false)}
        setFormRegister={setFormRegister}
      />
      {/* add address */}
      <Modal
        open={openModalAddress}//openModalAddress
        onClose={handleCloseAddress}
        className="popover-room"
      >
        <div className="popover-wrapper popover-wrapper-address">
          <div className="form-checkIn modal-address">
            <h2 className="title">Địa điểm sự kiện</h2>
            <Grid
              container
              spacing={1}
              alignItems="center"
              className="name-address"
            >
              <Grid item className="sp-except" >
                <img src={homeIcon} alt="ico" />
              </Grid>
              <Grid item className="content-address">
                <TextField
                  name="address"
                  label="Địa chỉ"
                  // defaultValue={address?.address?.split(",")[0] || ""}
                  defaultValue={address?.address ?? ""}
                  onChange={handleNameAddress}
                  inputRef={nameAddressRef}
                  helperText={validAddress && "Vui lòng nhập địa chỉ"}
                  error={validAddress}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              alignItems="center"
            >
              <ProvinceUI
                onChangeAddress={handleChangeAddress}
                provinceDefault={address?.province || "79"}
                districtDefault={address?.district || "785"}
              />
            </Grid>
            <Grid className="action" container justifyContent="flex-end">
              <Button className="action-btn cancel" onClick={() => setOpenModalAddress(false)}>
                Hủy
              </Button>
              <Button className="action-btn save" variant="contained" color="primary" onClick={submitAddress}>
                Lưu
              </Button>
            </Grid>
          </div>
        </div>
      </Modal>

      <InviteModal
        id={id}
        onCloseInviteModal={()=>setInviteModal(false)}
        openInviteModal={inviteModal}
        onSuccess = {()=>setInviteSuccessModal(true)}
        openUploadListUser = {()=>setUploadListUser(true)}
      />

      <InviteSuccessModal
        onCloseInviteSuccessModal={()=>setInviteSuccessModal(false)}
        openInviteSuccessModal={inviteSuccessModal}
      />

    </div>
  );
}

export default AddEvent;
