import ActionType from "./action-types";
import {
  IForgotPasswordCredentials,
  IForgotPasswordSuccess,
} from "@Interfaces/credentials";
export const forgotPasswordAction = (
  reCaptchaToken: string,
  credentials: IForgotPasswordCredentials,
  callbackSuccess: Function,
  callbackError: Function
) => ({
  type: ActionType.FORGOT_START,
  reCaptchaToken,
  credentials,
  callbackSuccess,
  callbackError,
  payload: null,
  error: null,
});
export const forgotPasswordSuccessAction = (
  result: IForgotPasswordSuccess
) => ({
  type: ActionType.FORGOT_SUCCESS,
  payload: result,
  error: null,
});
export const forgotPasswordErrorAction = (error: unknown) => ({
  type: ActionType.FORGOT_ERROR,
  payload: null,
  error: error,
});
