export const profileConfigRender =
    {
      id: "2",
      title: "Thông tin liên hệ",
      rowConfig: [
        {
            name: "Tên công ty",
            type: "CHANGE_NAME",
            popoverTitle: "Tên công ty",
            keyValue: "name",
          },
        {
            name: "Mã số thuế",
            type: "CHANGE_TAX",
            className: "tax",
            popoverTitle: "Mã số thuế",
            keyValue: "tax",
          },
        {
          name: "Điện thoại",
          type: "CHANGE_PHONE",
          popoverTitle: "Điện thoại",
          keyValue: "phone",
        },
        {
          name: "Email",
          type: "CHANGE_EMAIL",
          popoverTitle: "Email",
          keyValue: "email",
        },
        {
          name: "Địa chỉ",
          className: "address",
          type: "CHANGE_ADDRESS",
          popoverTitle: "Địa chỉ",
          keyValue: "address",
        },
      ],
    };
  export const typesPopover = {
    CHANGE_TAX: "CHANGE_TAX",
    CHANGE_ADDRESS: "CHANGE_ADDRESS",
    CHANGE_NAME: "CHANGE_NAME",
    CHANGE_PHONE: "CHANGE_PHONE",
    CHANGE_EMAIL: "CHANGE_EMAIL",
  };
  