import { loginActiontest } from "@Actions/Login/action";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import PrivateRoute from "./PrivateRoute"
import PublicRoute from "./PublicRoute"
interface IRouteWithLayoutProps {
  page: any;
  layout: any;
  path:string,
  isPrivate:boolean,
  authenticatedRedirect:boolean,
  rest?: any;
}
function RouteWithLayout(props:IRouteWithLayoutProps){
  const LOGIN_REDIRECT_URL = process.env.REACT_APP_LOGIN_REDIRECT_URL
  const { page,layout,path,isPrivate,authenticatedRedirect,...rest}= props;
  const dispatch = useDispatch()
  const location = useLocation()
  const token = new URLSearchParams(location.search).get('token') || new URLSearchParams(location.search).get('sid')
  const id = new URLSearchParams(location.search).get('id')
  const { loginReducer } = useSelector((state: any) => ({
    loginReducer: state.loginReducer,
  }));
  const  isAuthenticated  = loginReducer?.get("payload")?.get("result")?.get("token");  
    useEffect(() => {
      if((!isAuthenticated && !token) && isPrivate) {
        // window.open(`${LOGIN_REDIRECT_URL}?redirect=${window.location.origin}`,'_self')
        window.open(`${LOGIN_REDIRECT_URL}/login?context=qrcode-app&redirect=${window.location.origin}`, '_self')
      }
    },[isAuthenticated,token, LOGIN_REDIRECT_URL, isPrivate])
    
  useEffect(() => {
    if(token) {
      dispatch(loginActiontest({
        "message": "Authentication success",
        "result": {
            "token": token,
            "id": id,
        }
    }))
      
    }
  },[dispatch, id, token])
  return (
    isPrivate?<PrivateRoute page={page} path={path} layout={layout} {...rest} />:<PublicRoute  page={page} path={path} layout={layout} authenticatedRedirect={authenticatedRedirect} {...rest} />
  );
}
export default RouteWithLayout;