import ActionType from "./action-types";
// Add user
export const addUserAction = (
  data: any,
  callbackSuccess: Function,
  callbackError: Function
) => ({
  type: ActionType.ADD_USER_EVENT_START,
  data,
  callbackSuccess,
  callbackError,
  payload: null,
  error: null,
});

export const addEventUserSuccessAction = (result: any) => ({
  type: ActionType.ADD_USER_EVENT_SUCCESS,
  payload: result,
  error: null,
});

export const addEventUserErrorAction = (error: unknown) => ({
  type: ActionType.ADD_USER_EVENT_ERROR,
  payload: null,
  error: error,
});

// Get list user

export const getListUserEventAction = (
  prams: any,
  callbackSuccess: Function
) => ({
  type: ActionType.GET_LIST_USER_EVENT_START,
  prams,
  callbackSuccess,
  payload: null,
  error: null,
});

export const getListEventUserSuccessAction = (result: any) => ({
  type: ActionType.GET_LIST_USER_EVENT_SUCCESS,
  payload: result,
  error: null,
});

export const getListEventUserErrorAction = (error: unknown) => ({
  type: ActionType.GET_LIST_USER_EVENT_ERROR,
  payload: null,
  error: error,
});

// Get list role

export const getListEventRoleAction = (
  data: any,
  callbackSuccess: Function
) => ({
  type: ActionType.GET_LIST_ROLE_EVENT_START,
  data,
  callbackSuccess,
  payload: null,
  error: null,
});

export const getListEventRoleSuccessAction = (result: any) => ({
  type: ActionType.GET_LIST_ROLE_EVENT_SUCCESS,
  payload: result,
  error: null,
});

export const getListEventRoleErrorAction = (error: unknown) => ({
  type: ActionType.GET_LIST_ROLE_EVENT_ERROR,
  payload: null,
  error: error,
});

// Update User Event

export const updateUserEventAction = (
  selectedUserID: any,
  selectedRole: any,
  callbackSuccess: Function
) => ({
  type: ActionType.UPDATE_USER_EVENT_START,
  selectedUserID,
  selectedRole,
  callbackSuccess,
  payload: null,
  error: null,
});

export const updateUserEventSuccessAction = (result: any) => ({
  type: ActionType.UPDATE_USER_EVENT_SUCCESS,
  payload: result,
  error: null,
});
export const updateUserEventErrorAction = (error: unknown) => ({
  type: ActionType.UPDATE_USER_EVENT_ERROR,
  payload: null,
  error: error,
});

// Delete User

export const deleteUserEventAction = (
  selectedUserID: any,
  callbackSuccess: Function
) => ({
  type: ActionType.DELETE_USER_EVENT_START,
  selectedUserID,
  callbackSuccess,
  payload: null,
  error: null,
});

export const deleteUserEventSuccessAction = (result: any) => ({
  type: ActionType.DELETE_USER_EVENT_SUCCESS,
  payload: result,
  error: null,
});
export const deleteUserEventErrorAction = (error: unknown) => ({
  type: ActionType.DELETE_USER_EVENT_ERROR,
  payload: null,
  error: error,
});
// Get permission

export const getPermissionAction = (prams: any, callbackSuccess: Function) => ({
  type: ActionType.GET_PERMISSION_EVENT_START,
  prams,
  callbackSuccess,
  payload: null,
  error: null,
});

export const getEventPermissionSuccessAction = (result: any) => ({
  type: ActionType.GET_PERMISSION_EVENT_SUCCESS,
  payload: result,
  error: null,
});
export const getEventPermissionErrorAction = (error: unknown) => ({
  type: ActionType.GET_PERMISSION_EVENT_ERROR,
  payload: null,
  error: error,
});
