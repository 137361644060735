import { IAction } from "@Interfaces/common";
import ActionType from "@Actions/UserNotification/action-types";
import { fromJS } from 'immutable';

const initialState = fromJS({
    type: null,
    isLoading: false,
    payload: null,
    error: null,
});
export default (state: any = initialState, action: IAction) => {
    switch (action.type) {
        case ActionType.GET_USER_NOTIFICATION_LIST_START:
            return state
                .set("type", fromJS(action.type))
                .set('isLoading', true)
                .set('error', null)
        case ActionType.GET_USER_NOTIFICATION_LIST_SUCCESS:
            return state
                .set("type", fromJS(action.type))
                .set('isLoading', false)
                .set('payload', fromJS(action.payload))
                .set('error', null)
        case ActionType.GET_USER_NOTIFICATION_LIST_ERROR:
            return state
                .set("type", fromJS(action.type))
                .set('isLoading', false)
                .set('error', fromJS(action.error))
        case ActionType.MARK_AS_READ_START:
            return state
                .set("type", fromJS(action.type))
                .set('error', fromJS(action.error))
        case ActionType.MARK_AS_READ_SUCCESS:
            return state
                .set("type", fromJS(action.type))
                .set('error', null)
        case ActionType.MARK_AS_READ_ERROR:
            return state
                .set("type", fromJS(action.type))
                .set('error', null)
        case ActionType.REMOVE_NOTIFICATION_START:
            return state
                .set("type", fromJS(action.type))
                .set('error', fromJS(action.error))
        case ActionType.REMOVE_NOTIFICATION_SUCCESS:
            return state
                .set("type", fromJS(action.type))
                .set('error', null)
        case ActionType.REMOVE_NOTIFICATION_ERROR:
            return state
                .set("type", fromJS(action.type))
                .set('error', null)
        default:
            return state;
    }
};
