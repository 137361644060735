import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import apiConfig from "@Configs/api/api.config";
import { Api } from "@Configs/api/api";
import  {encodeBase64} from "@Helpers/index";
import {ILoginCredentials} from "@Interfaces/credentials"

import { API_LOGIN,API_GET_PROFILE} from "./endpoint";

class BaseApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config);
    this.loginUser = this.loginUser.bind(this);
    this.getProfile = this.getProfile.bind(this);
  }
  public loginUser(reCaptchaToken: string, credentials: ILoginCredentials): Promise<any> {

    return this.post<any>(API_LOGIN, JSON.stringify({}), {
      headers: {
        Authorization: `Basic ${encodeBase64(
          `${credentials.username}:${credentials.password}`
        )}`,
        "x-recaptcha-token": reCaptchaToken,
      },
    })
      .then((response: AxiosResponse<any>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }
  public getProfile(token: string, uid:string): Promise<any> {

    return this.get<any>(API_GET_PROFILE,{
      headers:{
          'Authorization': `Bearer ${token}`,
          "x-uid":uid
      }
  })
      .then((response: AxiosResponse<any>) => {
          const { data } = response;
          return data;
      })
      .catch((error: AxiosError) => {
          throw error;
      });
    }
}

 const baseApi = new BaseApi(apiConfig);
 export default baseApi ;
