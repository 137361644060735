import EmailIcon from "@Assets/icons/event/email.svg";
import NoteIcon from "@Assets/icons/event/note.svg";
import PlaceIcon from "@Assets/icons/event/place.svg";
import SmartphoneIcon from "@Assets/icons/event/smartphone.svg";
import UserIcon from "@Assets/icons/event/user2.svg";
import {
  Checkbox,
  Dialog,
  Switch,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  Grid
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import "./style.scss";
import { useFieldArray, Controller, useForm } from "react-hook-form"
import { useDispatch } from "react-redux";
import * as action from "@Actions/Event/action";
import baseApi from "@Apis/Event/api";
import { makeStyles } from '@material-ui/core/styles';
import { ChangeToSlug } from "@Helpers/Slug";


const useStyles = makeStyles(theme => ({
    dialogPaper: {
      minWidth : '300px'
    },
}));

interface IModalFormRegister {
  open: boolean;
  close: () => void;
  formRegister: any;
  id: any;
  setFormRegister?: any;
}

const ModalFormRegister: React.FC<IModalFormRegister> = (props) => {
  const dispatch = useDispatch();
  const { open, formRegister, close, id, setFormRegister } = props;
  const [openAddField, setOpenAddField] = useState(false);
  const [checkError,setCheckError] = useState(false);
  const [fieldName, setFieldName]: any = useState("")
  const [typeField,setTypeField]:any = useState("text")
  const { handleSubmit, reset, control } = useForm({
    defaultValues: {
      formRegister,
      addFormRegister:[]
    }
  })

  const { fields, append } = useFieldArray({
    control,
    name: "addFormRegister"
  })

  const callbackSuccess = (data: any) => close()
  const callbackError = () => {};

  const onSubmit = async(values: any)=>{
    if(id){
      dispatch(action.updateEventAction({requiredRegister:values},id, callbackSuccess, callbackError))
    }
    else{
      setFormRegister(values)
      close()
    }
  }

  const fetchData = async()=>{
    try {
      const {result}:any = await baseApi.getDetailEvent(id)
      reset(result?.requiredRegister || formRegister)
    } catch (error) {
      console.log("error fetchData event detail",error);
    }
  }

  useEffect(()=>{
    if(id && open===true){
      fetchData()
    }
    else reset(formRegister)
  },[open, id, reset])

  const classes = useStyles()

  const handleAdd = ()=>{
    if(fieldName!==""){
      append({
        required: true,
        show: true,
        type: typeField,
        title: fieldName,
        name: ChangeToSlug(fieldName)
      })
      setOpenAddField(false)
    }
    else{
      setCheckError(true)
    }
  }

  const handleCloseAddFiled = ()=>{
    setOpenAddField(false)
  }

  const handleGetName =(e: any)=>{
    const value: any = e.target.value
    
    if(value && value!==""){
      setFieldName(value)
      setCheckError(false)
    }
    else{
      setCheckError(true)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby='form-dialog-title'
      className='event-register'
      maxWidth='md'
      classes={{ paper : classes.dialogPaper}}
    >
      <div className='content'>
        <div className='content__title'>Form đăng ký sự kiện</div>
        <form className='form' onSubmit={handleSubmit(onSubmit)}>
          <div className='field'>
            <div className='field__checkbox'>
              <Controller
                name="name.required"
                control={control}
                defaultValue={true}
                render={({ onChange, onBlur, value }:any) => (
                  <Checkbox
                    color='primary'
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                    disabled
                  />
                )}
              />
            </div>
            <img src={UserIcon} alt='' />
            <p>Họ và tên</p>
            <div className='field__switch'>
              <Controller
                name="name.show"
                control={control}
                defaultValue={false}
                render={({ onChange, onBlur, value }:any) => (
                  <Switch
                    color='primary'
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                    disabled
                  />
                )}
              />
            </div>
          </div>

          <div className='field'>
            <div className='field__checkbox'>
              <Controller
                name="email.required"
                control={control}
                defaultValue={true}
                render={({ onChange, onBlur, value }:any) => (
                  <Checkbox
                    color='primary'
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                    disabled
                  />
                )}
              />
            </div>
            <img src={EmailIcon} alt='' />
            <p>Email</p>
            <div className='field__switch'>
              <Controller
                name="email.show"
                control={control}
                defaultValue={false}
                render={({ onChange, onBlur, value }:any) => (
                  <Switch
                    color='primary'
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                    disabled
                  />
                )}
              />
            </div>
          </div>

          <div className='field'>
            <div className='field__checkbox'>
              <Controller
                name="phone.required"
                control={control}
                defaultValue={true}
                render={({ onChange, onBlur, value }:any) => (
                  <Checkbox
                    color='primary'
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                  />
                )}
              />
            </div>
            <img src={SmartphoneIcon} alt='' />
            <p>Số điện thoại</p>
            <div className='field__switch'>
              <Controller
                name="phone.show"
                control={control}
                defaultValue={false}
                render={({ onChange, onBlur, value }:any) => (
                  <Switch
                    color='primary'
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                  />
                )}
              />
            </div>
          </div>

          <div className='field'>
            <div className='field__checkbox'>
              <Controller
                name="address.required"
                control={control}
                defaultValue={true}
                render={({ onChange, onBlur, value }:any) => (
                  <Checkbox
                    color='primary'
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                  />
                )}
              />
            </div>
            <img src={PlaceIcon} alt='' />
            <p>Địa chỉ</p>
            <div className='field__switch'>
              <Controller
                name="address.show"
                control={control}
                defaultValue={false}
                render={({ onChange, onBlur, value }:any) => (
                  <Switch
                    color='primary'
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                  />
                )}
              />
            </div>
          </div>

          <div className='field note'>
            <div className='field__checkbox'>
              <Controller
                name="note.required"
                control={control}
                defaultValue={true}
                render={({ onChange, onBlur, value }:any) => (
                  <Checkbox
                    color='primary'
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                  />
                )}
              />
            </div>
            <img src={NoteIcon} alt='' />
            <p className='box-note'>Ghi chú</p>
            <div className='field__switch'>
              <Controller
                name="note.show"
                control={control}
                defaultValue={false}
                render={({ onChange, onBlur, value }:any) => (
                  <Switch
                    color='primary'
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                  />
                )}
              />
            </div>
          </div>

          {
            fields.map((item, index)=>(
              <div className={`field ${(item.type==="note" || Object.values(item)[1].type==="note") ? "note":""}`} key={item.id}>
                <div className='field__checkbox'>
                  <Controller
                    name={`addFormRegister.[${index}].${item.name || Object.keys(item)[1]}.required`}
                    control={control}
                    defaultValue={true}
                    render={({ onChange, onBlur, value }:any) => (
                      <Checkbox
                        color='primary'
                        onChange={(e) => onChange(e.target.checked)}
                        checked={value}
                      />
                    )}
                  />
                </div>
                <img src={NoteIcon} alt='' />
                <p className='box-note'>{item.title || Object.values(item)[1].title}</p>
                <div className='field__switch'>
                  <Controller
                    name={`addFormRegister.[${index}].${item.name || Object.keys(item)[1]}.show`}
                    control={control}
                    defaultValue={false}
                    render={({ onChange, onBlur, value }:any) => (
                      <Switch
                        color='primary'
                        onChange={(e) => onChange(e.target.checked)}
                        checked={value}
                      />
                    )}
                  />
                  {/* add attribute fields not show UI*/}
                  <Controller
                    name={`addFormRegister.[${index}].${item.name || Object.keys(item)[1]}.type`}
                    control={control}
                    defaultValue={typeField}
                    render={() => (<></>)}
                  />
                  <Controller
                    name={`addFormRegister.[${index}].${item.name || Object.keys(item)[1]}.title`}
                    control={control}
                    defaultValue={fieldName}
                    render={() => (<></>)}
                  />
                </div>
                {/* <Button
                  className="setting"
                  variant="text"
                  onClick={()=>remove(index)}
                  style={{textTransform:"none", fontFamily:'Roboto-Regular'}}
                >
                  Xóa
                </Button> */}
              </div>
            ))
          }
          <div className="btn-register-form">
            <Button
              className="setting"
              variant="contained" 
              color="primary"
              onClick={()=>{
                setOpenAddField(true);
                setFieldName("");
                setCheckError(false)
              }}
              style={{textTransform:"none", fontFamily:'Roboto-Regular'}}
            >
              Thêm
            </Button>
            <Button
              className="setting"
              variant="text"
              onClick={close}
              style={{textTransform:"none", fontFamily:'Roboto-Regular'}}
            >
              Hủy
            </Button>
            <Button
              variant="contained" 
              type="submit"
              color="primary"
              style={{textTransform:"none", fontFamily:'Roboto-Regular'}}
            >
              Lưu
            </Button>
          </div>
        </form>
      </div>
      <Dialog
        open={openAddField}
        onClose={handleCloseAddFiled}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Thêm yêu cầu cho form đăng ký sự kiện"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
              label='Tên mục yêu cầu'
              type='text'
              onChange={handleGetName}
              fullWidth
              error={checkError}
              helperText={checkError ? "Vui lòng nhập tên mục." : ""}
              className="input-title"
              InputProps={{
                style:{fontSize:18, fontFamily:'Roboto-Regular'}
              }}
              InputLabelProps={{style: {fontSize: 18, fontFamily:'Roboto-Regular'}}}
            />
            </Grid>
            <Grid item xs={12}>
              <Select
                value={typeField}
                style={{width:"100%"}}
                onChange={(e)=>setTypeField(e.target.value)}
              >
                <MenuItem value="text">Chọn định dạng văn bản</MenuItem>
                <MenuItem value="number">Chọn định dạng số</MenuItem>
                <MenuItem value="note">Chọn định dạng ghi chú</MenuItem>
              </Select>
            </Grid>
          </Grid>
            
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddFiled}>Hủy</Button>
          <Button onClick={handleAdd} autoFocus>Thêm</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default ModalFormRegister;
