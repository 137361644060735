import React, { useState, useEffect } from 'react'
import { Box, Button, TextField, Modal, Chip, Avatar, Paper, Typography, Tabs, Tab, Grid, IconButton, InputAdornment } from '@material-ui/core';
import { UploadListUserPropsInterface } from './types';
import PropTypes from 'prop-types';
import CloseIcon from "@material-ui/icons/Close"
import FileIcon from "@Assets/icons/event/FileIcon.svg";
import Template from "@Assets/images/Event/template.png";
import Template2 from "@Assets/images/Event/template2.png";
import styles from './style.module.scss';
import DragDropFile from './DragDropFile';
import { exportToXlsx } from '@Helpers/exportFile/exportXlsxSimple';
import {emailList, phoneList} from "./const";

const UploadListUser = (props: UploadListUserPropsInterface) => {
  const { eventID, onCloseUploadListUser, openUploadListUser, onSuccess } = props;

  const exportXlsx = async(data:any, title:any)=>{
    const dataExport:any = data || []
    const inforUser:any = [];
    for(let i in dataExport){
      inforUser[i] = {
        header: dataExport[i],
      }
    }
    const wscols = [
      { wch: 30 }
    ];
    if(dataExport.length>0) await exportToXlsx(inforUser, title ,wscols)
  }
  
  return (
    <Modal
      open={openUploadListUser}
      onClose={onCloseUploadListUser}
    >
      <Paper
        classes={{
          root: styles.UploadPaper,
        }}>
        <h2 className={styles.title}>
        Tải tệp lên
          <IconButton className={styles.closeBtn} onClick={onCloseUploadListUser}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </h2>

        <div className={styles.content}>
          <DragDropFile
            eventID={eventID}
            onSuccess={onSuccess}
          />

          <div className={styles.template}>
            <Button onClick={()=>exportXlsx(emailList, "Mẫu danh sách khách mời qua email")}>
              <img src={Template} alt="" style={{width: '100%', height: '100%'}} />
            </Button>
            {/* <Button onClick={()=>exportXlsx(emailList, "Mẫu danh sách khách mời qua email")}>
              <img src={Template2} alt="" />
            </Button> */}
          </div>
        </div>
      </Paper>
    </Modal>
  )
}

export default UploadListUser